const taskTypes = [
    {
        value: "generic",
        translation: "TaskType.Generic.long",
        color: "#939597",
        headerColor: "#F4F5F7",
        icon: "flag_20_20.svg",
        showToggleButton: true
    },
    {
        value: "meeting",
        translation: "TaskType.Meeting.long",
        color: "#F81CA0",
        headerColor: "#FFF2F7",
        icon: "calendar_20_20.svg",
    },
    {
        value: "notary_meeting",
        translation: "TaskType.NotaryMeeting.long",
        color: "#4C8DD9",
        headerColor: "#DBE8F7",
        icon: "calendar_20_20.svg",
    },
    {
        value: "delivery_of_premises",
        translation: "TaskType.DeliveryOfPremises.long",
        color: "#FFD702",
        headerColor: "#FFFACC",
        icon: "calendar_20_20.svg",
    },
    {
        value: "client_visit",
        translation: "ClientVisit",
        color: "#2B7B6D",
        headerColor: "#F2FFE0",
        icon: "calendar_20_20.svg"
    },
    {
        translation: "TaskType.ShowingTheObject.long",
        value: "showing_the_object",
        color: "#2B7B6D",
        headerColor: "#F2FFE0",
        icon: "calendar_20_20.svg"
    },
    {
        value: "open_house",
        translation: "OpenHouse",
        color: "#7200E2",
        headerColor: "#F7EEFF",
        icon: "calendar_20_20.svg",
        showAddButton: true
    },
    {
        value: "resource",
        translation: "Resource",
        icon: "pencil_20_20.svg",
        color: "#16AFB9",
        headerColor: "#E8F7F8",
        clickable: true,
        dropdown: true,
    },
    {
        value: "call",
        translation: "TaskType.Call.long",
        color: "#939597",
        headerColor: "#F4F5F7",
        icon: "phone_20_20.svg"
    },
    {
        value: "email",
        translation: "TaskType.Email.long",
        color: "#939597",
        headerColor: "#F4F5F7",
        icon: "email_20_20.svg"
    },
    {
        value: "invoice",
        translation: "TaskType.Invoice.long",
        color: "#939597",
        headerColor: "#F4F5F7",
        icon: "invoice.svg"
    },
    {
        value: "task",
        translation: "TaskType.Task.long",
        color: "#939597",
        headerColor: "#F4F5F7",
        icon: "flag_20_20.svg"
    },
];

module.exports = taskTypes;