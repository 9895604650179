var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-button",
    class: {
      large: _vm.large
    },
    attrs: {
      "id": "edit-button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('clicked');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "edit-button-text"
  }, [_vm._v(_vm._s(_vm.$t("Edit")))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }