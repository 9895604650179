var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notifications"
  }, [_c('div', {
    staticClass: "notifications-button",
    on: {
      "click": _vm.toggleMenu
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/bell.svg"),
      "alt": ""
    }
  }), _vm.newNotificationsCounter ? _c('div', {
    staticClass: "unread-counter"
  }, [_c('p', {
    staticClass: "label10"
  }, [_vm._v(_vm._s(_vm.newNotificationsCounter))])]) : _vm._e()]), _vm.open ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeMenu,
      expression: "closeMenu"
    }],
    staticClass: "notifications-menu"
  }, [_c('div', {
    staticClass: "notifications-wrapper"
  }, [!_vm.notifications.length ? _c('div', {
    staticClass: "empty-notifications"
  }, [_c('p', {
    staticClass: "content-semibold"
  }, [_vm._v(_vm._s(_vm.$t("NotificationsListIsEmpty")))]), _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("YourNotificationsWillAppearHere")) + " ")])]) : _vm._e(), _vm.notifications.length ? _c('div', _vm._l(_vm.notifications, function (notification, index) {
    return _c('div', {
      key: notification._id,
      staticClass: "notification",
      on: {
        "click": function ($event) {
          return _vm.readNotification(notification, index);
        }
      }
    }, [_c('div', {
      class: {
        'notification-status visited': notification.visited,
        'notification-status not-visited': !notification.visited
      }
    }), _c('div', {
      staticClass: "notification-content"
    }, [_c('p', {
      staticClass: "content",
      domProps: {
        "innerHTML": _vm._s(_vm.formatNotificationMessage(notification))
      }
    }), _c('p', {
      staticClass: "content-small notification-date grey900"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(notification.createdAt)) + " ")])])]);
  }), 0) : _vm._e()]), _vm.notifications.length ? _c('div', {
    staticClass: "clear-notifications",
    on: {
      "click": _vm.clearNotifications
    }
  }, [_c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$t("ClearAll")))])]) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }