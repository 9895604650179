var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loaded && _vm.isOpen ? _c('div', {
    key: _vm.listing._id,
    staticClass: "outputs-widget-wrapper"
  }, [_vm.isOpen ? _c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 101,
      "h": "auto",
      "id": 'outputs-draggable',
      "resizable": false,
      "drag-handle": ".drag-area"
    }
  }, [_c('div', {
    staticClass: "output-settings",
    attrs: {
      "id": "outputs-widget"
    }
  }, [_c('div', {
    staticClass: "output-settings-header drag-area"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/menu.svg'),
      "alt": "drag"
    }
  })]), _c('div', {
    staticClass: "output-settings-content"
  }, [_c('div', {
    staticClass: "output-settings-content-header"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Outputs")))]), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.outputsInfo()))]), _c('p', {
    staticClass: "content-small"
  }, [_vm._v(_vm._s(_vm.$t('ExportId') + ": " + _vm.listing.identifier))])]), _c('div', {
    staticClass: "output-settings-content-content"
  }, [!_vm.changeExportAccounts && _vm.hasAccess(_vm.listing) ? _c('p', {
    staticClass: "link-small",
    on: {
      "click": function ($event) {
        _vm.changeExportAccounts = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("ChangeExportAccounts")))]) : _vm._e(), _vm._l(_vm.listing.marketplaces, function (marketplace) {
    return _c('div', {
      key: marketplace.name + '-' + _vm.marketplacesUpdater + '-output',
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "gap": "2px"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "justify-content": "space-between"
      }
    }, [_c('BaseToggle', {
      staticClass: "output-toggle",
      attrs: {
        "green": true,
        "label": marketplace.label || marketplace.name
      },
      on: {
        "change": function (booleanValue) {
          return _vm.marketplaceStatusUpdated(booleanValue, marketplace);
        }
      },
      model: {
        value: marketplace.isActive,
        callback: function ($$v) {
          _vm.$set(marketplace, "isActive", $$v);
        },
        expression: "marketplace.isActive"
      }
    }), marketplace.activeUser ? _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(marketplace.activeUser))]) : _vm._e(), marketplace.url ? _c('div', {
      staticClass: "external-link-row",
      on: {
        "click": function ($event) {
          return _vm.openMarketplaceURL(marketplace);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/images/link.svg'),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "link-small"
    }, [_vm._v(_vm._s(_vm.$t("ViewListing")))])]) : _vm._e()], 1), _vm.changeExportAccounts && _vm.marketplaceUsers ? _c('div', [_c('v-autocomplete', {
      attrs: {
        "outlined": "",
        "dense": "",
        "clearable": "",
        "hide-details": "",
        "hide-no-data": "",
        "label": `${marketplace.name}  ${_vm.$t('account')}`,
        "placeholder": `${_vm.$t('Select')} ${_vm.$t('account')}`,
        "items": _vm.marketplaceUsers[marketplace.name]
      },
      on: {
        "input": function (email) {
          return _vm.openConfirmation(email, marketplace);
        }
      },
      model: {
        value: marketplace.activeUser,
        callback: function ($$v) {
          _vm.$set(marketplace, "activeUser", $$v);
        },
        expression: "marketplace.activeUser"
      }
    })], 1) : _vm._e()]);
  })], 2)])])]) : _vm._e(), _vm.confirmationModalOpen ? _c('ConfirmationModal', {
    staticStyle: {
      "z-index": "1522"
    },
    on: {
      "onConfirm": _vm.onConfirmation,
      "onCancel": _vm.closeConfirmation
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ListingWillBeSharedWithUser')) + ": " + _vm._s(_vm.confirmationModalEmail) + " ")]) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }