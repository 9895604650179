var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "build-notification"
  }, [_c('p', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t("NewVersionNotification")))]), _c('div', {
    staticClass: "refresh-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('update');
      }
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Upgrade")))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }