<template>
  <div class="overlay" id="overlay-project">
    <div id="panel-wrapper-project" class="modal-wrapper">
      <div
        @click="closeContactAdd"
        style="padding-left: 24px"
        id="wrapped-header-project"
      >
        <h4 style="align-self: center">
          {{
            isEdit
              ? $t("Edit") + " " + $t("Project").toLowerCase()
              : $t("AddNewProject")
          }}
        </h4>
      </div>
      <div v-if="!loaded">
        <div
          style="margin-top: 24px; position: absolute; top: 30%; left: 50%"
          class="loader"
        ></div>
      </div>
      <div v-if="loaded" class="content-overlay" id="content-wrapper">
        <div class="sticky-header" style="z-index: 126">
          <v-row class="top-row" style="height: 24px; padding-left: 32px"
            ><h4 v-if="!isEdit" style="height: 24px">
              {{ $t("AddNewProject") }}
            </h4>
            <h4 v-if="isEdit" style="height: 24px">
              {{ $t("ChangeProject") }}
            </h4>
          </v-row>
        </div>
        <v-divider
          v-if="!clientAddOpen"
          style="top: 97px; z-index: 126; position: fixed; width: 560px"
        />
        <div style="padding: 129px 32px">
          <v-row style="padding-bottom: 12px">
            <h5>{{ $t("Subblock.GeneralData") }}</h5>
          </v-row>

          <v-row>
            <v-col class="pa-0">
              <v-row class="faded-title required" title="required">
                <h6 class="deal-type-lable">
                  {{ $t("Project") + " " + $t("Type").toLowerCase() }}
                </h6>
              </v-row>
              <v-row style="gap: 8px">
                <span
                  v-for="(dealType, index) in dealTypes"
                  :key="index"
                  :name="'project-' + dealType"
                  @click="setDealType(dealType)"
                >
                  <DealTypeContainer
                    :deal-type="dealType"
                    :class="
                      'project-type-con ' +
                      (dealType === form.dealType
                        ? 'project-type-selected-con-' + dealType
                        : null)
                    "
                  />
                </span>
              </v-row>
            </v-col>
          </v-row>

          <v-row
            class="flex-column"
            v-if="
              this.form.dealType !== 'Sale' && this.form.dealType !== 'LeaseOut'
            "
          >
            <BaseInput
              :id="'project-name-field'"
              :placeholder="$t('ProjectName')"
              v-model="form.name"
              :required="true"
              :error="showErrors && !isFieldValid('name') ? 'not-valid' : ''"
              :error-message="$t('FormErrors.' + errors.name)"
            />
          </v-row>

          <v-row
            v-if="
              this.form.dealType === 'Sale' || this.form.dealType === 'LeaseOut'
            "
          >
            <div
              style="
                display: flex;
                margin-top: 16px;
                margin-bottom: 16px;
                flex-direction: column;
              "
            >
              <div class="content-bold">{{ $t("AddressType") }}</div>
              <div class="radio-group-label" v-if="user.access.company._id !== 'xmwei_Rt9ryfpkxL4BL8'" style="margin-top: 16px">
                <v-radio-group v-model="form.addressType" row>
                  <v-radio
                    v-if="!user.isItaly"
                    id="address-type-est"
                    @click="switchAddressType"
                    color="#FF5C01"
                    value="estonia"
                    class="radio-button"
                    :label="$t('Estonian')"
                  ></v-radio>
                  <v-radio
                    id="address-type-generic"
                    @click="switchAddressType"
                    color="#FF5C01"
                    value="generic"
                    class="radio-button"
                    :label="
                      $i18n.locale === 'et'
                        ? $t('ForeignCountry')
                        : $t('Generic')
                    "
                  ></v-radio>
                </v-radio-group>
                <div
                  class="disclaimer-row"
                  v-if="form.addressType === 'generic' && $i18n.locale === 'et'"
                >
                  <img src="../../assets/images/infoMark20.svg" alt="" />
                  <p class="content-small">
                    Välismaa aadressiga tehtud kuulutust ei saa eksportida
                    kinnisvara portaali
                  </p>
                </div>
              </div>
            </div>
            <div style="z-index: 125; width: 100%">
              <AddressFinder
                v-if="form.addressType === 'estonia'"
                ref="addressFinder"
                :object="form.objects ? form.objects[0] : ''"
                :placeholder="$t('Object') + ' ' + $t('Address').toLowerCase()"
                :show-error="showErrors && !isFieldValid('object')"
                :error-message="errors && errors.object ? errors.object : ''"
                @setObject="setObject"
                @objectSetting="objectSetting"
                @removeObject="removeObject"
                @loaded="loadedAF"
              />
            </div>
            <div
              v-if="form.addressType === 'generic'"
              :class="{ 'input-edit': isEdit }"
              style="width: 100%; margin-bottom: -5px"
            >
              <GoogleAddressSearch
                v-model="googleFormObjects"
                :editMode="true"
                :id="'project-generic-address-search'"
                :placeholder="$t('Object') + ' ' + $t('Address').toLowerCase()"
                required="true"
                @change="setObject"
                :return-object="true"
                :error="showErrors && !isFieldValid('object')"
                :error-message="errors && errors.object ? errors.object : ''"
              />
            </div>
          </v-row>
          <v-row
            class="flex-column"
            v-bind:class="{
              'flex-column': form.addressType === 'estonian',
              'flex-column-gen': form.addressType === 'generic',
            }"
            v-if="
              this.form.dealType === 'Sale' || this.form.dealType === 'LeaseOut'
            "
          >
            <v-row :class="{ 'input-with-apartment': showApartmentNumber }">
              <BaseDropdown
                :id="'project-estateType-field'"
                :placeholder="$t('EstateType')"
                v-model="form.estateType"
                :items="estateTypes"
                :show-dropdown-arrow="true"
                required="true"
                :error-message="
                  errors && errors.estateType
                    ? $t('FormErrors.' + errors.estateType)
                    : ''
                "
                type="dropdown"
                :error="showErrors && !isFieldValid('estateType')"
                @change="resetExtras($event)"
              />

              <BaseInput
                :id="'project-apartmentNumber'"
                v-if="showApartmentNumber"
                v-model="form.apartmentNumber"
                :placeholder="$t('ApartmentNumber')"
              >
              </BaseInput>
            </v-row>
            <v-row>
              <BaseInput
              :id="'project-amount-field'"
              :placeholder="$t('ProjectValue')"
              v-model="form.amount"
            />
            </v-row>
            <v-row v-if="correctExtraTypes && correctExtraTypes.length">
              <DropdownMulti
                :key="'extras' + multiUpdater"
                :custom-style-button="'width: 100% !important; height: 52px;'"
                :custom-style="'width: 100% !important; height: 52px;'"
                :items="correctExtraTypes"
                :display-key="'name'"
                :value-key="'name'"
                :width="'100%'"
                :regular-size="true"
                :default-item="extraTypes.placeholder"
                :selected="form.extraTypes"
                :pre-selected-items="form.extraTypes"
                @selectedItems="form.extraTypes = [...arguments]"
              />
            </v-row>
          </v-row>
          <v-row class="client-dropdown-row">
            <ClientListSearch
              id="project-contact-search"
              :placeholder="$t('Contact')"
              :icon-left="'clients.svg'"
              v-model="form.contactPerson"
              @addNew="openContactAdd"
              @itemChosen="contactPersonSelected"
              :required="true"
              :update-component="updateSelectedContact"
              :error="showErrors && !isFieldValid('contactPerson')"
              :error-message="
                errors ? $t('FormErrors.' + errors.contactPerson) : ''
              "
            >
            </ClientListSearch>
          </v-row>
          <v-row v-if="form.contactPerson" style="padding-bottom: 12px">
            <div
              style="padding-bottom: 12px; padding-top: 12px; font-weight: 600"
            >
              {{ $t("Client") }}
            </div>
            <ClientSelection
              :isEdit="isEdit"
              :existingClient="initialClient"
              :projectContact="form.contactPerson"
              :contactId="form.contactPerson.contactId"
              :newProjectClient="newProjectClient"
              @openAddPanel="addNewPrivateContact"
              @clientSelected="setProjectClient"
            />
          </v-row>
          <!--          <v-row>
            <BaseDropdown
                icon-left="calendar_20_20.svg"
                :id="'expected-closing-date'"
                :placeholder="$t('ExpectedClosingDate')"
                v-model="form.estimatedCloseDate"
                :show-dropdown-arrow="true"
                type="date"
            />
          </v-row>-->
        <!--   <v-row
            style="
              gap: 12px;
              display: flex;
              margin-top: 12px;
              width: 100%;
              flex-wrap: nowrap;
            "
          >
            <BaseDropdown
              style="display: none"
              :id="'project-currency-field'"
              v-model="form.currency"
              :items="currencies"
              :show-dropdown-arrow="true"
              :dropdown-allow-null="false"
              :placeholder="$t('Currency')"
              :type="'dropdown'"
            />
          </v-row> -->

       <!--    <v-row style="width: 50%; padding-right: 6px">
            <BaseInput
              style="display: none"
              icon-right="percentage.svg"
              :id="'project-probability-field'"
              :placeholder="$t('Probability')"
              v-model="form.probability"
            />
          </v-row> -->

          <div style="margin-top: 32px">
            <v-row
              ><h5>{{ $t("BrokerageFee") }}</h5></v-row
            >
            <v-row style="margin-top: 24px"
              ><p class="content-small-semibold">
                {{ $t("BrokerageFeeIs") }}
              </p></v-row
            >
            <v-radio-group
              style="padding-top: 12px"
              v-model="form.brokerageFeeType"
              row
            >
              <v-radio
                color="#FF5C01"
                value="percentOfPrice"
                class="radio-button"
                :label="$t('PercentageOfFinalPrice')"
              ></v-radio>
              <v-radio
                color="#FF5C01"
                value="agreedPrice"
                class="radio-button"
                :label="$t('AgreedAmount')"
              ></v-radio>
            </v-radio-group>
            <div
              v-if="form.brokerageFeeType === 'agreedPrice'"
              style="display: contents"
            >
              <v-row
                style="gap: 12px; display: flex; margin-top: 24px; width: 100%"
              >
                <p class="content-small-semibold">
                  {{ $t("HowMuchIsBrokerageFee") }}
                </p>
                <BaseInput
                  type="number"
                  :format="'price'"
                  :id="'brokerage-fee-field'"
                  :placeholder="$t('BrokerageFee')"
                  icon-right="euroSign.svg"
                  v-model="form.brokerageFee"
                />
              </v-row>
            </div>
            <v-row
              v-else-if="form.brokerageFeeType === 'percentOfPrice'"
              style="
                gap: 12px;
                display: flex;
                margin-top: 24px;
                width: 100%;
                flex-wrap: nowrap;
              "
            >
              <BaseInput
                type="number"
                :format="'price'"
                :id="'project-amount-percent'"
                icon-right="percentage.svg"
                :placeholder="$t('Percentage')"
                v-model="form.brokerFeePercentage"
              />
              <BaseInput
                type="number"
                :format="'price'"
                :id="'project-amount-min-fee'"
                :placeholder="$t('MinimumFee')"
                icon-right="euroSign.svg"
                v-model="form.minimumFee"
              />
            </v-row>
          </div>

          <span v-if="!isEdit">
            <v-row style="margin-top: 32px">
              <h5>{{ $t("AddNote") }}</h5>
            </v-row>
            <v-row>
              <BaseTextarea
                :id="'project-add-note'"
                v-model="noteContent"
                :placeholder="$t('Note')"
              />
            </v-row>
          </span>

          <v-row v-if="!isEdit" style="margin-top: 32px">
            <h5>{{ $t("Add") + " " + $t("Image").toLowerCase() }}</h5>
          </v-row>
          <v-row v-if="isEdit" style="margin-top: 32px">
            <h5>{{ $t("SelectCoverImage") }}</h5>
          </v-row>

          <v-row>
            <UploadImages
              v-if="!isEdit"
              :Imgs="[]"
              @changed="putImages($event)"
              class="image-uploader"
            />
          </v-row>
          <v-row
            class="nothing-to-show"
            v-if="isEdit && !activeListingsHaveImages && !containImages"
          >
            <UploadImages
              :Imgs="[]"
              @changed="putImages($event)"
              class="image-uploader"
            />
          </v-row>
          <v-row
            v-if="
              isEdit &&
              activeListings &&
              activeListings.length > 0 &&
              activeListingsHaveImages &&
              containImages
            "
            :key="imagesUpdater"
            style="display: contents"
          >
            <div
              style="margin-bottom: 12px"
              v-for="listing in activeListings"
              :key="listing._id"
            >
              <p style="margin-bottom: 8px !important">
                {{ listing.listingName }}
              </p>
              <UploadImages
                :Imgs2="listing.images"
                :cover-image-selection="true"
                class="image-uploader"
                @setCoverImage="newCoverImage"
              />
            </div>
          </v-row>
          <v-row
            v-if="
              isEdit &&
              activeListings &&
              activeListings.length === 0 &&
              !activeListingsHaveImages &&
              containImages
            "
            :key="imagesUpdater"
            style="display: contents"
          >
            <UploadImages
              :Imgs2="form.images"
              :cover-image-selection="true"
              class="image-uploader"
              @setCoverImage="newCoverImage"
            />
          </v-row>
          <div class="modal-footer">
            <v-divider
              style="z-index: 111; position: fixed !important; width: 560px"
            ></v-divider>
            <div class="buttons-row" style="padding-left: 24px">
              <button
                v-if="isEdit && dealData.statusCode === 1 && hasAccess(dealData, 'projects', 'delete')"
                id="deal-delete-button"
                class="delete-button"
                color="blue darken-1"
                text
                @click="isDeleteModalOpen = true"
              >
                <img
                  style="padding-right: 8px"
                  src="../../assets/images/TrashBin.svg"
                  alt=""
                />
                {{ $t("Delete") }}
              </button>
              <button
                v-if="isEdit && dealData.statusCode === 0"
                class="restore-button"
                text
                @click="restoreDeal(dealData)"
              >
                {{ $t("Restore") }}
              </button>
              <button
                v-if="!isEdit"
                @click="
                  changeSidepanelStateDeals(0);
                  closeModal();
                "
                class="cancel"
              >
                {{ $t("Cancel") }}
              </button>
              <button v-if="isEdit" @click="closeModal()" class="cancel">
                {{ $t("Cancel") }}
              </button>

              <SubmitButton
                id="save-edit-project-button"
                v-if="isEdit"
                :loading="isLoading"
                @clicked="updateDeal()"
              />
              <SubmitButton
                :id="'save-project-button'"
                v-if="!isEdit"
                :loading="isLoading"
                @clicked="saveDeal()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddModal
      style="z-index: 120"
      @newAdded="chooseNewClient"
      @closed="closeContactAdd"
      :accordion="true"
      :not-contact-view-route="true"
      v-if="clientAddOpen"
    />
    <DeleteModal
      :removing="false"
      @canceled="isDeleteModalOpen = false"
      @approved="deleteDeal"
      v-if="isDeleteModalOpen"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import nanoid from "nanoid";
import ClickOutside from "vue-click-outside";
import AddModal from "@/components/Kliendiregister/AddModal";
import DeleteModal from "@/components/common/DeleteModal";
import UploadImages from "../../components/common/vue-upload-drop-images/dist/vue-upload-drop-images";
import AddressFinder from "@/components/Deals/AddressFinder";
import DealTypeContainer from "@/components/common/DealTypeContainer";
import BaseDropdown from "@/components/common/BaseDropdown";
import BaseInput from "@/components/common/BaseInput";
import ClientListSearch from "../common/ClientListSearch";
import ClientSelection from "../common/ClientSelection";
import BaseTextarea from "@/components/common/BaseTextarea";
import GoogleAddressSearch from "@/components/common/GoogleAddressSearch";
import SubmitButton from "@/components/common/SubmitButton";
import DropdownMulti from "@/components/common/DropdownMulti";
import _ from "lodash";
import contactApi from "@/http/contact";

export default {
  components: {
    AddressFinder,
    UploadImages,
    AddModal,
    DeleteModal,
    DealTypeContainer,
    BaseDropdown,
    BaseInput,
    BaseTextarea,
    GoogleAddressSearch,
    ClientListSearch,
    ClientSelection,
    SubmitButton,
    DropdownMulti,
  },
  name: "SidepanelAdd",
  props: [
    "dealData",
    "isEdit",
    "noRefresh",
    "fromAnotherPanel",
    "contactPerson",
    "fromAddListing",
  ],
  directives: {
    ClickOutside,
  },
  data() {
    return {
      multiUpdater: 0,
      initialClient: null,
      loaded: false,
      showApartmentNumber: false,
      hasOnlyOneListing: true,
      isLoading: false,
      showErrors: false,
      clientAddOpen: false,
      isDeleteModalOpen: false,
      noteContent: "",
      listingsInDeal: null,
      activeListings: null,
      activeListingsHaveImages: false,
      containImages: false,
      imagesUpdater: 0,
      updateSelectedContact: 0,
      validate: ["name", "object", "estateType", "contactPerson"],
      dealTypes: ["Sale", "LeaseOut", "Purchase", "AquireLease", "Referral"],
      currencies: [
        { name: "EUR", value: "EUR" },
        { name: "USD", value: "USD" },
      ],
      dateChanged: false,
      errors: null,
      object: null,
      form: {
        addressType: "estonia",
        apartmentNumber: null,
        estateType: "",
        extraTypes: [],
        name: "",
        brokerageFeeType: "percentOfPrice",
        brokerageFeePayer: "client",
        brokerageFee: null,
        brokerBusinessDays: null,
        brokerFeePercentage: null,
        minimumFee: null,
        dealType: "Sale",
        description: null,
        amount: "",
        service: null,
        currency: "EUR",
        probability: null,
        estimatedCloseDate: null,
        pipelineStep: "qualified",
        contactPerson: null,
        organization: null,
        objects: "",
        notes: [],
        images: [],
        creator: {
          userId: null,
          name: null,
          companyId: null,
        },
        client: {
          name: this.$t("SameAsContact"),
          value: "same_as_contact",
        },
      },
      addFromClientSelection: false,
      newProjectClient: null,
      extraTypes: {
        placeholder: {
          name: this.$t("CommercialPurposeOfUse"),
          value: "placeholder",
          _id: () => nanoid(16),
        },
        land: [
          {
            name: this.$t("CommercialPurposeOfUse"),
            value: "placeholder",
            _id: () => nanoid(16),
          },
          {
            name: this.$t("ResidentialLand"),
            value: "residentialLand",
          },
          {
            name: this.$t("ResidentialLandApartments"),
            value: "residentialLandApartment",
          },
          { name: this.$t("CommercialLand"), value: "commercialLand" },
          { name: this.$t("ProductionLand"), value: "productionLand" },
          { name: this.$t("IndustrialLand"), value: "industrialLand" },
          { name: this.$t("ForestLand"), value: "forestLand" },
          { name: this.$t("ProfitYieldingLand"), value: "profityieldingLand" },
          { name: this.$t("OtherUse"), value: "otherUse" },
        ],
        commercial: [
          {
            name: this.$t("CommercialPurposeOfUse"),
            value: "placeholder",
            _id: () => nanoid(16),
          },
          {
            name: this.$t("Office"),
            value: "office",
          },
          { name: this.$t("TradeShop"), value: "tradeshop" },
          { name: this.$t("Accomodation"), value: "accomodation" },
          { name: this.$t("Services"), value: "services" },
          { name: this.$t("WareHouse"), value: "warehouse" },
          { name: this.$t("Production"), value: "production" },
          {
            name: this.$t("WareHouseProduction"),
            value: "warehouseProduction",
          },
          { name: this.$t("FoodServices"), value: "foodServices" },
          { name: this.$t("OtherUsePossible"), value: "otherUsePossible" },
          { name: this.$t("NotDefined"), value: "notDefined" },
        ],
      },
      correctExtraTypes: [],
      objectReady: Promise.resolve(),
      isObjectSetting: false,
    };
  },
  created() {
    this.initData();
  },
  mounted() {
    this.errors = this.getEmptyErrors();
    this.hideOverlay();
  },
  watch: {
    "form.estateType": function (estateType) {
      this.showApartmentNumber =
        estateType === "apartment" || estateType === "house_part";
      this.correctExtraTypes = this.extraTypes[estateType];
    },
    correctExtraTypes() {
      this.multiUpdater++;
    },
  },
  beforeDestroy() {
    document.documentElement.style.overflow = "visible";
  },
  computed: {
    ...mapGetters(["customers", "user"]),
    estateTypes() {
      if (this.user.isItaly) {
        return [
          { name: this.$t("CommercialSpace"), value: "commercial" },
          { name: this.$t("Apartment"), value: "apartment" },
          { name: this.$t("House"), value: "house" },
          { name: this.$t("HousePart"), value: "house_part" },
          { name: this.$t("LandPlot"), value: "land" },
          { name: this.$t("SummerHouse"), value: "cottage" },
          { name: this.$t("Garage"), value: "garage" },
          { name: this.$t("Office"), value: "office" },
          { name: this.$t("Storage"), value: "storage" },
          { name: this.$t("Building"), value: "building" },
          { name: this.$t("Room"), value: "room" },
        ];
      }
      return [
        { name: this.$t("CommercialSpace"), value: "commercial" },
        { name: this.$t("Apartment"), value: "apartment" },
        { name: this.$t("House"), value: "house" },
        { name: this.$t("HousePart"), value: "house_part" },
        { name: this.$t("LandPlot"), value: "land" },
        { name: this.$t("SummerHouse"), value: "cottage" },
        { name: this.$t("Garage"), value: "garage" },
      ];
    },

    googleFormObjects() {
      if (this.isEdit) {
        return this.form.objects[0];
      } else {
        return this.form.objects;
      }
    },
  },
  methods: {
    ...mapActions(["changeSidepanelStateDeals"]),
    setExtras(extras) {
      this.form.extraTypes = extras;
    },
    resetExtras(ev) {
      if (this.dealData && this.dealData.estateType === ev) return;
      if (this.dealData && this.dealData.estateType === ev) return;

      this.form.extraTypes = [];
      this.multiUpdater++;
    },
    async initData() {
      this.$nextTick(async () => {
        if (this.user.isItaly) {
          this.form.addressType = "generic";
        }
        if (this.user.access.company._id === "xmwei_Rt9ryfpkxL4BL8") {
          this.form.addressType = "generic";
        }
        if (this.fromAddListing) {
          this.dealTypes = ["Sale", "LeaseOut"];
        }
        //oli mountedis
        await this.mapToFormData();
        this.loaded = true;

        if (!this.isEdit) {
          this.initialClient = this.form.contactPerson;
          this.form.client = this.form.contactPerson;
        } else {
          this.initialClient = this.dealData.client;
          this.form.client = this.dealData.client;
          if (!this.form.client.contactId) {
            this.form.client.contactId = this.form.contactPerson.contactId
          }
        }
      });
    },
    async contactPersonSelected(person) {
      if (!person) {
        this.form.contactPerson = null;
        return;
      }
      const companyId =
        person.organizations.length > 0 ? person.organizations[0]._id : null;

      const regCode =
        person.organizations.length > 0
          ? person.organizations[0].reg_code
          : null;
      this.form.contactPerson = {
        _id: person._id,
        company_name: person.organization ? person.organization : null,
        contactId: person.customerId,
        contactName: person.customerName
          ? person.customerName
          : person.contactName,
        companyId: companyId,
        regCode,
        createdAt: person.createdAt,
        email: person.email,
      };
    },
    async chooseNewClient(client) {
      const object = await contactApi.getContactById(client);
      if (this.addFromClientSelection) {
        this.newProjectClient = object;
        this.addFromClientSelection = false;
      } else {
        this.form.contactPerson = {
          _id: object._id,
          contactId: object.customerId,
          contactName: object.customerName
            ? object.customerName
            : object.contactName,
          createdAt: object.createdAt,
          email: object.email,
        };
        this.chosenNewClient = object;
        this.newProjectClient = object;
      }
    },
    newCoverImage(image) {
      this.dealData.images.forEach((image) => {
        image.coverImage = false;
      });
      const deal = this.dealData.images.find(
        (listImage) => listImage.name === image.name
      );
      if (!deal) {
        this.dealData.images.push({
          name: image.name,
          coverImage: image.coverImage,
        });
      } else {
        deal.coverImage = image.coverImage;
      }
      this.form.images = this.dealData.images;
      this.allListingImages(image);
    },
    allListingImages(image) {
      if (this.activeListings.length > 0) {
        this.activeListings.forEach((listing) => {
          listing.images.forEach((img) => {
            img.hidden = false;
            if (img.name === image.name) {
              img.selected = true;
            } else {
              img.selected = false;
            }
          });
        });
        this.imagesUpdater++;
      } else {
        this.form.images.forEach((img) => {
          img.hidden = false;
          if (img.name === image.name) {
            img.selected = true;
          } else {
            img.selected = false;
          }
        });
        this.imagesUpdater++;
      }
    },
    getSelectedImages() {
      const selectedImage = this.dealData.images.find(
        (listImage) => listImage.coverImage
      );
      this.activeListings.forEach((listing) => {
        listing.images.forEach((img) => {
          img.hidden = false;
          img.selected = img.name === selectedImage?.name;
        });
      });
      this.imagesUpdater++;
    },
    closeContactAdd() {
      let panel = document.getElementById("panel-wrapper-project");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-project");
      let wrappedHeader = document.getElementById("wrapped-header-project");
      let wrappedPanelCustomer = document.getElementById(
        "panel-wrapper-customer"
      );
      let modalFooter = document.getElementsByClassName("modal-footer");
      if (modalFooter.length > 0) {
        modalFooter[0].style.display = "initial";
      }
      if (wrappedPanelCustomer) {
        wrappedPanelCustomer.style.right = "560px";
      }
      wrappedHeader.style.display = "none";
      wrappedHeader.style.opacity = "0";
      panelContent.style.display = "initial";
      panel.style.overflow = "scroll";
      panel.style.minWidth = "560px";
      panel.style.width = "560px";
      panel.style.right = "0";
      overlay.style.zIndex = "101";
      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
      this.$emit("closedClientAdd");
      //waiting for animations
      setTimeout(async () => {
        this.clientAddOpen = false;
      }, 500);
    },
    addNewPrivateContact() {
      this.addFromClientSelection = true;
      this.openContactAdd();
    },
    setProjectClient(type, client) {
      let clientObject;
      if (type === "private") {
        if (client.value === "same_as_contact") {
          clientObject = {
            isPrivatePerson: true,
            _id: this.form.contactPerson._id,
            contactId: this.form.contactPerson.contactId,
            contactName: this.form.contactPerson.contactName,
            createdAt: this.form.contactPerson.createdAt,
            email: this.form.contactPerson.email,
          };
          this.form.client = clientObject;
        }
        if (
          client.value !== "new_contact" &&
          client.value !== "same_as_contact"
        ) {
          clientObject = {
            isPrivatePerson: true,
            _id: client._id,
            contactId: client.customerId,
            contactName: client.customerName,
            createdAt: client.createdAt,
            email: client.email,
          };
          this.form.client = clientObject;
        }
      } else {
        clientObject = {
          isPrivatePerson: false,
          _id: client._id,
          contactId: this.form.contactPerson.contactId,
          contactName: this.form.contactPerson.contactName,
          reg_code: client.reg_code,
          company_name: client.company_name,
          createdAt: this.form.contactPerson.createdAt,
        };
        this.form.client = clientObject;
      }
    },
    openContactAdd() {
      let panel = document.getElementById("panel-wrapper-project");
      let modalFooter = document.getElementsByClassName("modal-footer");
      let panelContent = document.getElementById("content-wrapper");
      let overlay = document.getElementById("overlay-project");
      let wrappedPanelCustomer = document.getElementById(
        "panel-wrapper-customer"
      );
      if (wrappedPanelCustomer) {
        wrappedPanelCustomer.style.right = "638px";
        panel.style.right = "731px";
      } else {
        panel.style.right = "638px";
      }
      if (modalFooter.length > 0) {
        modalFooter[0].style.display = "none";
      }
      panelContent.style.display = "none";
      panel.style.overflow = "hidden";
      panel.style.minWidth = "94px";
      panel.style.width = "94px";
      panel.style.zIndex = "121";
      //panel.style.boxShadow = "0px 0 1px rgba(28, 14, 3, 0.2)";
      overlay.style.zIndex = "11";

      let wrappedHeader = document.getElementById("wrapped-header-project");
      wrappedHeader.style.display = "flex";
      wrappedHeader.style.opacity = "1";
      this.$emit("openedClientAdd");
      this.clientAddOpen = true;
    },
    closeModal() {
      this.$emit("closed");
      this.changeSidepanelStateDeals(0);
    },
    async saveDeal() {
      const saleOrRent = this.dealTypeSaleOrRent();

      if (saleOrRent && this.isObjectSetting) {
        await this.objectReady;
      }

      if (!this.isFormValid(saleOrRent)) {
        this.showErrors = true;
        return;
      }
      this.form.creator.userId = this.user._id;
      this.form.creator.name = this.user.name;
      this.form.creator.companyId = this.user.companyId;
      let deal = this.form;
      deal._id = nanoid(8);
      deal = this.mapDeal(deal, saleOrRent);

      this.isLoading = true;
      let response = await axios.post("/api/project", deal);
      if (response.data === 0) {
        this.toastError(this.$t("ErrorCreating"));
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.$emit("saveResponse", response.data);
        await this.changeSidepanelStateDeals(0);
        this.toastSuccess(
          this.$t("Project") + " " + this.$t("Created").toLowerCase()
        );
      }
      if (!this.fromAddListing && !this.fromAnotherPanel) {
        this.$router.push(`/projects/${response.data._id}`);
      }
    },
    async updateDeal() {
      const saleOrRent = this.dealTypeSaleOrRent();
      if (!this.isFormValid(saleOrRent)) {
        this.showErrors = true;
        return;
      }

      this.isLoading = true;

      let deal = this.form;
      deal = this.mapDeal(deal, saleOrRent);
      const response = await axios.patch("/api/project", deal);
      if (response.data === 0) {
        this.toastError(this.$t("ErrorUpdating"));
        this.isLoading = false;
        return;
      } else {
        this.isLoading = false;
        await this.changeSidepanelStateDeals(0);
        if (!this.noRefresh && !this.isEdit) {
          await this.$router.go(0);
        }
        // location.reload();
        await this.$emit("closed");
        this.toastSuccess(
          this.$t("Project") + " " + this.$t("Updated").toLowerCase()
        );
      }
    },
    async restoreDeal(deal) {
      const response = await axios.patch("/api/project/restore", deal);
      if (response.status === 200) {
        await this.$router.go(0);
      }
    },
    async deleteDeal() {
      let listings = this.form.objects[0].listings;
      const deleteResponse = await axios.post(
        `/api/project/delete/${this.form._id}`,
        { listings }
      );
      if (deleteResponse.data === 0) {
        this.toastError(this.$t("ErrorDeleting"));
        return;
      } else {
        await this.$router.push({ name: "Deals" });
        this.toastSuccess(
          this.$t("Project") + " " + this.$t("Deleted").toLowerCase()
        );
      }
    },
    isFormValid(saleOrRent) {
      this.errors = this.getEmptyErrors();

      return this.validate
        .map((field) => {
          if (
            (field === "name" && saleOrRent) ||
            (field === "object" && !saleOrRent) ||
            (field === "estateType" && !saleOrRent)
          )
            return true;
          return this.isFieldValid(field);
        })
        .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "name":
          if (!this.form.name) {
            this.errors.name = "FieldRequired";
            return false;
          }
          break;
        case "object":
          if (!this.object) {
            this.errors.object = "FieldRequired";
            return false;
          }
          break;
        case "estateType":
          if (!this.form.estateType) {
            this.errors.estateType = "FieldRequired";
            return false;
          }
          break;
        case "contactPerson":
          if (!this.form.contactPerson) {
            this.errors.contactPerson = "FieldRequired";
            return false;
          }
          break;
      }

      return true;
    },
    mapDeal(deal, saleOrRent) {
      //convert dropdown objects to string values
      const itemsAreCorrect = (arr) => arr.every((i) => typeof i === "string");
      if (!itemsAreCorrect(deal.extraTypes)) {
        deal.extraTypes = [].concat(...deal.extraTypes);
        deal.extraTypes = deal.extraTypes.map((extra) => extra.value);
      }

      if (saleOrRent) {
        if (this.object) {
          if (this.form.addressType !== "estonia") {
            this.object.foreignCountry = true;
          }
          deal.objects = [this.object];
          deal.name = this.object.address;
        }
      } else {
        deal.estateType = "";
      }

      deal.contactName = deal.contactPerson.contactName
        ? deal.contactPerson.contactName
        : deal.contactPerson.customerName;

      if (this.noteContent) {
        deal.notes.push({
          content: this.noteContent,
          linkedTo: deal._id,
          parentName: deal.name,
        });
      }

      if (deal.apartmentNumber && this.form.addressType === "estonia") {
        let houseNumber = deal.name.match(/\d+\w*(\/\w)*/);
        if (houseNumber) {
          deal.name = deal.name.replace(
            houseNumber[0],
            houseNumber[0] + "-" + deal.apartmentNumber
          );
        }
      }
      return deal;
    },
    async loadedAF() {
      if (this.isEdit) {
        const addressFinderLoad = setInterval(() => {
          // this.form.objects[0].address = this.dealData.name
          this.$refs.addressFinder.aadressSearch.setAddress(this.form.name);
          this.$refs.addressFinder.focusInput();

          clearInterval(addressFinderLoad);
        }, 30);
      }
    },
    async mapToFormData() {
      if (this.isEdit && this.dealData) {
        this.form = _.cloneDeep(this.dealData);

        if (this.extraTypes[this.form.estateType]) {
          //map project extras to dropdown items format
          this.correctExtraTypes = this.extraTypes[this.form.estateType];
          let extras = [];
          if (this.form.extraTypes) {
            extras = this.correctExtraTypes.filter((type) =>
              this.form.extraTypes.includes(type.value)
            );
          }
          this.form.extraTypes = extras;
        }

        if (this.dealData.objects) {
          this.object = this.dealData.objects[0];
        }
        let listingIds = this.dealData.objects[0].listings;
        let payload = { listingIds };
        let matchingListings = await axios.post("/api/listings", payload);
        this.listingsInDeal = matchingListings.data.listings;
        this.activeListings = [];
        this.listingsInDeal.forEach((listing) => {
          if (listing.status !== "archived" && listing.images.length > 0) {
            this.activeListings.push(listing);
          }
        });
        this.activeListings.forEach((listing) => {
          if (listing.images.length > 0) {
            this.activeListingsHaveImages = true;
          } else {
            this.activeListingsHaveImages = false;
          }
        });
        if (!this.activeListingsHaveImages && this.form.images.length === 0) {
          this.containImages = false;
        } else {
          this.containImages = true;
        }
        this.updateSelectedContact++;
      }
      if (this.isEdit) {
        this.getSelectedImages();
      }
      if (this.contactPerson && !this.isEdit) {
        await this.contactPersonSelected(this.contactPerson);
        this.updateSelectedContact++;
      }
    },
    getEmptyErrors() {
      return {
        name: "",
        object: "",
        estateType: "",
        contactPerson: "",
      };
    },
    setDealType(dealType) {
      this.form.dealType = dealType;
    },
    switchAddressType() {
      if (!this.isEdit) {
        this.form.objects = "";
      } else {
        this.form.objects[0].address = "";
      }
    },
    setObject(object) {
      let existingListings = null;
      let existingObjectId = null;

      if (this.isEdit) {
        existingListings = this.form.objects[0].listings;
        existingObjectId = this.form.objects[0]._id;
      }

      this.form.objects = [];
      this.object = object;
      this.form.objects.push(this.object);

      if (this.isEdit) {
        this.form.objects[0].listings = existingListings;
        this.form.objects[0]._id = existingObjectId;
      }

      if (this.objectReadyResolve) {
        this.objectReadyResolve();
        this.objectReadyResolve = null;
      }

      this.isObjectSetting = false;
    },
    objectSetting() {
      this.isObjectSetting = true;
      this.objectReady = new Promise(resolve => {
        this.objectReadyResolve = resolve;
      });
    },
    removeObject() {
      if (!this.isEdit) {
        this.object = null;
        if (this.form.objects.length) {
          this.form.objects.pop();
        }
      }
    },
    removeContact() {
      this.form.contactPerson = null;
    },
    putImages(e) {
      this.form.images = [];
      e.forEach((el) => {
        if (el.name) {
          this.form.images.push({ name: el.name, hidden: false });
        }
      });
    },
    dealTypeSaleOrRent() {
      return (
        this.form.dealType === "Sale" ||
        this.form.dealType === "LeaseOut" ||
        this.form.dealType === "Lease Out"
      );
    },
    handleChange() {
      if (this.form.probability < 0) {
        this.form.probability = 0;
      }
      if (this.form.probability > 100) {
        this.form.probability = 100;
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
    hideOverlay() {
      document.documentElement.style.overflow = "hidden";

      window.onclick = (e) => {
        if (e.target.classList[0] === "overlay") {
          this.closeModal();
          e.target.style.display = "none";
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

#panel-wrapper-project {
  -webkit-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out, right 0.5s ease-in-out,
    min-width 0.5s ease-in-out;
}

#wrapped-header-project {
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;
  padding: 48px 24px 24px;
  border-right: 1px solid $grey500;

  h4 {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    text-align: center;
    position: relative;
    justify-self: center;
    align-self: center;
  }
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

::v-deep .delete {
  padding: 0 !important;
}

::v-deep .plus {
  padding-top: 1px;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.sticky-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}

.top-row {
  width: 100%;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

.flex-column-gen {
  margin-top: 17px !important;
}

.disclaimer-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  img {
    filter: invert(34%) sepia(84%) saturate(4273%) hue-rotate(340deg)
      brightness(94%) contrast(122%);
  }
}
::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Sale {
  background: $lightgreen !important;
  color: $green !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-LeaseOut {
  background: $lightpink !important;
  color: $magenta !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Purchase {
  background: $lightblue !important;
  color: $blue !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-AquireLease {
  background: $beige !important;
  color: $orange !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Referral {
  background: $lightbrown !important;
  color: $brown !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;

  background: #fff;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}

.modal-wrapper {
  position: absolute;
  right: 0;
  top: 0%;
  bottom: 0%;
  background-color: #ffffff;
  padding-left: 0px;
  z-index: 7;
  width: 560px;
  //border: 1px solid #e6e8ec;
  overflow-y: scroll;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.client-dropdown-row {
  display: flex;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.restore-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

.input-edit {
  margin-top: 12px;
}

.input-with-apartment {
  display: flex;
  justify-content: space-between;

  #project-estateType-field {
    width: 60%;
  }

  #project-apartmentNumber {
    width: 38%;
  }
}

::v-deep .v-messages {
  display: initial;
}

::v-deep .radio-group-label .v-label {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
}

::v-deep .v-input__control {
  flex-direction: unset;
}
</style>
