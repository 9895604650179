var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'toast-wrapper toast-success': _vm.type === 'success',
      'toast-wrapper toast-error': _vm.type === 'error'
    }
  }, [_vm.type === 'success' ? _c('img', {
    attrs: {
      "src": require("../../assets/images/checkmark-14.svg"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("../../assets/images/alert.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "toast-message-wrapper"
  }, [_c('p', {
    class: {
      'toast-message toast-success-message': _vm.type === 'success',
      'toast-message toast-error-message': _vm.type === 'error'
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }