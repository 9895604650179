<template>
  <span
      v-if="info.length > 0"
      class="project-pipeline-info-container content-small"
  >
    {{ info.join(" • ") }}
  </span>
</template>

<script>
import moment from "moment";

export default {
  name: "PipelineInfoContainer",
  props: ["projectPipelineStepTimeLog"],
  data: () => ({
    info: []
  }),
  created() {
    if (!this.projectPipelineStepTimeLog || this.projectPipelineStepTimeLog.length === 0) return

    const currentPipeline = this.projectPipelineStepTimeLog[this.projectPipelineStepTimeLog.length - 1];

    if (currentPipeline.to) {
      const nameInfo = []
      const pipelineStepNumber = this.getPipelineStepNumber(currentPipeline.to);
      if (pipelineStepNumber) nameInfo.push(pipelineStepNumber)
      const name = this.$pfMapper.pipelineStep(currentPipeline.to)
      nameInfo.push(name)
      this.info.push(nameInfo.join(" "))
    }

    if (currentPipeline.date) {
      const dateInfo =
          this.$t("Last") + " " + this.$t("Activity").toLocaleLowerCase()
          + " " + moment(currentPipeline.date).format("DD.MM.YYYY")
      this.info.push(dateInfo)
    }
  },
  mounted() {},
  methods: {
    getPipelineStepNumber(pipelineName) {
      switch (pipelineName) {
        case "qualified":
          return 1;
        case "contactMade":
          return 2;
        case "preparation":
          return 3;
        case "review":
          return 4;
        case "Finished":
          return 5;
        case "Done":
          return 6;
        default:
          return null;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.project-pipeline-info-container {
  background: $grey;
  border-radius: 4px;
  padding: 2px 4px;
}
</style>
