var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _this.showDropdown = false;
      },
      expression: "() => (this.showDropdown = false)"
    }],
    class: {
      'marginTop': _vm.label === 'Contact' || _vm.label === 'Owner'
    },
    staticStyle: {
      "display": "table",
      "width": "100%",
      "margin-top": "16px"
    },
    attrs: {
      "id": _vm.dropdownId
    },
    on: {
      "click": function ($event) {
        return _vm.rowClick($event);
      }
    }
  }, [_c('div', {
    staticClass: "dropdown",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "dropbtn-hover",
    class: {
      'dropbtn': _vm.label !== 'Owner',
      'dropbtn-two': (_vm.label === 'Owner' || _vm.label === 'Project') && _vm.label !== 'Contact',
      'fixed-height': !_vm.selectedItem || !_vm.selectedItem && _vm.label === 'Owner'
    },
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "label-and-item-wrapper",
    staticStyle: {
      "height": "20px"
    },
    on: {
      "click": function ($event) {
        return _vm.openPanel(_vm.label, _vm.selectedItem);
      }
    }
  }, [_c('p', {
    staticClass: "justify-center",
    class: {
      '': _vm.label === 'Owner',
      'cursor-class': _vm.label !== 'Owner'
    },
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "height": "20px",
      "width": "20px"
    }
  }, [_c('img', {
    staticClass: "icon-img",
    attrs: {
      "src": require(`../../assets/images/${_vm.icon}`),
      "alt": ""
    }
  })])]), _c('v-col', {
    class: {
      '': _vm.label === 'Owner',
      'cursor-class': _vm.label !== 'Owner'
    },
    staticStyle: {
      "text-align": "left",
      "display": "inline-grid",
      "min-height": "32px",
      "width": "100%"
    },
    on: {
      "click": function ($event) {
        return _vm.openPanel(_vm.label, _vm.selectedItem);
      }
    }
  }, [_c('span', {
    staticClass: "entry-field-label",
    staticStyle: {
      "align-items": "center",
      "width": "100%",
      "height": "16px"
    }
  }, [_c('span', {
    class: {
      'entry-field-label': _vm.selectedItem,
      'entry-field-label padding-content': !_vm.selectedItem || !_vm.selectedItem[_vm.displayKey],
      'required': _vm.fieldRequired
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")])]), !_vm.fieldRequired && _vm.allowDelete && _vm.selectedItem && _vm.selectedItem[_vm.displayKey] ? _c('span', {
    staticStyle: {
      "width": "16px",
      "height": "16px",
      "margin-bottom": "0px"
    }
  }, [_c('img', {
    staticClass: "icon-img",
    staticStyle: {
      "background-color": "#e6e8ec",
      "border-radius": "4px"
    },
    attrs: {
      "src": require(`@/assets/images/close.svg`),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        _vm.removeItem();
        _vm.showDropdown = true;
      }
    }
  })]) : _vm._e()]), _c('v-row', {
    staticClass: "entry-field-value"
  }, [_vm._v(" " + _vm._s(_vm.selectedItem && _vm.selectedItem[_vm.displayKey] ? _vm.selectedItem[_vm.displayKey] : "") + " ")])], 1)], 1), _c('div', {
    staticClass: "dropdown-single scrollable",
    style: 'display: none; z-index:1; display: ' + (_vm.showDropdown && !_vm.disabled ? 'flex' : 'none'),
    attrs: {
      "id": _vm.dropdownId
    }
  }, [_c('v-row', [_c('v-text-field', {
    staticStyle: {
      "background": "white",
      "border": "0px !important"
    },
    attrs: {
      "autocomplete": "off",
      "outlined": "",
      "hide-details": "",
      "id": "search-bar",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": _vm.$t('Search') + '...',
      "shaped": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('div', {
    staticClass: "separator"
  }), _vm._l(_vm.foundItems, function (item) {
    return _c('v-row', {
      key: item._id,
      staticClass: "dropdown-item-wrapper",
      on: {
        "click": function ($event) {
          return _vm.selectItem(item);
        }
      }
    }, [_c('p', {
      staticClass: "small-size-text",
      style: _vm.selectedItem && _vm.selectedItem[_vm.valueKey] === item[_vm.valueKey] ? 'font-weight: bold' : null
    }, [_vm._v(" " + _vm._s(item[_vm.displayKey]) + " ")])]);
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }