<template>
  <div class="contact-tags">
    <ContactTagContainer
        v-for="(tagName) in tags"
        :key="tagName"
        :tag-name="tagName"
    />
  </div>
</template>

<script>

import ContactTagContainer from "@/components/Kliendiregister/ContactTagContainer";
export default {
  name: "ContactTags",
  components: {ContactTagContainer},
  props: ["tagsObject"],
  computed: {
    tags() {
      return Object.entries(this.tagsObject)
          .filter(([,v]) => v)
          .map(([k]) => k);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.contact-tags {
  display: flex;
  gap: 4px;
}

</style>