import { render, staticRenderFns } from "./InterestComment.vue?vue&type=template&id=5cea1e4a&scoped=true"
import script from "./InterestComment.vue?vue&type=script&lang=js"
export * from "./InterestComment.vue?vue&type=script&lang=js"
import style0 from "./InterestComment.vue?vue&type=style&index=0&id=5cea1e4a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cea1e4a",
  null
  
)

export default component.exports