var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "selection-background"
  }, [_c('div', {
    staticClass: "tab-row"
  }, [_c('div', {
    staticClass: "tab",
    class: _vm.tab === 'private' ? 'tab-selected' : null,
    on: {
      "click": function ($event) {
        return _vm.changeTab('private');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PrivatePerson.Label")) + " ")]), _c('div', {
    staticClass: "tab",
    class: _vm.tab === 'company' ? 'tab-selected' : null,
    staticStyle: {
      "margin-left": "24px"
    },
    on: {
      "click": function ($event) {
        return _vm.changeTab('company');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Organization')) + " ")])]), _vm.tab === 'private' ? _c('div', [_vm.clientSearchOpen ? _c('div', [_c('ClientListSearch', {
    attrs: {
      "placeholder": _vm.$t('Client'),
      "icon-left": 'clients.svg'
    },
    on: {
      "addNew": _vm.openContactAdd,
      "itemChosen": _vm.selectClient,
      "closed": _vm.closeClientListSearch
    }
  })], 1) : _vm._e(), !_vm.clientSearchOpen ? _c('div', {
    staticClass: "options-row"
  }, _vm._l(_vm.privateOptions, function (option, index) {
    return _c('div', {
      key: option.name
    }, [option.value !== 'new_contact' ? _c('div', {
      class: option.selected ? 'chosen-object' : 'regular-object',
      staticStyle: {
        "cursor": "default"
      },
      attrs: {
        "id": 'private-' + index
      },
      on: {
        "click": function ($event) {
          option.selected ? null : _vm.selectPrivateOption(option);
        }
      }
    }, [option.value === 'new_contact' ? _c('img', {
      staticStyle: {
        "margin-right": "8px"
      },
      attrs: {
        "src": require("../../assets/images/plusSign.svg"),
        "alt": ""
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(option.name) + " "), option.selected && option.value !== 'same_as_contact' ? _c('img', {
      staticClass: "chosen-object-icon",
      attrs: {
        "src": require("../../assets/images/action_close_borderless.svg"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          option.selected ? _vm.selectPrivateOption(option) : null;
        }
      }
    }) : _vm._e()]) : _vm._e(), option.value === 'new_contact' && (!_vm.chosenNewPrivateClient || _vm.privateOptions.length === 2) && !_vm.addContactButtonHidden ? _c('div', {
      staticClass: "regular-object",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.selectPrivateOption(option);
        }
      }
    }, [_c('img', {
      staticStyle: {
        "margin-right": "8px"
      },
      attrs: {
        "src": require("../../assets/images/plusSign.svg"),
        "alt": ""
      }
    }), _vm._v(" " + _vm._s(option.name) + " ")]) : _vm._e()]);
  }), 0) : _vm._e()]) : _vm._e(), _vm.tab === 'company' ? _c('div', {
    staticClass: "options-row"
  }, [_vm.companyOptions.length > 0 ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap",
      "gap": "8px"
    }
  }, _vm._l(_vm.companyOptions, function (option, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      class: option.selected ? 'chosen-object' : 'regular-object',
      staticStyle: {
        "cursor": "default"
      },
      attrs: {
        "id": 'company-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.selectCompanyOption(option, index);
        }
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")])]);
  }), 0) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('AddOrganizationToContact')) + " ")])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }