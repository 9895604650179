var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "my-application"
  }, [_vm.sidepanelDealsAdd ? _c('Sidepanel') : _vm._e(), _vm.sidepanelPropertiesAdd ? _c('SidepanelProperty') : _vm._e(), _vm.sidepanelDevelopmentsAdd ? _c('SidepanelDeveloper') : _vm._e(), _vm.invoiceSidepanelOpen || _vm.invoiceUserSidepanelOpen ? _c('div', {
    staticClass: "sidepanels-wrapper",
    attrs: {
      "id": "sidepanels-wrapper"
    }
  }, [_vm.invoiceSidepanelOpen ? _c('InvoiceSidepanel') : _vm._e(), _vm.invoiceUserSidepanelOpen ? _c('InvoiceBrokerSidepanel') : _vm._e()], 1) : _vm._e(), _vm.sidePanelContactAdd ? _c('ContactAddModal', {
    staticStyle: {
      "z-index": "150"
    },
    attrs: {
      "not-contact-view-route": true,
      "isEdit": false
    },
    on: {
      "closed": function ($event) {
        return _vm.toggleContactAddPanel(0);
      }
    }
  }) : _vm._e(), _vm.newVersionAvailable ? _c('BuildUpdateNotification', {
    on: {
      "update": _vm.updateBuild
    }
  }) : _vm._e(), _c('WelcomeNotification'), _vm.$route.name && _vm.showRoutes ? _c('Topbar', {
    class: {
      '': !_vm.newVersionAvailable,
      'top-88': _vm.newVersionAvailable
    }
  }) : _vm._e(), _vm.$route.name && _vm.showRoutes ? _c('NavBar', {
    class: {
      '': !_vm.newVersionAvailable,
      'top-64': _vm.newVersionAvailable
    }
  }) : _vm._e(), _c('v-main', {
    staticClass: "main-wrapper",
    class: {
      'main-wrapper': !_vm.newVersionAvailable,
      'main-wrapper': _vm.newVersionAvailable
    }
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }