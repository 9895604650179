<template>
  <div style="width: 100%; display: flex; flex-direction: column; gap: 16px">
    <div
      class="interest"
      v-for="(interest, index) in interests"
      :key="interest._id"
    >
      <div class="interest-title-wrapper" :id="'title' + index">
        <div class="interest-title-row">
          <div
            class="development-heading-wrapper"
            v-if="interest.development && interest.development.name"
          >
            <div class="development-tag">
              <p class="content-bold-small">{{ $t("NewDevelopment") }}</p>
            </div>

            <div class="interest-heading">
              <p class="interest-title-text">{{ interest.development.name }}</p>
            </div>
          </div>
          <div v-if="!interest.development" class="interest-heading">
            <p
              class="interest-title-text"
              v-bind:class="{
                'interest-title-text': interest.statusCode === 1,
                'interest-title-text inactive-text': interest.statusCode !== 1,
              }"
            >
              {{ formatTransactionType(interest.transactionType) }}
            </p>

            <img
              v-bind:class="{
                'point-separator': interest.statusCode === 1,
                'point-separator inactive-svg': interest.statusCode !== 1,
              }"
              src="../../assets/images/•.svg"
              alt=""
            />
            <p
              v-bind:class="{
                'interest-title-text': interest.statusCode === 1,
                'interest-title-text inactive-text': interest.statusCode !== 1,
              }"
              v-if="interest.estateType"
            >
              {{ formatEstateType(interest.estateType) }}
            </p>
            <img
              v-if="interest.address.county"
              v-bind:class="{
                'point-separator': interest.statusCode === 1,
                'point-separator inactive-svg': interest.statusCode !== 1,
              }"
              src="../../assets/images/•.svg"
              alt=""
            />
            <p
              v-if="interest.address.county"
              v-bind:class="{
                'interest-address-text': interest.statusCode === 1,
                'interest-address-text inactive-text':
                  interest.statusCode !== 1,
              }"
            >
              {{ interestTitle(interest) }}
            </p>
          </div>
          <IconDropdown
            style="border-radius: 8px"
            :items="interestActions(interest)"
            :display-key="'name'"
            :value-key="'value'"
            :icon="'dot_menu.svg'"
            @itemSelected="selectInterestAction($event, interest)"
          />
        </div>
        <div class="interest-buttons-row">
          <div
            :key="countUpdater"
            @click="openExtras(interest._id, 'listings')"
            v-bind:class="{
              'interest-button': !openedExtras.listings.includes(interest._id),
              'interest-button button-active': openedExtras.listings.includes(
                interest._id
              ),
            }"
          >
            <img src="../../assets/images/objects.svg" alt="" />
            <p class="button-small">
              {{ listingsCount(interest) }}&nbsp;{{ $t("Listingst") }}
            </p>
          </div>
          <div
            @click="openExtras(interest._id, 'details')"
            v-bind:class="{
              'interest-button': !openedExtras.details.includes(interest._id),
              'interest-button button-active': openedExtras.details.includes(
                interest._id
              ),
            }"
          >
            <img src="../../assets/images/info.svg" alt="" />
            <p class="button-small">{{ $t("Details") }}</p>
          </div>
          <div
            @click="openExtras(interest._id, 'comments')"
            v-bind:class="{
              'interest-button': !openedExtras.comments.includes(interest._id),
              'interest-button button-active': openedExtras.comments.includes(
                interest._id
              ),
            }"
          >
            <img
              v-if="interest.comment"
              src="../../assets/images/chat-bubble.svg"
              alt=""
            />
            <img v-else src="../../assets/images/chat-no-bubble.svg" alt="" />

            <p class="button-small">{{ $t("Comment") }}</p>
          </div>
          <div
            @click="openExtras(interest._id, 'settings')"
            v-bind:class="{
              'interest-button': !openedExtras.settings.includes(interest._id),
              'interest-button button-active': openedExtras.settings.includes(
                interest._id
              ),
            }"
          >
            <img src="../../assets/images/admin.svg" alt="" />
            <p class="button-small">{{ $t("Settings") }}</p>
          </div>
        </div>
      </div>
      <InterestListings
        @openListingPreview="openListingPanel"
        @closeListingPreview="closeListingPanel"
        @funnelRemoved="$emit('funnelRemoved')"
        @refreshLeads="refresh"
        @autoSuggestionsCount="setAutoSuggestionsCount"
        :key="listingsUpdater"
        v-show="openedExtras.listings.includes(interest._id)"
        :customer-id="customer.customerId"
        :interest="interest"
        :chosen-listing-preview-id="chosenListingPreviewId"
        :chosen-interest-id="chosenInterestId"
      />
      <InterestComment
        :comment="interest.comment"
        :customer-id="customer.customerId"
        :interest="interest"
        @commentUpdated="refresh"
        v-show="openedExtras.comments.includes(interest._id)"
      />
      <InterestDetails
        :interest="interest"
        v-show="openedExtras.details.includes(interest._id)"
        @editInterest="editFromDetails($event)"
      />
      <InterestSettings
        :interest="interest"
        v-show="openedExtras.settings.includes(interest._id)"
        @changeInterestStatus="changeInterestStatus"
        @changeInterestWideSearch="changeInterestWideSearch"
      />
    </div>
  </div>
</template>

<script>
import nanoid from "nanoid";
import IconDropdown from "@/components/common/IconDropdown";
import InterestListings from "@/components/Kliendiregister/InterestListings";
import InterestDetails from "@/components/Kliendiregister/InterestDetails";
import InterestComment from "@/components/Kliendiregister/InterestComment";
import InterestSettings from "@/components/Kliendiregister/InterestSettings";
import contactApi from "@/http/contact";
import axios from "axios";

export default {
  name: "InterestsArea",
  props: ["interestsData", "customerData", "listingKey"],
  components: {
    IconDropdown,
    InterestListings,
    InterestComment,
    InterestDetails,
    InterestSettings,
  },
  data() {
    return {
      customer: null,
      interests: null,
      chosenListingPreviewId: null,
      chosenInterestId: null,
      openedExtras: {
        listings: [],
        details: [],
        comments: [],
        settings: [],
      },
      openedDetails: [],
      openedComments: [],
      autoSuggestionsCounts: {},
      countUpdater: 0,
      listingsUpdater: 0,
    };
  },
  created() {
    this.customer = this.customerData;
    console.log("kontakt", this.customer);
    this.interests = this.interestsData;
  },
  watch: {
    listingKey: function () {
      this.chosenListingPreviewId = null;
      this.chosenInterestId = null;
    },
  },
  methods: {
    async getCustomerInterests() {
      const newInterest = await contactApi.getInterests(
        this.customer.customerId
      );
      this.customer.interests = newInterest;
      this.interests = newInterest;
    },
    async changeInterestStatus(status, interest) {
      let newStatus = status ? 1 : 0;
      await contactApi.changeInterestStatus(this.customer.customerId, {
        interest,
        status: newStatus,
      });
      await this.refresh();
    },
    async changeInterestWideSearch(wideSearch, interest) {
      await contactApi.changeInterestWideSearch(this.customer.customerId, {
        interest,
        wideSearch: wideSearch,
      });
      await this.refresh();
    },
    interestActions(interest) {
      if (interest.development && this.customer.isProspect) {
        return [
          {
            name: this.$t("Edit"),
            value: "edit",
            _id: nanoid(8),
          },
        ];
      }
      if (interest.development && !this.customer.isProspect) {
        return [
          {
            name: this.$t("Edit"),
            value: "edit",
            _id: nanoid(8),
          },
          {
            name: this.$t("Remove"),
            value: "delete",
            _id: nanoid(8),
          },
        ];
      } else {
        return [
          {
            name: this.$t("Edit"),
            value: "edit",
            _id: nanoid(8),
          },
          {
            name: this.$t("Duplicate"),
            value: "duplicate",
            _id: nanoid(8),
          },
          {
            name: this.$t("Remove"),
            value: "delete",
            _id: nanoid(8),
          },
        ];
      }
    },
    interestTitle(interest) {
      const county = interest.address.county.name;
      const cities = interest.address.cities;
      const cityParts = interest.address.cityParts;

      let title = county;
      if (cities) {
        cities.forEach((city) => {
          title = title + ", " + city.name;
        });
      }
      if (cityParts) {
        cityParts.forEach((citypart) => {
          title = title + ", " + citypart.name;
        });
      }
      return title;
    },
    listingsCount(interest) {
      if (!this.autoSuggestionsCounts[interest._id]) {
        return `${interest.funnels.length}/${interest.funnels.length}`;
      }
      return `${interest.funnels.length}/${
        this.autoSuggestionsCounts[interest._id] + interest.funnels.length
      }`;
    },
    async refresh() {
      await this.getCustomerInterests();
      this.$emit("refreshLeads");
      this.listingsUpdater++;
    },
    async editFromDetails(interest) {
      await this.selectInterestAction({ value: "edit" }, interest);
    },
    setAutoSuggestionsCount(interest, count) {
      this.autoSuggestionsCounts[interest] = count;
      this.countUpdater++;
    },
    openListingPanel(listingId, interestId) {
      if (
        this.chosenListingPreviewId === listingId &&
        this.chosenInterestId === interestId
      ) {
        this.closeListingPanel(listingId, interestId);
      } else {
        this.closeListingPanel();
        this.chosenListingPreviewId = listingId;
        this.chosenInterestId = interestId;
        this.$emit(
          "openListingPanel",
          this.chosenListingPreviewId,
          this.chosenInterestId
        );
      }
    },
    closeListingPanel(listingId, interestId) {
      if (!listingId && !interestId) {
        listingId = this.chosenListingPreviewId;
        interestId = this.chosenInterestId;
      }
      if (listingId && interestId) {
        this.chosenListingPreviewId = null;
      }
      this.$emit("closeListingPanel", listingId, interestId);
    },
    openExtras(interestId, extraType) {
      if (this.openedExtras[extraType].includes(interestId)) {
        this.openedExtras[extraType] = this.openedExtras[extraType].filter(
          (e) => e !== interestId
        );
      } else {
        Object.keys(this.openedExtras).forEach((extra) => {
          this.openedExtras[extra] = this.openedExtras[extra].filter(
            (e) => e !== interestId
          );
        });
        this.openedExtras[extraType].push(interestId);
      }
    },
    formatEstateType(estateType) {
      if (estateType == "commercial") {
        return this.$t("Commercial");
      } else if (estateType == "apartment") {
        return this.$t("Apartment");
      } else if (estateType == "house") {
        return this.$t("House");
      } else if (estateType == "house_part") {
        return this.$t("HousePart");
      } else if (estateType == "land") {
        return this.$t("LandPlot");
      } else if (estateType == "cottage") {
        return this.$t("SummerHouse");
      } else if (estateType == "garage") {
        return this.$t("Garage");
      }
    },
    formatTransactionType(type) {
      switch (type) {
        case "rent":
          return this.$t("Rent");
        case "sale":
          return this.$t("Purchase");
        case "short_term_rent":
          return this.$t("ShortTermRent");
      }
    },
    async selectInterestAction(action, interest) {
      try {
        if (action.value === "edit") {
          this.$emit("editInterest", interest);
        }
        if (action.value === "duplicate") {
          let interestToDuplicate = interest;
          interestToDuplicate._id = null;
          await axios.post(
            `/api/contact/interest/${this.customer.customerId}`,
            interestToDuplicate
          );
          await this.refresh();
          //this.listingsUpdater++;
        }
        if (action.value === "delete") {
          await contactApi.deleteInterest(this.customer.customerId, interest);
          await this.refresh();
          //this.listingsUpdater++;
        }
      } catch (e) {
        this.toastError(this.$t("ErrorAdding"));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.customer-interests {
  margin-bottom: 12px;
}
.interest {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
}
.interest-title-wrapper {
  width: 100%;
  padding: 16px;
}
.interest-title-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}
.interest-heading {
  display: flex;
  flex-direction: row;
  width: 85%;
  align-items: center;
}
.inactive-text {
  color: #75787a;
}
.interest-title-text {
  white-space: nowrap;
  &:first-letter {
    text-transform: capitalize;
  }
}
.interest-buttons-row {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  gap: 12px;
}
.interest-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 8px;
  width: 128px;
  height: 32px;
  background: #f4f5f7;
  border-radius: 8px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
}
.button-active {
  p {
    color: white;
  }
  background: #ff5c01;
  img {
    filter: invert(100%) sepia(0%) saturate(7493%) hue-rotate(186deg)
      brightness(108%) contrast(95%);
  }
}
.point-separator {
  margin-left: 8px;
  margin-right: 8px;
  width: 8px;
  height: 8px;
}
.inactive-svg {
  filter: invert(48%) sepia(1%) saturate(1099%) hue-rotate(161deg)
    brightness(96%) contrast(85%);
}
.interest-address-text {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.development-heading-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.development-tag {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  gap: 8px;
  height: 20px;
  border: 2px solid #16afb9;
  border-radius: 4px;
  width: fit-content;
  p {
    color: #16afb9;
  }
}
</style>
