import Vue from "vue";

const nMapper = {
  price(price, currency) {
    if (!price && currency) return "0" + " " + currency;
    if (!price && !currency) return "0";

    let parts = price.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    let formattedPrice = parts.join(".");

    if (currency) {
      return formattedPrice + " " + currency;
    }

    return formattedPrice;
  },
};

const plugin = {
  install() {
    Vue.nMapper = nMapper;
    Vue.prototype.$nMapper = nMapper;
  },
};

Vue.use(plugin);
