var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: _vm.OverlayStyle,
    attrs: {
      "id": "broker-invoice-overlay"
    }
  }, [_vm.selectedInvoice ? _c('div', {
    staticClass: "invoice-panel-header-sideways",
    class: {
      'project-sidepanel-open': _vm.projectClientAddSpidepanelOpen,
      'project-sidepanel-closed': !_vm.projectClientAddSpidepanelOpen
    },
    style: _vm.sidewaysHeaderStyle,
    attrs: {
      "id": "broker-invoice-header"
    },
    on: {
      "click": function ($event) {
        return _vm.closeSidepanels();
      }
    }
  }, [_vm.invoiceUser ? _c('h4', [_vm._v(_vm._s(_vm.invoiceUser.firstname + " " + _vm.invoiceUser.lastname))]) : _vm._e()]) : _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function ($event) {
        return _vm.closeModal($event);
      },
      expression: "($event) => closeModal($event)"
    }],
    staticClass: "invoice-panel",
    attrs: {
      "id": "invoice-panel"
    }
  }, [_c('div', {
    staticClass: "invoice-panel-header"
  }, [_vm.invoiceUser ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "width": "100%"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.invoiceUser.firstname + " " + _vm.invoiceUser.lastname))])]) : _vm._e()]), _c('div', {
    staticClass: "invoice-panel-content"
  }, [!_vm.invoiceLoading && _vm.userInvoices.length > 0 ? [_c('h5', [_vm._v(_vm._s(_vm.$t("Invoices")))]), _c('div', {
    staticClass: "broker-invoice-container"
  }, _vm._l(_vm.userInvoices, function (invoice, index) {
    var _invoice$sharedBroker;
    return _c('div', {
      key: 'broker-invoice-' + index,
      staticClass: "invoice-card",
      style: _vm.hoverInvoiceIndex === index ? _vm.hoverInvoiceStyle : null,
      on: {
        "mouseover": function ($event) {
          return _vm.hoveringInvoice(index);
        },
        "mouseleave": _vm.hoveringInvoiceStopped,
        "click": function ($event) {
          return _vm.openInvoiceSidepanel(invoice);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap",
        "gap": "12px"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "100%",
        "display": "flex",
        "gap": "8px"
      }
    }, [_c('p', {
      staticClass: "content-bold"
    }, [_vm._v(_vm._s(_vm.$t('Invoice') + ' ' + (invoice.number ? invoice.number : '')))]), (invoice === null || invoice === void 0 ? void 0 : (_invoice$sharedBroker = invoice.sharedBrokers) === null || _invoice$sharedBroker === void 0 ? void 0 : _invoice$sharedBroker.length) > 0 ? _c('img', {
      attrs: {
        "src": require('@/assets/images/additional_users.svg'),
        "width": "20",
        "height": "20",
        "alt": ""
      }
    }) : _vm._e()]), _c('div', {
      on: {
        "mouseover": _vm.stopHoveringInvoice,
        "mouseleave": _vm.enableHovering
      }
    }, [_c('StatusDropdown', {
      attrs: {
        "show-additional-info": true,
        "invoice": invoice
      },
      on: {
        "reloadInvoice": _vm.getBrokerInvoices
      }
    })], 1), _c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "4px",
        "white-space": "nowrap",
        "min-width": "104px"
      }
    }, [_c('p', {
      staticClass: "content-semibold"
    }, [_vm._v(_vm._s(_vm.$nMapper.price(invoice.sum, '€')))]), invoice.totalSum ? _c('p', {
      staticClass: "content-small"
    }, [_vm._v("/ " + _vm._s(_vm.$nMapper.price(invoice.totalSum, '€')))]) : _vm._e()])]), invoice.project ? _c('div', {
      staticClass: "content-small",
      staticStyle: {
        "display": "block",
        "white-space": "nowrap",
        "text-overflow": "ellipsis",
        "overflow": "hidden"
      }
    }, [_vm._v(" " + _vm._s(invoice.project.name) + " ")]) : _vm._e()]);
  }), 0)] : [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t('NoInvoices')))])]], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }