var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('div', {
    class: {
      'top-row': _vm.$route.name !== 'DevelopmentGeneral',
      'top-row padding-left-96': _vm.$route.name === 'DevelopmentGeneral'
    }
  }, [_c('div', {
    staticClass: "left-top-row"
  }, [_vm.showBackButton ? _c('div', {
    staticClass: "back-button",
    on: {
      "click": _vm.updateBackButton
    }
  }, [_c('img', {
    attrs: {
      "height": "24",
      "width": "24",
      "src": require('@/assets/images/chevron-left.svg'),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("Back")))])]) : _vm._e()]), _c('div', {
    staticClass: "right-top-row"
  }, [_c('button', {
    staticClass: "transparent-button",
    staticStyle: {
      "margin-right": "40px"
    },
    attrs: {
      "name": "send-offers-button"
    },
    on: {
      "click": _vm.openOffersSidepanel
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/ads_20_20.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("SendOffer")) + " ")]), _c('div', {
    staticClass: "offers-amount content-small",
    class: {
      'offers-amount-red': _vm.offersLeadsCount > 0
    }
  }, [_vm._v(" " + _vm._s(_vm.offersLeadsCount) + " ")])]), _vm.user ? _c('Notifications', {
    staticStyle: {
      "margin-right": "12px"
    }
  }) : _vm._e(), _c('Info', {
    staticStyle: {
      "margin-right": "40px"
    }
  }), _c('UserSettingsButtonDropdown', {
    attrs: {
      "current-user": _vm.user
    }
  }), _c('div', [_vm.offersSidepanelOpen ? _c('OffersSidepanel', {
    on: {
      "closePanel": function ($event) {
        _vm.offersSidepanelOpen = false;
      },
      "openClientPanel": _vm.openClientFromOffers
    }
  }) : _vm._e(), _vm.clientSidepanelOpen ? _c('ClientSidepanel', {
    attrs: {
      "customer-data": _vm.offersCustomerId,
      "only-panel": true
    },
    on: {
      "funnelRemoved": function ($event) {
        return _vm.getListings();
      },
      "panelClosed": function ($event) {
        _vm.clientSidepanelOpen = false;
      }
    }
  }) : _vm._e()], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }