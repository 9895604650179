var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "extras",
    attrs: {
      "id": "extra-section"
    }
  }, [_c('v-row', {
    staticClass: "extra-info-row",
    attrs: {
      "id": "extra-info-wrapper"
    }
  }, [_c('v-col', {
    staticClass: "icon-col"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/info.svg"),
      "alt": ""
    }
  })]), _c('v-col', {
    staticClass: "info-col"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Extras")))]), _c('v-row', {
    attrs: {
      "id": "info-text-row"
    }
  }, [_vm.listing.balcony && _vm.listing.balconySize && _vm.listing.balconySize != 0 ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Balcony") + " ") + _vm._s(_vm.listing.balconySize) + " m²,  ")]) : _vm._e(), _vm.listing.balcony && (!_vm.listing.balconySize || _vm.listing.balconySize == 0) ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Balcony")) + ",  ")]) : _vm._e(), _vm.listing.closedBalcony ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClosedBalcony")) + ",  ")]) : _vm._e(), _vm.listing.fullyGlazedBalcony ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FullyGlazedBalcony")) + ",  ")]) : _vm._e(), _vm.listing.frenchBalcony ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FrenchBalcony")) + ",  ")]) : _vm._e(), _vm.listing.garage ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Garage")) + ",  ")]) : _vm._e(), _vm.listing.undergroundGarage ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("UndergroundGarage")) + ",  ")]) : _vm._e(), _vm.listing.lift ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Lift")) + ",  ")]) : _vm._e(), _vm.listing.parquet ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Parquet")) + ",  ")]) : _vm._e(), _vm.listing.packetWindows ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("PacketWindows")) + ",  ")]) : _vm._e(), _vm.listing.furniture ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Furniture")) + ",  ")]) : _vm._e(), _vm.listing.furnitureAbility ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FurnitureChangePossibility")) + ",  ")]) : _vm._e(), _vm.listing.severalFloorsPlanning ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("PlanThroughMultipleFloors")) + ",  ")]) : _vm._e(), _vm.listing.highCeilings ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("HighCeilings")) + ",  ")]) : _vm._e(), _vm.listing.electricalWiring ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("NewWiring")) + ",  ")]) : _vm._e(), _vm.listing.electricity ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Electricity")) + ",  ")]) : _vm._e(), _vm.listing.wardrobe ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Wardrobe")) + ",  ")]) : _vm._e(), _vm.listing.attic ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Attic")) + ",  ")]) : _vm._e(), _vm.listing.storeRoom ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Storeroom")) + ",  ")]) : _vm._e(), _vm.listing.terrace ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Terrace")) + ",  ")]) : _vm._e(), _vm.listing.showCaseWindows ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ShowCaseWindows")) + ",  ")]) : _vm._e(), _vm.listing.streetEntrance ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("StreetEntrance")) + ",  ")]) : _vm._e(), _vm.listing.trestle ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Trestle")) + ",  ")]) : _vm._e(), _vm.listing.goodsLift ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("GoodsLift")) + ",  ")]) : _vm._e(), _vm.listing.roofType ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.formatRoofType(_vm.listing.roofType)) + ",  ")]) : _vm._e(), _vm.listing.parkingOption && _vm.listing.parkingOption != 'NO_PARKING' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.formatParkingOption(_vm.listing.parkingOption) + " ") + _vm._s(_vm.$t("Parking").toLowerCase()) + ",  ")]) : _vm._e(), _vm.listing.numberOfDesks && _vm.listing.estateType == 'commercial' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("NumberOfDesks") + " (") + _vm._s(_vm.listing.numberOfDesks + ")") + ",  ")]) : _vm._e(), _vm.listing.tv ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("TV")) + ",  ")]) : _vm._e(), _vm.listing.basementFloor ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BasementFloor")) + ",  ")]) : _vm._e(), _vm.listing.rooftopApartment ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("RoofTopApartment")) + ",  ")]) : _vm._e(), _vm.listing.separateEntrance ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SeparateEntrance")) + ",  ")]) : _vm._e(), _vm.listing.closedYard ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClosedYard")) + ",  ")]) : _vm._e(), _vm.listing.meetingRoom ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("MeetingRoom")) + ",  ")]) : _vm._e(), _vm.listing.industrialPowerSupply ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("IndustrialPowerSupply")) + ",  ")]) : _vm._e(), _vm.listing.climaticDevice ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClimaticDevice")) + ",  ")]) : _vm._e(), _vm.listing.ventilation ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Ventilation")) + ",  ")]) : _vm._e(), _vm.listing.well ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Well")) + ",  ")]) : _vm._e(), _vm.listing.sewerage ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sewerage")) + ",  ")]) : _vm._e(), _vm.listing.gasIncluded ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("GasIncluded")) + ",  ")]) : _vm._e(), _vm.listing.water ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Water")) + ",  ")]) : _vm._e(), _vm.listing.road ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Road")) + ",  ")]) : _vm._e(), _vm.listing.publicTransport ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("PublicTransport")) + ",  ")]) : _vm._e(), _vm.listing.handicappedAdaptedAccess ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("HandicappedAdaptedAccess")) + ",  ")]) : _vm._e(), _vm.listing.handicappedAdaptedUse ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("HandicappedAdaptedUse")) + ",  ")]) : _vm._e(), _vm.listing.orientationNorth ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OrientationNorth")) + ",  ")]) : _vm._e(), _vm.listing.orientationSouth ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OrientationSouth")) + ",  ")]) : _vm._e(), _vm.listing.orientationWest ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OrientationWest")) + ",  ")]) : _vm._e(), _vm.listing.orientationEast ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OrientationEast")) + ",  ")]) : _vm._e(), _vm.listing.parkingPrice ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ParkingPrice") + " (") + _vm._s(_vm.listing.parkingPrice + "€)") + ",  ")]) : _vm._e(), _vm.listing.tenantNumber ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ParkingPrice") + " (") + _vm._s(_vm.listing.tenantNumber + ")") + ",  ")]) : _vm._e(), _vm.listing.recommendedForChildren ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("RecommendedForChildren")) + ",  ")]) : _vm._e(), _vm.listing.studio ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Studio")) + ",  ")]) : _vm._e(), _vm.listing.depositMonths ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("DepositMonths") + " (") + _vm._s(_vm.listing.depositMonths + ")") + ",  ")]) : _vm._e(), _vm.listing.isAuction ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("IsAuction")) + ",  ")]) : _vm._e(), _vm.listing.minAuctionBidIncrement ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("MinAuctionBidIncrement")) + ",  ")]) : _vm._e(), _vm.listing.auctionDeposit ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AuctionDeposit") + " (") + _vm._s(_vm.listing.auctionDeposit + "%)") + ",  ")]) : _vm._e(), _vm.listing.auctionDate ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AuctionDate") + " (") + _vm._s(_vm.$lfMapper.expiryDate(_vm.listing.auctionDate) + ")") + ",  ")]) : _vm._e(), _vm.listing.auctionTribunal ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AuctionTribunal") + " (") + _vm._s(_vm.listing.auctionTribunal + ")") + ",   ")]) : _vm._e(), _vm.listing.gardenType ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("GardenType") + " (") + _vm._s(_vm.listing.gardenType + ")") + ",   ")]) : _vm._e(), _vm.listing.parkingSpaceArea ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ParkingSpaceArea") + " (") + _vm._s(_vm.listing.parkingSpaceArea + "m²)") + ",   ")]) : _vm._e(), _vm.listing.outdoorParkingSpaceAvailable ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OutdoorParkingSpaceAvailable")) + ",   ")]) : _vm._e(), _vm.listing.outdoorParkingSpaceType ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OutdoorParkingSpaceType") + " (") + _vm._s(_vm.listing.outdoorParkingSpaceType + ")") + ",   ")]) : _vm._e(), _vm.listing.outdoorParkingSpaceNumber ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OutdoorParkingSpaceNumber") + " (") + _vm._s(_vm.listing.outdoorParkingSpaceNumber + ")") + ",   ")]) : _vm._e(), _vm.listing.energyCertificatePerformance ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("EnergyCertificatePerformance") + " (") + _vm._s(_vm.listing.energyCertificatePerformance + ")") + ",   ")]) : _vm._e(), _vm.listing.energyCertificateLaw ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("EnergyCertificateLaw") + " (") + _vm._s(_vm.listing.energyCertificateLaw + ")") + ",   ")]) : _vm._e(), _vm.listing.garden ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Garden")) + ",   ")]) : _vm._e(), _vm.listing.floorLevel ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FloorLevel") + " (") + _vm._s(_vm.listing.floorLevel + ")") + ",   ")]) : _vm._e(), _vm.listing.chimney ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Chimney")) + ",   ")]) : _vm._e(), _vm.listing.currentOccupation ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CurrentOccupation") + " (") + _vm._s(_vm.listing.currentOccupation + ")") + ",   ")]) : _vm._e(), _vm.listing.garageCapacity ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("GarageCapacity") + " (") + _vm._s(_vm.listing.garageCapacity + ")") + ",   ")]) : _vm._e(), _vm.listing.parkingAutomaticDoor ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ParkingAutomaticDoor")) + ",   ")]) : _vm._e(), _vm.listing.parkingPlaceCovered ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ParkingPlaceCovered")) + ",   ")]) : _vm._e(), _vm.listing.buildingAdapted ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BuildingAdapted")) + ",   ")]) : _vm._e(), _vm.listing.conditionedAirType ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ConditionedAirType") + " (") + _vm._s(_vm.listing.conditionedAirType + ")") + ",   ")]) : _vm._e(), _vm.listing.doorman ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Doorman")) + ",   ")]) : _vm._e(), _vm.listing.emergencyExit ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("EmergencyExit")) + ",   ")]) : _vm._e(), _vm.listing.emergencyLights ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("EmergencyLights")) + ",   ")]) : _vm._e(), _vm.listing.extinguishers ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Extinguishers")) + ",   ")]) : _vm._e(), _vm.listing.fireDetectors ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FireDetectors")) + ",   ")]) : _vm._e(), _vm.listing.fireDoors ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FireDoors")) + ",   ")]) : _vm._e(), _vm.listing.floorsProperty ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FloorsProperty") + " (") + _vm._s(_vm.listing.floorsProperty + ")") + ",   ")]) : _vm._e(), _vm.listing.officeBuilding ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OfficeBuilding")) + ",   ")]) : _vm._e(), _vm.listing.roomsSplitted ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("RoomsSplitted") + " (") + _vm._s(_vm.listing.roomsSplitted + ")") + ",   ")]) : _vm._e(), _vm.listing.sprinklers ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sprinklers")) + ",   ")]) : _vm._e(), _vm.listing.suspendedCeiling ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SuspendedCeiling")) + ",   ")]) : _vm._e(), _vm.listing.suspendedFloor ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SuspendedFloor")) + ",   ")]) : _vm._e(), _vm.listing.windowsDouble ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SindowsDouble")) + ",   ")]) : _vm._e(), _vm.listing.areaTradableMinimum ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AreaTradableMinimum")) + ",   ")]) : _vm._e(), _vm.listing.auxiliaryEntrance ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AuxiliaryEntrance")) + ",   ")]) : _vm._e(), _vm.listing.bathroomAdapted ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BathroomAdapted")) + ",   ")]) : _vm._e(), _vm.listing.bridgeCrane ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BridgeCrane")) + ",   ")]) : _vm._e(), _vm.listing.facadeArea ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FacadeArea") + " (") + _vm._s(_vm.listing.facadeArea + ")") + ",   ")]) : _vm._e(), _vm.listing.lastActivity ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LastActivity") + " (") + _vm._s(_vm.listing.lastActivity + ")") + ",   ")]) : _vm._e(), _vm.listing.loadingDock ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LoadingDock")) + ",   ")]) : _vm._e(), _vm.listing.locatedAtCorner ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LocatedAtCorner")) + ",   ")]) : _vm._e(), _vm.listing.smokeExtraction ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SmokeExtraction")) + ",   ")]) : _vm._e(), _vm.listing.windowsNumber ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("WindowsNumber") + " (") + _vm._s(_vm.listing.windowsNumber + ")") + ",   ")]) : _vm._e(), _vm.listing.classificationBlocks ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClassificationBlocks")) + ",   ")]) : _vm._e(), _vm.listing.classificationChalet ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClassificationChalet")) + ",   ")]) : _vm._e(), _vm.listing.classificationCommercial ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClassificationCommercial")) + ",   ")]) : _vm._e(), _vm.listing.classificationHotel ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClassificationHotel")) + ",   ")]) : _vm._e(), _vm.listing.classificationIndustrial ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClassificationIndustrial")) + ",   ")]) : _vm._e(), _vm.listing.classificationOffice ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClassificationOffice")) + ",   ")]) : _vm._e(), _vm.listing.classificationOther ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClassificationOther")) + ",   ")]) : _vm._e(), _vm.listing.classificationPublic ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ClassificationPublic")) + ",   ")]) : _vm._e(), _vm.listing.naturalGas ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("NaturalGas")) + ",   ")]) : _vm._e(), _vm.listing.areaHeight ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AreaHeight") + " (") + _vm._s(_vm.listing.areaHeight + ")") + ",  ")]) : _vm._e(), _vm.listing.propertyTenants ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("PropertyTenants") + " (") + _vm._s(_vm.listing.propertyTenants + ")") + ",   ")]) : _vm._e(), _vm.listing.minTenantAge ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("MinTenantAge")) + ",   ")]) : _vm._e(), _vm.listing.maxTenantAge ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("MaxTenantAge") + " (") + _vm._s(_vm.listing.maxTenantAge + ")") + ",   ")]) : _vm._e(), _vm.listing.couplesAllowed ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CouplesAllowed")) + ",   ")]) : _vm._e(), _vm.listing.bedType ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BedType") + " (") + _vm._s(_vm.listing.bedType + ")") + ",   ")]) : _vm._e(), _vm.listing.minimalStay ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("MinimalStay") + " (") + _vm._s(_vm.listing.minimalStay + ")") + ",   ")]) : _vm._e(), _vm.listing.windowView ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("WindowView") + " (") + _vm._s(_vm.$lfMapper.windowView(_vm.listing.windowView) + ")") + ",   ")]) : _vm._e(), _vm.listing.ownerLiving ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OwnerLiving")) + ",   ")]) : _vm._e(), _vm.listing.tenantGender ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("TenantGender") + " (") + _vm._s(_vm.listing.tenantGender + ")") + ",   ")]) : _vm._e(), _vm.listing.houseKeeper ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("HouseKeeper")) + ",   ")]) : _vm._e(), _vm.listing.privateBathroom ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("PrivateBathroom")) + ",   ")]) : _vm._e(), _vm.listing.genderPreference ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("GenderPreference") + " (") + _vm._s(_vm.listing.genderPreference + ")") + ",   ")]) : _vm._e(), _vm.listing.occupation ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Occupation") + " (") + _vm._s(_vm.listing.occupation + ")") + ",   ")]) : _vm._e(), _vm.listing.lgtbFriendly ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LgtbFriendly")) + ",   ")]) : _vm._e(), _vm.listing.occupiedNow ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OccupiedNow")) + ",   ")]) : _vm._e(), _vm.listing.tenantWorkers ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("TenantWorkers")) + ",   ")]) : _vm._e(), _vm.listing.tenantStudents ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("TenantStudents")) + ",   ")]) : _vm._e(), _vm.listing.minAge ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("MinAge") + " (") + _vm._s(_vm.listing.minAge + ")") + ",   ")]) : _vm._e(), _vm.listing.maxAge ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("MaxAge") + " (") + _vm._s(_vm.listing.maxAge + ")") + ",   ")]) : _vm._e(), _vm.listing.exteriorAccessibility ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ExteriorAccessibility")) + ",   ")]) : _vm._e(), _vm.listing.interiorAccessibility ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("InteriorAccessibility")) + ",   ")]) : _vm._e(), _vm.listing.lifeStyle ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LifeStyle") + " (") + _vm._s(_vm.listing.lifeStyle + ")") + ",   ")]) : _vm._e()])], 1)], 1), _c('v-row', {
    staticClass: "extra-info-row",
    attrs: {
      "id": "sanitary-info-wrapper"
    }
  }, [_c('v-col', {
    staticClass: "icon-col"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/sanitary.svg"),
      "alt": ""
    }
  })]), _c('v-col', {
    staticClass: "info-col"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("SanitaryArrangements")))]), _c('v-row', {
    attrs: {
      "id": "sanitary-text-row"
    }
  }, [_vm.listing.bathroomInside ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BathroomInside")) + ",  ")]) : _vm._e(), _vm.listing.bathroomType ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BathroomType") + " (") + _vm._s(_vm.listing.bathroomType + ")") + ", ")]) : _vm._e(), _vm.listing.washingMachine ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("WashingMachine")) + ",  ")]) : _vm._e(), _vm.listing.bath ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Bath")) + ",  ")]) : _vm._e(), _vm.listing.wcBath ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SeparateToiletAndBath")) + ",  ")]) : _vm._e(), _vm.listing.boiler ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Boiler")) + ",  ")]) : _vm._e(), _vm.listing.pool ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Pool")) + ",  ")]) : _vm._e(), _vm.listing.shower ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Shower")) + ",  ")]) : _vm._e(), _vm.listing.localWaterSupply ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LocalWaterSupply")) + ",  ")]) : _vm._e(), _vm.listing.centralWaterSupply ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CentralWaterSupply")) + ",  ")]) : _vm._e(), _vm.listing.localSewerage ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LocalSewerage")) + ",  ")]) : _vm._e(), _vm.listing.centralSewerage ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CentralSewerage")) + ",  ")]) : _vm._e(), _vm.listing.sauna ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sauna")) + " ")]) : _vm._e()])], 1)], 1), _c('v-row', {
    staticClass: "extra-info-row",
    attrs: {
      "id": "comm-info-wrapper"
    }
  }, [_c('v-col', {
    staticClass: "icon-col"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/com_and_sec.svg"),
      "alt": ""
    }
  })]), _c('v-col', {
    staticClass: "info-col"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Communications&Security")))]), _c('v-row', {
    attrs: {
      "id": "comm-text-row"
    }
  }, [_vm.listing.security24h ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Security24h")) + ",   ")]) : _vm._e(), _vm.listing.access24h ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Access24h")) + ",   ")]) : _vm._e(), _vm.listing.securityAlarm ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SecurityAlarm")) + ",   ")]) : _vm._e(), _vm.listing.securityCamera ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SecurityCamera")) + ",   ")]) : _vm._e(), _vm.listing.securitySystem ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SecuritySystem")) + ",  ")]) : _vm._e(), _vm.listing.internet ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Internet")) + ",  ")]) : _vm._e(), _vm.listing.securitySystem && _vm.listing.estateType != 'land' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SecuritySystem") + " (") + _vm._s(_vm.formatSecuritySystem(_vm.listing.securitySystem) + ")") + ",  ")]) : _vm._e(), _vm.listing.cableTv ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CableTv")) + ",  ")]) : _vm._e(), _vm.listing.telephone ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Phone")) + ",  ")]) : _vm._e(), _vm.listing.securityDoor ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SecurityDoor")) + ",  ")]) : _vm._e(), _vm.listing.staircaseLocked ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LockedStairway")) + ",  ")]) : _vm._e(), _vm.listing.neighbourhoodSecurity ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("NeighbourhoodWatch")) + ",  ")]) : _vm._e(), _vm.listing.guard ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Guard")) + ",  ")]) : _vm._e(), _vm.listing.fence && _vm.listing.estateType != 'commercial' && _vm.listing.estateType != 'land' && _vm.listing.estateType != 'apartment' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Fence")) + ",  ")]) : _vm._e(), _vm.listing.videoSecurity ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("VideoSurveillance")) + ",  ")]) : _vm._e()])], 1)], 1), _c('v-row', {
    staticClass: "extra-info-row",
    attrs: {
      "id": "heating-info-wrapper"
    }
  }, [_c('v-col', {
    staticClass: "icon-col"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/hvac.svg"),
      "alt": ""
    }
  })]), _c('v-col', {
    staticClass: "info-col"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("HeatingandVentilation")))]), _c('v-row', {
    attrs: {
      "id": "heating-text-row"
    }
  }, [_vm.listing.heating ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Heating")) + ",  ")]) : _vm._e(), _vm.listing.firePlace ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Fireplace")) + ",  ")]) : _vm._e(), _vm.listing.centralHeating ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CentralHeating")) + ",  ")]) : _vm._e(), _vm.listing.gasHeating ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("GasHeating")) + ",  ")]) : _vm._e(), _vm.listing.furnaceHeating ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FurnaceHeating")) + ",  ")]) : _vm._e(), _vm.listing.electricalHeating ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ElectricalHeating")) + ",  ")]) : _vm._e(), _vm.listing.solidFuel ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("SolidFuel")) + ",  ")]) : _vm._e(), _vm.listing.combinedHeating ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CombinedHeating")) + ",  ")]) : _vm._e(), _vm.listing.floorHeating ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FloorHeating")) + ",  ")]) : _vm._e(), _vm.listing.centralGas ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CentralGas")) + ",  ")]) : _vm._e(), _vm.listing.conditioner ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AirConditioner")) + ",  ")]) : _vm._e(), _vm.listing.geothermalHeating ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("GeothermalHeating")) + ",  ")]) : _vm._e(), _vm.listing.airHeatPump ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AirHeatPump")) + ",  ")]) : _vm._e(), _vm.listing.airWaterPump && _vm.listing.liquidFuel ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AirWaterHeatPump")) + ",  ")]) : _vm._e(), _vm.listing.airWaterPump && !_vm.listing.liquidFuel ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AirWaterHeatPump")) + " ")]) : _vm._e(), _vm.listing.liquidFuel ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("LiquidFuel")) + " ")]) : _vm._e()])], 1)], 1), _c('v-row', {
    staticClass: "extra-info-row",
    attrs: {
      "id": "kitchen-info-wrapper"
    }
  }, [_c('v-col', {
    staticClass: "icon-col"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/Kitchen.svg"),
      "alt": ""
    }
  })]), _c('v-col', {
    staticClass: "info-col"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Kitchen")))]), _c('v-row', {
    attrs: {
      "id": "kitchen-text-row"
    }
  }, [_vm.listing.stove ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.formatStove(_vm.listing.stove)) + ",  ")]) : _vm._e(), _vm.listing.electricalStove ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ElectricalStove")) + ",  ")]) : _vm._e(), _vm.listing.gasStove ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("GasStove")) + ",  ")]) : _vm._e(), _vm.listing.ceramicStove ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CeramicStove")) + ",  ")]) : _vm._e(), _vm.listing.fireWoodStove ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("FireWoodStove")) + ",  ")]) : _vm._e(), _vm.listing.inductionStove ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("InductionHob")) + ",  ")]) : _vm._e(), _vm.listing.openedKitchen ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("OpenKitchen")) + ",  ")]) : _vm._e(), _vm.listing.kitchenFurniture ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("KitchenFurniture")) + ",  ")]) : _vm._e(), _vm.listing.fridge ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Fridge")) + ",  ")]) : _vm._e(), _vm.listing.kitchen ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Kitchen")) + ",  ")]) : _vm._e(), _vm.listing.equipment ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.listing.equipment) + ",  ")]) : _vm._e()])], 1)], 1), _c('v-row', {
    staticClass: "extra-info-row",
    attrs: {
      "id": "surroundings-info-wrapper"
    }
  }, [_c('v-col', {
    staticClass: "icon-col"
  }, [_c('img', {
    staticStyle: {
      "width": "24px",
      "height": "24px"
    },
    attrs: {
      "src": require("../../assets/images/Surroundings.svg"),
      "alt": ""
    }
  })]), _c('v-col', {
    staticClass: "info-col"
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Surroundings")))]), _c('v-row', {
    attrs: {
      "id": "surroundings-text-row"
    }
  }, [_vm.listing.ideaLocation ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("IdeaLocation") + " (") + _vm._s(_vm.listing.ideaLocation + ")") + ",   ")]) : _vm._e(), _vm.listing.accessType ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("AccessType") + " (") + _vm._s(_vm.listing.accessType + ")") + ",   ")]) : _vm._e(), _vm.listing.streetLighting ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("StreetLighting")) + ",   ")]) : _vm._e(), _vm.listing.sidewalk ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Sidewalk")) + ",   ")]) : _vm._e(), _vm.listing.roadAccess ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("RoadAccess")) + ",   ")]) : _vm._e(), _vm.listing.nearestLocationKm ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("NearestLocationKm") + " (") + _vm._s(_vm.listing.nearestLocationKm + ")") + ",   ")]) : _vm._e(), _vm.listing.reservoir && _vm.listing.reservoir != 'WATERBODY_OTHER' && _vm.listing.estateType != 'garage' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.formatReservoirs(_vm.listing.reservoir)) + ",  ")]) : _vm._e(), _vm.listing.reservoirName && _vm.listing.estateType != 'garage' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BodyOfWaterName") + " (" + _vm.listing.reservoirName + ")") + ",  ")]) : _vm._e(), _vm.listing.reservoirDistance && _vm.listing.estateType != 'garage' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("BodyOfWaterDistance") + " (" + _vm.listing.reservoirDistance + "m)") + ",  ")]) : _vm._e(), _vm.listing.coastLineLength && _vm.listing.estateType != 'garage' && _vm.listing.estateType != 'apartment' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("CoastLineLength1") + " (" + _vm.listing.coastLineLength + "m)") + ",  ")]) : _vm._e(), _vm.listing.neighbours && _vm.listing.estateType != 'garage' && _vm.listing.estateType != 'commercial' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Neighbours") + " (" + _vm.formatNeighbours(_vm.listing.neighbours) + ")") + ",  ")]) : _vm._e(), _vm.listing.building && _vm.listing.estateType != 'garage' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Building1") + " (" + _vm.formatBuilding(_vm.listing.building) + ")") + ",  ")]) : _vm._e(), _vm.listing.roads && _vm.listing.estateType != 'garage' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("Roads") + " (" + _vm.formatRoads(_vm.listing.roads) + ")") + ",  ")]) : _vm._e(), _vm.listing.location && _vm.listing.estateType != 'garage' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("IsLocated") + " " + _vm.formatLocations(_vm.listing.location).toLowerCase()) + ",  ")]) : _vm._e(), _vm.listing.forestIsNear && _vm.listing.estateType != 'commercial' && _vm.listing.estateType != 'garage' && _vm.listing.estateType != 'apartment' ? _c('p', {
    staticClass: "content-normal grey-950"
  }, [_vm._v(" " + _vm._s(_vm.$t("ForestIsNear")) + ",  ")]) : _vm._e()])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }