import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./en.json";
import et from "./et.json";

let defaultLocale = "en"
const messages = {
  en: en,
  et: et,
  dk: en,
  nl: en,
  fr: en,
  de: en,
  it: en,
  lv: en,
  lt: en,
  pt: en,
  pl: en,
  ru: en,
  ro: en,
  es: en,
  se: en,
  bg: en,
  ct: en,
  cz: en,
  fi: en,
  gr: en,
  hu: en,
  no: en,
  sk: en,
  si: en,
  tr: en
};

Vue.use(VueI18n);
export default new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
  silentTranslationWarn: true,
  silentFallbackWarn: false,
}, );



