var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.smallFont ? _c('span', {
    class: 'default-deal-type-con ' + _vm.dealType.toLowerCase()
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.dealType)) + " ")]) : _vm.smallFont ? _c('span', {
    class: 'content-small ' + _vm.dealType,
    staticStyle: {
      "font-weight": "bold",
      "overflow": "hidden",
      "white-space": "nowrap",
      "text-overflow": "ellipsis",
      "border-radius": "4px",
      "border-width": "0",
      "padding": "0 4px",
      "flex-direction": "row",
      "display": "inline-flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.dealType)) + " ")]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }