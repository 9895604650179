<template>
  <div
      v-if="showBanner"
      style="height: 64px"
  >
    <div class="welcome-notification">
      <div class="welcome-content">
        <div class="welcome-text content-bold">
          {{ $t("WelcomeText").replace("%Firstname%", user.firstname) }}
        </div>
        <div class="buttons">
          <button
              @click="watchGuide"
              class="transparent-button"
          >
            <img
              src="../../assets/images/info.svg"
              alt=""
              class="img16"
            />
            <div class="button-small">
              {{ $t("WatchGuide") }}
            </div>
          </button>

          <button
              @click="close"
              class="transparent-button close-button"
          >
            <img
                src="../../assets/images/close.svg"
                alt=""
                class="img16"
            />
            <div class="button-small">
              {{ $t("Close") }}
            </div>
          </button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "WelcomeNotification",
  data() {
    return {
    };
  },
  created() {
    console.log("user", this.user);
  },
  computed: {
    ...mapGetters(["user"]),
    showBanner() {
      return this.user && this.user.welcomeBannerActive;
    }
  },
  methods: {
    ...mapActions([
      "setUser"
    ]),
    watchGuide() {
      window.open("https://drive.google.com/file/d/1Lu68KrHcYWw8le1IrxK8_oyE7f7_KfR_/view", "popup")
      this.close();
    },
    async close() {
      let newUser = this.user;
      newUser.welcomeBannerActive = false;
      this.setUser(newUser);
      await axios.post(`/api/admin/update-fields/${this.user._id}`, {welcomeBannerActive: false} );
    },
  }
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.buttons {
  display: flex;
  gap: 8px;
}

.img16 {
  filter: invert(1);
}

.welcome-text {
  color: white;
}

.button-small {
  color: white;
}

.welcome-content {
  display: flex;
  gap: 16px;
  align-items: center;
}

button.transparent-button {
  border: none !important;
  background-color: black !important;
  padding: 8px 12px;
}

button.close-button {
  border: 1px solid $grey500 !important;
  background-color: transparent !important;
}

.welcome-notification {
  position: fixed;
  z-index: 990;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background: $darkgreen;
}

</style>
