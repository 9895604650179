<template>
  <div
      class="contact-tag-container label10"
      :class="'tag-' + tagName"
  >
    {{ $t(abbreviation) }}
  </div>
</template>

<script>

export default {
  name: "ContactTagContainer",
  props: ["tagName"],
  computed: {
    abbreviation() {
      switch (this.tagName) {
        case "propertyOwner":
          return "PO";
        case "landlord":
          return "L";
        case "buyer":
          return "B";
        case "tenant":
          return "T";
        case "other":
          return "O";
        case "photographer":
          return "PH";
        case "valuator":
          return "VA";
        case "notary":
          return "NO";
        case "bankRepresentative":
          return "BA";
        default:
          return "?";
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.contact-tag-container {
  border-radius: 4px;
  padding: 4px 2.5px;
  width: 20px;
  height: 20px;
  justify-content: center;
}

.tag-propertyOwner {
  background: $green300;
  color: black !important;
}

.tag-landlord {
  background: $blue300;
  color: black !important;
}

.tag-buyer {
  background: $yellow300;
  color: black !important;
}

.tag-tenant {
  background: $magenta300;
  color: black !important;
}

.tag-other {
  background: $grey800;
  color: black !important;
}

.tag-photographer {
  background: $turquoise;
}

.tag-valuator {
  background: $dark_magenta;
}

.tag-notary {
  background: $blue;
}

.tag-bankRepresentative {
  background: $dark_orange;
}

</style>