var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    class: {
      dropdown: !_vm.disabled,
      'dropdown dropdown-disabled': _vm.disabled
    },
    style: _vm.customStyle
  }, [_c('div', {
    staticClass: "dropbtn",
    class: {
      'dropbtn dropbtn-hover': !_vm.disabled,
      dropbtn: _vm.disabled,
      'drobtn-white': _vm.projectView
    },
    style: _vm.customStyleButton,
    on: {
      "click": _vm.openDropdown
    }
  }, [!_vm.projectView && !_vm.propertyView ? _c('div', {
    staticClass: "label-and-item-wrapper"
  }, [_c('p', {
    staticClass: "justify-center",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('img', {
    staticClass: "icon-img",
    attrs: {
      "src": require(`../../assets/images/${_vm.icon}`),
      "alt": ""
    }
  })])]) : _vm._e(), _vm.projectView || _vm.propertyView ? _c('div', {
    staticClass: "label-and-item-wrapper",
    class: {
      'label-and-item-wrapper align-style': !_vm.propertyView,
      'outlined-small-button-add align-add-button': _vm.propertyView
    }
  }, [_c('img', {
    class: {
      '': !_vm.propertyView,
      'whiten-pic': _vm.propertyView
    },
    staticStyle: {
      "width": "16px",
      "height": "16px",
      "margin": "0"
    },
    attrs: {
      "src": require(`../../assets/images/${_vm.icon}`),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "justify-center",
    class: {
      'justify-center': !_vm.propertyView,
      'property-button-inside': _vm.propertyView
    },
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "padding-left": "8px"
    }
  }, [_vm.projectView ? _c('a', {
    staticClass: "button-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("Options")) + " ")]) : _vm._e(), _vm.propertyView ? _c('a', {
    staticClass: "button-small",
    staticStyle: {
      "color": "#ffffff"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")]) : _vm._e()])]) : _vm._e()]), _c('div', {
    staticClass: "dropdown-content dropdown-single",
    class: {
      'dropdown-content dropdown-single dropdown-width scrollable': _vm.projectView,
      'dropdown-content dropdown-single scrollable': !_vm.projectView && !_vm.propertyView && !_vm.listingView,
      'dropdown-content dropdown-single dropdown-border ': _vm.propertyView,
      'dropdown-content dropdown-single dropdown-width scrollable-listing': _vm.listingView
    },
    staticStyle: {
      "display": "none",
      "z-index": "1"
    },
    attrs: {
      "id": _vm.dropdownId
    }
  }, _vm._l(_vm.dropdownItems, function (item) {
    return _c('div', {
      key: item._id,
      staticClass: "dropdown-item-wrapper",
      on: {
        "click": function ($event) {
          return _vm.selectItem(item);
        }
      }
    }, [item.value !== 'delete_listing' ? _c('p', {
      class: {
        'regular-size-text': _vm.regularSize,
        'small-size-text': !_vm.regularSize
      },
      style: _vm.selectedItem && _vm.selectedItem[_vm.valueKey] === item[_vm.valueKey] ? 'font-weight: bold' : null
    }, [_vm._v(" " + _vm._s(item[_vm.displayKey]) + " ")]) : _vm._e(), item.value === 'delete_listing' ? _c('p', {
      staticClass: "small-size-text",
      staticStyle: {
        "color": "red"
      }
    }, [_vm._v(" " + _vm._s(item[_vm.displayKey]) + " ")]) : _vm._e()]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }