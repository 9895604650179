var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticStyle: {
      "line-height": "16px"
    }
  }, [_vm.task && _vm.version === 'large' ? _c('v-row', {
    staticClass: "content-small open-house-wrapper",
    class: {
      'inverted-colors': _vm.invert
    }
  }, [!_vm.invert ? _c('img', {
    attrs: {
      "src": require("../../assets/images/calendar.svg"),
      "alt": ""
    }
  }) : _vm._e(), _c('span', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("OpenHouse")) + " ")]), _vm.task.dates.startDate ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate) + " ")]) : _vm._e(), _vm.task.dates.startTime || _vm.task.dates.endTime ? _c('span', [_vm._v(" • ")]) : _vm._e(), _c('span', [_vm.task.dates.startTime ? _c('span', [_vm._v(" " + _vm._s(_vm.task.dates.startTime) + " ")]) : _vm._e(), _vm.task.dates.endTime ? _c('span', {
    staticStyle: {
      "margin-left": "-2px"
    }
  }, [_vm._v(" –" + _vm._s(_vm.task.dates.endTime) + " ")]) : _vm._e()])]) : _vm._e(), _vm.task && _vm.version === 'small' ? _c('v-row', {
    class: {
      'open-house-wrapper': 1,
      'content-small': 1,
      'inverted-colors': _vm.invert
    },
    staticStyle: {
      "padding": "0 2px"
    }
  }, [_c('span', {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("OpenHouse")) + " ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }