var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showBanner ? _c('div', {
    staticStyle: {
      "height": "64px"
    }
  }, [_c('div', {
    staticClass: "welcome-notification"
  }, [_c('div', {
    staticClass: "welcome-content"
  }, [_c('div', {
    staticClass: "welcome-text content-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("WelcomeText").replace("%Firstname%", _vm.user.firstname)) + " ")]), _c('div', {
    staticClass: "buttons"
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": _vm.watchGuide
    }
  }, [_c('img', {
    staticClass: "img16",
    attrs: {
      "src": require("../../assets/images/info.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "button-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("WatchGuide")) + " ")])]), _c('button', {
    staticClass: "transparent-button close-button",
    on: {
      "click": _vm.close
    }
  }, [_c('img', {
    staticClass: "img16",
    attrs: {
      "src": require("../../assets/images/close.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "button-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("Close")) + " ")])])])])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }