var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-broker-modal-wrapper"
  }, [!_vm.isDeleteModalOpen ? _c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 121,
      "resizable": false,
      "drag-handle": ".drag-area"
    },
    on: {
      "dragstop": _vm.onDragStop
    }
  }, [_c('div', {
    staticClass: "add-broker-widget"
  }, [_c('div', {
    staticClass: "widget-header drag-area"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "close-button"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/close_grey.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.closePanel
    }
  })])]), _vm.filteredBrokers.length > 0 ? _c('div', {
    staticClass: "dropdown-row"
  }, [_c('ItemSearch', {
    attrs: {
      "items": _vm.filteredBrokers,
      "placeholder": _vm.$t('UserSearch'),
      "name-key": 'name'
    },
    on: {
      "itemChosen": _vm.brokerSelected
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "modal-content"
  }, [_c('p', {
    staticClass: "content-title"
  }, [_vm._v(_vm._s(_vm.$t("SharedWith")))]), _vm._l(_vm.sharedCreators, function (creator, index) {
    return _c('div', {
      key: creator._id,
      staticClass: "person-row"
    }, [_c('div', {
      staticClass: "broker-icon",
      on: {
        "click": function ($event) {
          return _vm.removeFromModule(creator);
        }
      }
    }, [_c('span', {
      staticClass: "font-20-medium",
      attrs: {
        "id": "text"
      }
    }, [_vm._v(" " + _vm._s(creator.name.charAt(0).toUpperCase()) + " ")])]), _c('span', {
      staticClass: "person-name"
    }, [_vm._v(" " + _vm._s(creator.name) + " ")]), _c('span', {
      key: _vm.showDropdownUpdater,
      staticClass: "ownership-dropdown",
      style: {
        cursor: creator.ownershipType === 'owner' ? 'text' : 'pointer'
      },
      on: {
        "click": function ($event) {
          return _vm.openChangeOwnershipDropdown(creator, index);
        }
      }
    }, [_vm._v(" " + _vm._s(creator.ownershipType === "owner" ? _vm.$t(`${"Owner"}`).toLowerCase() : _vm.$t(`access.level.${creator.accessLevel}`)) + " "), creator.dropdownOpen && creator.ownershipType !== 'owner' ? _c('img', {
      attrs: {
        "src": require("@/assets/images/chevron-up.svg"),
        "alt": ""
      }
    }) : _vm._e(), !creator.dropdownOpen && creator.ownershipType !== 'owner' ? _c('img', {
      attrs: {
        "src": require("@/assets/images/chevron-down.svg"),
        "alt": ""
      }
    }) : _vm._e()]), creator.dropdownOpen ? _c('div', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: function () {
          return _vm.closeDropdown(creator);
        },
        expression: "() => closeDropdown(creator)"
      }],
      key: _vm.showOwnerShipDropdownUpdater,
      staticClass: "transfer-dropdown"
    }, _vm._l(_vm.dropdownOptions, function (option, index) {
      return _c('div', {
        key: index,
        staticClass: "dropdown-option",
        class: index === 0 ? 'first-option' : index === _vm.dropdownOptions.length - 1 ? 'last-option' : '',
        on: {
          "click": function ($event) {
            return _vm.assignOwnershipType(creator, option);
          }
        }
      }, [_c('p', {
        class: option.value === 'remove_from' ? 'remove-broker' : ''
      }, [_vm._v(" " + _vm._s(option.name) + " ")])]);
    }), 0) : _vm._e()]);
  })], 2)])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }