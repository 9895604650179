var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-wrapper"
  }, [_c('div', {
    staticClass: "setting-row"
  }, [_c('div', {
    staticClass: "setting-header"
  }, [_c('div', {
    staticClass: "content-semibold"
  }, [_vm._v(" " + _vm._s(_vm.$t("Interest")) + " " + _vm._s(_vm.$t("Status").toLocaleLowerCase()) + " ")]), _c('BaseToggle', {
    attrs: {
      "label": _vm.$t('Active')
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeInterestStatus', _vm.interest.statusCode, _vm.interest);
      }
    },
    model: {
      value: _vm.interest.statusCode,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "statusCode", $$v);
      },
      expression: "interest.statusCode"
    }
  })], 1), _c('div', {
    staticClass: "setting-description content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("InterestStatusDescription")) + " ")])]), _c('div', {
    staticClass: "setting-row"
  }, [_c('div', {
    staticClass: "setting-header"
  }, [_c('div', {
    staticClass: "content-semibold"
  }, [_vm._v(_vm._s(_vm.$t("Wide")) + " " + _vm._s(_vm.$t("search")))]), _c('BaseToggle', {
    attrs: {
      "label": _vm.$t('Active'),
      "disabled": !_vm.interest.statusCode
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('changeInterestWideSearch', _vm.interest.wideSearch, _vm.interest);
      }
    },
    model: {
      value: _vm.interest.wideSearch,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "wideSearch", $$v);
      },
      expression: "interest.wideSearch"
    }
  })], 1), _c('div', {
    staticClass: "setting-description content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("InterestWideSearchDescription")) + " ")])]), _vm.projectId ? _c('div', {
    staticClass: "setting-row"
  }, [_c('div', {
    staticClass: "setting-header"
  }, [_c('div', {
    staticClass: "content-semibold"
  }, [_vm._v(_vm._s(_vm.$t("ExportToWWW")))]), _c('BaseToggle', {
    staticClass: "output-toggle",
    attrs: {
      "value": _vm.interest.marketplaces.find(function (market) {
        return market.name === 'www';
      }).isActive,
      "label": _vm.$t('Active')
    },
    on: {
      "change": function ($event) {
        return _vm.toggleOutput();
      }
    }
  })], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }