<template>
  <div
    @click="closePopover($event)"
    id="statuses-dropdown-overlay"
    class="overlay"
    v-click-outside="closePopOver"
  >
    <div id="statuses-dropdown">
      <div
        v-for="(status, index) in statuses"
        :key="index"
        class="status-label-wrapper"
      >
        <div
          @click="openModal(status.value, null)"
          class="status-label"
          :style="'background:' + status.color"
        >
          <p class="content-bold">{{ status.name }}</p>
        </div>

        <StatusConfirmationModal
          @canceled="closeStatusPanel()"
          @approved="updateListingStatus(chosenStatus, chosenUntil)"
          v-if="statusModalOpen"
          :count="selectedListingsList"
        />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import StatusConfirmationModal from "@/components/Listings/StatusConfirmationModal";

import "vue-date-pick/dist/vueDatePick.css";

export default {
  name: "StatusDropdown",
  props: [
    "position",
    "multi",
    "listing",
    "listings",
    "selectedListingsList",
    "unitsView",
    "units",
    "unit",
    "developmentId",
    "buildingId",
    "isDevelopment",
  ],
  components: {
    StatusConfirmationModal,
  },
  data() {
    return {
      bookingDetailsModalOpen: false,
      statusModalOpen: false,
      chosenStatus: null,
      chosenUntil: null,
    };
  },
  created() {},
  mounted() {
    let topPosition = this.position.top;
    let rightPosition = this.position.right;
    document
      .getElementById("statuses-dropdown")
      .style.setProperty("top", topPosition + "px");
    document
      .getElementById("statuses-dropdown")
      .style.setProperty("right", rightPosition + "px");
  },
  beforeDestroy() {},
  computed: {
    statuses() {
      if (this.unitsView) {
        if (this.unit.dealType === "LeaseOut") {
          return [
            { name: this.$t("Free"), value: "free", color: "#0AAF60" },
            { name: this.$t("Booked"), value: "booked", color: "#FF5C01" },
            { name: this.$t("Rented"), value: "rented", color: "#FF1E24" },
          ];
        } else {
          return [
            { name: this.$t("Free"), value: "free", color: "#0AAF60" },
            { name: this.$t("Booked"), value: "booked", color: "#FF5C01" },
            { name: this.$t("Sold"), value: "sold", color: "#FF1E24" },
          ];
        }
      }
      if (this.listing?.isNewDevelopment && this.listing.dealType === "sale") {
        return [
          { name: this.$t("Active"), value: "active", color: "#0AAF60" },
          { name: this.$t("Booked"), value: "booked", color: "#FF5C01" },
          { name: this.$t("Sold"), value: "sold", color: "#FF1E24" },
        ];
      }
      if (this.listing?.isNewDevelopment && this.listing.dealType === "rent") {
        return [
          { name: this.$t("Active"), value: "active", color: "#0AAF60" },
          { name: this.$t("Booked"), value: "booked", color: "#FF5C01" },
          { name: this.$t("Rented"), value: "sold", color: "#FF1E24" },
        ];
      }
      if (!this.multi) {
        if (this.listing.dealType === "sale") {
          return [
            { name: this.$t("Draft"), value: "draft", color: "#75787A" },
            { name: this.$t("Active"), value: "active", color: "#0AAF60" },
            { name: this.$t("Booked"), value: "booked", color: "#FF5C01" },
            { name: this.$t("Sold"), value: "sold", color: "#FF1E24" },
            {
              name: this.$t("Archived"),
              value: "archived",
              color: "#C3C4C6",
            },
          ];
        } else {
          return [
            { name: this.$t("Draft"), value: "draft", color: "#75787A" },
            { name: this.$t("Active"), value: "active", color: "#0AAF60" },
            { name: this.$t("Booked"), value: "booked", color: "#FF5C01" },
            { name: this.$t("Rented"), value: "rented", color: "#FF1E24" },
            {
              name: this.$t("Archived"),
              value: "archived",
              color: "#C3C4C6",
            },
          ];
        }
      } else {
        return [
          { name: this.$t("Draft"), value: "draft", color: "#75787A" },
          { name: this.$t("Active"), value: "active", color: "#0AAF60" },
          { name: this.$t("Booked"), value: "booked", color: "#FF5C01" },
          {
            name: this.$t("Sold/Rented"),
            value: "sold_rented",
            color: "#FF1E24",
          },
          { name: this.$t("Archived"), value: "archived", color: "#C3C4C6" },
        ];
      }
    },
  },

  methods: {
    isFutureDate(date) {
      const currentDate = new Date();
      return date <= currentDate;
    },
    async addBookingDate(status, event) {
      if (this.multi) {
        if (this.selectedListingsList > 1) {
          this.chosenStatus = status;
          this.chosenUntil = event;
          this.statusModalOpen = true;
        } else {
          this.statusModalOpen = false;
          this.updateListingStatus(status, event);
        }
      } else {
        this.statusModalOpen = true;
        this.chosenStatus = status;
        this.chosenUntil = event;
        if (this.selectedListingsList > 1) {
          this.statusModalOpen = true;
        } else {
          this.statusModalOpen = false;
          this.updateListingStatus(status, event);
        }
      }
    },
    closeStatusPanel() {
      this.statusModalOpen = false;
      this.$emit("closed");
    },
    openBookingDetailsModal() {
      this.$emit("open-booking-modal", this.listing);
    },
    openModal(status, bookedUntil) {
      if (status !== "booked") {
        if (this.multi) {
          this.chosenStatus = status;
          this.chosenUntil = bookedUntil;
          if (this.selectedListingsList > 1) {
            this.statusModalOpen = true;
          } else {
            this.updateListingStatus(status, bookedUntil);
          }
          //this.$emit("closed");
        } else if (!this.multi) {
          this.updateListingStatus(status, bookedUntil);
        }
      } else {
        this.openBookingDetailsModal();
      }
    },
    async updateListingStatus(status, bookedUntil) {
      if (this.isDevelopment) {
        console.log("heipster");
        let statusObject = {
          status: status,
          bookedUntil: bookedUntil,
          developmentId: this.developmentId,
          buildingId: this.buildingId,
        };
        return this.$emit("statusChanged", statusObject);
      }
      if (!this.multi && !this.unitsView) {
        let statusObject = {
          listingId: this.listing._id,
          status: status,
          prevStatus: this.listing.status,
          bookedUntil: bookedUntil,
          statusChangedDate: this.listing?.statusChangedDate,
          activeUntil: null,
        };
        if (!this.listing.statusChangedDate) {
          statusObject.statusChangedDate = Date.now();
        }
        const statusUpdateResponse = await axios.post(
          `/api/listing/update-status`,
          statusObject
        );
        statusObject.activeUntil = statusUpdateResponse.data.expiryDate;
        this.$emit("statusChanged", statusObject);
      } else if (!this.multi && this.unitsView) {
        let statusObject = {
          unitId: this.unit._id,
          status: status,
          bookedUntil: bookedUntil,
          developmentId: this.developmentId,
          buildingId: this.buildingId,
        };
        this.$emit("statusChanged", statusObject);
      } else if (this.multi && this.unitsView) {
        let statusObject = {
          unitIds: this.unit._id,
          status: status,
          bookedUntil: bookedUntil,
          developmentId: this.developmentId,
          buildingId: this.buildingId,
        };
        this.$emit("statusChanged", statusObject);
      } else {
        let apiData = {
          statusObjects: [],
          activeUntil: null,
        };
        for (const listing of this.listings) {
          let newStatus = status;
          if (status === "sold_rented") {
            if (listing.dealType === "sale") {
              newStatus = "sold";
            } else {
              newStatus = "rented";
            }
          } else if (status === "archived") {
            axios.post(`/api/listing/delete/${listing._id}`);
          }
          let statusObject = {
            listingId: listing._id,
            status: newStatus,
            bookedUntil: bookedUntil,
            statusChangedDate: this.listing?.statusChangedDate,
          };
          if (!this.listing?.statusChangedDate) {
            statusObject.statusChangedDate = Date.now();
            apiData.statusObjects.push(statusObject);
          }
        }
        const statusesChangedResponse = await axios.post(
          `/api/listing/update-status/multi`,
          apiData
        );
        apiData.activeUntil = statusesChangedResponse.data.expiryDate;
        this.$emit("statusesChanged", apiData);
      }
    },
    closePopOver() {
      this.$emit("closed");
    },
    closePopover(e) {
      let popoverWrapper = document.getElementById("statuses-dropdown-overlay");
      if (e.target === popoverWrapper) {
        this.$emit("closed");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#statuses-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 140px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  min-width: 116px;
  height: 20px;
  background: #75787a;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 1;
  cursor: pointer;
}
.status-label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px;
  min-width: 140px;
  height: 44px;
  background: #ffffff;
  border-radius: 8px;
  p {
    color: white;
  }
}
</style>
