var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    class: {
      'button-container': _vm.label
    },
    style: _vm.buttonStyle,
    on: {
      "click": _vm.buttonClicked
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "24px",
      "height": "24px"
    }
  }) : _vm._e(), _vm.iconLeft && !_vm.isLoading ? _c('img', {
    staticClass: "icon-left",
    style: _vm.iconStyle,
    attrs: {
      "alt": "",
      "src": require(`@/assets/images/${_vm.iconLeft}`)
    }
  }) : _vm._e(), _vm.label ? _c('div', {
    class: _vm.labelClass,
    staticStyle: {
      "white-space": "nowrap"
    },
    style: _vm.labelStyle
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]) : _vm._e(), _vm.iconRight && !_vm.isLoading ? _c('img', {
    style: _vm.iconStyle,
    attrs: {
      "alt": "",
      "src": require(`@/assets/images/${_vm.iconRight}`)
    }
  }) : _vm._e()]), _vm.dropdownOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    ref: "buttonDropdown",
    staticClass: "button-dropdown-container",
    attrs: {
      "tabindex": "0"
    }
  }, [_vm.items && _vm.items.length > 0 ? _vm._l(_vm.items, function (item) {
    return _c('div', {
      key: item._id,
      staticClass: "button-dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.selectItem(item);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column",
        "width": "100%"
      }
    }, [_c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(item[_vm.displayKey]))]), _vm.descriptionKey && item[_vm.descriptionKey] ? _c('div', {
      staticClass: "content-small",
      staticStyle: {
        "color": "#75787a",
        "max-width": "100%",
        "white-space": "normal"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(item[_vm.descriptionKey])) + " ")]) : _vm._e()]), _vm.iconKey && item[_vm.iconKey] ? _c('img', {
      attrs: {
        "height": "16",
        "width": "16",
        "src": require(`@/assets/images/${_vm.$t(item[_vm.iconKey])}`),
        "alt": "icon"
      }
    }) : _vm._e()]);
  }) : [_c('div', {
    staticStyle: {
      "display": "flex",
      "width": "200px",
      "padding": "16px"
    }
  }, [_c('p', {
    staticClass: "content-small",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.emptyDropdownText) + " ")])])]], 2) : _vm._e(), _vm.isConfirmModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": true
    },
    on: {
      "approved": function ($event) {
        return _vm.emitSelectedItem();
      },
      "canceled": function ($event) {
        _vm.isConfirmModalOpen = false;
      }
    }
  }) : _vm._e(), _vm.isStatusConfirmationModalOpen ? _c('ConfirmationModal', {
    attrs: {
      "text-start-icon": 'info.svg'
    },
    on: {
      "onConfirm": _vm.emitConfirmationModalConfirm,
      "onCancel": function ($event) {
        _vm.isStatusConfirmationModalOpen = false;
      }
    }
  }, [_c('p', {
    staticClass: "content-normal",
    staticStyle: {
      "padding": "2px 0"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedItem.statusConfirmationModalText) + " ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }