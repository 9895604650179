var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notes-container"
  }, [_vm.noteAddOpen ? _c('div', {
    staticClass: "note-card"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newNoteContent,
      expression: "newNoteContent"
    }],
    ref: "noteAddTextArea",
    attrs: {
      "placeholder": _vm.$t('AddNote') + '...',
      "oninput": "this.style.height = \"\";this.style.height = this.scrollHeight + \"px\"",
      "onfocus": "this.style.height = \"\";this.style.height = this.scrollHeight + \"px\""
    },
    domProps: {
      "value": _vm.newNoteContent
    },
    on: {
      "focusout": _vm.addNote,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.newNoteContent = $event.target.value;
      }
    }
  })]) : _vm._e(), _vm.notes.length > 0 ? _c('div', {
    staticClass: "notes-content"
  }, _vm._l(_vm.notes, function (note) {
    return _c('div', {
      key: note.id,
      staticClass: "note-card"
    }, [_c('img', {
      attrs: {
        "height": "32",
        "width": "32",
        "src": require('@/assets/images/note_32_32.svg'),
        "alt": ""
      }
    }), _vm.editingNote !== note ? _c('div', {
      staticClass: "note-card-container",
      on: {
        "click": function ($event) {
          return _vm.openNoteEdit(note);
        }
      }
    }, [_c('div', {
      staticClass: "note-card-header"
    }, [_c('p', {
      class: {
        'note-public': note.isPublic
      }
    }, [_vm._v(" " + _vm._s(note.isPublic ? _vm.$t("Public").toUpperCase() : _vm.$t("Private").toUpperCase()))]), _c('p', [_vm._v(_vm._s(_vm.formatDate(note.createdAt)))])]), _c('div', {
      staticClass: "note-card-content"
    }, [_vm._v(_vm._s(note.content))])]) : _vm._e(), _vm.editingNote === note ? _c('div', {
      staticClass: "note-edit-container"
    }, [_c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: note.content,
        expression: "note.content"
      }],
      ref: "noteEditTextArea",
      refInFor: true,
      attrs: {
        "oninput": "this.style.height = \"\";this.style.height = this.scrollHeight + \"px\"",
        "onfocus": "this.style.height = \"\";this.style.height = this.scrollHeight + \"px\""
      },
      domProps: {
        "value": note.content
      },
      on: {
        "focusout": function ($event) {
          return _vm.updateNote(note);
        },
        "input": function ($event) {
          if ($event.target.composing) return;
          _vm.$set(note, "content", $event.target.value);
        }
      }
    })]) : _vm._e(), _c('div', [_c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "height": "24",
        "width": "24",
        "src": require('@/assets/images/dot_menu_grey.svg'),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.openNoteDropdown(note);
        }
      }
    }), _vm.noteDropdown && _vm.noteDropdown.id === note.id ? _c('div', {
      ref: "noteDropdown",
      refInFor: true,
      staticClass: "note-dropdown-menu",
      style: _vm.dropdownStyle,
      attrs: {
        "tabindex": "0"
      },
      on: {
        "focusout": function ($event) {
          _vm.noteDropdown = null;
        }
      }
    }, [_c('div', {
      staticClass: "note-dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.updateNoteStatus(note);
        }
      }
    }, [_c('img', {
      attrs: {
        "height": "20",
        "width": "20",
        "src": note.isPublic ? require('@/assets/images/toggle-active-24x24.svg') : require('@/assets/images/toggle-inactive-20x20.svg'),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(_vm.$t("PublicEntry")))])]), _c('div', {
      staticClass: "note-dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.deleteNote(note);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require(`@/assets/images/red_trash_20_20.svg`),
        "alt": ""
      }
    }), _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "color": "#FF1E24",
        "height": "16px"
      }
    }, [_vm._v(_vm._s(_vm.$t("Delete")))])])]) : _vm._e()])]);
  }), 0) : _vm._e(), _vm.notes.length < 1 && !_vm.noteAddOpen && !_vm.loading && _vm.showNoNotesMessage ? _c('div', {
    staticClass: "notes-empty-container"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("NoNotesAdded")))])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }