import Vue from "vue";
import {mapGetters} from "vuex";

const accessColleaguesOptions = {
    sort: {
        field: "name", // field name
        order: 1, // 1, -1, 0
    },
}

const moduleAccessCommands = {
    "default": (document, user) => {
        if (document.access.users[user._id]) {
            return true;
        }
        if (user.access.accessLevel === "group_admin" && user.access.users) {
            for (const userId in user.access.users) {
                const userInDocument = document.access.users[userId];
                if (userInDocument && userInDocument.ownershipType === "owner") {
                    return true;
                }
            }
        }
        return false;
    },
    projects: {
        "delete": (document, user) => {
            if (user.access.accessLevel === "group_admin" && user.access.users) {
                for (const userId in user.access.users) {
                    const userInDocument = document.access.users[userId];
                    if (userInDocument && userInDocument.ownershipType === "owner") {
                        return true;
                    }
                }
            }
            return false;
        }
    },
    listings: {
        "delete": (document, user) => {
            const userInDocument = document.access.users[user._id];
            if (userInDocument && userInDocument.ownershipType === "owner") {
                return true;
            }
            if (user.access.accessLevel === "group_admin" && user.access.users) {
                for (const userId in user.access.users) {
                    const userInDocument = document.access.users[userId];
                    if (userInDocument && userInDocument.ownershipType === "owner") {
                        return true;
                    }
                }
            }
            return false;
        }
    },
    developments: {
        "delete": (document, user) => {
            const userInDocument = document.access.users[user._id];
            return !!(user.access.accessLevel === "group_admin" && userInDocument && userInDocument.ownershipType === "owner");
        }
    },
    invoices: {
        "delete": (document, user) => {
            if (user.access.accessLevel === "group_admin" && user.access.users) {
                for (const userId in user.access.users) {
                    const userInDocument = document.access.users[userId];
                    if (userInDocument && userInDocument.ownershipType === "owner") {
                        return true;
                    }
                }
            }
        },
        "edit": (document, user) => {
            const userInDocument = document.access.users[user._id];
            if (userInDocument && userInDocument.ownershipType === "owner") {
                return true;
            } else if (user.access.accessLevel === "group_admin" && user.access.users) {
                for (const userId in user.access.users) {
                    const userInDocument = document.access.users[userId];
                    if (userInDocument && userInDocument.ownershipType === "owner") {
                        return true;
                    }
                }
            }
            return false;
        }
    }

}


Vue.mixin({
    computed: {
        ...mapGetters(["user", "users"]),
    },
    methods: {
        // Logged in user has access to this document. Document + access level?
        hasAccess(document, module = null, command = null) {
            if (this.user.access.accessLevel === "company_admin") return true;
            if (module && command) {
                return moduleAccessCommands[module][command](document, this.user);
            } else {
                return moduleAccessCommands['default'](document, this.user);
            }
        },
        // Logged in user has access rights over these users.
        accessColleagues(options = accessColleaguesOptions) {
            let returnArray = [];
            switch (this.user.access.accessLevel) {
                case "company_admin":
                    returnArray = this.users;
                    break;
                case "group_admin":
                    returnArray = this.users.filter((user) => this.user.access.users[user._id])
            }
            return returnArray.sort((a, b) => {
                return options.sort.order * a[options.sort.field].localeCompare(b[options.sort.field])
            })
        },
        // Logged in user shares groups wih these users.
        groupColleagues() {
            const returnUsers = [];
            for (const groupId in this.user.access.groups) {
                for (const colleague of this.users) {
                    if (colleague.access.groups[groupId]) {
                        returnUsers.push(colleague)
                    }
                }
            }
            return returnUsers;
        }
    },
});