var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-property"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-property"
    }
  }, [_c('div', {
    staticClass: "content-overlay",
    staticStyle: {
      "height": "100%",
      "display": "flex",
      "flex-direction": "column"
    },
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddNewProperty")) + " ")]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeProperty")) + " ")]) : _vm._e()])], 1), _c('div', {
    staticStyle: {
      "padding": "24px 32px",
      "height": "100%",
      "overflow-y": "scroll"
    }
  }, [_c('v-row', {
    staticStyle: {
      "padding-bottom": "12px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("GeneralInformation")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'property-owner-name-field',
      "placeholder": _vm.$t('PropertyName')
    },
    model: {
      value: _vm.form.propertyName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "propertyName", $$v);
      },
      expression: "form.propertyName"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-value-field',
      "placeholder": _vm.$t('Value'),
      "icon-right": 'euroSign.svg'
    },
    model: {
      value: _vm.form.propertyValue,
      callback: function ($$v) {
        _vm.$set(_vm.form, "propertyValue", $$v);
      },
      expression: "form.propertyValue"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-size-field',
      "placeholder": _vm.$t('PropertySize'),
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.form.propertySize,
      callback: function ($$v) {
        _vm.$set(_vm.form, "propertySize", $$v);
      },
      expression: "form.propertySize"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": 'property-owner-closedNetArea-field',
      "placeholder": _vm.$t('ClosedNetArea'),
      "icon-right": 'm2_20.svg'
    },
    model: {
      value: _vm.form.closedNetArea,
      callback: function ($$v) {
        _vm.$set(_vm.form, "closedNetArea", $$v);
      },
      expression: "form.closedNetArea"
    }
  }), !_vm.isEdit ? _c('BaseTextarea', {
    attrs: {
      "id": 'project-add-note',
      "placeholder": _vm.$t('Note')
    },
    model: {
      value: _vm.noteContent,
      callback: function ($$v) {
        _vm.noteContent = $$v;
      },
      expression: "noteContent"
    }
  }) : _vm._e()], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("AssetType")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "24px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "id": 'property-owner-business-field',
      "placeholder": _vm.$t('BusinessId')
    },
    model: {
      value: _vm.form.businessId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "businessId", $$v);
      },
      expression: "form.businessId"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "id": 'property-owner-currency-field',
      "items": _vm.assetTypes,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": true,
      "placeholder": _vm.$t('AssetType')
    },
    model: {
      value: _vm.form.assetType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assetType", $$v);
      },
      expression: "form.assetType"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "id": 'property-owner-currency-field',
      "items": _vm.assetClasses,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": true,
      "placeholder": _vm.$t('AssetClass')
    },
    model: {
      value: _vm.form.assetClass,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assetClass", $$v);
      },
      expression: "form.assetClass"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Image")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [!_vm.isEdit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs": [],
      "upload-msg": _vm.$t('UploadImage'),
      "max": 1
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e(), _vm.isEdit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs2": _vm.form.images,
      "upload-msg": _vm.$t('UploadImage'),
      "max": 1
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.isEdit ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.changeSidepanelStateProperties(0);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "save-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.updateProperty();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.saveProperty();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e()])])])]), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteProperty
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }