var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    key: 'status-dropdown-' + _vm.invoice._id + '-' + _vm.componentUpdater,
    staticClass: "content-medium status-dropdown-container",
    class: {
      'selectable': _vm.selectedStatus.opensDropdown,
      'not-selectable': !_vm.selectedStatus.opensDropdown
    },
    on: {
      "click": _vm.openDropdown
    }
  }, [_c('div', {
    staticClass: "status-dropdown-container-text",
    class: {
      'additional-info-borders': !_vm.showAdditionalInfo || _vm.showAdditionalInfo && _vm.invoice.payments && _vm.invoice.payments.length === 0
    },
    style: {
      backgroundColor: _vm.selectedStatus.backgroundColor
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.selectedStatus.translation)) + " ")]), _vm.dropdownOpen ? _c('div', {
    staticClass: "type-dropdown"
  }, _vm._l(_vm.statuses.filter(function (arrayStatus) {
    return arrayStatus.selectableFromDropdown && (!_vm.selectedStatus.ifSelectedHidesFromDropdown || _vm.selectedStatus.ifSelectedHidesFromDropdown && !_vm.selectedStatus.ifSelectedHidesFromDropdown.includes(arrayStatus.value));
  }), function (status) {
    return _c('p', {
      key: status.value,
      staticClass: "content-medium",
      style: {
        backgroundColor: status.backgroundColor
      },
      on: {
        "click": function ($event) {
          return _vm.selectDropdownStatus(status);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(status.translation)) + " ")]);
  }), 0) : _vm._e(), _vm.invoice.payments && _vm.invoice.payments.length > 0 && _vm.showAdditionalInfo ? _c('div', {
    staticClass: "status-info-container",
    class: {
      'status-info-container-hover': !_vm.dropdownOpen
    },
    style: {
      backgroundColor: _vm.selectedStatus.backgroundColor
    },
    on: {
      "mouseover": _vm.openHover,
      "mouseleave": _vm.closeHover
    }
  }, [_c('img', {
    attrs: {
      "height": "16",
      "width": "16",
      "src": require('@/assets/images/info.svg'),
      "alt": ""
    }
  }), _vm.hoverOpen && _vm.invoice.payments.length > 0 ? _c('div', {
    staticClass: "status-info-dropdown"
  }, _vm._l(_vm.invoice.payments, function (info, index) {
    return _c('div', {
      key: 'status-info-' + index,
      staticClass: "status-info"
    }, [_c('div', {
      staticClass: "content-normal"
    }, [_vm._v(_vm._s(_vm.formatDate(info.date)))]), _c('div', {
      staticClass: "content-semibold",
      staticStyle: {
        "width": "100%",
        "text-align": "right"
      }
    }, [_vm._v(_vm._s(_vm.$nMapper.price(info.sum, '€')) + " ")])]);
  }), 0) : _vm._e()]) : _vm._e()]), _vm.paymentWidgetOpen ? _c('InvoicePaymentWidget', {
    attrs: {
      "invoice": _vm.invoice,
      "pre-fill-unpaid": true
    },
    on: {
      "updateInvoice": _vm.addPaymentConfirmed,
      "closeWidget": _vm.addPaymentCanceled
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }