<template>
  <div
      v-click-outside="() => (isOpen = false)"
      class="main"
      @focusin="handleFocus"
  >
    <v-text-field
        prepend-inner-icon="mdi-magnify"
        autocomplete="off"
        @focusin="isOpen = true"
        v-model="search"
        :label="placeholder"
        :error="errorKey"
        outlined
        @click:clear="clearSelection()"
        clearable
        hide-details
        style="background: white"
        @input="onSearchInput"
    >
    </v-text-field>
    <div
        v-if="items.length > -1 && isOpen"
        class="items"
    >
      <div @scroll="handleScroll" class="item-wrapper">
        <div :key="resultsUpdater">
          <div v-if="search && searchResults.length > 0">
            <div
                v-for="(item, index) in searchResults"
                @click="chooseResultItem(item)"
                :key="index"
                class="item"
            >
              <p>
                {{ item[nameKey] }}
              </p>
            </div>
          </div>
          <div v-else-if="search && searchResults.length === 0">
            <p class="no-results">{{$t("NoResultsFound")}}</p>
          </div>
          <div v-else>
            <div
                v-for="(item, index) in items"
                @click="chooseResultItem(item)"
                :key="index"
                class="item"
            >
              <p>
                {{ item[nameKey] }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Autocomplete",
  props: [
    "items",
    "placeholder",
    "errorKey",
    "nameKey",
  ],
  data: () => ({
    search: null,
    searchResults: [],
    resultsUpdater: 0,
    isOpen: false,
  }),
  watch: {
    items: {
      handler() {
        this.applySearch();
      },
      deep: true,
      immediate: true
    },
    search: {
      handler() {
        this.applySearch();
      },
      immediate: true
    }
  },
  methods: {
    clearSelection() {
      this.search = null;
      this.searchResults = [];
    },
    chooseResultItem(person) {
      this.$emit("itemChosen", person);
      this.search = null;
      this.isOpen = false;
      this.searchResults = [];
      this.resultsUpdater++;
    },
    handleFocus() {
      this.isOpen = true;
      this.$emit('focus-event');
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit('load-more');
      }
    },
    onSearchInput() {
      this.$emit('search', this.search);
    },
    applySearch() {
      this.searchResults = [];
      if (this.search) {
        this.items.forEach((item) => {
          if (item[this.nameKey].toLowerCase().includes(this.search.toLowerCase())) {
            this.searchResults.push(item);
          }
        });
      }
      this.resultsUpdater++;
    }
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  width: 100%;
  max-height: 54px;
}

.items {
  box-sizing: border-box;
  //border: 1px solid #e6e8ec;
  margin-top: 8px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  z-index: 111;
  position: absolute;
  background-color: white;
  width: 100%;
  border-radius: 8px;
}

.item-wrapper {
  max-height: 180px;
  overflow: auto;
}

.item {
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;

  &:hover {
    background-color: #f4f5f7;
    cursor: pointer;
  }
}

.no-results {
  min-height: 44px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
}

.separator {
  border-top: 1px solid #e6e8ec;
  width: 100%;
  height: 1px;
}

.add-new {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  padding: 16px 12px 16px 12px;
  background-color: white;
  gap: 8px;

  &:hover {
    cursor: pointer;
  }
}

.no-hover {
  cursor: default !important;
  background-color: white !important;
}

p,
span {
  font-size: 14px;
  line-height: 20px;
}

span {
  color: #939597;
}
</style>
