var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contact-simple-info-card"
  }, [_c('div', {
    staticClass: "contact-first-row"
  }, [_c('div', {
    staticClass: "contact-main-info"
  }, [_vm.customerName ? _c('span', [_c('p', {
    class: _vm.mainInfoFieldClass,
    on: {
      "click": _vm.nameOnPress
    }
  }, [_vm._v(" " + _vm._s(_vm.customerName) + " ")])]) : _vm._e(), _vm.phoneNumber ? _c('span', [_c('span', {
    staticClass: "main-info-divider"
  }, [_vm._v("|")]), _c('p', {
    class: _vm.mainInfoFieldClass,
    on: {
      "click": _vm.call
    }
  }, [_vm._v(" " + _vm._s(_vm.phoneNumber) + " ")])]) : _vm._e(), _vm.email ? _c('span', [_c('span', {
    staticClass: "main-info-divider"
  }, [_vm._v("|")]), _c('p', {
    class: _vm.mainInfoFieldClass,
    on: {
      "click": _vm.sendEmail
    }
  }, [_vm._v(" " + _vm._s(_vm.email) + " ")])]) : _vm._e()]), _c('ContactTags', {
    attrs: {
      "tags-object": _vm.contact.tags
    }
  })], 1), _c('UserNameContainer', {
    attrs: {
      "item": _vm.contact.creator,
      "is-owner": true
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }