<template>
  <div class="confirmation-modal-overlay">
    <div v-click-outside="onCancel" class="confirmation-modal">
      <div style="display: flex;">
        <img
            v-if="textStartIcon"
            style="margin-right: 8px;"
            :src="require(`@/assets/images/${textStartIcon}`)"
            alt=""
        />
        <slot></slot>
      </div>
      <div class="confirmation-modal-buttons-container">
        <BaseButton size="large" :label="$t('Cancel')" @click="onCancel"/>
        <BaseButton v-if="isDecline" size="large" :label="$t('No')" @click="onDecline"/>
        <BaseButton :primary="true" size="large" :label="$t('Confirm')" @click="onConfirm"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationModal",
  props: ['textStartIcon','isDecline'],
  components: {BaseButton: () => import("@/components/common/BaseButton")},
  mounted() {
  },
  created() {
  },
  methods: {
    onConfirm() {
      this.$emit("onConfirm")
    },
    onDecline() {
      this.$emit("onDecline")
    },
    onCancel() {
      this.$emit("onCancel")
    }
  }
}
</script>

<style scoped>
.confirmation-modal-overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 20;
}

.confirmation-modal {
  background-color: white;
  max-width: 400px;
  min-width: 400px;
  min-height: 136px;
  border-radius: 8px;
  padding: 16px 24px;
  z-index: 9;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.confirmation-modal-buttons-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: 8px;
}
</style>