var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('v-select', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    attrs: {
      "items": _vm.languages,
      "outlined": "",
      "item-text": "name",
      "item-value": "value",
      "label": _vm.$t('DisplayLanguage')
    },
    model: {
      value: _vm.selectedLanguage,
      callback: function ($$v) {
        _vm.selectedLanguage = $$v;
      },
      expression: "selectedLanguage"
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }