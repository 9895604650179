<template>
  <div
      v-if="loaded && isOpen"
      class="outputs-widget-wrapper"
      :key="listing._id"
  >
    <vue-draggable-resizable
        v-if="isOpen"
        :z="101"
        h="auto"
        :id="'outputs-draggable'"
        :resizable="false"
        drag-handle=".drag-area"
        class="widget-container"
        v-click-outside="closePanel"
    >
      <div id="outputs-widget" class="output-settings">
        <div class="output-settings-header drag-area">
          <img :src="require('@/assets/images/menu.svg')" alt="drag"/>
        </div>
        <div class="output-settings-content">
          <div class="output-settings-content-header">
            <h5>{{ $t("Outputs") }}</h5>
            <p class="content-normal">{{ outputsInfo() }}</p>
            <p class="content-small">{{ $t('ExportId') + ": " + listing.identifier }}</p>
          </div>
          <div class="output-settings-content-content">
            <p class="link-small" v-if="!changeExportAccounts && hasAccess(listing)"
               @click="changeExportAccounts = true;">{{ $t("ChangeExportAccounts") }}</p>
            <div v-for="marketplace of listing.marketplaces"
                 :key="marketplace.name + '-' + marketplacesUpdater + '-output'"
                 style="display: flex; flex-direction: column; gap: 2px;">
              <div style="display: flex; justify-content: space-between">
                <BaseToggle
                    :green="true"
                    class="output-toggle"
                    v-model="marketplace.isActive"
                    :label="marketplace.label || marketplace.name"
                    @change="(booleanValue) => marketplaceStatusUpdated(booleanValue, marketplace)"
                />
                <p v-if="marketplace.activeUser" class="content-small">{{ marketplace.activeUser }}</p>
                <div class="external-link-row" v-if="marketplace.url" @click="openMarketplaceURL(marketplace)">
                  <img :src="require('@/assets/images/link.svg')" alt=""/>
                  <p class="link-small">{{ $t("ViewListing") }}</p>
                </div>
              </div>
              <div v-if="changeExportAccounts && marketplaceUsers">
                <v-autocomplete
                    outlined
                    dense
                    clearable
                    hide-details
                    hide-no-data
                    :label="`${marketplace.name}  ${$t('account')}`"
                    :placeholder="`${$t('Select')} ${$t('account')}`"
                    :items="marketplaceUsers[marketplace.name]"
                    v-model="marketplace.activeUser"
                    @input="(email) => openConfirmation(email, marketplace)"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-draggable-resizable>
    <ConfirmationModal @onConfirm="onConfirmation" @onCancel="closeConfirmation" v-if="confirmationModalOpen" style="z-index: 1522">
      {{ $t('ListingWillBeSharedWithUser') }}: {{ confirmationModalEmail }}
    </ConfirmationModal>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import axios from "axios";
import BaseToggle from "@/components/common/BaseToggle.vue";
import {mapGetters} from "vuex";
import ConfirmationModal from "@/components/common/ConfirmationModal";

export default {
  name: "OutputsModal",
  props: ["listing", "project", "toggleModal", "position"],
  components: {
    ConfirmationModal,
    VueDraggableResizable,
    BaseToggle,
  },
  data() {
    return {
      confirmationModalOpen: false,
      confirmationModalEmail: "",
      confirmationModalMarketplace: null,
      marketplacesUpdater: 0,
      marketplaceUsers: null,
      changeExportAccounts: false,
      loaded: false,
      isOpen: false,
    };
  },
  created() {
    this.setupListing();
  },
  watch: {
    isOpen(val) {
      if (val) {
        const interval = setInterval(() => {
          const element = document.getElementById("outputs-draggable");
          if (element) {
            element.style.top = this.position.y + "px";
            element.style.left = this.position.x + "px";
            element.style.visibility = "visible";
            const widget = document
                .getElementById("outputs-widget")
                .getBoundingClientRect();
            const isOutOfViewport = this.isOutOfViewport(element, widget);
            if (isOutOfViewport.bottom) {
              element.style.top = "unset";
              element.style.bottom = isOutOfViewport.rect.height - 20 + "px";
            }
            if (isOutOfViewport.right) {
              element.style.left = "unset";
              element.style.right = "340px";
            }

            clearInterval(interval);
          }
        }, 50);
      }
    },
    listing() {
      this.setupListing();
    },
    toggleModal() {
      this.isOpen = !this.isOpen;
      this.changeExportAccounts = false;
    },
  },
  computed: {
    ...mapGetters(["user", "users"]),
  },
  methods: {
    isOutOfViewport(elem, realWidget) {
      const rect = elem.getBoundingClientRect();
      rect.width = realWidget.width;
      rect.height = realWidget.height;
      const outOfViewport = {rect: rect};
      outOfViewport.bottom =
          rect.bottom >
          (window.innerHeight || document.documentElement.clientHeight);
      outOfViewport.right =
          rect.right >
          (window.innerWidth || document.documentElement.clientWidth);
      return outOfViewport;
    },
    async closePanel(el) {
      if (this.confirmationModalOpen) return;
      el = el.target;
      if (
          (el.classList && el.classList.contains("v-list-item__title")) ||
          el.classList.contains("v-list-item") ||
          el.classList.contains("v-list") ||
          el.classList.contains("v-list-item__content")
      ) {
        return;
      }
      while (el.parentNode) {
        el = el.parentNode;
        if (el.id === "outputs-widget") return;
      }
      this.isOpen = false;
    },
    async setupListing() {
      await this.getMarketplaceUsers();
      this.loaded = true;
    },
    outputsInfo() {
      const listingAccess = this.listing.access;
      const listingOwner = listingAccess?.usersArray.find((user) => user.ownershipType === 'owner')
      return this.$t('ByDefaultAllActiveListingsAreExportedFromYourAccount') + ` ${listingOwner.email || ''}`;
    },
    openMarketplaceURL(marketplace) {
      if (marketplace.url || marketplace.portalURL) {
        window.open(marketplace.url || marketplace.portalURL, "_blank").focus();
      }
    },
    async getMarketplaceUsers() {
      const response = await axios.post('/api/users/output', {listing: {_id: this.listing._id}})
      this.marketplaceUsers = response.data;
    },
    async onConfirmation() {
      await this.marketplaceUpdated(this.confirmationModalEmail, this.confirmationModalMarketplace);
      this.confirmationModalOpen = false;
      this.confirmationModalEmail = "";
      this.confirmationModalMarketplace = null;
    },
    async closeConfirmation() {
      this.$nextTick(() => {
        this.confirmationModalOpen = false;
        this.confirmationModalEmail = "";
        const listingMarketplace = this.listing.marketplaces.find((listingMp) => listingMp.name === this.confirmationModalMarketplace.name);
        listingMarketplace.activeUser = null;
        this.confirmationModalMarketplace = null;
      })
    },
    async openConfirmation(email, marketplace) {
      // Check if user is in listing
      let userIsSharedCreator = false;
      const listingAccess = this.listing.access;
      for (const listingUser of listingAccess.usersArray) {
        if (listingUser.email === email) {
          userIsSharedCreator = true;
        }
      }
      if (!userIsSharedCreator && email !== null) {
        this.confirmationModalOpen = true;
        this.confirmationModalEmail = email;
        this.confirmationModalMarketplace = marketplace;
      } else {
        await this.marketplaceUpdated(email, marketplace)
      }
    },
    async marketplaceUpdated(email, marketplace) {
      const response = await axios.post('/api/users/output-user-changed', {
        listing: {_id: this.listing._id},
        newActiveUser: email,
        marketplace: marketplace
      })
      if (response && response.status === 200) {
        switch (response.data.message) {
          case "success":
            this.toastSuccess(this.$t("Updated"))
            break;
          case "marketplace_active_limit_exceeded":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.toastError(`${marketplace.name} ${this.$t("LimitHasBeenReached")}`)
            break;
        }
      } else {
        this.toastError(this.$t("ErrorUpdating"))
      }
    },
    async marketplaceStatusUpdated(booleanValue, marketplace) {
      const response = await axios.post('/api/users/output-status-changed', {
        listing: {_id: this.listing._id},
        isActive: booleanValue,
        marketplace: marketplace
      })
      if (response && response.status === 200) {
        switch (response.data.message) {
          case "success":
            this.toastSuccess(this.$t("Updated"))
            break;
          case "marketplace_active_limit_exceeded":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.toastError(`${marketplace.name} ${this.$t("LimitHasBeenReached")}`)
            break;
          case "saleAML":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.toastError(this.$t('CompleteKnowYourCustomerFirst'));
            break;
          case "saleAMLFileMissing":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.toastError(this.$t('KYCMustIncludeFile'));
            break;
          case "saleAgreement":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.toastError(this.$t('ProjectMustHaveAnActiveSalesAgreement'));
            break;
          case "rentalAgreement":
            marketplace.isActive = false;
            this.marketplacesUpdater++;
            this.toastError(this.$t('ProjectMustHaveAnActiveRentAgreement'));
            break;
        }
      } else {
        marketplace.isActive = !booleanValue;
        this.marketplacesUpdater++;
        this.toastError(this.$t("ErrorUpdating"))
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/global_variables.scss";

.outputs-widget-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 103;
}

.widget-container {
  position: fixed;
  padding-bottom: 20px;
  padding-right: 40px;
  visibility: hidden;
}

.drag-area {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
}

.link-small {
  line-height: 15px !important;
}

.output-toggle {
  min-width: 140px;
  flex-grow: 0;
}

.output-settings {
  cursor: default;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-width: 540px;
  z-index: 102;
  border-radius: 8px;
  position: fixed;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.output-settings-header {
  display: flex;
  align-items: center;
  height: 34px;
  padding-left: 10px;
  border-radius: 8px 8px 0 0;
  background-color: $grey;
}

.drag-area {
  cursor: grab;
}

.drag-area:active {
  cursor: grabbing;
}

.output-settings-content {
  border-radius: 0 0 8px 8px;
  padding: 16px 24px;
  background-color: white;
}

.output-settings-content-header {
  display: flex;
  gap: 4px;
  flex-direction: column;
  padding-bottom: 16px;
  border-bottom: 1px solid $grey500;
}

.output-settings-content-content {
  padding-top: 16px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.external-link-row {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  gap: 6px;
}

</style>
