var render = function render(){
  var _vm$invoice, _vm$invoice2, _vm$invoice3, _vm$invoice4, _vm$invoice4$project, _vm$invoice5, _vm$invoice6, _vm$invoice7, _vm$invoice7$project, _vm$invoice9, _vm$invoice9$project, _vm$invoice10, _vm$invoice10$project, _vm$invoice12, _vm$invoice$client, _vm$invoice$client2, _vm$invoice$client3, _vm$invoice$client4, _vm$invoice$client5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    style: _vm.OverlayStyle,
    attrs: {
      "id": "invoice-overlay"
    }
  }, [_vm.sidewaysHeader ? _c('div', {
    staticClass: "invoice-panel-header-sideways",
    class: {
      'project-sidepanel-open': _vm.projectClientAddSpidepanelOpen,
      'project-sidepanel-closed': !_vm.projectClientAddSpidepanelOpen
    },
    style: _vm.sidewaysHeaderStyle,
    attrs: {
      "id": "invoice-header"
    },
    on: {
      "click": function ($event) {
        return _vm.closeSidepanels();
      }
    }
  }, [_c('h4', [_vm._v(" " + _vm._s(!_vm.invoice ? _vm.$t("AddNewInvoice") : _vm.$t("EditInvoice") + " " + ((_vm$invoice = _vm.invoice) !== null && _vm$invoice !== void 0 && _vm$invoice.number ? _vm.invoice.number : "")) + " ")])]) : _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleOutsideClick,
      expression: "handleOutsideClick"
    }],
    staticClass: "invoice-panel",
    attrs: {
      "id": "invoice-panel"
    }
  }, [_vm.invoiceLoading ? _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "loader"
  })]) : [_vm.invoice && !_vm.editInvoice ? _c('div', {
    staticClass: "invoice-panel-header"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "header-first-row"
  }, [_c('div', {
    staticClass: "invoice-name-row"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Invoice")) + " " + _vm._s((_vm$invoice2 = _vm.invoice) === null || _vm$invoice2 === void 0 ? void 0 : _vm$invoice2.number))]), (_vm$invoice3 = _vm.invoice) !== null && _vm$invoice3 !== void 0 && _vm$invoice3.project ? _c('DealTypeContainer', {
    attrs: {
      "small-font": false,
      "deal-type": (_vm$invoice4 = _vm.invoice) === null || _vm$invoice4 === void 0 ? void 0 : (_vm$invoice4$project = _vm$invoice4.project) === null || _vm$invoice4$project === void 0 ? void 0 : _vm$invoice4$project.dealType
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "header-button-container"
  }, [_vm.hasAccess(_vm.invoice, 'invoices', 'edit') ? _c('BaseButton', {
    attrs: {
      "icon-left": 'edit_16_16.svg',
      "label": _vm.$t('Edit'),
      "size": "small"
    },
    on: {
      "click": _vm.setupEditFields
    }
  }) : _vm._e()], 1)]), _c('v-row', {
    staticClass: "header-second-row",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/projects.svg"),
      "alt": ""
    }
  }), _c('a', {
    class: {
      'link-small hoverable-info': (_vm$invoice5 = _vm.invoice) === null || _vm$invoice5 === void 0 ? void 0 : _vm$invoice5.project,
      'content-small cursor-none': !((_vm$invoice6 = _vm.invoice) !== null && _vm$invoice6 !== void 0 && _vm$invoice6.project)
    },
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "href": _vm.invoice.isUnit ? _vm.path + '/property-development/' + _vm.developmentId : _vm.path + '/projects/' + ((_vm$invoice7 = _vm.invoice) === null || _vm$invoice7 === void 0 ? void 0 : (_vm$invoice7$project = _vm$invoice7.project) === null || _vm$invoice7$project === void 0 ? void 0 : _vm$invoice7$project._id) + '/general'
    },
    on: {
      "keyup": function ($event) {
        var _vm$invoice8;
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.openProject((_vm$invoice8 = _vm.invoice) === null || _vm$invoice8 === void 0 ? void 0 : _vm$invoice8.project._id);
      }
    }
  }, [_vm._v(" " + _vm._s((_vm$invoice9 = _vm.invoice) !== null && _vm$invoice9 !== void 0 && (_vm$invoice9$project = _vm$invoice9.project) !== null && _vm$invoice9$project !== void 0 && _vm$invoice9$project.name ? _vm.invoice.project.name : (_vm$invoice10 = _vm.invoice) !== null && _vm$invoice10 !== void 0 && (_vm$invoice10$project = _vm$invoice10.project) !== null && _vm$invoice10$project !== void 0 && _vm$invoice10$project.apartmentNumber ? _vm.$t("Apartment") + " " + _vm.invoice.project.apartmentNumber : "-") + " ")]), _vm.invoice && _vm.invoice.project ? _c('div', {
    staticClass: "open-new-tab-button",
    on: {
      "click": function ($event) {
        var _vm$invoice11;
        return _vm.openProjectNewTab((_vm$invoice11 = _vm.invoice) === null || _vm$invoice11 === void 0 ? void 0 : _vm$invoice11.project._id);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/open_new_tab.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t("OpenInNewTab")))])]) : _vm._e()])])], 1)]) : _c('div', {
    staticClass: "invoice-panel-header-add"
  }, [_c('h4', [_vm._v(" " + _vm._s(!_vm.invoice ? _vm.$t("AddNewInvoice") : _vm.$t("EditInvoice") + " " + ((_vm$invoice12 = _vm.invoice) !== null && _vm$invoice12 !== void 0 && _vm$invoice12.number ? _vm.invoice.number : "")) + " ")])]), _vm.invoice && !_vm.editInvoice ? _c('div', {
    staticClass: "invoice-panel-content"
  }, [_c('div', {
    key: 'invoice-content-' + _vm.invoiceContentUpdater,
    staticClass: "invoice-panel-content-info"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("MainInfo")))]), _c('div', {
    staticClass: "invoice-panel-content-info-details"
  }, [_c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("InvoiceDate")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.invoice.invoiceDate)) + " ")])]), _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PaymentDueDate")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.invoice.paymentDueDate)) + " ")])]), _vm.hasAccess(_vm.invoice) ? _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Client")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('div', [_vm._v(" " + _vm._s((_vm$invoice$client = _vm.invoice.client) === null || _vm$invoice$client === void 0 ? void 0 : _vm$invoice$client.name) + " " + _vm._s((_vm$invoice$client2 = _vm.invoice.client) === null || _vm$invoice$client2 === void 0 ? void 0 : _vm$invoice$client2.organization) + " ")]), _c('div', [_vm._v(" " + _vm._s((_vm$invoice$client3 = _vm.invoice.client) === null || _vm$invoice$client3 === void 0 ? void 0 : _vm$invoice$client3.registryCode) + " ")]), _c('div', [_vm._v(" " + _vm._s((_vm$invoice$client4 = _vm.invoice.client) === null || _vm$invoice$client4 === void 0 ? void 0 : _vm$invoice$client4.personalCode) + " ")]), _c('div', [_vm._v(" " + _vm._s((_vm$invoice$client5 = _vm.invoice.client) === null || _vm$invoice$client5 === void 0 ? void 0 : _vm$invoice$client5.address) + " ")])])]) : _vm._e(), _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("InvoiceType")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatInvoiceType(_vm.invoice.type)) + " ")])]), _vm.invoice.description ? _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Description")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.invoice.description) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Status")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_c('StatusDropdown', {
    attrs: {
      "invoice": _vm.invoice
    }
  })], 1)]), _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("TotalSum")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.$nMapper.price(_vm.invoice.sum, "€")))])])]), _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("TotalSumWithVAT")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.$nMapper.price(_vm.invoice.sumVAT, "€")))])])]), _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("InvoiceOwner")) + " ")]), _c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "display": "flex",
      "gap": "12px",
      "flex-wrap": "nowrap"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.invoice.broker ? _vm.invoice.broker.name : _vm.invoice.creator.name) + " ")]), _c('div', {
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v(" " + _vm._s(_vm.invoice.sharedBrokers && _vm.invoice.sharedBrokers.length > 0 ? `${_vm.invoice.broker.share.sum} € (${_vm.formatDecimals(_vm.invoice.broker.share.percentage)}%)` : "") + " ")])])]), _vm.invoice.sharedBrokers && _vm.invoice.sharedBrokers.length > 0 ? _c('div', {
    staticClass: "invoice-panel-content-info-row"
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "width": "50%",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Broker")) + " ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "width": "50%"
    }
  }, _vm._l(_vm.invoice.sharedBrokers, function (sharedBroker, index) {
    return _c('div', {
      key: 'invoice-share-' + sharedBroker._id + '-' + index,
      staticClass: "content-normal",
      staticStyle: {
        "width": "100%",
        "display": "flex",
        "gap": "12px",
        "flex-wrap": "nowrap"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "50%"
      }
    }, [_vm._v(" " + _vm._s(sharedBroker._id ? sharedBroker.name : _vm.$t(sharedBroker.name)) + " ")]), _c('div', {
      staticStyle: {
        "width": "50%"
      }
    }, [_vm._v(" " + _vm._s(`${sharedBroker.share.sum} € (${_vm.formatDecimals(sharedBroker.share.percentage)}%)`) + " ")])]);
  }), 0)]) : _vm._e()])]), _c('div', {
    staticClass: "invoice-panel-content-payments"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Payments")))]), _vm.invoice.status !== 'paid' && _vm.hasAccess(_vm.invoice) ? _c('BaseButton', {
    attrs: {
      "icon-left": 'add_16_16.svg',
      "label": _vm.$t('Add'),
      "size": "small"
    },
    on: {
      "click": _vm.addPayment
    }
  }) : _vm._e()], 1), _vm.invoice.payments.length > 0 ? _c('div', {
    staticStyle: {
      "width": "fit-content"
    }
  }, _vm._l(_vm.invoice.payments, function (payment, index) {
    return _c('div', {
      key: 'invoice-payment-' + index,
      staticClass: "payment-card"
    }, [_c('p', {
      staticClass: "content-normal",
      staticStyle: {
        "width": "80px"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatDate(payment.date)) + " ")]), _c('div', {
      staticClass: "content-semibold",
      staticStyle: {
        "width": "100%"
      }
    }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(payment.sum, "€")) + " ")]), _vm.hasAccess(_vm.invoice) ? _c('div', {
      staticClass: "invoice-payment-delete",
      on: {
        "click": function ($event) {
          return _vm.deletePayment(payment);
        }
      }
    }, [_c('img', {
      attrs: {
        "width": "16",
        "height": "16",
        "src": require('@/assets/images/trash.svg'),
        "alt": ""
      }
    })]) : _vm._e()]);
  }), 0) : _c('div', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("InvoiceHasNoPaymentsYet")) + " ")])])]) : _c('div', {
    staticClass: "invoice-panel-content-add"
  }, [_c('div', {
    staticClass: "invoice-panel-content-add-project"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('InvoiceNumber'),
      "type": "text"
    },
    model: {
      value: _vm.invoiceNumber,
      callback: function ($$v) {
        _vm.invoiceNumber = $$v;
      },
      expression: "invoiceNumber"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "icon-left": "calendar_20_20.svg",
      "placeholder": _vm.$t('InvoiceDate'),
      "type": "date"
    },
    model: {
      value: _vm.invoiceDate,
      callback: function ($$v) {
        _vm.invoiceDate = $$v;
      },
      expression: "invoiceDate"
    }
  })], 1), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "12px"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "50%"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('PaymentTermInDays'),
      "type": "number",
      "error": _vm.PaymentTermInDaysError.showError,
      "error-message": _vm.PaymentTermInDaysError.errorMessage
    },
    model: {
      value: _vm.paymentTermInDays,
      callback: function ($$v) {
        _vm.paymentTermInDays = $$v;
      },
      expression: "paymentTermInDays"
    }
  })], 1), _c('div', {
    staticStyle: {
      "width": "50%",
      "padding": "14px 12px"
    }
  }, [_c('div', {
    staticClass: "content-semibold",
    staticStyle: {
      "width": "fit-content",
      "padding": "2px 4px",
      "background-color": "#fff0d4",
      "border-radius": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PaymentDate")) + ": " + _vm._s(_vm.paymentTermInDays && _vm.invoiceDate && _vm.paymentDueDate ? _vm.paymentDueDate : "-") + " ")])])]), _c('BaseProjectSearch', {
    key: 'project-' + _vm.projectUpdater,
    attrs: {
      "icon-left": 'search20.svg',
      "value": _vm.invoiceProject,
      "get-client": true,
      "placeholder": _vm.$t('Project'),
      "show-completed-projects": false,
      "dropdown-offset-y": 52
    },
    on: {
      "change": function (item) {
        _vm.selectInvoiceProject(item);
      },
      "addNew": _vm.openProjectAddSidepanel
    }
  }), _c('BaseDropdown', {
    attrs: {
      "placeholder": _vm.$t('InvoiceType'),
      "dropdown-offset-y": 60,
      "show-dropdown-arrow": true,
      "type": "dropdown",
      "required": true,
      "error-message": _vm.InvoiceTypeError.errorMessage,
      "error": _vm.InvoiceTypeError.showError,
      "items": _vm.invoiceTypes
    },
    model: {
      value: _vm.invoiceType,
      callback: function ($$v) {
        _vm.invoiceType = $$v;
      },
      expression: "invoiceType"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Description'),
      "type": "text"
    },
    model: {
      value: _vm.invoiceDescription,
      callback: function ($$v) {
        _vm.invoiceDescription = $$v;
      },
      expression: "invoiceDescription"
    }
  }), _c('InvoiceSharing', {
    attrs: {
      "date": _vm.invoiceDate,
      "error": _vm.VATError,
      "project": _vm.invoiceProject
    },
    on: {
      "changed": function (value) {
        return _vm.invoiceSharesUpdated(value);
      }
    }
  })], 1)]), _c('div', {
    key: 'client-section-' + _vm.clientUpdater,
    staticClass: "invoice-panel-content-add-client"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Client")))]), _c('div', [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.invoiceClient.type,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceClient, "type", $$v);
      },
      expression: "invoiceClient.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('PrivatePerson.Label'),
      "value": 'privatePerson',
      "color": "orange darken-3"
    }
  }), _vm.invoiceClient.type !== 'generalOrganization' ? _c('v-radio', {
    attrs: {
      "label": _vm.$t('Company'),
      "value": 'estoniaOrganization',
      "color": "orange darken-3"
    }
  }) : _vm._e(), _vm.invoiceClient.type === 'generalOrganization' ? _c('v-radio', {
    attrs: {
      "label": _vm.$t('Company'),
      "value": 'generalOrganization',
      "color": "orange darken-3"
    }
  }) : _vm._e()], 1), _vm.invoiceClient.type === 'privatePerson' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, [_c('ClientListSearch', {
    attrs: {
      "icon-left": 'search20.svg',
      "required": true,
      "error-message": _vm.ClientError.errorMessage,
      "error": _vm.ClientError.showError,
      "placeholder": _vm.$t('FullName')
    },
    on: {
      "itemChosen": _vm.selectClient,
      "addNew": _vm.openClientAddSidepanel,
      "removeValue": _vm.removePrivatePerson
    },
    model: {
      value: _vm.invoiceClientSearch,
      callback: function ($$v) {
        _vm.invoiceClientSearch = $$v;
      },
      expression: "invoiceClientSearch"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('PersonalCode'),
      "type": "text"
    },
    model: {
      value: _vm.invoiceClient.personalCode,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceClient, "personalCode", $$v);
      },
      expression: "invoiceClient.personalCode"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Address'),
      "type": "text"
    },
    model: {
      value: _vm.invoiceClient.address,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceClient, "address", $$v);
      },
      expression: "invoiceClient.address"
    }
  })], 1) : _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, [_c('div', {
    staticClass: "organization-wrapper"
  }, [_c('div', {
    staticClass: "organization-header"
  }, [_c('v-radio-group', {
    attrs: {
      "hide-details": "",
      "row": ""
    },
    on: {
      "change": function ($event) {
        _vm.clientUpdater++;
      }
    },
    model: {
      value: _vm.invoiceClient.type,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceClient, "type", $$v);
      },
      expression: "invoiceClient.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('Estonian'),
      "color": "orange darken-3",
      "value": 'estoniaOrganization'
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('Generic'),
      "value": 'generalOrganization',
      "color": "orange darken-3"
    }
  })], 1)], 1), _c('div', {
    staticClass: "organization-body"
  }, [_vm.invoiceClient.type === 'estoniaOrganization' ? _c('div', [_c('BaseDropdown', {
    attrs: {
      "placeholder": _vm.$t('OrganizationsName'),
      "dropdown-allow-null": true,
      "type": "organizationsearch",
      "dropdown-offset-y": 52,
      "required": true,
      "error-message": _vm.ClientError.errorMessage,
      "error": _vm.ClientError.showError
    },
    on: {
      "change": function ($event) {
        _vm.invoiceClient.type = 'estoniaOrganization';
      }
    },
    model: {
      value: _vm.invoiceClient,
      callback: function ($$v) {
        _vm.invoiceClient = $$v;
      },
      expression: "invoiceClient"
    }
  })], 1) : _vm.invoiceClient.type === 'generalOrganization' ? _c('div', [_c('v-row', [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('OrganizationsName'),
      "required": true,
      "error-message": _vm.ClientError.errorMessage,
      "error": _vm.ClientError.showError
    },
    model: {
      value: _vm.invoiceClient.organization,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceClient, "organization", $$v);
      },
      expression: "invoiceClient.organization"
    }
  })], 1), _c('v-row', [_c('GoogleAddressSearch', {
    attrs: {
      "placeholder": _vm.$t('Object') + ' ' + _vm.$t('Address').toLowerCase()
    },
    model: {
      value: _vm.invoiceClient.address,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceClient, "address", $$v);
      },
      expression: "invoiceClient.address"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('CustomerField.phoneNumber'),
      "icon-left": "phone_20_20.svg"
    },
    model: {
      value: _vm.invoiceClient.phoneNum,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceClient, "phoneNum", $$v);
      },
      expression: "invoiceClient.phoneNum"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('CustomerField.email'),
      "icon-left": "email_20_20.svg"
    },
    model: {
      value: _vm.invoiceClient.orgEmail,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceClient, "orgEmail", $$v);
      },
      expression: "invoiceClient.orgEmail"
    }
  })], 1)], 1) : _vm._e()])])])], 1)])]), !_vm.invoice || _vm.invoice && _vm.editInvoice ? _c('div', {
    staticClass: "invoice-panel-add-footer",
    style: _vm.invoice && _vm.editInvoice ? 'justify-content: space-between;' : 'justify-content: flex-end;'
  }, [_vm.invoice && _vm.editInvoice ? _c('div', {
    staticStyle: {
      "justify-self": "flex-start"
    }
  }, [_vm.hasAccess(_vm.invoice, 'invoices', 'delete') ? _c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Delete'),
      "icons-style": 'width: 16px; height: 16px;',
      "label-color": '#ff1e24',
      "border-color": '#ff1e24',
      "icon-left": "red_trash_16_16.svg"
    },
    on: {
      "click": _vm.deleteInvoice
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.closeModal
    }
  }), _c('BaseButton', {
    attrs: {
      "icon-left": 'checkmark_white_16_16.svg',
      "size": "large",
      "primary": "true",
      "label": _vm.$t('Save')
    },
    on: {
      "click": _vm.saveButtonClicked
    }
  })], 1)]) : _vm._e()], _vm.paymentWidgetOpen ? _c('InvoicePaymentWidget', {
    attrs: {
      "invoice": _vm.invoice,
      "pre-fill-unpaid": true
    },
    on: {
      "updateInvoice": _vm.addPaymentConfirmed,
      "closeWidget": function ($event) {
        _vm.paymentWidgetOpen = false;
      }
    }
  }) : _vm._e(), _vm.invoiceDeleteModalOpen ? _c('DeleteModal', {
    on: {
      "approved": _vm.deleteInvoiceConfirmed,
      "canceled": function ($event) {
        _vm.invoiceDeleteModalOpen = false;
      }
    }
  }) : _vm._e(), _vm.paymentToDelete ? _c('ConfirmationModal', {
    on: {
      "onCancel": function ($event) {
        _vm.paymentToDelete = null;
      },
      "onConfirm": _vm.deletePaymentConfirmed
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("RecordedPaymentFor") + " " + _vm.$nMapper.price(_vm.paymentToDelete.sum, "€") + " " + _vm.$t("WillBeDeleted")) + " ")]) : _vm._e(), _vm.invoiceSaveConfirmation ? _c('ConfirmationModal', {
    attrs: {
      "isDecline": true
    },
    on: {
      "onCancel": _vm.handleCancelInvoiceSave,
      "onDecline": _vm.handleDeclineInvoiceSave,
      "onConfirm": _vm.handleConfirm
    }
  }, [_c('h5', {
    staticClass: "title-text",
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "padding-top": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("DoYouWantToSaveInvoice")) + " ")])]) : _vm._e()], 2), _vm.projectAddSidepanelOpen ? _c('SidepanelAdd', {
    attrs: {
      "from-another-panel": true
    },
    on: {
      "closed": _vm.closeProjectAddSidepanel,
      "openedClientAdd": _vm.projectSidepanelOpenedClientSidepanel,
      "closedClientAdd": _vm.projectSidepanelClosedClientSidepanel,
      "saveResponse": _vm.newProjectAdded
    }
  }) : _vm._e(), _vm.clientAddSidepanelOpen ? _c('AddModal', {
    attrs: {
      "not-contact-view-route": true
    },
    on: {
      "newAdded": _vm.newClientAdded,
      "closed": _vm.closeClientAddSidepanel
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }