<template>
  <div
    id="listing-overlay"
    ref="listingOverlay"
    :style="style"
    v-bind:class="{
      'listing-overlay-full': !contactView && !projectView,
      'listing-overlay-small': contactView,
      'listing-overlay-small-border': projectView,
    }"
    @click="closePanel($event)"
  >
    <div
      class="listing-panel"
      :style="mobileView ? 'width: 380px' : null"
      v-click-outside="closePanel"
    >
      <div
        :key="updater"
        v-if="listing.linkedDeal && openHouse"
        style="
          background-color: #7200e2;
          width: 100%;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <OpenHouseContainer :task="openHouse" :invert="false" />
      </div>
      <div
        v-if="!listingLoaded"
        class="loader"
        style="position: absolute; top: 50%; left: 50%"
      ></div>
      <div
        v-if="
          listing.status === 'archived' &&
          relatedProject &&
          (relatedProject.pipelineStep === 'archived' ||
            relatedProject.pipelineStep === 'Done')
        "
        class="project-archived-header content-small"
      >
        {{ $t("ToMakeThisListingActiveGoTo") }}
        <a
          class="link-small"
          :href="
            listing.isNewDevelopment
              ? path + '/property-development/' + relatedProject._id
              : path + '/projects/' + listing.linkedDeal + '/general'
          "
          >{{ $t("Project's").toLowerCase() }}</a
        >
        {{ $t("andPress") }}
        <p style="font-weight: 600">"{{ $t("DuplicateAsNew") }}"</p>
        {{ $t("Button").toLowerCase() }}.
      </div>
      <div
        :key="updater"
        class="listing-panel-header"
        id="listingpanel-header"
        v-if="listingLoaded"
      >
        <div v-if="listing.isNewDevelopment">
          <BaseToggle
            v-show="
              listing.developmentStatus &&
              listing.developmentStatus !== 'archived'
            "
            style=""
            :value="listing.isPublic"
            :label="$t('Public')"
            @change="(val) => toggleListingPublic(val, listing._id)"
          />
        </div>
        <v-row
          class="header-first-row"
          v-if="!publicListing && !companyLanderPage && !clientReportPage"
          style="display: flex; align-items: center; margin: 0"
        >
          <v-col style="padding: 0">
            <v-row
              v-if="
                !listing.isNewDevelopment ||
                (listing.isNewDevelopment && listing.isPublic)
              "
            >
              <div
                style="
                  flex: auto;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: nowrap;
                  margin-right: 4px;
                  margin-top: 1px;
                  align-items: center;
                  gap: 8px;
                "
              >
                <p
                  @click="openStatusDropdown"
                  :id="'statusLabel' + listing._id"
                  class="statusLabel content-bold"
                  v-bind:class="{
                    'draft-label ': listing.status === 'draft',
                    'active-label ': listing.status === 'active',
                    'booked-label': listing.status === 'booked',
                    'sold-label ': listing.status === 'sold',
                    'archived-label': listing.status === 'archived',
                    'rented-label': listing.status === 'rented',
                    'expired-label': listing.status === 'expired',
                  }"
                >
                  {{ $lfMapper.status(listing.status) }}
                </p>

                <v-tooltip bottom right :key="bookingUpdater">
                  <template v-slot:activator="{ on, attrs }">
                    <p
                      v-bind="attrs"
                      v-on="on"
                      v-if="listing.status === 'booked'"
                      class="booked-label booked-info-label-sidepanel"
                    >
                      <img
                        style="filter: invert(1)"
                        src="../../assets/images/info.svg"
                        alt=""
                        @click="openBookingDetailsModal(listing)"
                      />
                    </p>
                  </template>
                  <span>
                    {{ $t("BookingDetails") }}
                  </span>
                </v-tooltip>

                <p
                  style="margin-left: 8px"
                  v-if="listing.status === 'expired' && listing.expiryDate"
                  class="content-small"
                >
                  {{ $lfMapper.expiryDate(listing.expiryDate) }}
                </p>
                <Outputs
                    v-if="listing.status === 'active' || listing.status === 'booked'"
                    :listing="listing"
                    @openOutputSettings="(outputPosition) => openOutputsModal(outputPosition)"
                />
              </div>
            </v-row>
          </v-col>
          <v-col style="max-width: 240px; padding: 0">
            <v-row
              v-if="!companyLanderPage"
              style="justify-content: flex-end"
            >
              <div
                class="button"
                style="margin-right: 2px"
                @click="goToPrintPreview()"
              >
                <img src="../../assets/images/print.svg" alt="" />
                <p class="button-text">
                  {{ $t("Print") }}
                </p>
              </div>
              <a
                v-if="$route.params.fromListingsTable && hasAccess(listing) && (!relatedProject ||
                  listing.status !== 'archived' ||
                  (listing.status === 'archived' &&
                    relatedProject &&
                    relatedProject.pipelineStep !== 'archived' &&
                    relatedProject.pipelineStep !== 'Done'))"
                class="button"
                :href="editPath"
                id="link-button"
                style="margin: 0 2px; text-decoration: unset"
                @keyup.enter="(event) => editListingMode(event)"
              >
                <img
                  src="../../assets/images/edit.svg"
                  alt=""
                  style="height: 16px; width: 16px"
                />
                <p class="button-text">
                  {{ $t("Edit") }}
                </p>
              </a>
              <a
                v-if="!$route.params.fromListingsTable && hasAccess(listing)"
                class="button"
                id="link-button"
                :href="editPath"
                style="margin: 0 2px; text-decoration: unset"
                @keyup.enter="(event) => editListingMode(event)"
              >
                <img
                  src="../../assets/images/edit.svg"
                  alt=""
                  style="height: 16px; width: 16px"
                />
                <p class="button-text">
                  {{ $t("Edit") }}
                </p>
              </a>

              <div
                class="button"
                style="margin: 0 2px"
                @click="copyToClipboard()"
              >
                <img
                  src="../../assets/images/add-link.svg"
                  alt=""
                  style="height: 16px; width: 16px"
                />
              </div>
              <div v-if="(!relatedProject ||
                  listing.status !== 'archived' ||
                  (listing.status === 'archived' &&
                    relatedProject &&
                    relatedProject.pipelineStep !== 'archived' &&
                    relatedProject.pipelineStep !== 'Done'))" class="button" style="margin-left: 2px">
                <IconDropdown
                  style="width: 16px; margin-top: -1px"
                  :custom-style="'border: 1px solid $grey500; display: flex; align-items: center; justify-content: center; margin: auto; border-radius: 6px; height: 24px; padding: 0 4px; cursor: pointer; width: 20px; &:hover { background-color: #F4F5F7; }'"
                  :listing-view="true"
                  :items="additional_activities"
                  :display-key="'name'"
                  :value-key="'value'"
                  :icon="'dot_menu.svg'"
                  @itemSelected="selectActivity"
                />
              </div>
            </v-row>
          </v-col>
        </v-row>
        <div
          class="dates-row"
          v-show="
            !listing.developmentStatus ||
            (listing.developmentStatus &&
              listing.developmentStatus !== 'archived')
          "
        >
          <p
            style="padding-top: 2px"
            @click="openBookingDetailsModal(listing)"
            v-if="listing.status === 'booked' && listing.bookedUntil"
            class="content-small booking-until-date"
          >
            {{ $t("until") }} {{ formatDate(listing.bookedUntil) }}
          </p>
          <date-pick
            :isDateDisabled="isFutureDate"
            @input="changeActiveUntil(listing, $event)"
            v-if="listing.status === 'active' && listing.activeUntil"
          >
            <template v-slot:default="{ toggle }">
              <p
                @click.stop="toggle"
                style="padding-top: 2px; cursor: pointer"
                v-if="listing.status === 'active' && listing.activeUntil"
                class="content-small"
              >
                {{ $t("until") }} {{ listing.activeUntil }}
              </p>
            </template>
          </date-pick>
        </div>
        <v-row
          class="header-first-row"
          style="margin-top: 8px; padding-bottom: 8px"
        >
          <h4>{{ listing.listingName }}</h4>
        </v-row>
        <v-row
          v-if="publicListing && activeNavItem === 'offers'"
          style="display: flex; gap: 8px"
        >
          <div class="content-small" style="min-width: 100px">
            {{ $t("WishToView") }}
          </div>
          <div style="display: flex; gap: 8px">
            <button
              @click="$emit('reject', listing._id)"
              class="transparent-button"
            >
              <div class="content-bold">
                {{ $t("No") }}
              </div>
            </button>
            <button
              @click="$emit('accept', listing._id)"
              class="transparent-button yes-button"
            >
              <div class="content-bold">
                {{ $t("Yes") }}
              </div>
            </button>
          </div>
        </v-row>
        <v-row
          v-if="publicListing && activeNavItem === 'lost'"
          style="display: flex; gap: 16px"
        >
          <div class="content-small" style="min-width: 100px">
            {{ $t("ReviewOfferAgain") }}
          </div>
          <div style="width: 32px">
            <button
              @click="$emit('undoLost', listing._id)"
              class="transparent-button yes-button"
            >
              <img src="../../assets/images/undo.svg" alt="" />
            </button>
          </div>
        </v-row>
        <div
          style="width: inherit"
          v-if="!publicListing && !companyLanderPage && !clientReportPage"
        >
          <v-row
            class="project-info"
            v-if="relatedProject && relatedProject.name"
          >
            <img
              v-if="!listing.isNewDevelopment"
              src="../../assets/images/projects.svg"
              alt=""
            />
            <img
              v-if="listing.isNewDevelopment"
              src="../../assets/images/PropertyDeveloper.svg"
              alt=""
            />
            <a
              :href="
                listing.isNewDevelopment
                  ? path + '/property-development/' + relatedProject._id
                  : path + '/projects/' + listing.linkedDeal + '/general'
              "
              class="button-small hoverable-info"
              style="text-decoration-line: none"
              @keyup.enter="openProject(listing)"
            >
              {{ relatedProject.name }}
            </a>
            <div
              class="open-new-tab-button"
              @click="openProjectNewTab(listing)"
            >
              <img
                style="width: 16px; height: 16px"
                src="../../assets/images/open_new_tab.svg"
                alt=""
              />
              <span>{{ $t("OpenInNewTab") }}</span>
            </div>
            <div
              class="open-new-tab-button"
              @click="openLinkListings"
            >
              <img
                src="../../assets/images/edit.svg"
                alt=""
                style="height: 16px; width: 16px"
              />
              <span>{{ $t("ChangeProject") }}</span>
            </div>
          </v-row>
          <div
            class="project-info"
            v-if="!relatedProject && !listing.isNewDevelopment"
          >
            <img src="../../assets/images/projects.svg" alt="" />
            <p class="button-small">-</p>
          </div>
        </div>
        <div
          v-if="!companyLanderPage && !clientReportPage && !activeNavItem"
          class="broker-info"
        >
          <img src="../../assets/images/users.svg" alt="" />
          <span
            v-for="(creator, userKey) in listing.access.users"
            :key="userKey"
            class="button-small hoverable-info"
            style="margin-left: -2px"
            @click="(event) => openBrokerDetailPanel(event, userKey)"
          >
            {{ creator.name }}
            {{
              creator.ownershipType === "owner"
                ? "(" + $t("Owner").toLowerCase() + ")"
                : ""
            }}
          </span>
          <button
            v-if="hasAccess(listing)"
            class="animated-button"
            @click="openAddUsers()"
          >
            <p style="font-size: 16px; margin-top: -1px">+</p>
            <span>{{ $t("ManageUsers") }}</span>
          </button>
        </div>
      </div>
      <div
        :key="updater"
        class="listing-panel-body"
        id="listing-body"
        v-if="listingLoaded"
      >
        <div
          v-if="
            listing?.meta?.images?.status?.original === 'uploading' ||
            listing?.meta?.images?.status?.original === 'pending'
          "
          class="listing-images-updating"
        >
          <img
            height="20"
            width="20"
            :src="require('@/assets/images/alert.svg')"
            alt="info"
          />
          <p
            v-if="
              listing?.meta?.images?.count - (listing.images?.length || 0) > 0
            "
          >
            {{ listing?.meta?.images?.count - (listing.images?.length || 0) }}
            {{
              $tc(
                "ImagesAreCurrentlyBeingUploaded",
                listing?.meta?.images?.count - (listing.images?.length || 0)
              )
            }}
          </p>
          <p v-else>{{ $t("ImagesAreBeingUpdated") }}</p>
        </div>
        <div class="images-row" v-if="listing.images && listing.images.length">
          <div
            v-for="(image, index) in renderedThumbnails()"
            :key="index"
            class="image-preview"
            id="lightgallery"
            style="display: flex; gap: 8px"
            @click="openImageGallery(index)"
          >
            <img :src="image" alt="image" />
          </div>

          <div class="all-images" @click="openImageGallery(null)">
            <img src="../../assets/images/image.svg" alt="" />
            <p class="content-small top12">All images</p>
            <p class="content-small">({{ countVisibleImages }})</p>
          </div>
        </div>
        <div
          :class="{
            'main-info': 1,
            'main-info-mobile': mobileView,
          }"
        >
          <h5 style="margin-bottom: 24px">{{ $t("Subblock.GeneralData") }}</h5>
          <v-row class="listing-field-row top24">
            <p class="field-name">{{ $t("Created") }}</p>
            <p class="field-value">
              {{ formatCreatedDate(listing.createdAt) }}
            </p>
          </v-row>
          <v-row
            v-if="listing.firstPublishedAt"
            class="listing-field-row top24"
          >
            <p class="field-name">{{ $t("Published") }}</p>
            <p class="field-value">
              {{ formatCreatedDate(listing.firstPublishedAt) }}
            </p>
          </v-row>
          <v-row v-if="listing.updatedAt" class="listing-field-row top24">
            <p class="field-name">{{ $t("Modified") }}</p>
            <p class="field-value">
              {{ formatCreatedDate(listing.updatedAt) }}
            </p>
          </v-row>
          <v-row
            v-if="listing.identifierUpdatedAt"
            class="listing-field-row top24"
          >
            <p class="field-name">
              {{ "ID" + " " + $t("Updated").toLowerCase() }}
            </p>
            <p class="field-value">
              {{ formatCreatedDate(listing.identifierUpdatedAt) }}
            </p>
          </v-row>
          <v-row class="listing-field-row top16">
            <p class="field-name">{{ $t("ListingType") }}</p>
            <p class="field-value">
              {{ $lfMapper.dealType(listing.dealType) }}
            </p>
          </v-row>
          <v-row class="listing-field-row top16">
            <p class="field-name">{{ $t("EstateType") }}</p>
            <p class="field-value">
              {{ $lfMapper.estateType(listing.estateType) }}
            </p>
          </v-row>
          <!--                  { name: this.$t("Office"), value: "office" },-->
          <!--        { name: this.$t("Storage"), value: "storage" },-->
          <!--        { name: this.$t("Building"), value: "building" },-->
          <!--        { name: this.$t("Room"), value: "room" },-->
          <v-row
            v-if="
              listing.houseNumber &&
              ((!companyLanderPage && !publicListing) ||
                ((companyLanderPage || publicListing) &&
                  listing.showHouseNumber))
            "
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("HouseNumber") }}</p>
            <p v-if="!companyLanderPage" class="field-value">
              {{
                $lfMapper.showNumber(
                  listing.showHouseNumber,
                  listing.houseNumber
                )
              }}
            </p>
            <p v-else-if="companyLanderPage && listing.showHouseNumber">
              {{ listing.houseNumber }}
            </p>
          </v-row>
          <v-row
            v-if="
              listing.apartmentNumber &&
              ((!companyLanderPage && !publicListing) ||
                ((companyLanderPage || publicListing) &&
                  listing.showApartmentNumber))
            "
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("ApartmentNumber") }}</p>
            <p v-if="!companyLanderPage" class="field-value">
              {{
                $lfMapper.showNumber(
                  listing.showApartmentNumber,
                  listing.apartmentNumber
                )
              }}
            </p>
            <p v-else-if="companyLanderPage && listing.showApartmentNumber">
              {{ listing.apartmentNumber }}
            </p>
          </v-row>
          <v-row
            v-if="listing.price || listing.price === 0"
            class="listing-field-row top16 price-info-dropdown"
          >
            <p class="field-name">
              {{ $t("Price") }}
            </p>
            <p
              v-bind:class="{
                'price-history-log':
                  listing.priceHistoryLog && listing.priceHistoryLog.length,
              }"
              class="field-value"
              @click="openDropdown"
              style="max-width: max-content"
            >
              {{ listingPrice }}
            </p>
            <div
              v-if="
                listing.priceHistoryLog &&
                listing.priceHistoryLog.length &&
                dropdownIsOpen
              "
              class="price-info-container price-info-container-hover"
            >
              <div v-click-outside="closeDropdown" class="price-history-info-dropdown">
                <div
                  class="price-info"
                  v-for="(info, index) in listing.priceHistoryLog.slice(-20)"
                  :key="'price-info-' + index"
                >
                  <div class="content-normal">
                    {{ formatDate(info.date) }}
                  </div>
                  <div
                    style="width: 100%; text-align: right"
                    class="content-semibold"
                  >
                    {{ $nMapper.price(info.to, "€") }}
                  </div>
                </div>
              </div>
            </div>
          </v-row>
          <v-row
            v-if="listing.priceTransfer || listing.priceTransfer === 0"
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("PriceTransfer") }}</p>
            <p class="field-value">{{ listing.priceTransfer }}</p>
          </v-row>
          <v-row
            v-if="listing.pricePerSquareMeter"
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("Price") }} /m²</p>
            <p class="field-value">{{ listing.pricePerSquareMeter }} €/m²</p>
          </v-row>
          <v-row
            v-if="listing.estateType !== 'land' && listing.generalSurfaceArea"
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("GeneralSurfaceArea") }}</p>
            <p class="field-value">{{ listing.generalSurfaceArea }} m²</p>
          </v-row>
          <v-row v-if="listing.areaBuildable" class="listing-field-row top16">
            <p class="field-name">{{ $t("AreaBuildable") }}</p>
            <p class="field-value">{{ listing.areaBuildable }} m²</p>
          </v-row>
          <v-row
            v-if="
              listing.landArea &&
              listing.estateType === 'land' &&
              (listing.landArea.m2 || listing.landArea.ha)
            "
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("LandArea") }}</p>

            <p
              v-if="listing.landArea.m2 && listing.landArea.m2Selected"
              class="field-value"
            >
              {{ listing.landArea.m2 }} m²
            </p>
            <p
              v-if="listing.landArea.ha && listing.landArea.haSelected"
              class="field-value"
            >
              {{ listing.landArea.ha }} ha
            </p>
          </v-row>
          <v-row class="listing-field-row top16">
            <p class="field-name">{{ $t("AvailableFrom") }}</p>
            <p v-if="listing.availableImmediately" class="field-value">
              {{ $t("Immediately") }}
            </p>
            <p class="field-value" v-else>
              {{ formatDate(listing.dateAvailable) }}
            </p>
          </v-row>
          <v-row
            v-if="listing.expensesInSummer"
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("ExpensesInSummer") }}</p>
            <p class="field-value">{{ listing.expensesInSummer }} €</p>
          </v-row>
          <v-row
            v-if="listing.expensesInWinter"
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("ExpensesInWinter") }}</p>
            <p class="field-value">{{ listing.expensesInWinter }} €</p>
          </v-row>
          <v-row
            v-if="
              listing.floor &&
              !listing.floors &&
              listing.estateType !== 'land' &&
              listing.estateType !== 'house' &&
              listing.estateType !== 'cottage'
            "
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("Floor") }}</p>
            <p class="field-value">
              {{ listing.floor }}
            </p>
          </v-row>
          <v-row
            v-if="
              (!listing.floor &&
                listing.floors &&
                listing.estateType !== 'land') ||
              (!listing.floor &&
                listing.floors &&
                (listing.estateType === 'house' ||
                  listing.estateType === 'house_part' ||
                  listing.estateType === 'cottage'))
            "
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("Floors") }}</p>
            <p class="field-value">
              {{ listing.floors }}
            </p>
          </v-row>
          <v-row
            v-if="
              listing.floor &&
              listing.floors &&
              listing.estateType !== 'land' &&
              listing.estateType !== 'house' &&
              listing.estateType !== 'cottage'
            "
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("Floor") }}</p>
            <p class="field-value">
              {{ $lfMapper.floors(listing.floor, listing.floors) }}
            </p>
          </v-row>
          <v-row
            v-if="listing.buildYear && listing.estateType !== 'land'"
            class="listing-field-row top16"
          >
            <p class="field-name">{{ $t("BuildYear") }}</p>
            <p class="field-value">
              {{ listing.buildYear }}
            </p>
          </v-row>
          <v-row class="listing-field-row top16">
            <p class="field-name">{{ $t("RentToOwn") }}</p>
            <p class="field-value">
              {{ listing.rentToOwn ? $t("Yes") : $t("No") }}
            </p>
          </v-row>
          <v-row class="listing-field-row top16">
            <p class="field-name">{{ $t("Tradable") }}</p>
            <p class="field-value">
              {{ listing.additionalInfo.tradable ? $t("Yes") : $t("No") }}
            </p>
          </v-row>
          <v-row v-if="listing.rooms" class="listing-field-row top16">
            <p class="field-name">{{ $t("RoomCount") }}</p>
            <p class="field-value">{{ listing.rooms }}</p>
          </v-row>
          <v-row v-if="listing.bedrooms" class="listing-field-row top16">
            <p class="field-name">{{ $t("BedroomCount") }}</p>
            <p class="field-value">{{ listing.bedrooms }}</p>
          </v-row>

          <v-row v-if="listing.bathroomCount" class="listing-field-row top16">
            <p class="field-name">{{ $t("BathroomCount") }}</p>
            <p class="field-value">
              {{ listing.bathroomCount }}
            </p>
          </v-row>
          <v-row v-if="listing.roomsSeparate" class="listing-field-row top16">
            <p class="field-name">{{ $t("RoomsSeparate") }}</p>
            <p class="field-value">
              {{ $t("Yes") }}
            </p>
          </v-row>
          <v-row v-if="listing.condition" class="listing-field-row top16">
            <p class="field-name">{{ $t("Condition") }}</p>
            <p class="field-value">
              {{ $lfMapper.condition(listing.condition) }}
            </p>
          </v-row>
          <v-row v-if="listing.ownershipForm" class="listing-field-row top16">
            <p class="field-name">{{ $t("OwnershipType") }}</p>
            <p class="field-value">
              {{ $lfMapper.ownershipForm(listing.ownershipForm) }}
            </p>
          </v-row>

          <v-row v-if="listing.priceCommunity" class="listing-field-row top16">
            <p class="field-name">{{ $t("PriceCommunity") }}</p>
            <p class="field-value">
              {{ listing.priceCommunity + " €" }}
            </p>
          </v-row>

          <v-row
            v-if="
              listing.cadastralNumber &&
              ((!companyLanderPage && !publicListing) ||
                ((companyLanderPage || publicListing) &&
                  listing.showCadastralNumber))
            "
            class="listing-field-row top16"
          >
            <p class="field-name">
              {{ $t("CadastralNumber") }}
            </p>
            <p
              v-if="!companyLanderPage && !publicListing"
              class="field-value"
              @click="openCadastralLink(listing.cadastralNumber)"
              style="cursor: pointer; text-decoration-line: underline"
            >
              {{
                $lfMapper.showNumber(
                  listing.showCadastralNumber,
                  listing.cadastralNumber
                )
              }}
            </p>
            <p
              v-if="
                (companyLanderPage || publicListing) &&
                listing.showCadastralNumber
              "
              class="field-value"
              @click="openCadastralLink(listing.cadastralNumber)"
              style="cursor: pointer; text-decoration-line: underline"
            >
              {{ listing.cadastralNumber }}
            </p>
          </v-row>
          <v-row
            v-if="
              ((!companyLanderPage && !publicListing) ||
                ((companyLanderPage || publicListing) &&
                  listing.showEstateNumber)) &&
              !user.isItaly
            "
            class="listing-field-row top16"
          >
            <p class="field-name">
              {{ $t("EstateNumber") }}
            </p>
            <p
              v-if="!companyLanderPage && !publicListing"
              class="field-value"
              style="cursor: pointer; text-decoration: underline"
              @click="
                openELandRegistryLink(
                  listing.cadastralNumber,
                  listing.estateNumber
                )
              "
            >
              {{
                $lfMapper.showEstateNumber(
                  listing.showEstateNumber,
                  listing.estateNumber
                )
              }}
            </p>
            <p
              v-if="
                (companyLanderPage || publicListing) && listing.showEstateNumber
              "
              class="field-value"
            >
              {{ listing.estateNumber }}
            </p>
          </v-row>

          <v-row
            v-if="
              listing.ehrNumber &&
              ((!companyLanderPage && !publicListing) ||
                companyLanderPage ||
                publicListing) &&
              !user.isItaly
            "
            class="listing-field-row top16"
          >
            <p class="field-name">
              {{ $t("EhrNumber") }}
            </p>
            <p
              v-if="!companyLanderPage && !publicListing"
              class="field-value"
              @click="openEhrNumberLink(listing.ehrNumber)"
              style="cursor: pointer; text-decoration-line: underline"
            >
              {{ listing.ehrNumber }}
            </p>
            <p
              v-if="companyLanderPage || publicListing"
              class="field-value"
              @click="openEhrNumberLink(listing.ehrNumber)"
              style="cursor: pointer; text-decoration-line: underline"
            >
              {{ listing.ehrNumber }}
            </p>
          </v-row>

          <v-row v-if="listing.buildingMaterial" class="listing-field-row top16"
            ><p class="field-name">{{ $t("BuildingMaterial") }}</p>
            <p class="field-value">
              {{ $lfMapper.buildingMaterial(listing.buildingMaterial) }}
            </p></v-row
          >
          <v-row v-if="listing.energyLabel" class="listing-field-row top16"
            ><p class="field-name">{{ $t("EnergyLabel") }}</p>
            <p class="field-value">
              {{ $lfMapper.energyLabel(listing.energyLabel) }}
            </p></v-row
          >
          <!--   <v-row v-if="listing.roofType" class="listing-field-row top16"
            ><p class="field-name">{{ $t("RoofType") }}</p>
            <p class="field-value">
              {{ formatRoofType(listing.roofType) }}
            </p></v-row
          > -->
          <!--  <v-row v-if="listing.numberOfDesks && listing.estateType == 'commercial'" class="listing-field-row top16"
            ><p class="field-name">{{ $t("NumberOfDesks") }}</p>
            <p class="field-value">
              {{ listing.numberOfDesks }}
            </p></v-row
          > -->
          <v-row v-if="listing.readiness" class="listing-field-row top16"
            ><p class="field-name">{{ $t("Readiness") }}</p>
            <p class="field-value">
              {{ $lfMapper.readiness(listing.readiness) }}
            </p></v-row
          >
          <v-row v-if="listing.limitations" class="listing-field-row top16"
            ><p class="field-name">{{ $t("Limitations") }}</p>
            <p class="field-value">
              {{ $lfMapper.limitations(listing.limitations) }}
            </p></v-row
          >
          <v-row
            v-if="
              listing.estateType == 'commercial' &&
              (listing.office ||
                listing.tradeshop ||
                listing.accomodation ||
                listing.services ||
                listing.warehouse ||
                listing.production ||
                listing.foodServices ||
                listing.notDefined ||
                listing.warehouseProduction ||
                listing.otherUsePossible)
            "
            class="listing-field-row top16"
            style="gap: 0px"
            ><p class="field-name">{{ $t("Purpose") }}</p>
            <div style="padding-left: 24px">
              <p v-if="listing.office" class="content-normal">
                {{ $t("Office") }}
              </p>
              <p v-if="listing.tradeshop" class="content-normal">
                {{ $t("TradeShop") }}
              </p>
              <p v-if="listing.accomodation" class="content-normal">
                {{ $t("Accomodation") }}
              </p>
              <p v-if="listing.services" class="content-normal">
                {{ $t("Services") }}
              </p>
              <p v-if="listing.warehouse" class="content-normal">
                {{ $t("WareHouse") }}
              </p>
              <p v-if="listing.production" class="content-normal">
                {{ $t("Production") }}
              </p>
              <p v-if="listing.foodServices" class="content-normal">
                {{ $t("FoodServices") }}
              </p>
              <p v-if="listing.notDefined" class="content-normal">
                {{ $t("NotDefined") }}
              </p>
              <p v-if="listing.warehouseProduction" class="content-normal">
                {{ $t("WareHouseProduction") }}
              </p>
              <p v-if="listing.otherUsePossible" class="content-normal">
                {{ $t("OtherUsePossible") }}
              </p>
            </div>
          </v-row>
          <v-row
            v-if="
              listing.estateType == 'land' &&
              (listing.commercialLand ||
                listing.industrialLand ||
                listing.productionLand ||
                listing.forestLand ||
                listing.residentialLand ||
                listing.profityieldingLand ||
                listing.residentialLandApartment ||
                listing.otherUse)
            "
            class="listing-field-row top16"
            style="gap: 0px"
            ><p class="field-name">{{ $t("Purpose") }}</p>
            <div style="padding-left: 24px">
              <p v-if="listing.commercialLand" class="content-normal">
                {{ $t("CommercialLand") }}
              </p>
              <p v-if="listing.industrialLand" class="content-normal">
                {{ $t("IndustrialLand") }}
              </p>
              <p v-if="listing.productionLand" class="content-normal">
                {{ $t("ProductionLand") }}
              </p>
              <p v-if="listing.forestLand" class="content-normal">
                {{ $t("ForestLand") }}
              </p>
              <p v-if="listing.residentialLand" class="content-normal">
                {{ $t("ResidentialLand") }}
              </p>
              <p v-if="listing.profityieldingLand" class="content-normal">
                {{ $t("ProfitYieldingLand") }}
              </p>
              <p v-if="listing.residentialLandApartment" class="content-normal">
                {{ $t("ResidentialLandApartments") }}
              </p>
              <p v-if="listing.otherUse" class="content-normal">
                {{ $t("OtherUse") }}
              </p>
            </div>
          </v-row>
          <!--   <v-row v-if="listing.parkingOption" class="listing-field-row top16"
            ><p class="field-name">{{ $t("ParkingOption") }}</p>
            <p class="field-value">
              {{ formatParkingOption(listing.parkingOption) }}
            </p></v-row
          > -->
        </div>
        <ExtraInfo :listing="listing" />
        <DescriptionArea :listing="listing" />
        <div>
          <v-row v-if="listing.videoLink" style="margin-top: 32px">
            <h6>{{ $t("VideoLink") }}</h6>
          </v-row>
          <a
            v-if="listing.videoLink"
            @click="openVideoLink(listing.videoLink)"
            class="field-value"
            href="#"
            style="cursor: pointer"
          >
            {{ listing.videoLink }}
          </a>
          <v-row v-if="listing.virtualTourLink" style="margin-top: 24px">
            <h6>{{ $t("VirtualTourLink") }}</h6>
          </v-row>
          <a
            @click="openVideoLink(listing.virtualTourLink)"
            class="field-value"
            href="#"
            style="cursor: pointer"
          >
            {{ listing.virtualTourLink }}
          </a>
        </div>
        <div id="map" style="padding-top: 32px">
          <GmapMap
            :center="mapCoordinates"
            :zoom="18"
            map-type-id="terrain"
            style="width: 100%; height: 300px"
          >
            <GmapMarker
              id="marker"
              :position="markerCoordinates"
              :clickable="true"
              :draggable="true"
            />
          </GmapMap>
        </div>

        <button v-if="mobileView" class="back-button" @click="closePanel">
          <img
            class="img16"
            src="../../assets/images/chevron-left.svg"
            alt=""
            style="filter: invert(1)"
          />
          <div class="content-bold">
            {{ $t("Back") }}
          </div>
        </button>
      </div>
    </div>
    <ConfirmationModal
      v-if="confirmModalOpen"
      @onConfirm="createNewId"
      @onCancel="() => (confirmModalOpen = false)"
    >
      <div class="text-center">
        {{ $t("CreateNewId") }}
      </div>
    </ConfirmationModal>
    <StatusDropdown
      @closed="statusesOpen = false"
      @statusChanged="changeListingStatus"
      @open-booking-modal="openBookingDetailsModal"
      v-if="statusesOpen"
      :position="position"
      :listing="listing"
    />
    <AddBrokerModal
      v-if="isAddBrokerOpen"
      :item.sync="listing"
      @close-modal="isAddBrokerOpen = false"
      @updated="setupListing()"
      :listing-modal="true"
      :key="updater"
      @addSharedOwner="handleAddSharedOwner"
      @transferListingOwnership="handleTransferListing"
      @removeSharedOwner="handleSharedBrokerRemoved"
    />
    <ConfirmationModal
      v-if="transferConfirmationOpen"
      @onCancel="handleConfirmationCancel"
      @onConfirm="transferListingOwnerhsip()"
    >
      <div class="confirmation-wrapper">
        <div class="confirmation-header">
          <img
            style="margin-right: 8px"
            :src="require(`@/assets/images/info.svg`)"
            alt=""
          />
          <div>
            {{ $t("TransferingOwner") }}
          </div>
        </div>
        <div class="new-shared-broker">
          {{ transferedSharedBroker }}
        </div>
        <div>
          <div>
            <BaseToggle
              :label="$t('ChangeOwnerOfListingsProject')"
              v-model="transferProject"
              type="number"
            />
          </div>
        </div>
      </div>
    </ConfirmationModal>
    <ConfirmationModal
      v-if="addSharedOwnerConfirmationOpen"
      @onCancel="addSharedOwnerConfirmationOpen = false"
      @onConfirm="confirmAddSharedOwner"
    >
      <div class="confirmation-wrapper">
        <div class="confirmation-header">
          <img
            style="margin-right: 8px"
            :src="require(`@/assets/images/info.svg`)"
            alt=""
          />
          <div class="add-shared-owner">
            {{ $t("AddSharedOwner") }}
          </div>
        </div>
        <div class="new-shared-broker">
          {{ newSharedBroker.name  }}
        </div>
        <div>
          <div>
            <BaseToggle
              :label="$t('ChangeOwnerOfListingsProject')"
              v-model="transferProject"
              type="number"
            />
          </div>
        </div>
      </div>
    </ConfirmationModal>
    <ConfirmationModal
      v-if="removeSharedOwnerConfirmationOpen"
      @onCancel="removeSharedOwnerConfirmationOpen = false"
      @onConfirm="confirmRemoveSharedOwner"
    >
      <div class="confirmation-wrapper">
        <div class="confirmation-header">
          <img
            style="margin-right: 8px"
            :src="require(`@/assets/images/info.svg`)"
            alt=""
          />
          <div class="add-shared-owner">
            {{ $t("RemoveSharedOwner") }}
          </div>
        </div>
        <div class="new-shared-broker">
          {{ removedSharedBroker }}
        </div>
        <div>
          <div>
            <BaseToggle
              :label="$t('ChangeOwnerOfListingsProject')"
              v-model="transferProject"
              type="number"
            />
          </div>
        </div>
      </div>
    </ConfirmationModal>
    <LinkListingModal
      v-if="isLinkListing"
      :item.sync="listing"
      :linked-entity="relatedProject"
      @close-modal="isLinkListing = false"
      @new-link="handleNewLink"
      :key="updater"
      :disableClickOutside="linkConfirmation"
    />
    <ConfirmationModal
      v-if="linkConfirmation"
      @click.stop
      @onConfirm="handleLinkConfirm(selectedItem)"
      @onCancel="() => (linkConfirmation = false)"
    >
      <div style="display: flex; flex-direction: column; width: 100%;">
        <div class="text">{{ $t("LinkWithProject") }}</div>
        <h5 class="title-text" style="display: flex; width: 100%; padding-top: 10px;">
          {{ selectedItem.building ? selectedItem.building : selectedItem.name }}
        </h5>
      </div>
    </ConfirmationModal>
    <BrokerInfoPanel
      v-if="brokerDetailOpened"
      :item="clickedBroker"
      @closed="closeBrokerDetailPanel"
      :position="brokerPanelPosition"
    />
    <BookingDetailsModal
      v-if="bookingDetailsModalOpen"
      :opened-listing="bookedListing"
      @update-listing="changeListingStatus"
      @close-modal="closeBookingDetailsModal"
    >
    </BookingDetailsModal>
    <OutputsModal
      :toggle-modal="toggleOutputsModal"
      :listing="listing"
      :position="modalPosition"
      @updateStatuses="updateFromModal"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import ExtraInfo from "@/components/Listings/ExtraInfo";
import IconDropdown from "@/components/common/IconDropdown";
import DescriptionArea from "@/components/Listings/DescriptionArea";
import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.css";
import StatusDropdown from "@/components/Listings/StatusDropdown";
import nanoid from "nanoid";
import OpenHouseContainer from "@/components/common/OpenHouseContainer";
import moment from "moment";
import DatePick from "vue-date-pick";
import listingApi from "@/http/listing";
import AddBrokerModal from "@/components/common/AddBrokerModal";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel";
import BookingDetailsModal from "./BookingDetailsModal";
import ConfirmationModal from "@/components/common/ConfirmationModal";
import BaseToggle from "@/components/common/BaseToggle";
import OutputsModal from "@/components/Listings/OutputsModal2.vue";
// import offersSidepanel from "./OffersSidepanel";
import LinkListingModal from "@/components/Listings/LinkListingModal.vue";
import Outputs from "@/components/Listings/Outputs/Outputs";

export default {
  name: "ListingSidepanel",
  props: [
    "previousListingPage",
    "dontNavToPreviousRoute",
    "clientReportPage",
    "companyLanderPage",
    "listingPreviewId",
    "listingId",
    "publicListing",
    "onlyPanel",
    "mobileView",
    "activeNavItem",
    "contactView",
    "hiddenMarketplaces",
    "secondSidepanel",
    "selectedView",
    "projectView",
    "development",
  ],
  components: {
    Outputs,
    ConfirmationModal,
    BookingDetailsModal,
    AddBrokerModal,
    ExtraInfo,
    DescriptionArea,
    StatusDropdown,
    IconDropdown,
    OpenHouseContainer,
    DatePick,
    BrokerInfoPanel,
    BaseToggle,
    OutputsModal,
    LinkListingModal
  },
  data() {
    return {
      linkConfirmation: false,
      modalPosition: null,
      cadastralLink: "https://xgis.maaamet.ee/ky/",
      ehrLink: "https://livekluster.ehr.ee/ui/ehr/v1/building/",
      toggleOutputsModal: false,
      openHouse: null,
      closeOnlyTopModal: false,
      statusesOpen: false,
      isAddBrokerOpen: false,
      isLinkListing: false,
      brokerDetailOpened: false,
      confirmModalOpen: false,
      bookingUpdater: 0,
      bookingDetailsModalOpen: false,
      bookedListing: null,
      clickedBroker: null,
      position: {},
      brokerPanelPosition: null,
      updater: 0,
      listing: null,
      dropdownIsOpen: false,
      listingLoaded: false,
      userInSharedCreators: false,
      dynamicEl: [],
      imageGallery: null,
      outputsModal: false,
      relatedProject: null,
      path: null,
      editPath: null,
      selectedOutputs: [],
      brokerAccountsCity24: [],
      brokerAccountsKv24: [],
      brokerAccountsKv: [],
      account_kv24: "kati@maakler.ee (4/20)",
      account_city24: "mati@maakler.ee (1/30)",
      account_kv_ee: "timo@maakler.ee (8/20)",
      additional_activities: [
        {
          name: this.$t("ShowInListingsTable"),
          value: "highlight_listing",
          _id: nanoid(8),
        },
      ],
      style: {
        background: "#0000001a",
      },
      //
      selectedItem: null,
      addSharedOwnerConfirmationOpen: false,
      removeSharedOwnerConfirmationOpen: false,
      newSharedBroker: null,
      removedSharedId: null,
      removedSharedBroker: null,
      sharedOwnerPayload: null,
      transferConfirmationOpen: false,
      transferData: null,
      transferProject: true,
      transferedSharedBroker: null,
    };
  },
  async created() {
    await this.setupListing();
    console.log(this.relatedProject);
    console.log(this.listing);
  },
  mounted() {
    // const fromListingAdd = this.$route.params.fromListingsAdd
    this.hideOverlay();
    if (this.secondSidepanel) {
      this.style.background = "";
    }
  },
  beforeDestroy() {
    if (this.onlyPanel) {
      return;
    }
    if (this.contactView) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "visible";
    }
  },
  computed: {
    ...mapGetters(["user", "users", "listingsUpdater"]),
    mapCoordinates: function () {
      return this.listing.coordinates;
    },
    markerCoordinates: function () {
      return this.listing.coordinates;
    },
    countVisibleImages: function () {
      return this.renderedImages.length;
    },
    renderedImages: function () {

      // .filter((imag) => imag.name.includes('runproperty_listing_images'))
      if (
        (!this.companyLanderPage && !this.publicListing) ||
        !this.listing.isWatermarkApplied ||
        !this.listing.images[0]?.watermarkName
      ) {
        return this.listing.images
          .filter((images) => !images.hidden && (images.name?.includes('runproperty_listing_images') || 
       images.name?.includes('runproperty-listing-images') || images.name?.includes('runproperty-property-developer-images')))
          .map((image) => image.thumbnail || image.name);
      } else {
        return this.listing.images
          .filter((images) => !images.hidden && images.watermarkName && (images.name?.includes('runproperty_listing_images') || 
       images.name?.includes('runproperty-listing-images') || images.name?.includes('runproperty-property-developer-images')))
          .map((image) => image.watermarkName);
      }
    },
    renderedGalleryImages: function () {
      if (
        (!this.companyLanderPage && !this.publicListing) ||
        !this.listing.isWatermarkApplied ||
        !this.listing.images[0]?.watermarkName
      ) {
        return this.listing.images
          .filter((images) => !images.hidden)
          .map((image) => image.name);
      } else {
        return this.listing.images
          .filter((images) => !images.hidden && images.watermarkName)
          .map((image) => image.watermarkName);
      }
    },
    listingPrice: function () {
      return this.$nMapper.price(this.listing.price, "€");
    },
  },
  methods: {
    ...mapActions(["setListingSidepanel","projectOwnershipTransferred"]),
    async transferListingOwnerhsip() {
      let body = this.transferData;
      body.transferProject = this.transferProject;
      try {
        const response = await axios.post(
          "/api/listing/ownership/change",
          body
        );
        if (response.data && response.data.listingAccess) {
          this.listing.access.users = response.data.listingAccess.users;
          this.transferData = null;
          this.transferConfirmationOpen = false;
          this.isAddBrokerOpen = true;
          if (response.data.developmentAccess || response.data.dealAccess) {
            this.projectOwnershipTransferred(response.data.developmentAccess || response.data.dealAccess);
          }
          this.toastSuccess(this.$t("OwnershipTransfered"));
        } else {
          this.transferConfirmationOpen = false;
          this.toastError(this.$t("ErrorTransferingOwnership"));
        }
      } catch (error) {
        console.error(
          "Failed to transfer development project ownership:",
          error
        );
      }
    },
    async confirmAddSharedOwner() {
      if (this.sharedOwnerPayload) {
        this.sharedOwnerPayload = {
          ...this.sharedOwnerPayload,
          transferProject: this.transferProject,
        };
        const response = await axios.post(
          "/api/listing/add-shared-creator",
          this.sharedOwnerPayload
        );
        if (response.data && response.data.listingAccess) {
          this.sharedOwnerPayload = null;
          this.addSharedOwnerConfirmationOpen = false;
          this.listing.access.users = response.data.listingAccess.users;
          if (response.data.developmentAccess || response.data.dealAccess) {
            this.projectOwnershipTransferred(response.data.developmentAccess || response.data.dealAccess);
          }
          this.toastSuccess(this.$t("SharedOwnerAdded"));
          this.isAddBrokerOpen = true;
        }
      }
    },
    handleAddSharedOwner(payload) {
      this.sharedOwnerPayload = payload;
      this.newSharedBroker = payload.sharedCreators;
      this.addSharedOwnerConfirmationOpen = true;
      this.isAddBrokerOpen = false;
    },
    handleTransferListing(event) {
      this.isAddBrokerOpen = false;
      this.transferData = event;
      this.transferedSharedBroker = event.name;
      this.transferConfirmationOpen = true;
    },
    async confirmRemoveSharedOwner() {
      if (this.sharedOwnerPayload) {
        this.sharedOwnerPayload = {
          ...this.sharedOwnerPayload,
          transferProject: this.transferProject,
        };
        const response = await axios.post(
          "/api/listing/remove-shared-creator",
          this.sharedOwnerPayload
        );
        if (response.data && response.data.listingAccess) {
          this.sharedOwnerPayload = null;
          this.removeSharedOwnerConfirmationOpen = false;
          this.listing.access.users = response.data.listingAccess.users;
          if (response.data.developmentAccess || response.data.dealAccess) {
            this.projectOwnershipTransferred(response.data.developmentAccess || response.data.dealAccess);
          }
          this.toastSuccess(this.$t("SharedOwnerRemoved"));
          this.isAddBrokerOpen = true;
        }
      }
    },
    handleSharedBrokerRemoved(payload) {
      this.sharedOwnerPayload = payload;
      this.removedSharedBroker = payload.name;
      this.removedSharedId = payload.userId;
      this.removeSharedOwnerConfirmationOpen = true;
      this.isAddBrokerOpen = false;
    },
    handleConfirmationCancel() {
      this.transferConfirmationOpen = false;
      this.addSharedOwnerConfirmationOpen = false;
    },
    openOutputsModal(position) {
      this.modalPosition = position;
      this.$nextTick(
          () => (this.toggleOutputsModal = !this.toggleOutputsModal)
      );
    },
    handleNewLink(item) {
      this.selectedItem = item
      this.linkConfirmation = true;
    },
    handleLinkConfirm(link) {
      this.selectedItem = link;
      if (link.module == "development") {
        this.relatedProject._id = link.linkedId
        this.relatedProject.name = link.building
        let body = {
          linkedId: link.linkedId,
          listingId: link.listingId,
          module: link.module,
          buildingId: link.buildingId
        };
        this.updateLinkedDeal(body);
        this.listing.isNewDevelopment = true
        this.linkConfirmation = false;
        this.isLinkListing = false;
      } else {
        this.relatedProject.name = link.name
        let body = {
          linkedId: link._id,
          listingId: this.listing._id,
          module: 'project',
        };
        this.updateLinkedDeal(body);
        this.listing.isNewDevelopment = false;
        this.linkConfirmation = false;
        this.isLinkListing = false;
        this.listing.linkedDeal = link._id;
        return;
      }
    },
    async updateLinkedDeal(body) {
      try {
        await axios.patch("/api/listing/update/linked-deal", body);
        this.toastSuccess(this.$t("ListingLinked"));
      } catch (error) {
        console.error("Error updating linked deal:", error);
      }
    },
    async setupListing() {
      if (this.companyLanderPage || !this.user || this.publicListing) {
        await this.getListingPublic(this.listingId);
      } else {
        this.brokerAccountsCity24 = this.user.listingAccounts.filter(
          (acc) => acc.platform == "city24"
        );
        this.brokerAccountsKv = this.user.listingAccounts.filter(
          (acc) => acc.platform == "kv"
        );
        this.brokerAccountsKv24 = this.user.listingAccounts.filter(
          (acc) => acc.platform == "kv24"
        );
        if (this.listingPreviewId) {
          await this.getListing(this.listingPreviewId);
        } else if (this.listingId) {
          await this.getListing(this.listingId);
        } else if (this.$route.params.listingId) {
          await this.getListing(this.$route.params.listingId);
        }
        await this.getFirstOpenHouse(this.listing.linkedDeal);
      }
      this.removeOverlappingOverlay();
      this.editPath =
        window.location.origin + "/listings/change/" + this.listing._id;
      this.path = window.location.origin;
    },
    async toggleListingPublic(val, listingId) {
      if (!this.hasAccess(this.listing)) return;
      await axios.post(
        `/api/development/${this.listing.linkedDeal}/unit/listing/${listingId}/toggle-public`,
        { isPublic: val }
      );
      this.$emit("toggled", this.listing);
      await this.getListing(listingId);
    },
    openCadastralLink(cadastralLink) {
      window.open(this.cadastralLink + cadastralLink, "_blank");
    },
    openBrokerDetailPanel(event, userId) {
      let position = {
        left: null,
        top: null,
      };
      position.left = event.clientX + "px";
      position.top = event.clientY + "px";
      this.brokerPanelPosition = position;
      this.brokerDetailOpened = true;
      this.clickedBroker = userId;
    },
    openDropdown() {
      if (!this.dropdownOpen) {
        this.dropdownIsOpen = true;
      }
    },
    closeDropdown() {
      this.dropdownIsOpen = false;
    },
    openBookingDetailsModal(listing) {
      if (!this.hasAccess(listing)) return;
      this.bookedListing = listing;
      this.bookingDetailsModalOpen = true;
    },
    openLinkListings() {
      this.isLinkListing = true;
    },
    editListingMode(event) {
      event.preventDefault();
      if (this.hasAccess(this.listing)) {
        this.$router.push({
          name: "ListingsChange",
          params: {
            id: this.listing._id,
            previousListingPage: this.previousListingPage,
          },
        });
      } else {
        document.getElementById("link-button").href = this.listing._id;
        this.toastError(this.$t("noListingEditAccess"));
      }
    },
    closeBookingDetailsModal(event) {
      this.bookingDetailsModalOpen = false;
      if (event && event === "cancel") {
        this.closeOnlyTopModal = true;
      } else this.closeOnlyTopModal = false;
    },
    closeBrokerDetailPanel() {
      this.brokerDetailOpened = false;
    },
    openEhrNumberLink(ehrNumber) {
      if (ehrNumber) {
        window.open(this.ehrLink + ehrNumber, "_blank");
      } else {
        window.open(
          `https://livekluster.ehr.ee/ui/ehr/v1/detailsearch/BUILDINGS_SEARCH`,
          "_blank"
        );
      }
    },
    openELandRegistryLink(cadastralNumber, estateNumber) {
      if (!estateNumber && cadastralNumber) {
        window.open(
          `https://kinnistusraamat.rik.ee/PealeheOtsinguTulemus.aspx?lang=Est&kadasterAadress=&nimi=&regNrIsikuKood=${cadastralNumber}`,
          "_blank"
        );
      } else if (estateNumber) {
        window.open(
          `https://kinnistusraamat.rik.ee/PealeheOtsinguTulemus.aspx?kadasterAadress=&nimi=&regNrIsikuKood=${estateNumber}`,
          "_blank"
        );
      } else if (!estateNumber && !cadastralNumber) {
        window.open("https://kinnistusraamat.rik.ee/Avaleht.aspx", "_blank");
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(
        `${window.location.origin}/listings/${this.listing._id}`
      );
      this.toastSuccess(this.$t("LinkCopied"));
    },
    openAddUsers() {
      this.isAddBrokerOpen = true;
    },
    renderedThumbnails() {
      return this.renderedImages.slice(0, 4);
    },
    removeOverlappingOverlay() {
      if (document.getElementById("offers-sidepanel-overlay")) {
        document.getElementById("listing-overlay").style.position = "initial";
      }
    },
    // countVisibleImages() {
    //   let count = 0;
    //   this.listing.images.forEach((image) => {
    //     if (!image.hidden) {
    //       count++;
    //     }
    //   });
    //   return count;
    // },
    hideOverlay() {
      document.onkeydown = (evt) => {
        evt = evt || window.event;
        if (evt.key === "Escape") {
          this.closePanel();
          document.onkeydown = null;
        }
      };
      if (!this.projectView) {
        document.documentElement.style.overflow = "hidden";
      }
    },

    isFutureDate(date) {
      const currentDate = new Date();
      return date <= currentDate;
    },
    formatCreatedDate(created) {
      return moment(created).format("DD.MM.YYYY");
    },
    async changeActiveUntil(changedListing, newDate) {
      if (!this.hasAccess(changedListing)) return;
      newDate = this.formatDate(newDate);
      this.listing.activeUntil = newDate;

      let body = {
        listing: changedListing,
        newDate: newDate,
      };
      await listingApi.updateListingActiveUntil(body);
      let statusChangeObject = {
        listingId: this.listing._id,
        status: "active",
        bookedUntil: null,
        statusChangedDate: this.listing?.statusChangedDate,
        activeUntil: newDate,
      };
      this.$emit("statusChanged", statusChangeObject);
    },
    async getFirstOpenHouse(chosenObjectId) {
      const response = await axios.get(
        `/api/task/project/open-house/${chosenObjectId}`
      );
      this.openHouse = response.data;
    },
    changeListingStatus(event) {
      if (event.status === "booked") {
        this.bookingUpdater++;
      }
      this.$emit("statusChanged", event);
      this.listing.status = event.status;
      this.listing.bookedUntil = event.bookedUntil;
      if (event.activeUntil) {
        this.listing.activeUntil = event.activeUntil;
      }
      this.statusesOpen = false;
    },
    openStatusDropdown() {
      if (!this.hasAccess(this.listing)) return;
      if (
        this.listing.status === "archived" &&
        this.relatedProject &&
        (this.relatedProject.pipelineStep === "archived" ||
          this.relatedProject.pipelineStep === "Done")
      )
        return;
      let button = document.getElementById("statusLabel" + this.listing._id);
      const rect = button.getBoundingClientRect();
      this.position.right = window.innerWidth - rect.right - 150;
      this.position.top = rect.top;
      this.statusesOpen = true;
    },
    openVideoLink(e) {
      window.open(e, "_blank");
    },
    openImageGallery(index) {
      let dynamicEl = this.renderedGalleryImages.map((image) =>
        Object.assign({ src: image })
      );

      let imageGallery = null;
      const imageWrapper = document.getElementById("lightgallery");
      if (imageWrapper) {
        imageGallery = window.lightGallery(imageWrapper, {
          dynamic: true,
          dynamicEl,
        });
      }
      console.log(imageGallery, index);
      /*if (index) {
        imageGallery.openGallery(index);
      } else {
        imageGallery.openGallery(0);
      }*/
    },
    openProject(listing) {
      if (!listing.isNewDevelopment) {
        this.$router
          .push({
            name: "DealDetail",
            params: { id: listing.linkedDeal },
          })
          .then(() => {});
      } else {
        this.$router.push({
          name: "DevelopmentDetail",
          params: { id: this.relatedProject._id },
        });
      }
    },
    openProjectNewTab(listing) {
      if (!listing.isNewDevelopment) {
        const routeData = this.$router.resolve({
          name: "DealDetail",
          params: { id: listing.linkedDeal },
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "DevelopmentDetail",
          params: { id: this.relatedProject._id },
        });
        window.open(routeData.href, "_blank");
      }
    },
    closePanel: function (e) {
      this.$emit("closeListingPanel", e);
      if (!e || e.target !== e.currentTarget) return;
      if (e.target.parentElement.classList.toString().includes("close-button"))
        return;
      if (e.target.classList.toString().includes("lg-")) return;
      if (this.bookingDetailsModalOpen) return;
      if (this.closeOnlyTopModal) {
        this.closeOnlyTopModal = false;
        return;
      }
      if (this.isAddBrokerOpen) return;
      if (this.statusesOpen) return;
      if (this.listingId) {
        this.$emit("overlayClicked");
        this.setListingSidepanel(0);
      } else if (
        this.listingPreviewId ||
        this.contactView ||
        this.projectView
      ) {
        this.setListingSidepanel(0);
      } else {
        const paths = e.path || (e.composedPath && e.composedPath());
        let close = true;
        paths.forEach((path) => {
          if (path.id === "outputs-modal" || path.id === "broker-info") {
            close = false;
          }
        });
        if (close) {
          this.setListingSidepanel(0);
          const referrer = document.referrer;
          const expectedReferrer =
            window.location.origin + "/listings/listing/" + this.listing._id;
          if (referrer === expectedReferrer) {
            this.$router.push({
              name: "Listings",
              query: {
                page: this.$route.params.page,
                listingStatus: this.selectedView,
              },
            });
          } else if (this.$route.params.fromListingsTable) {
            this.$router.push({
              name: "Listings",
              query: {
                page: this.$route.params.page,
                listingStatus: this.selectedView,
              },
            });
          } else if (this.dontNavToPreviousRoute) {
            this.$router.push({
              name: "Listings",
              query: {
                page: this.previousListingPage,
                previousListingPage: this.previousListingPage,
              },
            });
            this.$route.query.notificationId = null;
          } else {
            this.$router.go(-1);
            this.$route.query.notificationId = null;
          }
        }
      }
    },
    async getListingPublic(listingId) {
      const response = await axios.get(`/api/listing/public/${listingId}`);
      this.listing = response.data;
      if (this.listing.corporateImageEnabled && this.listing.corporateImage) {
        this.listing.images.splice(2, 0, {
          name: this.listing.corporateImage,
          hidden: false,
          watermarkName: this.listing.corporateImage,
        });
      }
      if (
        this.listing.brokerLstImage &&
        this.listing.brokerLstImage.length > 0
      ) {
        this.listing.images.push({
          name: this.listing.brokerLstImage[0].name,
          hidden: false,
          watermarkName: this.listing.brokerLstImage[0].name,
        });
      }
    },
    async getListing(listingId) {
      const response = await axios.get(`/api/listing/${listingId}`, {
        params: {
          _: new Date().getTime(),
        },
      });
      this.listing = response.data[0];
      if (this.hasAccess(this.listing)) {
        this.additional_activities.push({
          name: this.$t("CreateNewId"),
          value: "create_new_id",
          _id: nanoid(8),
        });
      }
      if ((!this.user.config || (this.user.config && this.user.access?.features?.modules?.projects?.enabled)) && this.listing.linkedDeal) {
        let chosenObject = this.listing.linkedDeal;
        let resp;
        if (!this.listing.isNewDevelopment) {
          resp = await axios.get(`/api/project/name/${chosenObject}`);
        } else if (this.listing.isNewDevelopment) {
          resp = await axios.get(`/api/unit/name/${chosenObject}`);
        }

        if (resp && resp.status === 200) {
          this.relatedProject = resp.data;
        } else {
          this.relatedProject = null;
        }
      }
      this.updater++;
    },
    formatDate(item) {
      if (item) {
        return moment(item).format("DD.MM.YYYY");
      } else {
        return "-";
      }
    },
    updateFromModal: function (statusValues) {
      this.$emit("updateStatuses", statusValues);
    },
    async goToPrintPreview() {
      let routeData = this.$router.resolve({
        name: "ListingsFullDetail",
        params: { id: this.listing._id },
      });
      await window.open(routeData.href, "_blank");
    },
    async selectActivity(activity) {
      if (activity.value === "highlight_listing") {
        document.documentElement.style.overflow = "visible";

        let page = await listingApi.getListingsPage(this.listing._id);
        let listingStatus = "listings";
        if (page.status === "not found") return;
        if (page.listingStatus === "archived") {
          listingStatus = "archives";
        }
        await this.setListingSidepanel(0);
        await this.$router
          .push({
            name: "Listings",
            query: {
              notificationId: 0,
              page: page.page,
              listingStatus: listingStatus,
            },
          })
          .catch(() => {});

        await this.$router
          .push({
            name: "Listings",
            query: {
              notificationId: this.listing._id,
              page: page.page,
              listingStatus: listingStatus,
            },
          })
          .catch(() => {});
      } else if (activity.value === "create_new_id") {
        this.confirmModalOpen = true;
      }
    },
    async createNewId() {
      if (!this.hasAccess(this.listing)) return;
      this.confirmModalOpen = false;
      try {
        await listingApi.updateListingIdentifier(this.listing._id);
        this.toastSuccess(this.$t("NewIdCreated"));
      } catch (e) {
        this.toastError(this.$t("Error"));
      }
    },
  },
  watch: {
    listingsUpdater: function () {
      this.setupListing();
    },
    listingPreviewId: function () {
      this.getListing(this.listingPreviewId);
    },

    listing: function () {
      this.listing.marketplaces.forEach((e) => {
        if (e.isActive) {
          this.selectedOutputs.push(e.name);
        }
        if (e.name == "kv" && e.activeUser) {
          this.account_kv_ee = e.activeUser;
        } else if (e.name == "kv24" && e.activeUser) {
          this.account_kv24 = e.activeUser;
        } else if (e.name == "city24" && e.activeUser) {
          this.account_city24 = e.activeUser;
        }
      });
      this.listingLoaded = true;
      this.updater++;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.share-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}

.share-button {
  margin-right: 4px;
}

.dates-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 8px;
}

.back-button {
  position: fixed;
  bottom: 55px;
  background: black;
  color: white;
  width: 316px !important;
  display: inline-flex;
  text-transform: none;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 8px;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

#listing-overlay {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
}

.listing-overlay-small {
  width: 640px;
  left: unset;
}

.listing-overlay-small-border {
  width: 641px;
  left: unset;
}

.listing-overlay-full {
  width: unset;
  left: 0;
}

.listing-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 9;
  width: 640px;
  overflow-y: auto;
  overflow-x: hidden;
}

.listing-panel-header {
  display: flex;
  background: white;
  flex-direction: column;
  padding: 24px 32px 24px;
  width: inherit;
  border-top: 1px solid #e6e8ec;
  border-bottom: 1px solid #e6e8ec;

  h4 {
    max-width: 392px;
  }
}

.dot-menu-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 32px;

  box-sizing: border-box;
  border-radius: 8px;
  background-color: #e7e7e7;

  &:hover {
    cursor: pointer;
  }
}

.header-first-row {
  justify-content: space-between;
}

.project-info {
  flex-direction: row;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  img {
    width: 20px;
    height: 20px;
  }
}

.open-new-tab-button {
  min-width: 20px;
  height: 20px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid #e6e8ec;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px solid #000000;
    background: #000000;

    img {
      filter: invert(100%) sepia(22%) saturate(0%) hue-rotate(78deg)
        brightness(109%) contrast(101%);
    }

    span {
      padding: 2px;
      max-width: 7rem;
    }
  }
}

.open-new-tab-button span {
  max-width: 0;
  -webkit-transition: max-width 0.1s;
  transition: max-width 0.1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
  font-size: 12px;
}

.broker-info {
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.tags {
  .tag {
    padding: 4px 8px;
    margin-right: 4px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
  }

  p {
    margin-bottom: 0 !important;
    font-size: 12px;
    line-height: 16px;
  }

  .active {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-image: url("../../assets/images/green-circle.svg");
  }

  .tag-wrapper {
    align-items: center;
  }

  .in-active {
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-image: url("../../assets/images/red-circle.svg");
  }

  .gray {
    background: #f4f5f7;
  }
}

.listing-panel-body {
  padding: 32px 32px 64px;
}

.images-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
  overflow: hidden;
}

.image-preview {
  width: 108px;
  min-width: 108px;
  height: 108px;
  background: #f4f5f7;
  display: flex;
  border-radius: 12px;
  cursor: pointer;
  overflow: hidden;
  object-fit: cover;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.top12 {
  margin-top: 12px;
}

.top16 {
  margin-top: 16px;
}

.top24 {
  margin-top: 24px;
}

.all-images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 108px;
  width: 108px;
  height: 108px;
  border-radius: 12px;
  background: #f4f5f7;
  cursor: pointer;
}

.main-info {
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}

.main-info-mobile > div > p {
  width: 140px;
}

.listing-field-row {
  gap: 24px;
}

.field-name {
  color: #75787a;
  font-family: "Inter", sans-serif;
}

.field-name,
.field-value {
  width: 208px;
  font-size: 14px;
  line-height: 20px;
}

.grey-950 {
  color: #75787a;
}

.outputs-modal {
  background: white;
  padding: 16px;
}

.outputs {
  width: 18px;
  height: 18px;
}

.output-row {
  align-items: center;
  gap: 16px;
  flex-wrap: nowrap;
}

.output-checkbox {
  width: 100px;
}

.output-account {
  width: 200px;
}
.price-info-container {
  position: relative;
  display: flex;
  border-radius: 8px;
}
.price-info-dropdown {
  display: flex;
  align-items: center;
}

.price-info-container-hover:hover {
  background-color: black !important;
}

.price-info-container-hover:hover img {
  filter: invert(100%) sepia(0) saturate(0) hue-rotate(317deg) brightness(103%)
    contrast(102%);
}
.price-history-info-dropdown {
  box-shadow: rgba(0, 0, 0, 0.11) 0px 2px 8px, rgba(0, 0, 0, 0.11) 2px 1px 9px, rgba(0, 0, 0, 0.1) 0px 0px 0px;
  position: absolute;
  z-index: 8;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  gap: 12px;
  padding: 16px;
  left: -20px;
}

.price-info {
  border-radius: 8px 8px 0 0;
  display: flex;
  gap: 8px;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.statusLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px;
  max-height: 22px;
  color: white;
  max-width: fit-content;
  border-radius: 4px;
  cursor: pointer;
}

.statusLabel.booked-label {
  border-radius: 4px 0 0 4px;
}

.booked-info-label-sidepanel {
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #e6e8ec;
  display: flex;
  align-items: center;
  padding: 3px 4px;
  gap: 10px;
  min-height: unset;
  margin-left: -8px;

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    background: black;
    cursor: pointer;
  }
}

.booking-until-date {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
.price-history-log {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
}

.hoverable-info {
  cursor: pointer;
  background-color: #f4f5f7;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #ff5c01;
    color: #ffffff;
    font-weight: bold;
  }
}

.transparent-button {
  width: 80px;
  padding: 4px 12px !important;
  box-shadow: none !important;
  border: 1px solid $grey500 !important;
  justify-content: center;
}

.transparent-button.yes-button {
  color: white;
  background-color: $green !important;
  border: none !important;
}

.button {
  border: 1px solid $grey500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 24px;
  padding: 0 4px;
  cursor: pointer;

  &:hover {
    background-color: #f4f5f7;
  }
}

.button-text {
  font-size: 12px;
  padding-left: 4px;
}

.animated-button {
  border: 1px solid $grey500;
  border-radius: 6px;
  height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 4px;
  font-size: 12px;

  &:hover {
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #000000;
  }
}

.animated-button span {
  max-width: 0;
  -webkit-transition: max-width 0.1s;
  transition: max-width 0.1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
}

.animated-button:hover span {
  padding: 0px 2px;
  max-width: 7rem;
}

.confirmation-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.confirmation-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.new-shared-broker {
  width: 100%;
  display: flex;
  font-weight: 600;
}

.animated-button:hover p {
  color: #ffffff;
}

@media only screen and (max-width: 900px) {
  .listing-panel {
    width: 100%;
    max-width: 640px;
  }
}

.project-archived-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  font-weight: 500;
  height: 32px;
  padding: 2px 4px;
  white-space: pre;
  background-color: $yellow;
}

.listing-images-updating {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #fff0f0;
  padding: 12px;

  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $red;
  }
}
</style>
