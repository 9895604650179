var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "cancel-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('clicked');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }