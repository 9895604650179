var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _vm.closeDropdown();
      },
      expression: "() => closeDropdown()"
    }],
    staticClass: "main"
  }, [_c('div', {
    ref: "container",
    staticClass: "form-field-large",
    style: _vm.fieldStyle,
    on: {
      "click": _vm.focusInputElement
    }
  }, [_vm.iconLeft ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": require(`@/assets/images/` + _vm.iconLeft),
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    class: {
      required: _vm.fieldRequired
    },
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "flex-direction": "column"
    }
  }, [_vm.placeholder && !_vm.loading ? _c('label', {
    ref: "label",
    staticClass: "content",
    style: _vm.labelStyle
  }, [_vm._v(_vm._s(_vm.placeholder))]) : _vm._e(), _vm.loading ? _c('div', {
    staticClass: "loader-bar",
    staticStyle: {
      "position": "absolute",
      "left": "68px",
      "bottom": "32px"
    }
  }) : _vm._e(), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "input",
    staticClass: "input-field content",
    attrs: {
      "disabled": _vm.fieldDisabled
    },
    domProps: {
      "value": _vm.fieldValue
    },
    on: {
      "focusin": _vm.focusInputElementPlaceholder,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDropdown.apply(null, arguments);
      },
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.fieldValue = $event.target.value;
      }, function ($event) {
        _vm.fieldValue = $event.target.value;
      }]
    }
  })]), this.fieldValue ? _c('div', {
    staticClass: "remove-button",
    on: {
      "click": _vm.removeFieldContent
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/close.svg`),
      "width": "24",
      "height": "24"
    }
  })]) : _vm._e()]), _vm.items && _vm.isOpen ? _c('div', {
    staticClass: "items",
    attrs: {
      "id": "items-dropdown"
    }
  }, [_c('div', {
    ref: "scrollableArea",
    staticClass: "item-wrapper",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('div', {
    key: _vm.resultsUpdater
  }, [_vm.fieldValue && _vm.items.length > 0 ? _c('div', _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: item._id,
      staticClass: "item",
      attrs: {
        "id": 'client-list-search-item-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.chooseResultItem(item);
        }
      }
    }, [_c('p', [_vm._v(" " + _vm._s(item.customerName) + " "), item.organization ? _c('span', {
      staticStyle: {
        "padding-left": "4px"
      }
    }, [_vm._v(_vm._s("- ") + _vm._s(item.organization))]) : _c('span', {
      staticStyle: {
        "padding-left": "4px"
      }
    }, [_vm._v(_vm._s("- ") + _vm._s(_vm.$t("PrivatePerson.Label").toLowerCase()))]), item.contactIsPartner ? _c('span', {
      staticStyle: {
        "padding-left": "4px"
      }
    }, [_vm._v("( Partner )")]) : _vm._e()])]);
  }), 0) : _vm.fieldValue && _vm.items.length === 0 ? _c('div', [_c('p', {
    staticClass: "no-results"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoResultsFound")) + " \"" + _vm._s(_vm.fieldValue) + "\" ")])]) : _c('div', _vm._l(_vm.items, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "item",
      attrs: {
        "id": 'client-list-search-item-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.chooseResultItem(item);
        }
      }
    }, [_c('p', [_vm._v(" " + _vm._s(item.customerName) + " "), item.organization ? _c('span', {
      staticStyle: {
        "padding-left": "4px"
      }
    }, [_vm._v(_vm._s("- ") + _vm._s(item.organization))]) : _c('span', {
      staticStyle: {
        "padding-left": "4px"
      }
    }, [_vm._v(_vm._s("- ") + _vm._s(_vm.$t("PrivatePerson.Label").toLowerCase()))]), item.contactIsPartner ? _c('span', {
      staticStyle: {
        "padding-left": "4px"
      }
    }, [_vm._v("( Partner )")]) : _vm._e()])]);
  }), 0)]), _vm.isFetching && _vm.hasMoreItems ? _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "80px",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e()]), !_vm.addNewDisabled ? _c('div', {
    staticClass: "item add-new",
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "justify-content": "center"
    },
    attrs: {
      "tabindex": "0"
    },
    on: {
      "click": _vm.dropdownAddNewButton
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/action-add_16_16.svg`),
      "width": "22",
      "height": "22"
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("AddNew")))])]) : _vm._e()]) : _vm._e(), _vm.fieldError ? _c('v-row', {
    staticClass: "content error-message",
    staticStyle: {
      "margin": "12px 12px 0 0"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldErrorMessage ? _vm.fieldErrorMessage : _vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }