import Vue from "vue";
import router from '@/router';

Vue.mixin({
    methods: {
        openSidepanel(module, id) {
            const newRouteObject = {
                name: router.currentRoute.name,
                query: { ...router.currentRoute.query }
            }
            newRouteObject['query'][module] = id;
            this.$router.push(newRouteObject)
        }
    },
});