<template>
  <div class="comment-wrapper">
    <div class="detail-row-first">
      <div class="transaction-type-wrapper">
        <img
          v-if="interest.transactionType === 'sale'"
          src="../../assets/images/shopping-cart.svg"
          alt=""
        />
        <img v-else src="../../assets/images/renting.svg" />
        <p class="content-semibold">
          {{ formatTransactionType(interest.transactionType) }}
        </p>
      </div>
      <EditButton
        @clicked="editInterest(interest)"
        name="interest-edit-button"
      />
    </div>
    <div class="detail-row">
      <img :src="getEstateImage" alt="" />
      <div class="detail-row-address">
        <p class="content-normal">
          {{ formatEstateType(interest.estateType) }}
        </p>
        <ul v-if="extras && extras.length">
          <li v-for="extra in extras" :key="extra.value">
            <p class="content-normal">
              {{ $t(`${extra.name}`) }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="interest.address.county" class="detail-row">
      <img :src="require('@/assets/images/map_marker.svg')" alt="" />
      <div class="detail-row-address">
        <p class="content-normal">
          {{ interest.address.county.name }}
        </p>
        <ul v-if="interest.address.cities && interest.address.cities.length">
          <li v-for="city in interest.address.cities" :key="city.value">
            <p class="content-normal">
              {{ cityAndCityPartsText(city, interest.address.cityParts) }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="interest.minPrice || interest.maxPrice" class="detail-row">
      <img :src="require('@/assets/images/euroSign.svg')" alt="" />
      <p class="content-normal">
        {{ formatRange(interestMinPrice, interestMaxPrice) }}
      </p>
    </div>
    <div
      v-if="interest.minPricePerSquareMeter || interest.maxPricePerSquareMeter"
      class="detail-row-sqm"
    >
      <img :src="require('@/assets/images/euro_m2.svg')" alt="" />
      <p class="content-normal">
        {{
          formatRange(
            interestMinPricePerSquareMeter,
            interestMaxPricePerSquareMeter
          )
        }}
      </p>
    </div>
    <div
      v-if="interest.minGeneralSurfaceArea || interest.maxGeneralSurfaceArea"
      class="detail-row"
    >
      <img :src="require('../../assets/images/area.svg')" alt="" />
      <p class="content-normal">
        {{
          formatRange(
            interest.minGeneralSurfaceArea,
            interest.maxGeneralSurfaceArea
          )
        }}&nbsp;m²
      </p>
    </div>
    <div
      v-if="interest.minNumberOfRooms || interest.maxNumberOfRooms"
      class="detail-row"
    >
      <img :src="require('../../assets/images/door.svg')" alt="" />
      <p class="content-normal">
        {{
          formatRange(interest.minNumberOfRooms, interest.maxNumberOfRooms)
        }}&nbsp;{{ $t("rooms") }}
      </p>
    </div>
    <div v-if="interestHasExtras" class="detail-row">
      <img :src="require('@/assets/images/info.svg')" alt="" />
      <div class="detail-row-address">
        <p class="content-normal">
          {{ $t("Extras") }}
        </p>
        <ul>
          <li v-for="extra in interestExtras" :key="extra.value">
            <p class="content-normal">
              {{ extra.name }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import EditButton from "@/components/common/EditButton.vue";
import nanoid from "nanoid";

export default {
  name: "InterestDetails",
  components: { EditButton },
  props: ["interest"],
  data() {
    return {
      extras: [],
      extraTypes: {
        placeholder: {
          name: this.$t("CommercialPurposeOfUse"),
          value: "placeholder",
          _id: () => nanoid(16),
        },
        land: [
          {
            name: this.$t("CommercialPurposeOfUse"),
            value: "placeholder",
            _id: () => nanoid(16),
          },
          {
            name: this.$t("ResidentialLand"),
            value: "residentialLand",
          },
          {
            name: this.$t("ResidentialLandApartments"),
            value: "residentialLandApartment",
          },
          { name: this.$t("CommercialLand"), value: "commercialLand" },
          { name: this.$t("ProductionLand"), value: "productionLand" },
          { name: this.$t("IndustrialLand"), value: "industrialLand" },
          { name: this.$t("ForestLand"), value: "forestLand" },
          { name: this.$t("ProfitYieldingLand"), value: "profityieldingLand" },
          { name: this.$t("OtherUse"), value: "otherUse" },
        ],
        commercial: [
          {
            name: this.$t("CommercialPurposeOfUse"),
            value: "placeholder",
            _id: () => nanoid(16),
          },
          {
            name: this.$t("Office"),
            value: "office",
          },
          { name: this.$t("TradeShop"), value: "tradeshop" },
          { name: this.$t("Accomodation"), value: "accomodation" },
          { name: this.$t("Services"), value: "services" },
          { name: this.$t("WareHouse"), value: "warehouse" },
          { name: this.$t("Production"), value: "production" },
          {
            name: this.$t("WareHouseProduction"),
            value: "warehouseProduction",
          },
          { name: this.$t("FoodServices"), value: "foodServices" },
          { name: this.$t("OtherUsePossible"), value: "otherUsePossible" },
          { name: this.$t("NotDefined"), value: "notDefined" },
        ],
      },
    };
  },
  created() {
    this.convertExtras();
  },
  computed: {
    getEstateImage() {
      return require(`../../assets/images/${this.interest.estateType}.svg`);
    },
    interestMinPrice() {
      return this.interest.minPrice
        ? this.$nMapper.price(this.interest.minPrice)
        : "";
    },
    interestMaxPrice() {
      return this.interest.maxPrice
        ? this.$nMapper.price(this.interest.maxPrice)
        : "";
    },
    interestMinPricePerSquareMeter() {
      return this.interest.minPricePerSquareMeter
        ? this.$nMapper.price(this.interest.minPricePerSquareMeter)
        : "";
    },
    interestMaxPricePerSquareMeter() {
      return this.interest.maxPricePerSquareMeter
        ? this.$nMapper.price(this.interest.maxPricePerSquareMeter)
        : "";
    },
    interestHasExtras() {
      return Object.keys(this.interest.extras).some(
        (k) => this.interest.extras[k]
      );
    },
    interestExtras() {
      let extras = [
        { name: this.$t("Balcony"), value: "balcony" },
        { name: this.$t("Parking"), value: "parking" },
        { name: this.$t("RoomsSeparate"), value: "roomsSeparate" },
        { name: this.$t("Storeroom"), value: "storeRoom" },
        { name: this.$t("Sauna"), value: "sauna" },
      ];
      const interestExtras = Object.keys(this.interest.extras).filter(
        (k) => this.interest.extras[k]
      );

      extras = extras.filter(function (extra) {
        return interestExtras.includes(extra.value);
      });
      return extras;
    },
  },
  methods: {
    editInterest(interest) {
      this.$emit("editInterest", interest);
    },
    convertExtras() {
      //map project extras to dropdown items format
      this.correctExtraTypes = this.extraTypes[this.interest.estateType];
      let extras = [];
      if (this.interest.extraTypes && this.correctExtraTypes) {
        extras = this.correctExtraTypes.filter((type) =>
          this.interest.extraTypes.includes(type.value)
        );
      }
      this.extras = extras;
    },
    cityAndCityPartsText(city, cityParts) {
      let cityCode = city.value;
      let text = city.name;

      if (cityParts) {
        cityParts = cityParts.filter((cityPart) => {
          return cityPart.parent === city.value;
        });
      }
      if (!cityParts || cityParts.length < 1) return text;

      for (let i = 0; i < cityParts.length; i++) {
        if (cityParts[i].parent === cityCode) {
          if (cityParts.length === 1) {
            text = text + " (" + cityParts[i].name;
          } else {
            if (i === 0) {
              text = text + " (" + cityParts[i].name + ", ";
            } else if (i === cityParts.length - 1) {
              text = text + cityParts[i].name;
            } else {
              text = text + cityParts[i].name + ", ";
            }
          }
        }
      }
      text += ")";
      return text;
    },
    formatRange(min, max) {
      if (!min) {
        return "... - " + max;
      }
      if (!max) {
        return min + " - ...";
      }
      if (min && max) {
        return min + " - " + max;
      }
    },
    formatEstateType(estateType) {
      if (estateType == "commercial") {
        return this.$t("Commercial");
      } else if (estateType == "apartment") {
        return this.$t("Apartment");
      } else if (estateType == "house") {
        return this.$t("House");
      } else if (estateType == "house_part") {
        return this.$t("HousePart");
      } else if (estateType == "land") {
        return this.$t("LandPlot");
      } else if (estateType == "cottage") {
        return this.$t("SummerHouse");
      } else if (estateType == "garage") {
        return this.$t("Garage");
      }
    },
    formatTransactionType(type) {
      switch (type) {
        case "rent":
          return this.$t("Rent");
        case "sale":
          return this.$t("Purchase");
        case "short_term_rent":
          return this.$t("ShortTermRent");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.comment-wrapper {
  border-top: 1px solid #e6e8ec;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.detail-row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  img {
    width: 20px;
    height: 20px;
  }
  .first {
    gap: unset;
    justify-content: space-between !important;
  }
}
.detail-row-first {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: space-between;
  align-items: flex-start;
  img {
    width: 20px;
    height: 20px;
  }
}
.transaction-type-wrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  img {
    width: 20px;
    height: 20px;
  }
}
.detail-row-sqm {
  margin-left: -1px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: flex-start;
  //img {
  //  width: 20px;
  //  height: 20px;
  //}
}
.detail-row-address {
  display: flex;
  flex-direction: column;
}
</style>
