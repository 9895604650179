var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative",
      "width": "100%"
    }
  }, [_vm.defaultSearch ? _c('div', [_c('div', {
    staticClass: "task-linked-to-search-input",
    style: [_vm.searchOpen ? '' : {
      borderRadius: '8px'
    }],
    on: {
      "click": _vm.openSearch
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/search20.svg'),
      "alt": ""
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchItemText,
      expression: "searchItemText"
    }],
    ref: "searchInput",
    staticClass: "content-normal",
    attrs: {
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.searchItemText
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchItemText = $event.target.value;
      }
    }
  })])]) : _vm.smallSearch ? _c('div', [_c('div', {
    staticClass: "small-search-input",
    on: {
      "click": _vm.openSearch
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/add_16_16.svg')
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchItemText,
      expression: "searchItemText"
    }],
    ref: "searchInput",
    attrs: {
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.searchItemText
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchItemText = $event.target.value;
      }
    }
  })])]) : _c('div', {
    staticClass: "task-linked-to-search-content"
  }, [_c('div', {
    staticClass: "task-linked-to-search-container",
    on: {
      "click": _vm.openSearch
    }
  }, [_vm.iconName ? _c('img', {
    class: {
      'standard-icon': !_vm.booking,
      'booking-icon': _vm.booking
    },
    attrs: {
      "src": require(`@/assets/images/${_vm.iconName}`),
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "task-linked-to-search-container-content",
    class: {
      required: _vm.required
    }
  }, [_vm.chosenItemText ? _c('p', {
    class: {
      'colored-label label12': !_vm.booking,
      'link-small': _vm.booking
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.label)) + " ")]) : _c('p', {
    staticClass: "link-small",
    staticStyle: {
      "font-size": "12px",
      "line-height": "16px"
    }
  }, [_vm._v(" " + _vm._s("+ " + _vm.$t('Add') + " " + _vm.$t(_vm.label).toLowerCase()) + " ")]), _c('div', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.chosenItemText))])])]), _vm.value && !_vm.disableClear ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "width": "20",
      "height": "20",
      "src": require('@/assets/images/close_24_24.svg'),
      "alt": ""
    },
    on: {
      "click": _vm.emitClearValue
    }
  }) : _vm._e()]), _vm.searchOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeSearch,
      expression: "closeSearch"
    }],
    ref: "linkedToSearchDropdown",
    staticClass: "task-linked-to-search-dropdown",
    style: {
      width: _vm.inputWidth
    }
  }, [_c('div', {
    staticClass: "task-linked-to-search-input"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/search20.svg'),
      "alt": ""
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchItemText,
      expression: "searchItemText"
    }],
    ref: "searchInput",
    staticClass: "content-normal",
    attrs: {
      "placeholder": _vm.placeholder
    },
    domProps: {
      "value": _vm.searchItemText
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchItemText = $event.target.value;
      }
    }
  }), (_vm.value || _vm.searchItemText.length > 0) && !_vm.disableClear ? _c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "width": "20",
      "height": "20",
      "src": require('@/assets/images/close_24_24.svg'),
      "alt": ""
    },
    on: {
      "click": _vm.emitClearValue
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "task-linked-to-search-dropdown-items"
  }, _vm._l(_vm.foundItems, function (item) {
    return _c('div', {
      key: item._id,
      staticClass: "task-linked-to-search-dropdown-item content-normal",
      on: {
        "click": function ($event) {
          return _vm.selectItem(item);
        }
      }
    }, [_c('p', {
      staticClass: "mr-1",
      class: {
        'selected-item-text': item.id === _vm.value
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _vm.modalType === 'client' && item.organization ? _c('span', {
      staticStyle: {
        "color": "#939597"
      }
    }, [_vm._v(" " + _vm._s(" - ") + _vm._s(item.organization) + " ")]) : _vm.modalType === 'client' ? _c('span', {
      staticStyle: {
        "color": "#939597"
      }
    }, [_vm._v(" " + _vm._s(" - ") + _vm._s(_vm.$t("PrivatePerson.Label").toLowerCase()) + " ")]) : _vm._e(), _vm.modalType === 'client' && item.contactIsPartner ? _c('span', {
      staticStyle: {
        "color": "#939597",
        "margin-left": "4px"
      }
    }, [_vm._v(" ( Partner ) ")]) : _vm._e()]);
  }), 0), _vm.modalType === 'client' ? _c('div', {
    staticClass: "task-linked-to-search-add-contact-button",
    on: {
      "click": _vm.addButtonClicked
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/action-add_16_16.svg"),
      "alt": "add-img"
    }
  }), _vm._v(" " + _vm._s(_vm.$t("AddContact")) + " ")]) : _vm._e()]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }