<template>
  <span style="line-height: 16px">
    <v-row
      v-if="task && version === 'large'"
      class="content-small open-house-wrapper"
      :class="{'inverted-colors': invert}"
    >
    <img
        v-if="!invert"
        src="../../assets/images/calendar.svg"
        alt=""
    >
    <span style="font-weight: bold">
      {{ $t("OpenHouse") }}
    </span>
    <span v-if="task.dates.startDate">
      {{ formatDate }}
    </span>
    <span v-if="task.dates.startTime || task.dates.endTime">
      •
    </span>
    <span>
      <span v-if="task.dates.startTime">
        {{ task.dates.startTime }}
      </span>
      <span v-if="task.dates.endTime" style="margin-left: -2px">
        –{{ task.dates.endTime }}
      </span>
    </span>
  </v-row>

    <v-row
      v-if="task && version === 'small'"
      :class="{
        'open-house-wrapper': 1,
        'content-small': 1,
        'inverted-colors': invert
      }"
      style="padding: 0 2px"
    >
      <span style="font-weight: bold">
        {{ $t("OpenHouse") }}
      </span>
    </v-row>
  </span>
</template>

<script>

import moment from "moment";

export default {
  name: "OpenHouseContainer",
  props: ["task", "invert", "variant"],
  data() {
    return {
      version: "large"
    }
  },
  created() {
    if (this.variant) {
      this.version = this.variant;
    }
  },
  mounted() {
  },
  computed: {
    formatDate() {
      return moment(this.task.dates.startDate).format("DD.MM");
    }
  },
  beforeDestroy() {
    window.onclick = null;
  },
};
</script>

<style lang="scss" scoped>

.open-house-wrapper.inverted-colors {
  background: transparent !important;
  color: #7200E2 !important;
  font-weight: bold;
  padding: 0 !important;
}

.open-house-wrapper {
  display: inline-flex !important;
  background: #7200E2;
  border-radius: 4px;
  gap: 4px;
  padding: 2px 4px;
  color: white !important;

  img {
    filter: invert(1);
  }
}

</style>
