<template>
  <div id="broker-invoice-overlay" :style="OverlayStyle">
    <div
        id="broker-invoice-header"
        :class="{'project-sidepanel-open': projectClientAddSpidepanelOpen, 'project-sidepanel-closed': !projectClientAddSpidepanelOpen}"
        @click="closeSidepanels()" :style="sidewaysHeaderStyle" v-if="selectedInvoice"
        class="invoice-panel-header-sideways">
      <h4 v-if="invoiceUser">{{ invoiceUser.firstname + " " + invoiceUser.lastname }}</h4>
    </div>
    <div v-else id="invoice-panel" class="invoice-panel" v-click-outside="($event) => closeModal($event)">
      <div class="invoice-panel-header">
        <div style="display: flex; flex-direction: column; gap: 8px; width: 100%;" v-if="invoiceUser">
          <h4>{{ invoiceUser.firstname + " " + invoiceUser.lastname }}</h4>
        </div>
      </div>
      <div class="invoice-panel-content">
        <template v-if="!invoiceLoading && userInvoices.length > 0">
          <h5>{{ $t("Invoices") }}</h5>
          <div class="broker-invoice-container">
            <div v-for="(invoice, index) of userInvoices" class="invoice-card" @mouseover="hoveringInvoice(index)"
                 @mouseleave="hoveringInvoiceStopped" :style="hoverInvoiceIndex === index ? hoverInvoiceStyle : null"
                 @click="openInvoiceSidepanel(invoice)" :key="'broker-invoice-' + index">
              <div style="display: flex; flex-wrap: nowrap; gap: 12px;">
                <div style="width: 100%; display: flex; gap: 8px;">
                  <p class="content-bold">{{ $t('Invoice') + ' ' + (invoice.number ? invoice.number : '') }}</p>
                  <img v-if="invoice?.sharedBrokers?.length > 0" :src="require('@/assets/images/additional_users.svg')"
                       width="20" height="20" alt=""/>
                </div>
                <div @mouseover="stopHoveringInvoice" @mouseleave="enableHovering">
                  <StatusDropdown @reloadInvoice="getBrokerInvoices" :show-additional-info="true" :invoice="invoice"/>
                </div>
                <div style="display: flex; gap: 4px; white-space: nowrap; min-width: 104px">
                  <p class="content-semibold">{{ $nMapper.price(invoice.sum, '€') }}</p>
                  <p class="content-small" v-if="invoice.totalSum">/ {{ $nMapper.price(invoice.totalSum, '€') }}</p>
                </div>
              </div>
              <div v-if="invoice.project" class="content-small"
                   style="display: block; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                {{ invoice.project.name }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="content-normal">{{ $t('NoInvoices') }}</p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import axios from "axios";
import StatusDropdown from "@/components/Invoices/StatusDropdown";

export default {
  name: "InvoiceBrokerSidepanel",
  components: {StatusDropdown},
  props: ["broker"],
  data() {
    return {
      OverlayStyle: {
        background: "#0000001a",
      },
      projectClientAddSpidepanelOpen: false,
      sidewaysHeader: false,
      sidewaysHeaderStyle: {},
      hoverInvoiceStyle: {},
      hoverInvoiceIndex: null,
      brokerInvoices: [],
      selectedInvoice: null,
      loaded: true,
      canHoverInvoice: true,
    }
  },
  computed: {
    ...mapGetters(["userInvoices", "invoiceUser", "invoiceLoading", "invoiceSidepanelOpen"])
  },
  watch: {
    invoiceSidepanelOpen(sidepanelOpen) {
      if (sidepanelOpen) {
        this.OverlayStyle.background = "#00000000";
      } else {
        this.OverlayStyle.background = "#0000001a";
        this.projectClientAddSpidepanelOpen = false;
        this.selectedInvoice = null;
        this.clientAddSidepanelOpen = false;
        this.sidewaysHeader = false;
      }
    },
    userInvoices(invoices) {
      this.setupInvoiceFieldForUsers( {[`${this.invoiceUser._id}`]: true}, invoices)
    }
  },
  methods: {
    ...mapActions(["closeInvoiceSidepanel"]),
    async getBrokerInvoices() {
      const response = await axios.post(`/api/invoices/brokers/${this.broker.brokerId}`, {filters: this.provisionalFilters["invoices"]});
      this.brokerInvoices = await this.setupInvoiceFieldFilter(response.data, this.broker.brokerId);
      this.loaded = true;
      if (this.selectedInvoice) {
        this.selectedInvoice = this.brokerInvoices.find((invoice) => invoice._id === this.selectedInvoice._id)
        this.$emit('reloadInvoices');
      }
    },
    async setupInvoiceFieldFilter(invoices, brokerId) {
      return await this.setupInvoiceFieldForUsers({[brokerId]: true}, invoices)
    },
    async setupInvoiceFieldForUsers(users, invoices) {
      const returnedInvoices = []
      for (const invoice of invoices) {
        if (invoice.sharedBrokers && invoice.sharedBrokers.length > 0) {
          // Total invoice values.
          invoice.totalSum = invoice.sum;
          invoice.totalSumVAT = invoice.sumVAT;
          // Replaced with broker values to keep sorting working.
          invoice.sum = 0;
          invoice.sumVAT = 0;
          for (const sharedBroker of invoice.sharedBrokers) {
            if (users[sharedBroker._id]) {
              invoice.sum += parseFloat(sharedBroker.share.sum);
              invoice.sumVAT += parseFloat(sharedBroker.share.sumVAT);
            }
          }
          if (users[invoice.broker.userId]) {
            invoice.sum += parseFloat(invoice.broker.share.sum);
            invoice.sumVAT += parseFloat(invoice.broker.share.sumVAT);
          }
          invoice.sum = this.formatFixed(invoice.sum, 0);
          invoice.sumVAT = this.formatFixed(invoice.sumVAT, 0);
          if (invoice.totalSum === invoice.sum && invoice.totalSumVAT === invoice.sumVAT) {
            delete invoice.totalSum;
            delete invoice.totalSumVAT;
          }
        }
        returnedInvoices.push(invoice);
      }
      return returnedInvoices;
    },
    formatFixed(value, toFixedDecimalCount) {
      let num = value;

      // Handle undefined or null values
      if (num === undefined || num === null) return num;

      // Convert to number
      num = Number(num);

      // Check if it's a whole number
      if (num % 1 !== 0) {
        // If not, return it with 2 decimal places
        return parseFloat(num.toFixed(toFixedDecimalCount));
      } else {
        // If it is, return it without decimal places
        return parseFloat(num.toFixed(0));
      }
    },
    async invoiceDeleted() {
      await this.getBrokerInvoices();
      this.selectedInvoice = null;
      this.$emit('reloadInvoices');
    },
    closeModal(event = null) {
      if (event.target.id !== "broker-invoice-overlay") return;
      this.closeInvoiceSidepanel();
    },
    openInvoiceSidepanel(invoice) {
      if (!this.canHoverInvoice) return;
      this.hoverInvoiceIndex = null;
      this.sidewaysHeaderStyle.right = "640px";
      this.sidewaysHeaderStyle.zIndex = "13";
      this.sidewaysHeader = true;
      this.openSidepanel('invoices', invoice._id)
      this.selectedInvoice = invoice
      this.$nextTick(() => {
        document.getElementById('sidepanels-wrapper').append(document.getElementById('broker-invoice-header'))
      })
    },
    openClientAddSidepanel() {
      this.OverlayStyle.background = "#00000000";
      this.sidewaysHeaderStyle.right = "640px";
      this.sidewaysHeaderStyle.zIndex = "12";
      this.sidewaysHeader = true;
      this.clientAddSidepanelOpen = true;
    },
    closeClientAddSidepanel() {
      this.OverlayStyle.background = "#0000001a";
      this.sidewaysHeader = false;
      this.clientAddSidepanelOpen = false;
    },
    closeSidepanels() {
      this.closeInvoiceSidepanel(['invoices']);
      document.getElementById('broker-invoice-overlay').append(document.getElementById('broker-invoice-header'))
      this.projectClientAddSpidepanelOpen = false;
      this.selectedInvoice = null;
      this.clientAddSidepanelOpen = false;
      this.sidewaysHeader = false;
    },
    projectSidepanelOpenedClientSidepanel() {
      this.sidewaysHeaderStyle.right = "732px";
      this.sidewaysHeaderStyle.zIndex = "102";
      this.projectClientAddSpidepanelOpen = true;
    },
    projectSidepanelClosedClientSidepanel() {
      this.sidewaysHeaderStyle.right = "560px";
      this.projectClientAddSpidepanelOpen = false;
    },
    hoveringInvoice(index) {
      if (!this.canHoverInvoice) return;
      this.hoverInvoiceIndex = index;
      this.hoverInvoiceStyle.background = "#FAFAFA";
    },
    hoveringInvoiceStopped() {
      this.hoverInvoiceIndex = null;
      this.canHoverInvoice = true;
    },
    stopHoveringInvoice() {
      this.canHoverInvoice = false;
      this.hoverInvoiceIndex = null;
      this.hoverInvoiceStyle.background = ""
    },
    enableHovering() {
      this.canHoverInvoice = true;
      this.hoverInvoiceStyle.background = "#FAFAFA";
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/global_variables";

#broker-invoice-overlay {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 7;
  width: unset;
  left: 0;
}

.invoice-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 8;
  width: 640px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.invoice-panel-header {
  height: 96px;
  padding: 40px 32px 32px 32px;
  background-color: white;
  border-bottom: 1px solid #E6E8EC;
}

.invoice-panel-content {
  padding: 32px 72px 112px 32px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.broker-invoice-container {
  display: flex;
  flex-direction: column;
}

.invoice-panel-header-sideways {
  width: 98px;
  height: 100%;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 48px 24px 24px;
  cursor: pointer;
  border-right: 1px solid $grey500;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.invoice-panel-header-sideways h4 {
  transform: rotate(180deg);
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  writing-mode: vertical-lr;
  top: 0;
  left: 0;
}

.invoice-card {
  border-left: 1px solid #E6E8EC;
  border-top: 1px solid #E6E8EC;
  border-right: 1px solid #E6E8EC;
  padding: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 72px;
  gap: 4px;
}

.invoice-card:hover {
  cursor: pointer;
}

.invoice-card:first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.invoice-card:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom: 1px solid #E6E8EC;
}

</style>