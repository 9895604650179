var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dropdown",
    class: {
      'dropdown-disabled': _vm.disabled || _vm.shouldDisable
    },
    style: {
      width: _vm.dropdownWidth
    },
    attrs: {
      "tabindex": !_vm.isInput ? 0 : null
    },
    on: {
      "keydown": _vm.handleKeyDown,
      "focus": _vm.handleFocus,
      "blur": _vm.handleBlur
    }
  }, [_c('div', {
    key: _vm.dropdownId,
    ref: "container",
    staticClass: "field-style",
    class: {
      'required-mark': _vm.required,
      'dropdown-error': _vm.error
    },
    style: {
      height: _vm.height,
      fontSize: _vm.fontSize,
      cursor: _vm.cursorStyle
    },
    attrs: {
      "name": 'dropdown-' + _vm.dropdownId
    },
    on: {
      "mousedown": _vm.toggleDropdown,
      "click": _vm.focusInput
    }
  }, [_vm.fieldType ? _c('label', {
    ref: "label",
    staticClass: "content label-on-input",
    class: {
      'focused-class': _vm.inputFocus,
      'focused-text': _vm.dropdownOpen
    },
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(_vm._s(_vm.fieldType))]) : _vm._e(), _c('div', {
    staticClass: "input-wrapper",
    style: {
      transform: _vm.translateY
    }
  }, [_c('div', {
    staticClass: "placeholder-wrapper"
  }, [_c('div', {
    staticClass: "input-placeholder",
    style: {
      color: _vm.fontColor,
      height: _vm.height,
      opacity: _vm.placeholderOpacity
    }
  }, [_c('span', {
    staticClass: "truncate-text"
  }, [_vm._v(_vm._s(_vm.getPlaceholderText()))])]), _vm.isInput ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchInput,
      expression: "searchInput"
    }],
    ref: "input",
    staticClass: "input-field content input-blur",
    style: {
      cursor: _vm.cursorStyle,
      fontSize: _vm.fontSize,
      transform: _vm.fieldType ? 'translateY(14px)' : 'translateY(5px)'
    },
    attrs: {
      "readonly": !_vm.isInputFocused,
      "placeholder": _vm.showPlaceholder ? _vm.$t('Search') : '',
      "disabled": _vm.isInputDisabled || _vm.shouldDisable
    },
    domProps: {
      "value": _vm.searchInput
    },
    on: {
      "focus": function ($event) {
        return _vm.handleInputFocus();
      },
      "click": function ($event) {
        _vm.isInputFocused = true;
      },
      "blur": function ($event) {
        return _vm.handleInputBlur();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchInput = $event.target.value;
      }
    }
  }) : _vm._e()])]), _c('img', {
    class: {
      'arrow-disabled': _vm.disabled || _vm.shouldDisable
    },
    attrs: {
      "src": require(`@/assets/images/dropdown_20_20.svg`),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "dropdown-wrapper",
    style: {
      fontSize: _vm.fontSize
    }
  }, [_vm.dropdownOpen ? _c('div', {
    ref: "dropdownContainer",
    staticClass: "dropdown-items"
  }, _vm._l(_vm.flattenedDropdownItems, function (entry, index) {
    return _c('div', {
      key: index,
      ref: `item${index}`,
      refInFor: true,
      staticClass: "items-wrapper",
      class: {
        'selected': !entry.heading && entry.type === 'item' && _vm.isSelected(entry),
        'subtitle': entry.heading
      },
      style: {
        fontSize: _vm.fontSize
      },
      on: {
        "mousedown": function ($event) {
          $event.preventDefault();
          $event.stopPropagation();
          !entry.heading && _vm.canSelectOrDeselect(entry) && entry.type === 'item' ? _vm.toggleItemSelection(entry) : null;
        },
        "mouseover": _vm.resetFocusedIndex
      }
    }, [entry.type === 'item' && !entry.heading ? _c('div', {
      staticClass: "dropdown-item",
      class: {
        'focused-item': !entry.heading && index === _vm.currentFocusedIndex
      },
      staticStyle: {
        "width": "100%"
      }
    }, [_c('div', {
      staticClass: "toggle-wrapper"
    }, [_c('img', {
      staticClass: "toggle-img",
      attrs: {
        "src": _vm.getToggleImage(entry),
        "alt": ""
      }
    })]), _vm.translate && _vm._typeOf(entry) === 'object' ? _c('div', {
      staticClass: "translated-label-item"
    }, [_vm._v(" " + _vm._s(_vm.$t(entry[_vm.displayKey])) + " ")]) : _vm._typeOf(entry) === 'object' ? _c('div', {
      staticClass: "label-item"
    }, [_vm._v(" " + _vm._s(entry[_vm.displayKey]) + " ")]) : _vm.translate && _vm._typeOf(entry) !== 'object' ? _c('div', {
      staticClass: "translated-label-item"
    }, [_vm._v(" " + _vm._s(_vm.$t(entry)) + " ")]) : _c('div', {
      staticClass: "label-item"
    }, [_vm._v(" " + _vm._s(entry) + " ")])]) : _c('div', {
      staticClass: "item-sub-title"
    }, [_c('div', {
      staticClass: "category-title"
    }, [_vm._v(_vm._s(_vm.$t(entry.name)))])])]);
  }), 0) : _vm._e()]), _vm.error && this.required ? _c('div', {
    staticClass: "content error-message"
  }, [_vm._v(" " + _vm._s(_vm.fieldErrorMessage ? _vm.fieldErrorMessage : _vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }