var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-field-container",
    attrs: {
      "id": _vm.id
    },
    on: {
      "keydown": _vm.searchItem
    }
  }, [_vm.fieldType !== 'date' ? _c('div', {
    key: _vm.fieldUpdater,
    ref: "container",
    staticClass: "form-field-large",
    class: {
      disabled: _vm.fieldDisabled
    },
    style: _vm.fieldStyle,
    attrs: {
      "name": 'dropdown-' + _vm.id
    },
    on: {
      "click": _vm.focusInput
    }
  }, [_vm.iconLeft ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": require(`@/assets/images/` + _vm.iconLeft),
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    class: {
      required: _vm.fieldRequired
    },
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "flex-direction": "column"
    }
  }, [_vm.fieldPlaceholder ? _c('label', {
    ref: "label",
    staticClass: "content",
    style: _vm.labelStyle
  }, [_vm._v(_vm._s(_vm.fieldPlaceholder))]) : _vm._e(), _vm.fieldType === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "input",
    staticClass: "input-field content",
    attrs: {
      "disabled": _vm.fieldDisabled,
      "id": "input-element",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.fieldValue) ? _vm._i(_vm.fieldValue, null) > -1 : _vm.fieldValue
    },
    on: {
      "focus": _vm.focusInputElementPlaceholder,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.focusInputElement.apply(null, arguments);
      },
      "focusout": _vm.unfocusInput,
      "change": function ($event) {
        var $$a = _vm.fieldValue,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.fieldValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.fieldValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.fieldValue = $$c;
        }
      }
    }
  }) : _vm.fieldType === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "input",
    staticClass: "input-field content",
    attrs: {
      "disabled": _vm.fieldDisabled,
      "id": "input-element",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.fieldValue, null)
    },
    on: {
      "focus": _vm.focusInputElementPlaceholder,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.focusInputElement.apply(null, arguments);
      },
      "focusout": _vm.unfocusInput,
      "change": function ($event) {
        _vm.fieldValue = null;
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "input",
    staticClass: "input-field content",
    attrs: {
      "disabled": _vm.fieldDisabled,
      "id": "input-element",
      "type": _vm.fieldType
    },
    domProps: {
      "value": _vm.fieldValue
    },
    on: {
      "focus": _vm.focusInputElementPlaceholder,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.focusInputElement.apply(null, arguments);
      },
      "focusout": _vm.unfocusInput,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.fieldValue = $event.target.value;
      }
    }
  })]), _vm.fieldDropdownArrow ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    attrs: {
      "src": require(`@/assets/images/dropdown_20_20.svg`),
      "alt": ""
    }
  }) : _vm._e(), _vm.iconRight ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    attrs: {
      "src": require(`@/assets/images/` + _vm.iconRight),
      "alt": ""
    }
  }) : _vm._e()]) : _vm._e(), _c('div', {
    class: {
      disabled: _vm.fieldDisabled
    },
    staticStyle: {
      "border-radius": "8px"
    },
    on: {
      "click": _vm.toggle
    }
  }, [_vm.fieldType === 'date' ? _c('DatePick', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleOutsideClick,
      expression: "handleOutsideClick"
    }],
    ref: "datePick",
    staticClass: "date-field",
    attrs: {
      "disabled": _vm.fieldDisabled,
      "format": 'YYYY-MM-DD',
      "selectableYearRange": _vm.selectableYearRange,
      "tabindex": "0"
    },
    on: {
      "input": _vm.updateDate
    },
    nativeOn: {
      "mousedown": function ($event) {
        return _vm.handleClick($event);
      },
      "blur": function ($event) {
        return _vm.handleBlur.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var toggle = _ref.toggle;
        return [_c('div', {
          staticClass: "date-picker-content form-field-large",
          style: _vm.fieldStyle,
          on: {
            "click": toggle
          }
        }, [_c('img', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "height": "20",
            "width": "20",
            "src": require('@/assets/images/calendar_20_20.svg'),
            "alt": ""
          }
        }), _c('div', {
          class: {
            required: _vm.fieldRequired
          },
          staticStyle: {
            "width": "100%",
            "cursor": "pointer",
            "display": "flex"
          },
          style: {
            flexDirection: _vm.fieldValue ? 'column' : 'row'
          }
        }, [_vm.fieldPlaceholder ? _c('label', {
          ref: "dateLabel",
          class: {
            'required-mark': _vm.fieldValue
          },
          style: _vm.dateLabelStyles
        }, [_vm._v(_vm._s(_vm.fieldPlaceholder))]) : _vm._e(), _c('div', {
          staticClass: "date-picker-value"
        }, [_vm.fieldValue ? _c('div', {
          staticClass: "content-normal",
          staticStyle: {
            "cursor": "pointer"
          }
        }, [_vm._v(_vm._s(_vm.formatDate(_vm.fieldValue)))]) : _vm._e()])]), _vm.fieldValue ? _c('img', {
          class: {
            'icon-disabled': _vm.fieldDisabled
          },
          staticStyle: {
            "z-index": "999"
          },
          attrs: {
            "src": require(`@/assets/images/cross.svg`),
            "alt": ""
          },
          on: {
            "click": function ($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.clearFieldValue.apply(null, arguments);
            }
          }
        }) : _vm._e(), _vm.fieldDropdownArrow ? _c('img', {
          class: {
            'icon-disabled': _vm.fieldDisabled
          },
          attrs: {
            "src": require(`@/assets/images/dropdown_20_20.svg`),
            "alt": ""
          }
        }) : _vm._e()])];
      }
    }], null, false, 2013650277),
    model: {
      value: _vm.fieldValue,
      callback: function ($$v) {
        _vm.fieldValue = $$v;
      },
      expression: "fieldValue"
    }
  }) : _vm._e()], 1), _vm.fieldDropdownOpen || _vm.fieldDropdownOpen && _vm.fieldDropdownAddNew ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    ref: "dropdown",
    staticClass: "dropdown-container",
    staticStyle: {
      "left": "12px",
      "top": "4px",
      "right": "12px"
    },
    style: _vm.fieldDropdownStyle,
    attrs: {
      "id": "container-dropdown"
    }
  }, [_vm.fieldValue.length > 3 && _vm.fieldDropdownAllowNull && !_vm.required ? _c('div', {
    staticClass: "content dropdown-item",
    attrs: {
      "tabindex": "0"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.selectDropdownitem(null);
      },
      "click": function ($event) {
        return _vm.selectDropdownitem(null);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("none")) + " ")]) : _vm._e(), _c('div', {
    class: {
      'dropdown-medium': _vm.fieldDropdownAllowNull,
      'dropdown-small': _vm.fieldDropdownAllowNull && _vm.fieldDropdownAddNew
    },
    staticStyle: {
      "position": "relative",
      "overflow-y": "auto",
      "max-height": "200px"
    },
    attrs: {
      "id": _vm.id + 'dropdown-parent-container'
    }
  }, _vm._l(_vm.usableItems, function (item, index) {
    return _c('div', {
      key: item.value ? item.value : index,
      staticClass: "content dropdown-item",
      class: {
        'content dropdown-item': !item.hidden,
        hidden: item.hidden
      },
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap"
      },
      attrs: {
        "tabindex": "0",
        "id": _vm.id + '-dropdown-item-' + index,
        "name": _vm.id + '-dropdown-item'
      },
      on: {
        "keyup": function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.selectDropdownitem(item);
        },
        "click": function ($event) {
          return _vm.selectDropdownitem(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name ? item.name : item.text) + " ")]);
  }), 0), _vm.fieldDropdownAddNew ? _c('div', {
    staticClass: "dropdown-item",
    class: {
      bordert: _vm.fieldDropdownItems.length > 0
    },
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "justify-content": "center"
    },
    on: {
      "click": _vm.dropdownAddNewButton
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/action-add_16_16.svg`)
    }
  }), _c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$t("AddNew")))])]) : _vm._e()]) : _vm._e(), _vm.fieldError ? _c('div', {
    staticClass: "content error-message",
    staticStyle: {
      "margin": "12px 12px 0px 0px"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldErrorMessage ? _vm.fieldErrorMessage : _vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }