var render = function render(){
  var _ref;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dropdownHasAccessibleData ? _c('div', {
    staticClass: "group-or-user-select-wrapper"
  }, [_c('div', {
    staticClass: "group-or-user-select-input-wrapper",
    class: [`group-or-user-select-input-wrapper-${_vm.size}`, `group-or-user-select-input-wrapper-${_vm.color}`],
    on: {
      "click": _vm.inputWrapperClicked,
      "mousedown": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('div', {
    staticClass: "group-or-user-select-selection-container"
  }, [_c('img', {
    class: [`group-or-user-select-search-icon-open-${_vm.color}`, (_ref = {}, _ref[`group-or-user-select-search-icon-open-${_vm.size}`] = _vm.dropdownOpen, _ref['group-or-user-select-search-icon-closed'] = !_vm.dropdownOpen, _ref)],
    attrs: {
      "src": require('@/assets/images/search.svg'),
      "alt": ""
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_vm.size === 'large' ? _c('p', {
    staticClass: "group-or-user-select-label",
    class: {
      'group-or-user-select-label-active': _vm.dropdownOpen
    }
  }, [_vm._v(_vm._s(_vm.$t(_vm.labelTranslation)))]) : _vm._e(), !_vm.dropdownOpen ? _c('p', {
    class: [`group-or-user-select-selected-value-${_vm.color}`, {
      'button-small': _vm.size === 'small',
      'content group-or-user-select-selected-value': _vm.size === 'large'
    }],
    style: _vm.selectedLabelStyle
  }, [_vm._v(_vm._s(_vm.showSelectedValue))]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchInput,
      expression: "searchInput"
    }],
    ref: _vm.id + _vm.inputElementRefName,
    class: [`group-or-user-select-input-${_vm.size}`, `group-or-user-select-input-${_vm.color}`, {
      'button-small': _vm.size === 'small',
      'content': _vm.size === 'large'
    }, {
      'group-or-user-select-input-dropdown-closed': !_vm.dropdownOpen
    }],
    attrs: {
      "placeholder": _vm.$tc('SearchForUser')
    },
    domProps: {
      "value": _vm.searchInput
    },
    on: {
      "focus": _vm.openFirstLevelDropdown,
      "blur": _vm.closeDropdown,
      "keydown": _vm.handleKeyDown,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchInput = $event.target.value;
      }
    }
  })])]), _c('img', {
    class: [`group-or-user-select-chevron-${_vm.color}`, `group-or-user-select-chevron-${_vm.size}`, {
      'group-or-user-select-chevron-icon-open': _vm.dropdownOpen
    }],
    attrs: {
      "src": require('@/assets/images/chevron-down.svg'),
      "alt": ""
    }
  })]), _vm.dropdownOpen ? _c('div', {
    staticClass: "group-or-user-select-level-1-dropdown",
    class: `group-or-user-select-dropdown-${_vm.size}`,
    on: {
      "mousedown": function ($event) {
        $event.preventDefault();
      }
    }
  }, [!_vm.searchInput && _vm.allowEmpty ? _c('div', {
    staticClass: "group-or-user-select-dropdown-item group-or-user-select-dropdown-item-level-1",
    class: [`group-or-user-select-dropdown-item-${_vm.size}`, {
      'group-or-user-select-dropdown-item-active': _vm.keyboardNavigation && _vm.keyboardNavigation.selectedIndex === -1 && _vm.keyboardNavigation.level === 1
    }],
    on: {
      "click": function ($event) {
        return _vm.selectItem(null);
      },
      "mouseenter": _vm.closeSecondLevelDropdown
    }
  }, [!_vm.value ? _c('img', {
    class: `group-or-user-select-img-placeholder-${_vm.size}`,
    attrs: {
      "src": require('@/assets/images/checkmark.svg'),
      "alt": ""
    }
  }) : _c('div', {
    class: `group-or-user-select-img-placeholder-${_vm.size}`
  }), _c('p', {
    class: {
      'content-small': _vm.size === 'small',
      'content': _vm.size === 'large'
    }
  }, [_vm._v(_vm._s(_vm.$t(_vm.emptyValueLabelTranslation)))])]) : _vm._e(), _vm._l(Array.from(_vm.firstLevelDropdownData.entries()).entries(), function (_ref2) {
    var index = _ref2[0],
      _ref2$ = _ref2[1],
      id = _ref2$[0],
      item = _ref2$[1];
    return _c('div', {
      key: 'first-level-' + id,
      ref: 'dropdownLevel1Item' + id,
      refInFor: true,
      staticClass: "group-or-user-select-dropdown-item group-or-user-select-dropdown-item-level-1",
      class: [`group-or-user-select-dropdown-item-${_vm.size}`, {
        'group-or-user-select-dropdown-item-active': _vm.keyboardNavigation && _vm.keyboardNavigation.selectedIndex === index && _vm.keyboardNavigation.level === 1
      }],
      on: {
        "mouseenter": function (event) {
          return _vm.openSecondLevelDropdown(event, id, index);
        },
        "click": function ($event) {
          return _vm.selectItem(id, item);
        }
      }
    }, [_vm.getSelectedItemIcon(id, item) ? _c('img', {
      class: `group-or-user-select-img-placeholder-${_vm.size}`,
      attrs: {
        "src": require(`@/assets/images/${_vm.getSelectedItemIcon(id, _vm.firstLevelDropdownData.get(id))}.svg`),
        "alt": ""
      }
    }) : _c('div', {
      class: `group-or-user-select-img-placeholder-${_vm.size}`
    }), _c('p', {
      class: {
        'content-small': _vm.size === 'small',
        'content': _vm.size === 'large'
      },
      staticStyle: {
        "width": "100%"
      }
    }, [_vm._v(" " + _vm._s(item[_vm.secondLevelDataKey] ? item[_vm.firstLevelValueLabelKey] + ` (${_vm.filter2ndLevelItems.parameter !== 'search' ? Object.keys(_vm.filterSecondLevelData(item[_vm.secondLevelDataKey])).length : Object.keys(item[_vm.secondLevelDataKey]).length})` : item[_vm.secondLevelValueLabelKey]))]), item[_vm.secondLevelDataKey] ? _c('img', {
      staticClass: "group-or-user-select-dropdown-icon",
      class: {
        'group-or-user-select-dropdown-icon-rotated': _vm.secondLevelDropdownOfFirstLevelId === id
      },
      attrs: {
        "height": "20",
        "width": "20",
        "src": require('@/assets/images/chevron-right.svg'),
        "alt": ""
      }
    }) : _vm._e()]);
  })], 2) : _vm._e(), _vm.secondLevelDropdownData ? _c('div', {
    staticClass: "group-or-user-select-level-2-dropdown",
    style: {
      top: `${_vm.secondDropdownPosition.y}px`,
      left: `${_vm.secondDropdownPosition.x}px`
    },
    on: {
      "mousedown": function ($event) {
        $event.preventDefault();
      }
    }
  }, _vm._l(_vm.secondLevelDropdownData, function (item, id, index) {
    return _c('div', {
      key: 'second-level-' + id,
      ref: 'dropdownLevel2Item' + id,
      refInFor: true,
      staticClass: "group-or-user-select-dropdown-item group-or-user-select-dropdown-item-level-2",
      class: [`group-or-user-select-dropdown-item-${_vm.size}`, {
        'group-or-user-select-dropdown-item-active': _vm.keyboardNavigation && _vm.keyboardNavigation.selectedIndex === index && _vm.keyboardNavigation.level === 2
      }],
      style: {
        width: `${_vm.dropdownItemWidth}px`
      },
      on: {
        "click": function ($event) {
          return _vm.selectItem(id, item);
        }
      }
    }, [_vm.getSelectedItemIcon(id, item) ? _c('img', {
      class: `group-or-user-select-img-placeholder-${_vm.size}`,
      attrs: {
        "src": require(`@/assets/images/${_vm.getSelectedItemIcon(id, item)}.svg`),
        "alt": ""
      }
    }) : _c('div', {
      class: `group-or-user-select-img-placeholder-${_vm.size}`
    }), _c('p', {
      class: {
        'content-small': _vm.size === 'small',
        'content': _vm.size === 'large'
      }
    }, [_vm._v(_vm._s(item[_vm.secondLevelValueLabelKey]))])]);
  }), 0) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }