var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "comment-wrapper"
  }, [_vm.commentContent && !_vm.editComment ? _c('p', {
    staticClass: "content-normal",
    on: {
      "click": _vm.toggleEdit
    }
  }, [_vm._v(" " + _vm._s(_vm.commentContent) + " ")]) : _vm._e(), !_vm.editComment && !_vm.commentContent ? _c('div', {
    staticClass: "link-small",
    on: {
      "click": _vm.toggleEdit
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("addComment")) + " ")]) : _vm._e(), _vm.editComment ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.commentContent,
      expression: "commentContent"
    }],
    ref: "commentbox",
    attrs: {
      "rows": "4",
      "placeholder": _vm.$t('addComment') + '...'
    },
    domProps: {
      "value": _vm.commentContent
    },
    on: {
      "focusout": _vm.saveComment,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.commentContent = $event.target.value;
      }
    }
  }) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }