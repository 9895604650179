<template>
  <div id="invoice-overlay" :style="OverlayStyle">
    <div
        id="invoice-header"
        :class="{
        'project-sidepanel-open': projectClientAddSpidepanelOpen,
        'project-sidepanel-closed': !projectClientAddSpidepanelOpen,
      }"
        @click="closeSidepanels()"
        :style="sidewaysHeaderStyle"
        v-if="sidewaysHeader"
        class="invoice-panel-header-sideways"
    >
      <h4>
        {{
          !invoice
              ? $t("AddNewInvoice")
              : $t("EditInvoice") + " " + (invoice?.number ? invoice.number : "")
        }}
      </h4>
    </div>
    <div
        v-else
        id="invoice-panel"
        class="invoice-panel"
        v-click-outside="handleOutsideClick"
    >
      <div v-if="invoiceLoading" style="display: flex; justify-content: center; align-items: center; height: 100%">
        <div class="loader"></div>
      </div>
      <template v-else>
        <div v-if="invoice && !editInvoice" class="invoice-panel-header">
          <div
              style="display: flex; flex-direction: column; gap: 12px; width: 100%"
          >
            <v-row class="header-first-row">
              <div class="invoice-name-row">
                <h4>{{ $t("Invoice") }} {{ invoice?.number }}</h4>
                <DealTypeContainer
                    v-if="invoice?.project"
                    :small-font="false"
                    :deal-type="invoice?.project?.dealType"
                />
              </div>
              <div class="header-button-container">
                <BaseButton
                    v-if="hasAccess(invoice, 'invoices', 'edit')"
                    @click="setupEditFields"
                    :icon-left="'edit_16_16.svg'"
                    :label="$t('Edit')"
                    size="small"
                />
              </div>
            </v-row>
            <v-row class="header-second-row" style="margin-top: 0px">
              <div style="display: flex; gap: 8px; align-items: center">
                <img src="../../assets/images/projects.svg" alt=""/>
                <a
                    :href="
                    invoice.isUnit
                      ? path + '/property-development/' + developmentId
                      : path + '/projects/' + invoice?.project?._id + '/general'
                  "
                    @keyup.enter="openProject(invoice?.project._id)"
                    style="text-decoration: none"
                    :class="{
                    'link-small hoverable-info': invoice?.project,
                    'content-small cursor-none': !invoice?.project,
                  }"
                >
                  {{
                    invoice?.project?.name
                        ? invoice.project.name
                        : invoice?.project?.apartmentNumber
                            ? $t("Apartment") + " " + invoice.project.apartmentNumber
                            : "-"
                  }}
                </a>
                <div
                    v-if="invoice && invoice.project"
                    class="open-new-tab-button"
                    @click="openProjectNewTab(invoice?.project._id)"
                >
                  <img
                      style="width: 16px; height: 16px"
                      src="../../assets/images/open_new_tab.svg"
                      alt=""
                  />
                  <span>{{ $t("OpenInNewTab") }}</span>
                </div>
              </div>
            </v-row>
          </div>
        </div>
        <div v-else class="invoice-panel-header-add">
          <h4>
            {{
              !invoice
                  ? $t("AddNewInvoice")
                  : $t("EditInvoice") +
                  " " +
                  (invoice?.number ? invoice.number : "")
            }}
          </h4>
        </div>
        <div v-if="invoice && !editInvoice" class="invoice-panel-content">
          <div
              class="invoice-panel-content-info"
              :key="'invoice-content-' + invoiceContentUpdater"
          >
            <h5>{{ $t("MainInfo") }}</h5>
            <div class="invoice-panel-content-info-details">
              <div class="invoice-panel-content-info-row">
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("InvoiceDate") }}
                </div>
                <div class="content-normal" style="width: 50%">
                  {{ formatDate(invoice.invoiceDate) }}
                </div>
              </div>
              <div class="invoice-panel-content-info-row">
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("PaymentDueDate") }}
                </div>
                <div class="content-normal" style="width: 50%">
                  {{ formatDate(invoice.paymentDueDate) }}
                </div>
              </div>
              <div
                  v-if="hasAccess(invoice)"
                  class="invoice-panel-content-info-row"
              >
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("Client") }}
                </div>
                <div
                    class="content-normal"
                    style="width: 50%; display: flex; flex-direction: column"
                >
                  <div>
                    {{ invoice.client?.name }} {{ invoice.client?.organization }}
                  </div>
                  <div>
                    {{ invoice.client?.registryCode }}
                  </div>
                  <div>
                    {{ invoice.client?.personalCode }}
                  </div>
                  <div>
                    {{ invoice.client?.address }}
                  </div>
                </div>
              </div>
              <div class="invoice-panel-content-info-row">
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("InvoiceType") }}
                </div>
                <div class="content-normal" style="width: 50%">
                  {{ formatInvoiceType(invoice.type) }}
                </div>
              </div>
              <div
                  v-if="invoice.description"
                  class="invoice-panel-content-info-row"
              >
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("Description") }}
                </div>
                <div class="content-normal" style="width: 50%">
                  {{ invoice.description }}
                </div>
              </div>
              <div class="invoice-panel-content-info-row">
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("Status") }}
                </div>
                <div class="content-normal" style="width: 50%">
                  <StatusDropdown
                      :invoice="invoice"
                  />
                </div>
              </div>
              <div class="invoice-panel-content-info-row">
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("TotalSum") }}
                </div>
                <div class="content-normal" style="width: 50%">
                  <b>{{ $nMapper.price(invoice.sum, "€") }}</b>
                </div>
              </div>
              <div class="invoice-panel-content-info-row">
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("TotalSumWithVAT") }}
                </div>
                <div class="content-normal" style="width: 50%">
                  <b>{{ $nMapper.price(invoice.sumVAT, "€") }}</b>
                </div>
              </div>
              <div class="invoice-panel-content-info-row">
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("InvoiceOwner") }}
                </div>
                <div
                    class="content-normal"
                    style="width: 50%; display: flex; gap: 12px; flex-wrap: nowrap"
                >
                  <div style="width: 50%">
                    {{
                      invoice.broker ? invoice.broker.name : invoice.creator.name
                    }}
                  </div>
                  <div style="width: 50%">
                    {{
                      invoice.sharedBrokers && invoice.sharedBrokers.length > 0
                          ? `${invoice.broker.share.sum} € (${
                              formatDecimals(invoice.broker.share.percentage)
                          }%)`
                          : ""
                    }}
                  </div>
                </div>
              </div>
              <div
                  v-if="invoice.sharedBrokers && invoice.sharedBrokers.length > 0"
                  class="invoice-panel-content-info-row"
              >
                <div class="content-normal" style="width: 50%; color: #75787a">
                  {{ $t("Broker") }}
                </div>
                <div style="display: flex; flex-direction: column; width: 50%">
                  <div
                      v-for="(sharedBroker, index) of invoice.sharedBrokers"
                      :key="'invoice-share-' + sharedBroker._id + '-' + index"
                      class="content-normal"
                      style="
                      width: 100%;
                      display: flex;
                      gap: 12px;
                      flex-wrap: nowrap;
                    "
                  >
                    <div style="width: 50%">
                      {{
                        sharedBroker._id
                            ? sharedBroker.name
                            : $t(sharedBroker.name)
                      }}
                    </div>
                    <div style="width: 50%">
                      {{
                        `${sharedBroker.share.sum} € (${formatDecimals(
                            sharedBroker.share.percentage
                        )}%)`
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="invoice-panel-content-payments">
            <div style="display: flex; gap: 16px">
              <h5>{{ $t("Payments") }}</h5>
              <BaseButton
                  v-if="invoice.status !== 'paid' && hasAccess(invoice)"
                  @click="addPayment"
                  :icon-left="'add_16_16.svg'"
                  :label="$t('Add')"
                  size="small"
              />
            </div>
            <div v-if="invoice.payments.length > 0" style="width: fit-content">
              <div
                  class="payment-card"
                  v-for="(payment, index) of invoice.payments"
                  :key="'invoice-payment-' + index"
              >
                <p class="content-normal" style="width: 80px">
                  {{ formatDate(payment.date) }}
                </p>
                <div class="content-semibold" style="width: 100%">
                  {{ $nMapper.price(payment.sum, "€") }}
                </div>
                <div
                    v-if="hasAccess(invoice)"
                    @click="deletePayment(payment)"
                    class="invoice-payment-delete"
                >
                  <img
                      width="16"
                      height="16"
                      :src="require('@/assets/images/trash.svg')"
                      alt=""
                  />
                </div>
              </div>
            </div>
            <div v-else class="content-normal">
              {{ $t("InvoiceHasNoPaymentsYet") }}
            </div>
          </div>
        </div>
        <div v-else class="invoice-panel-content-add">
          <div class="invoice-panel-content-add-project">
            <div style="display: flex; flex-direction: column; gap: 12px">
              <div style="display: flex; gap: 12px">
                <BaseInput
                    :placeholder="$t('InvoiceNumber')"
                    v-model="invoiceNumber"
                    type="text"
                />
                <BaseDropdown
                    icon-left="calendar_20_20.svg"
                    :placeholder="$t('InvoiceDate')"
                    v-model="invoiceDate"
                    type="date"
                />
              </div>
              <div style="display: flex; gap: 12px">
                <div style="width: 50%">
                  <BaseInput
                      :placeholder="$t('PaymentTermInDays')"
                      v-model="paymentTermInDays"
                      type="number"
                      :error="PaymentTermInDaysError.showError"
                      :error-message="PaymentTermInDaysError.errorMessage"
                  />
                </div>
                <div style="width: 50%; padding: 14px 12px">
                  <div
                      class="content-semibold"
                      style="
                      width: fit-content;
                      padding: 2px 4px;
                      background-color: #fff0d4;
                      border-radius: 4px;
                    "
                  >
                    {{ $t("PaymentDate") }}:
                    {{
                      paymentTermInDays && invoiceDate && paymentDueDate
                          ? paymentDueDate
                          : "-"
                    }}
                  </div>
                </div>
              </div>
              <BaseProjectSearch
                  :icon-left="'search20.svg'"
                  :value="invoiceProject"
                  :get-client="true"
                  :placeholder="$t('Project')"
                  :show-completed-projects="false"
                  :dropdown-offset-y="52"
                  :key="'project-' + projectUpdater"
                  @change="
                  (item) => {
                    selectInvoiceProject(item);
                  }
                "
                  @addNew="openProjectAddSidepanel"
              />
              <BaseDropdown
                  :placeholder="$t('InvoiceType')"
                  v-model="invoiceType"
                  :dropdown-offset-y="60"
                  :show-dropdown-arrow="true"
                  type="dropdown"
                  :required="true"
                  :error-message="InvoiceTypeError.errorMessage"
                  :error="InvoiceTypeError.showError"
                  :items="invoiceTypes"
              />
              <BaseInput
                  :placeholder="$t('Description')"
                  v-model="invoiceDescription"
                  type="text"
              />
              <InvoiceSharing :date="invoiceDate" :error="VATError" :project="invoiceProject" @changed="(value) => invoiceSharesUpdated(value)" />
            </div>
          </div>
          <div
              :key="'client-section-' + clientUpdater"
              class="invoice-panel-content-add-client"
          >
            <h5>{{ $t("Client") }}</h5>
            <div>
              <v-radio-group v-model="invoiceClient.type" row>
                <v-radio
                    :label="$t('PrivatePerson.Label')"
                    :value="'privatePerson'"
                    color="orange darken-3"
                ></v-radio>
                <v-radio
                    v-if="invoiceClient.type !== 'generalOrganization'"
                    :label="$t('Company')"
                    :value="'estoniaOrganization'"
                    color="orange darken-3"
                ></v-radio>
                <v-radio
                    v-if="invoiceClient.type === 'generalOrganization'"
                    :label="$t('Company')"
                    :value="'generalOrganization'"
                    color="orange darken-3"
                ></v-radio>
              </v-radio-group>
              <div
                  style="display: flex; flex-direction: column; gap: 12px"
                  v-if="invoiceClient.type === 'privatePerson'"
              >
                <ClientListSearch
                    @itemChosen="selectClient"
                    @addNew="openClientAddSidepanel"
                    @removeValue="removePrivatePerson"
                    :icon-left="'search20.svg'"
                    :required="true"
                    v-model="invoiceClientSearch"
                    :error-message="ClientError.errorMessage"
                    :error="ClientError.showError"
                    :placeholder="$t('FullName')"
                />
                <BaseInput
                    :placeholder="$t('PersonalCode')"
                    v-model="invoiceClient.personalCode"
                    type="text"
                />
                <BaseInput
                    :placeholder="$t('Address')"
                    v-model="invoiceClient.address"
                    type="text"
                />
              </div>
              <div
                  style="display: flex; flex-direction: column; gap: 12px"
                  v-else
              >
                <div class="organization-wrapper">
                  <div class="organization-header">
                    <v-radio-group
                        v-model="invoiceClient.type"
                        @change="clientUpdater++"
                        hide-details
                        row
                    >
                      <v-radio
                          :label="$t('Estonian')"
                          color="orange darken-3"
                          :value="'estoniaOrganization'"
                      ></v-radio>
                      <v-radio
                          :label="$t('Generic')"
                          :value="'generalOrganization'"
                          color="orange darken-3"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                  <div class="organization-body">
                    <div v-if="invoiceClient.type === 'estoniaOrganization'">
                      <BaseDropdown
                          :placeholder="$t('OrganizationsName')"
                          v-model="invoiceClient"
                          :dropdown-allow-null="true"
                          type="organizationsearch"
                          :dropdown-offset-y="52"
                          :required="true"
                          :error-message="ClientError.errorMessage"
                          :error="ClientError.showError"
                          @change="invoiceClient.type = 'estoniaOrganization'"
                      />
                    </div>
                    <div v-else-if="invoiceClient.type === 'generalOrganization'">
                      <v-row>
                        <BaseInput
                            v-model="invoiceClient.organization"
                            :placeholder="$t('OrganizationsName')"
                            :required="true"
                            :error-message="ClientError.errorMessage"
                            :error="ClientError.showError"
                        />
                      </v-row>
                      <v-row>
                        <GoogleAddressSearch
                            v-model="invoiceClient.address"
                            :placeholder="
                            $t('Object') + ' ' + $t('Address').toLowerCase()
                          "
                        />
                      </v-row>
                      <v-row style="display: flex; flex-wrap: nowrap; gap: 12px">
                        <BaseInput
                            :placeholder="$t('CustomerField.phoneNumber')"
                            icon-left="phone_20_20.svg"
                            v-model="invoiceClient.phoneNum"
                        />
                        <BaseInput
                            :placeholder="$t('CustomerField.email')"
                            icon-left="email_20_20.svg"
                            v-model="invoiceClient.orgEmail"
                        />
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!invoice || (invoice && editInvoice)" class="invoice-panel-add-footer"
             :style="
            invoice && editInvoice
              ? 'justify-content: space-between;'
              : 'justify-content: flex-end;'
          "
        >
          <div style="justify-self: flex-start" v-if="invoice && editInvoice">
            <BaseButton
                v-if="hasAccess(invoice, 'invoices', 'delete')"
                size="large"
                :label="$t('Delete')"
                :icons-style="'width: 16px; height: 16px;'"
                :label-color="'#ff1e24'"
                :border-color="'#ff1e24'"
                @click="deleteInvoice"
                icon-left="red_trash_16_16.svg"
            />
          </div>
          <div style="display: flex; gap: 16px">
            <BaseButton size="large" :label="$t('Cancel')" @click="closeModal">
            </BaseButton>
            <BaseButton
                :icon-left="'checkmark_white_16_16.svg'"
                size="large"
                primary="true"
                :label="$t('Save')"
                @click="saveButtonClicked"
            >
            </BaseButton>
          </div>
        </div>
      </template>
      <InvoicePaymentWidget
          v-if="paymentWidgetOpen"
          :invoice="invoice"
          :pre-fill-unpaid="true"
          @updateInvoice="addPaymentConfirmed"
          @closeWidget="paymentWidgetOpen = false"
      />
      <DeleteModal
          v-if="invoiceDeleteModalOpen"
          @approved="deleteInvoiceConfirmed"
          @canceled="invoiceDeleteModalOpen = false"
      />
      <ConfirmationModal
          v-if="paymentToDelete"
          @onCancel="paymentToDelete = null"
          @onConfirm="deletePaymentConfirmed"
      >
        {{
          $t("RecordedPaymentFor") +
          " " +
          $nMapper.price(paymentToDelete.sum, "€") +
          " " +
          $t("WillBeDeleted")
        }}
      </ConfirmationModal>
      <ConfirmationModal
        v-if="invoiceSaveConfirmation"
        :isDecline="true"
        @onCancel="handleCancelInvoiceSave"
        @onDecline="handleDeclineInvoiceSave"
        @onConfirm="handleConfirm"
      >
        <h5 class="title-text" style="display: flex; width: 100%; padding-top: 10px;">
          {{ $t("DoYouWantToSaveInvoice") }}
        </h5>
      </ConfirmationModal>
    </div>
    <SidepanelAdd
      v-if="projectAddSidepanelOpen"
      :from-another-panel="true"
      @closed="closeProjectAddSidepanel"
      @openedClientAdd="projectSidepanelOpenedClientSidepanel"
      @closedClientAdd="projectSidepanelClosedClientSidepanel"
      @saveResponse="newProjectAdded"
    />
    <AddModal
      @newAdded="newClientAdded"
      @closed="closeClientAddSidepanel"
      :not-contact-view-route="true"
      v-if="clientAddSidepanelOpen"
    />
  </div>
</template>

<script>
import DealTypeContainer from "@/components/common/DealTypeContainer";
import BaseButton from "@/components/common/BaseButton";
import BaseDropdown from "@/components/common/BaseDropdown";
import moment from "moment";
import BaseInput from "@/components/common/BaseInput";
import BaseProjectSearch from "@/components/common/BaseProjectSearch";
import nanoid from "nanoid";
import ClientListSearch from "@/components/common/ClientListSearch";
import InvoicePaymentWidget from "@/components/Invoices/InvoicePaymentWidget";
import {mapActions, mapGetters} from "vuex";
import ConfirmationModal from "@/components/common/ConfirmationModal";
import DeleteModal from "@/components/common/DeleteModal";
import AddModal from "@/components/Kliendiregister/AddModal";
import SidepanelAdd from "@/components/Deals/SidepanelAdd";
import GoogleAddressSearch from "@/components/common/GoogleAddressSearch";
import StatusDropdown from "@/components/Invoices/StatusDropdown";
import axios from "axios";
import InvoiceSharing from "@/components/Invoices/InvoiceSharing";

export default {
  name: "InvoiceSidepanel",
  components: {
    InvoiceSharing,
    StatusDropdown,
    GoogleAddressSearch,
    SidepanelAdd,
    AddModal,
    DeleteModal,
    ConfirmationModal,
    ClientListSearch,
    BaseProjectSearch,
    BaseInput,
    BaseButton,
    BaseDropdown,
    DealTypeContainer,
    InvoicePaymentWidget,
  },
  props: ["project", "isUnit"],
  data() {
    return {
      OverlayStyle: {
        background: "#0000001a",
      },
      useAmountsInsteadOfPercentage: false,
      brokersUpdater: 0,
      editInvoice: false,
      invoiceDate: null,
      paymentTermInDays: null,
      paymentDueDate: null,
      invoiceProject: null,
      invoiceType: null,
      invoiceNumber: null,
      invoiceDescription: null,
      invoiceSumBeforeVAT: null,
      invoiceTotalSum: 0,
      invoiceClientSearch: null,
      editingInvoiceBrokerSums: null,
      invoiceBroker: {
        _id: null,
        share: {percentage: "", sum: 0, sumVAT: 0, type: "equal"},
      },
      invoiceSharedBrokers: null,
      invoiceClient: {
        name: "",
        _id: "",
        contactId: "",
        registryCode: "",
        personalCode: "",
        address: "",
        type: "privatePerson",
      },
      invoiceTypes: [
        {name: this.$t("RentalMediation"), value: "rental_mediation"},
        {name: this.$t("SalesMediation"), value: "sales_mediation"},
        {
          name: this.$t("RepresentationOfThePurchasingCustomer"),
          value: "representation_of_the_purchasing_customer",
        },
        {name: this.$t("Consultation"), value: "consultation"},
        {name: this.$t("ReferralFee"), value: "referral_fee"},
        {name: this.$t("Appraisal"), value: "appraisal"},
        {name: this.$t("Collaboration"), value: "collaboration"},
        {name: this.$t("Other"), value: "other"},
      ],
      colleagues: [],
      clientUpdater: 0,
      clientSearchUpdater: 0,
      projectUpdater: 0,
      invoiceContentUpdater: 0,
      paymentWidgetOpen: false,
      paymentToDelete: null,
      invoiceDeleteModalOpen: false,
      clientAddSidepanelOpen: false,
      projectAddSidepanelOpen: false,
      projectClientAddSpidepanelOpen: false,
      saveClicked: false,
      path: null,
      developmentId: null,
      VATError: {
        showError: false,
        errorMessage: "",
      },
      InvoiceTypeError: {
        showError: false,
        errorMessage: "",
      },
      ClientError: {
        showError: false,
        errorMessage: "",
      },
      PaymentTermInDaysError: {
        showError: false,
        errorMessage: "",
      },
      sidewaysHeader: false,
      sidewaysHeaderStyle: {},
      invoiceSaveConfirmation: false,
      isInvoiceSaved: false,
      outsideClickStopPropagation: false,
    };
  },
  async created() {
    if (!this.invoice) {
      this.invoiceDate = moment().format("YYYY-MM-DD");
      this.clientUpdater++;
      if (this.addInvoiceProject) {
        await this.selectInvoiceProject(this.addInvoiceProject);
      }
    }
    this.path = window.location.origin;
    if (this.invoice && this.invoice.isUnit) {
      const unitResponse = await axios.get(
          `/api/unit/name/${this.invoice.projectId}`
      );
      this.developmentId = unitResponse.data._id;
    }
    this.setupColleagues();

  },
  computed: {
    ...mapGetters(["invoice", "addInvoiceProject", "invoiceLoading", "customers", "user", "users","getInvoiceData"]),
    isNewInvoice() {
      return this.invoice === null && !this.invoiceLoading;
    },
  },
  watch: {
    isNewInvoice: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && !oldVal && !this.invoice) {
          this.setupEditFields();
        }
      }
    },
    paymentTermInDays(value) {
      if (value) {
        if (!this.checkForPaymentDueDateErrors() && Math.sign(value) === 1) {
          this.paymentDueDate = moment(this.invoiceDate)
              .add(parseInt(value), "days")
              .format("DD.MM.YYYY");
        }
      } else {
        this.checkForPaymentDueDateErrors();
        this.paymentDueDate = null;
      }
    },
    invoiceDate() {
      if (this.paymentTermInDays) {
        if (
            !this.checkForPaymentDueDateErrors() &&
            Math.sign(this.paymentTermInDays) === 1
        ) {
          this.paymentDueDate = moment(this.invoiceDate)
              .add(parseInt(this.paymentTermInDays), "days")
              .format("DD.MM.YYYY");
        } else {
          this.paymentDueDate = null;
        }
      } else {
        this.checkForPaymentDueDateErrors();
      }
    },
    "invoiceClient.name": function () {
      if (this.ClientError.showError) this.checkForErrors(false);
    },
    "invoiceClient.type": function () {
      if (this.ClientError.showError) this.checkForErrors();
    },
    "invoiceClient.req_code": function () {
      if (this.ClientError.showError) this.checkForErrors();
    },
    "invoiceClient.organization": function () {
      if (this.ClientError.showError) this.checkForErrors();
    },
    invoiceType: function () {
      if (this.InvoiceTypeError.showError) this.checkForErrors(false);
    },
    invoiceTotalSum() {
      if (this.VATError.showError) {
        this.VATError.showError = false;
        this.VATError.errorMessage = "";
      }
    },
    editInvoice(newVal) {
      if (newVal === true) {
        this.outsideClickStopPropagation = false
      }
    }
  },
  methods: {
    ...mapActions(["getCustomers", "closeInvoiceSidepanel", "fetchInvoice", "invoiceUpdated","setInvoiceData","clearInvoiceData"]),
    handleOutsideClick() {
      if (!this.invoice) {
        if (!this.outsideClickStopPropagation) {
          this.invoiceSaveConfirmation = true;
          this.outsideClickStopPropagation = true;
        } else {
          this.outsideClickStopPropagation = false;
        }
      } else {
        this.closeModal()
      }
    },
    handleCancelInvoiceSave() {
      this.invoiceSaveConfirmation = false;
    },
    handleDeclineInvoiceSave() {
      this.closeInvoiceSidepanel(["invoices"]);
      this.clearInvoiceData(this.invoice?._id ? this.invoice?._id : 'newInvoice')
      this.invoiceSaveConfirmation = false;
      this.editInvoice = false;
    },
    async handleConfirm() {
      const selectedBroker = this.users.find(user => user._id === this.invoiceBroker._id);
      const brokerObject = {
          name: selectedBroker.name,
          userId: selectedBroker._id,
          email: selectedBroker.email,
          share: this.invoiceBroker.share,
      };
      let clientObject = this.invoiceClient;
      if (this.invoiceClient.type !== "privatePerson") {
          clientObject = {
              _id: this.invoiceClient._id,
              type: this.invoiceClient.type,
              reg_code: this.invoiceClient.reg_code,
              organization: this.invoiceClient.organization,
              address: this.invoiceClient.address,
              phoneNum: this.invoiceClient.phoneNum,
              orgEmail: this.invoiceClient.orgEmail,
          };
      }

      const newInvoiceObject = {
          invoiceDate: this.invoiceDate,
          paymentTermInDays: this.paymentTermInDays,
          creator: {
              name: this.user.name,
              userId: this.user._id,
              companyId: this.user.companyId,
          },
          project: this.invoiceProject,
          client: clientObject,
          broker: brokerObject,
          sharedBrokers: this.setupRevenueShareData(),
          type: this.invoiceType,
          number: this.invoiceNumber,
          description: this.invoiceDescription,
          sum: parseFloat(this.invoiceSumBeforeVAT).toFixed(2),
          sumVAT: this.invoiceTotalSum,
          status: "not_paid",
          paid: 0,
          paidVAT: 0,
          useAmountsInsteadOfPercentage: this.useAmountsInsteadOfPercentage,
          unpaid: parseFloat(this.invoiceSumBeforeVAT).toFixed(2),
          unpaidVAT: this.invoiceTotalSum,
          isUnit: this.isUnit,
      };
      this.setInvoiceData({ id: this.invoice?._id ? this.invoice?._id : 'newInvoice', invoiceData: newInvoiceObject });
      this.closeInvoiceSidepanel(["invoices"]);
      this.invoiceSaveConfirmation = false;
    },
    invoiceSharesUpdated(value) {
      this.invoiceBroker = value.owner;
      this.invoiceSharedBrokers = value.sharedUsers;
      this.invoiceSumBeforeVAT = value.sum;
      this.invoiceTotalSum = value.sumVAT;
      this.useAmountsInsteadOfPercentage = value.useAmountsInsteadOfPercentage;
    },
    checkVatError() {
      if (!this.invoiceSumBeforeVAT) {
        this.VATError.errorMessage = this.$t("FormErrors.FieldRequired");
        this.VATError.showError = true;
        return;
      } else if (Math.sign(this.invoiceSumBeforeVAT) !== 1) {
        this.VATError.errorMessage = this.$t("FieldCantBeNegativeOrNull");
        this.VATError.showError = true;
        return;
      } else if (this.editInvoice) {
        if (this.invoice?.paid > this.invoiceSumBeforeVAT) {
          this.VATError.errorMessage =
              this.$t("InvoiceAlreadyHasPaymentsFor") +
              " " +
              `${this.$nMapper.price(this.invoice.paid, "€")}`;
          this.VATError.showError = true;
          return;
        } else {
          this.VATError.showError = false;
          this.VATError.errorMessage = "";
        }
      } else {
        this.VATError.showError = false;
        this.VATError.errorMessage = "";
      }

      let sum = parseFloat(this.invoiceSumBeforeVAT);
      let brokersTotalSum = 0;
      if (this.invoiceSharedBrokers && this.invoiceSharedBrokers.length > 0) {
        for (const broker of this.invoiceSharedBrokers) {
          if (broker.share.sumFull && broker.share.sumFull > 0) {
            brokersTotalSum += broker.share.sumFull
          }
        }
        if (this.invoiceBroker.share.sumFull && this.invoiceBroker.share.sumFull > 0) {
          brokersTotalSum += this.invoiceBroker.share.sumFull;
        }
        sum = parseFloat(sum.toFixed(2))
        brokersTotalSum = parseFloat(brokersTotalSum.toFixed(2))
        if (brokersTotalSum > sum) {
          this.VATError.showError = true;
          this.VATError.errorMessage = this.$t("InvoiceSumLowerThanSharedValues") + ": " + `${this.$nMapper.price(parseFloat((brokersTotalSum - sum).toFixed(2)), "€")}`;
        } else if (sum > brokersTotalSum) {
          this.VATError.showError = true;
          this.VATError.errorMessage = this.$t("InvoiceSumHigherThanSharedValues") + ": " + `${this.$nMapper.price(parseFloat((sum - brokersTotalSum).toFixed(2)), "€")}`;
        } else {
          this.VATError.showError = false;
          this.VATError.errorMessage = ""
        }
      }

    },
    formatFixed(value, toFixedDecimalCount) {
      let num = value;

      // Handle undefined or null values
      if (num === undefined || num === null) return num;

      // Convert to number
      num = Number(num);

      // Check if it's a whole number
      if (num % 1 !== 0) {
        // If not, return it with 2 decimal places
        return num.toFixed(toFixedDecimalCount);
      } else {
        // If it is, return it without decimal places
        return num.toFixed(0);
      }
    },
    removePrivatePerson() {
      this.invoiceClient = {
        name: "",
        _id: "",
        contactId: "",
        registryCode: "",
        personalCode: "",
        address: "",
        type: "privatePerson",
      };
    },
    async setupColleagues() {
      const loggedUserGroupIds = [];
      this.user.groups.forEach((group) => {
        loggedUserGroupIds.push(group._id);
      });
      this.users.forEach((user) => {
        if (
            !user.groups.find((group) => loggedUserGroupIds.includes(group._id))
        ) {
          return;
        }
        if (user.userStatus === "Active") {
          const formattedUser = {
            value: user._id,
            name: user.name,
          };
          this.colleagues.push(formattedUser);
        }
      });
      this.colleagues.sort((a, b) => a.name.localeCompare(b.name));

      if (!this.invoiceBroker._id) {
        if (this.invoice) {
          if (this.invoice.broker) {
            this.invoiceBroker._id = this.invoice.broker.userId;
          } else {
            this.invoiceBroker._id = this.invoice.creator.userId;
          }
        } else {
          this.invoiceBroker._id = this.user._id;
        }
      }
    },
    async selectInvoiceProject(item) {
      const itemProject = JSON.parse(JSON.stringify(item));
      delete itemProject.client;
      this.invoiceProject = itemProject;
      if (item.client) {
        this.invoiceClientSearch = null;
        await this.newClientAdded(item.client.contactId);
        this.clientUpdater++;
      }
    },
    checkForPaymentDueDateErrors() {
      if (this.paymentTermInDays) {
        if (!this.invoiceDate) {
          this.PaymentTermInDaysError.showError = true;
          this.PaymentTermInDaysError.errorMessage =
              this.$t("InvoiceDateMissing");
          this.paymentDueDate = null;
        } else if (Math.sign(this.paymentTermInDays) !== 1) {
          this.PaymentTermInDaysError.showError = true;
          this.PaymentTermInDaysError.errorMessage = this.$t(
              "FieldCantBeNegativeOrNull"
          );
          this.paymentDueDate = null;
        } else if (this.paymentTermInDays > 30000) {
          this.PaymentTermInDaysError.showError = true;
          this.PaymentTermInDaysError.errorMessage = this.$t(
              "FieldValueCantBeTooLarge"
          );
          this.paymentDueDate = null;
        } else {
          this.PaymentTermInDaysError.showError = false;
          this.PaymentTermInDaysError.errorMessage = "";
        }
      } else {
        this.PaymentTermInDaysError.showError = false;
        this.PaymentTermInDaysError.errorMessage = "";
      }
      return this.PaymentTermInDaysError.showError;
    },
    closeModal(event = null) {
      if (
          this.invoiceDeleteModalOpen ||
          (event && event.target?.id === "cancel-button")
      ) {
        return;
      }

      if (this.clientAddSidepanelOpen) {
        return;
      }

      if (this.editInvoice) {
        this.editInvoice = false;
        return;
      }

      if (!event) {
        this.closeInvoiceSidepanel(["invoices"]);
        this.$emit('closePanel');
        return;
      }

      if (this.paymentWidgetOpen) {
        return;
      }

      if (event.target.id !== "invoice-overlay") return;
      this.closeInvoiceSidepanel(["invoices"]);
      document.documentElement.style.overflow = "visible"
    },
    async openProject(projectId) {
      const isUnit = this.invoice.isUnit;
      if (isUnit) {
        this.$router
            .push({
              name: "DevelopmentGeneral",
              params: {id: this.developmentId},
            })
            .then(() => {
            });
      } else {
        this.$router
            .push({
              name: "DealDetail",
              params: {id: projectId},
            })
            .then(() => {
            });
      }
    },
    async openProjectNewTab(projectId) {
      const isUnit = this.invoice.isUnit;
      if (isUnit) {
        const routeData = this.$router.resolve({
          name: "DevelopmentGeneral",
          params: {id: this.developmentId},
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "DealDetail",
          params: {id: projectId},
        });
        window.open(routeData.href, "_blank");
      }
    },
    checkForErrors(checkClick = true) {
      if (checkClick && !this.saveClicked) return;
      if (!this.invoiceType) {
        this.InvoiceTypeError.errorMessage = this.$t(
            "FormErrors.FieldRequired"
        );
        this.InvoiceTypeError.showError = true;
      } else if (this.invoiceType) {
        this.InvoiceTypeError.errorMessage = "";
        this.InvoiceTypeError.showError = false;
      }
      this.checkVatError()
      if (this.invoiceClient.type === "privatePerson") {
        if (!this.invoiceClient.name) {
          this.ClientError.errorMessage = this.$t("FormErrors.FieldRequired");
          this.ClientError.showError = true;
        } else {
          this.ClientError.errorMessage = "";
          this.ClientError.showError = false;
        }
      } else if (this.invoiceClient.type === "estoniaOrganization") {
        if (!this.invoiceClient.reg_code) {
          this.ClientError.errorMessage = this.$t("FormErrors.FieldRequired");
          this.ClientError.showError = true;
        } else {
          this.ClientError.errorMessage = "";
          this.ClientError.showError = false;
        }
      } else {
        if (!this.invoiceClient.organization) {
          this.ClientError.errorMessage = this.$t("FormErrors.FieldRequired");
          this.ClientError.showError = true;
        } else {
          this.ClientError.errorMessage = "";
          this.ClientError.showError = false;
        }
      }
      return !!(
          this.VATError.showError ||
          this.ClientError.showError ||
          this.InvoiceTypeError.showError
      );
    },
    formatDate(date) {
      return date ? moment(date).format("DD.MM.YYYY") : "-";
    },
    formatInvoiceType(type) {
      switch (type) {
        case "rental_mediation":
          return this.$t("RentalMediation");
        case "sales_mediation":
          return this.$t("SalesMediation");
        case "representation_of_the_purchasing_customer":
          return this.$t("RepresentationOfThePurchasingCustomer");
        case "consultation":
          return this.$t("Consultation");
        case "referral_fee":
          return this.$t("ReferralFee");
        case "appraisal":
          return this.$t("Appraisal");
        case "collaboration":
          return this.$t("Collaboration");
        case "other":
          return this.$t("Other");
        default:
          return "-";
      }
    },
    async newClientAdded(clientId) {
      if (!clientId) return;
      await this.getCustomers();
      const client = this.customers.find(
          (customer) => customer.customerId === clientId
      );
      this.invoiceClient._id = client._id;
      this.invoiceClient.contactId = client.customerId;
      this.invoiceClient.name = client.firstName + " " + client.lastName;
      this.invoiceClient.personalCode = client.personalCode;
      this.invoiceClient.address = client.address;

      this.invoiceClientSearch = this.invoiceClient;
      this.invoiceClientSearch.contactName = this.invoiceClient.name;
    },
    async newProjectAdded(deal) {
      if (!deal) return;
      await this.closeProjectAddSidepanel();
      this.invoiceProject = {
        _id: deal._id,
        name: deal.name,
        dealType: deal.dealType,
        pipelineStep: deal.pipelineStep,
        apartmentNumber: deal.apartmentNumber,
      };
      this.projectUpdater++;
    },
    selectClient(client) {
      if (!client) return;
      this.invoiceClient._id = client._id;
      this.invoiceClient.contactId = client.customerId;
      this.invoiceClient.name = client.firstName + " " + client.lastName;
      if (client.personalCode)
        this.invoiceClient.personalCode = client.personalCode;
      if (client.address) this.invoiceClient.address = client.address;
    },
    openProjectAddSidepanel() {
      this.OverlayStyle.background = "#00000000";
      this.sidewaysHeaderStyle.right = "560px";
      this.sidewaysHeaderStyle.zIndex = "12";
      this.sidewaysHeader = true;
      this.projectAddSidepanelOpen = true;

      this.$nextTick(() => {
        const brokerHeader = document.getElementById("broker-invoice-header");
        if (brokerHeader) {
          brokerHeader.style.display = "none";
        }
      });
    },
    closeProjectAddSidepanel() {
      this.OverlayStyle.background = "#0000001a";
      this.sidewaysHeader = false;
      this.projectAddSidepanelOpen = false;
      this.$nextTick(() => {
        const brokerHeader = document.getElementById("broker-invoice-header");
        if (brokerHeader) {
          brokerHeader.style.display = "flex";
        }
      });
    },
    openClientAddSidepanel() {
      this.OverlayStyle.background = "#00000000";
      this.sidewaysHeaderStyle.right = "640px";
      this.sidewaysHeaderStyle.zIndex = "12";
      this.sidewaysHeader = true;
      this.clientAddSidepanelOpen = true;

      this.$nextTick(() => {
        const brokerHeader = document.getElementById("broker-invoice-header");
        if (brokerHeader) {
          const invoiceHeader = document.getElementById("invoice-header");
          const invoiceOverlay = document.getElementById("invoice-overlay");
          invoiceOverlay.append(invoiceHeader);
        }
      });
    },
    closeClientAddSidepanel() {
      this.OverlayStyle.background = "#0000001a";
      this.sidewaysHeader = false;
      this.clientAddSidepanelOpen = false;
    },
    closeSidepanels() {
      this.OverlayStyle.background = "#0000001a";
      this.projectClientAddSpidepanelOpen = false;
      this.projectAddSidepanelOpen = false;
      this.clientAddSidepanelOpen = false;
      this.sidewaysHeader = false;
      this.$nextTick(() => {
        const brokerHeader = document.getElementById("broker-invoice-header");
        if (brokerHeader) {
          brokerHeader.style.display = "flex";
        }
      });
    },
    saveButtonClicked() {
      this.saveClicked = true;
      if (!this.editInvoice) {
        this.createNewInvoice();
      } else {
        this.editCurrentInvoice();
      }
      this.saveClicked = false;
    },
    projectSidepanelOpenedClientSidepanel() {
      this.sidewaysHeaderStyle.right = "732px";
      this.sidewaysHeaderStyle.zIndex = "102";
      this.projectClientAddSpidepanelOpen = true;
    },
    projectSidepanelClosedClientSidepanel() {
      this.sidewaysHeaderStyle.right = "560px";
      this.projectClientAddSpidepanelOpen = false;
    },
    setupRevenueShareData() {
      const returnArray = [];
      if (this.invoiceSharedBrokers && this.invoiceSharedBrokers.length > 0) {
        for (const broker of this.invoiceSharedBrokers) {
          delete broker.items;
          if (broker._id) {
            let colleague = this.users.find((user) => user._id === broker._id);
            broker.name = colleague.name;
            broker.email = colleague.email;
          } else {
            broker.name = "ThirdParty";
          }
          returnArray.push(broker);
        }
      }
      return returnArray;
    },
    async createNewInvoice() {
      const selectedBroker = this.users.find(
          (user) => user._id === this.invoiceBroker._id
      );
      const brokerObject = {
        name: selectedBroker.name,
        userId: selectedBroker._id,
        email: selectedBroker.email,
        share: this.invoiceBroker.share,
      };

      if (this.invoiceClient.type !== "privatePerson") {
        this.invoiceClient = {
          _id: this.invoiceClient._id,
          type: this.invoiceClient.type,
          reg_code: this.invoiceClient.reg_code,
          organization: this.invoiceClient.organization,
          address: this.invoiceClient.address,
          phoneNum: this.invoiceClient.phoneNum,
          orgEmail: this.invoiceClient.orgEmail,
        };
      }

      const newInvoiceObject = {
        invoiceDate: this.invoiceDate,
        paymentTermInDays: this.paymentTermInDays,
        creator: {
          name: this.user.name,
          userId: this.user._id,
          companyId: this.user.companyId,
        },
        project: this.invoiceProject,
        client: this.invoiceClient,
        broker: brokerObject,
        sharedBrokers: this.setupRevenueShareData(),
        type: this.invoiceType,
        number: this.invoiceNumber,
        description: this.invoiceDescription,
        sum: parseFloat(this.invoiceSumBeforeVAT).toFixed(2),
        sumVAT: this.invoiceTotalSum,
        status: "not_paid",
        paid: 0,
        paidVAT: 0,
        useAmountsInsteadOfPercentage: this.useAmountsInsteadOfPercentage,
        unpaid: parseFloat(this.invoiceSumBeforeVAT).toFixed(2),
        unpaidVAT: this.invoiceTotalSum,
        isUnit: this.isUnit,
      };

      if (this.checkForErrors()) {
        return;
      }
      const response = await axios.post("/api/invoice", newInvoiceObject);
      if (response && response.status === 200) {
        this.closeInvoiceSidepanel(["invoices"])
        this.invoiceUpdated()
        document.documentElement.style.overflow = "visible";
        this.toastSuccess(
            this.$t("Invoice") + " " + this.$t("Created").toLowerCase()
        );
      } else {
        this.toastError(this.$t("ErrorCreating"));
      }
      this.saveClicked = false;
    },
    async editCurrentInvoice() {
      const selectedBroker = this.users.find(
          (user) => user._id === this.invoiceBroker._id
      );
      const brokerObject = {
        name: selectedBroker.name,
        userId: selectedBroker._id,
        email: selectedBroker.email,
        share: this.invoiceBroker.share,
      };

      const updatedInvoiceObject = {
        invoiceDate: this.invoiceDate,
        paymentTermInDays: this.paymentTermInDays,
        sharedBrokers: this.setupRevenueShareData(),
        project: this.invoiceProject,
        broker: brokerObject,
        client: this.invoiceClient,
        type: this.invoiceType,
        number: this.invoiceNumber,
        description: this.invoiceDescription,
        useAmountsInsteadOfPercentage: this.useAmountsInsteadOfPercentage,
        sum: this.invoiceSumBeforeVAT,
        sumVAT: this.invoiceTotalSum,
      };

      if (this.checkForErrors()) {
        return;
      }
      let response;
      if (this.invoice?._id) {
        response = await axios.post(
          `/api/invoice/${this.invoice._id}`,
          updatedInvoiceObject
        );
      }
      if (
          response &&
          response.status === 200 &&
          response.data !== "paid_amount_larger_than_total"
      ) {
        if (this.invoice?._id) {
          await this.fetchInvoice(this.invoice._id);
        }
        await this.invoiceUpdated()
        document.documentElement.style.overflow = "visible";
        this.toastSuccess(
            this.$t("Invoice") + " " + this.$t("Updated").toLowerCase()
        );
        this.editInvoice = false;
      } else {
        this.toastError(this.$t("ErrorUpdating"));
      }
      this.saveClicked = false;
    },
    async setupEditFields() {
      let invoice;
      if (!this.invoice) {
        if (this.getInvoiceData('newInvoice')) {
          invoice = JSON.parse(JSON.stringify(this.getInvoiceData('newInvoice')));
        } else {
          return;
        }
      } else {
        this.editInvoice = true;
        invoice = JSON.parse(JSON.stringify(this.invoice));
      }
      this.invoiceDate = moment(invoice.invoiceDate).format("YYYY-MM-DD");
      this.paymentTermInDays = invoice.paymentTermInDays;
      this.paymentDueDate = invoice.paymentDueDate
          ? moment(invoice.paymentDueDate).format("DD.MM.YYYY")
          : null;
      this.invoiceProject = invoice.project;
      this.invoiceType = invoice.type;
      this.invoiceNumber = invoice.number;
      this.invoiceDescription = invoice.description;
      this.invoiceSumBeforeVAT = invoice.sum;
      this.invoiceTotalSum = invoice.sumVAT;
      this.invoiceClient = invoice.client;
      this.invoiceSharedBrokers = invoice.sharedBrokers;
      let share = invoice.broker.share;
      if (!share) {
        share = {percentage: "", sum: 0, sumVAT: 0, type: "equal"};
      }

      this.invoiceBroker = {
        _id: invoice.broker.userId,
        share: share,
        ...invoice.broker,
      };
      if (invoice.client.name) this.invoiceClientSearch = invoice.client.name;
    },
    addPayment() {
      this.paymentWidgetOpen = true;
    },
    async addPaymentConfirmed(data) {
      const payment = {
        ...data,
        _id: nanoid(6),
      };
      let response;
      if (this.invoice?._id) {
        response = await axios.post(
          `/api/invoice/add/payment/${this.invoice?._id}`,
          payment
        );
      }
      if (response && response.status === 200 && response.data !== "overpaid") {
        this.toastSuccess(
            this.$t("Payment") + " " + this.$t("Added").toLowerCase()
        );
        if (this.invoice?._id) {
          await this.fetchInvoice(this.invoice._id);
        }
        this.invoiceUpdated()
      } else {
        this.toastError(this.$t("ErrorAdding"));
      }
    },
    deletePayment(payment) {
      this.paymentToDelete = payment;
    },
    async deletePaymentConfirmed() {
      let response;
      if (this.invoice?._id) {
        response = await axios.post(
          `/api/invoice/delete/payment/${this.invoice?._id}`,
          this.paymentToDelete
        );
      }
      if (
          response &&
          response.status === 200 &&
          response.data !== "payment_does_not_exist"
      ) {
        this.toastSuccess(
            this.$t("Payment") +
            " " +
            this.$t("Succesfully").toLowerCase() +
            " " +
            this.$t("Deleted").toLowerCase()
        );
        if (this.invoice?._id) {
          await this.fetchInvoice(this.invoice._id);
        }
        this.invoiceUpdated()
      } else {
        this.toastError(this.$t("ErrorDeleting"));
      }
      this.paymentToDelete = null;
    },
    deleteInvoice() {
      this.invoiceDeleteModalOpen = true;
    },
    async deleteInvoiceConfirmed() {
      const updatedInvoiceObject = {
        statusCode: 0,
      };
      let response
      if (this.invoice?._id) {
        response = await axios.post(
          `/api/invoice/${this.invoice._id}`,
          updatedInvoiceObject
        );
      }
      if (response && response.status === 200) {
        this.$emit("deleteInvoice");
        this.toastSuccess(
            this.$t("Invoice") + " " + this.$t("Deleted").toLowerCase()
        );
        this.closeInvoiceSidepanel(['invoices'])
      } else {
        this.toastError(this.$t("ErrorDeleting"));
      }
      this.invoiceDeleteModalOpen = false;
    },
    formatDecimals(value) {
      const num = parseFloat(value);
      if (isNaN(num)) {
        return value;
      }
      if (Math.floor(num) === num) {
        return num;
      }
      else if (Math.floor(num * 10) === num * 10) {
        return num.toFixed(1);
      }
      else {
        return num.toFixed(2);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.organization-body {
  margin-top: 12px;
}

.organization-header {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.organization-wrapper {
  background-color: $grey;
  border-radius: 8px;
  padding: 16px;
}

#invoice-overlay {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8;
  width: unset;
  left: 0;
}

.invoice-panel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  bottom: 0;
  background-color: #ffffff;
  z-index: 13;
  width: 640px;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.invoice-panel-header {
  padding: 40px 32px 32px 32px;
  background-color: white;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid #e6e8ec;
}

.invoice-panel-content {
  padding: 32px 72px 0 32px;
}

.invoice-panel-content-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e6e8ec;
}

.invoice-panel-content-info-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.invoice-panel-content-info-row {
  display: flex;
  flex-wrap: nowrap;
}

.invoice-panel-content-payments {
  padding-top: 24px;
  padding-bottom: 72px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payment-card {
  border-left: 1px solid #e6e8ec;
  border-top: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  padding: 14px 12px;
  display: flex;
  width: 100%;
  gap: 24px;
}

.payment-card:first-of-type {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.payment-card:last-of-type {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom: 1px solid #e6e8ec;
}

.invoice-payment-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f5f7;
  border-radius: 100px;
  height: 24px;
  min-width: 24px;
  width: 24px;
  cursor: pointer;
}

.invoice-payment-delete:hover {
  background-color: #ff1e24;
}

.invoice-payment-delete:hover img {
  filter: invert(100%) sepia(0) saturate(0) hue-rotate(317deg) brightness(103%) contrast(102%);
}

.invoice-label-paid {
  background-color: #b5e7cf;
}

.invoice-label-not-paid {
  background-color: #ffb4b6;
  cursor: pointer;
}

.invoice-label-make-invoice {
  background-color: #e6e8ec;
  cursor: pointer;
}

.invoice-label-partially-paid {
  background-color: #fff2b3;
}

.invoice-panel-header-add {
  position: fixed;
  width: 640px;
  z-index: 100;
  height: 96px;
  padding: 40px 32px 32px 32px;
  background-color: white;
  border-bottom: 1px solid #e6e8ec;
}

.invoice-panel-content-add-project {
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #fafafa;
  padding: 32px 72px 32px 32px;
}

.invoice-panel-content-add-client {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 72px 32px 32px;
}

.invoice-panel-content-add {
  height: 100%;
  padding-top: 97px;
  padding-bottom: 113px;
  overflow-y: scroll;
}

.invoice-panel-add-footer {
  display: flex;
  position: fixed;
  width: 640px;
  bottom: 0;
  background-color: white;
  gap: 16px;
  padding: 32px 32px 40px 32px;
  height: 112px;
  border-top: 1px solid #e6e8ec;
}

.invoice-type-dropdown {
  cursor: pointer;
  border-radius: 8px;
  position: absolute;
  top: 20px;
  background-color: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  outline: none;
  z-index: 7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
  0 0 1px rgba(0, 0, 0, 0.04);
}

.invoice-type-dropdown p {
  border-radius: 4px;
  color: black;
  min-width: 116px;
  text-align: center;
}

.invoice-panel-header-sideways {
  width: 98px;
  height: 100%;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 48px 24px 24px;
  cursor: pointer;
  border-right: 1px solid $grey500;

  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.invoice-panel-header-sideways h4 {
  transform: rotate(180deg);
  white-space: nowrap;
  overflow: hidden;
  align-self: center;
  justify-self: center;
  writing-mode: vertical-lr;
  top: 0;
  left: 0;
}

.project-sidepanel-open {
  right: 732px;
}

.project-sidepanel-closed {
  right: 560px;
}

.hoverable-info {
  cursor: pointer;
  background-color: #f4f5f7;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  width: max-content;

  &:hover {
    background-color: #ff5c01;
    color: #ffffff;
    font-weight: bold;
  }
}

.open-new-tab-button {
  min-width: 20px;
  height: 20px;
  padding: 2px;
  cursor: pointer;
  border: 1px solid #e6e8ec;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px solid #000000;
    background: #000000;

    img {
      filter: invert(100%) sepia(22%) saturate(0%) hue-rotate(78deg) brightness(109%) contrast(101%);
    }

    span {
      padding: 2px;
      max-width: 7rem;
    }
  }
}

.open-new-tab-button span {
  max-width: 0;
  -webkit-transition: max-width 0.1s;
  transition: max-width 0.1s;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  overflow: hidden;
  color: #ffffff;
  font-size: 12px;
}

.cursor-none {
  cursor: auto;
}

.invoice-name-row {
  gap: 12px;
  display: flex;
  align-items: center;
}

.header-first-row {
  display: flex;
  justify-content: space-between;
}

.invoice-panel-add-another-broker-button {
  width: fit-content;
  display: flex;
  gap: 12px;
  outline: none;

  img {
    height: 20px;
    width: 20px;
  }

  span {
    text-decoration: underline;
  }
}
</style>
