var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-overlay",
    attrs: {
      "id": "widget-overlay"
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    attrs: {
      "id": "widget"
    }
  }, [_c('v-row', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        _vm.showTypes = false;
      },
      expression: "\n        () => {\n          showTypes = false;\n        }\n      "
    }],
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.showTypes = !_vm.showTypes;
      }
    }
  }, [_c('div', {
    staticClass: "field-icon-con"
  }, [_c('div', {
    staticClass: "type-con",
    style: 'background: ' + _vm.eventTypes.find(function (e) {
      return e.value === _vm.task.type;
    }).color
  }), _c('div', {
    staticClass: "dropdown-single scrollable",
    style: 'z-index: 1;' + (_vm.showTypes ? 'display: flex;' : 'display: none;')
  }, _vm._l(_vm.taskEventTypes, function (type) {
    return _c('div', {
      key: type.value,
      staticClass: "dropdown-item-wrapper",
      on: {
        "click": function ($event) {
          _vm.task.type = type.value;
          _vm.showTypes = true;
          _vm.updateTask();
        }
      }
    }, [_c('p', {
      style: _vm.task.type === type.value ? 'font-weight: bold' : null
    }, [_vm._v(" " + _vm._s(type.name) + " ")])]);
  }), 0)]), _c('v-col', [_c('v-row', {
    staticClass: "entry-field-label"
  }, [_vm._v(" " + _vm._s(_vm.$t("Type")) + " ")]), _vm._v(" " + _vm._s(_vm.eventTypes.find(function (e) {
    return e.value === _vm.task.type;
  }).name) + " ")], 1)], 1), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "field-icon-con"
  }, [_c('date-pick', {
    on: {
      "input": function (date) {
        _vm.setDate(date);
        _vm.updateTask();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var toggle = _ref.toggle;
        return [_c('img', {
          staticClass: "field-icon",
          attrs: {
            "src": require("../../assets/images/calendar.svg")
          },
          on: {
            "click": toggle
          }
        })];
      }
    }])
  })], 1), _c('span', {
    staticClass: "flex justify-space-between"
  }, [_c('v-col', [_c('date-pick', {
    on: {
      "input": function (date) {
        _vm.setDate(date);
        _vm.updateTask();
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var toggle = _ref2.toggle;
        return [_c('div', {
          staticClass: "entry-field-label",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": toggle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Field.dueDate")) + " ")]), _c('div', {
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": toggle
          }
        }, [_vm.task.dates.endDate ? _c('span', {
          staticStyle: {
            "font-size": "12px"
          }
        }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.task.dates.endDate)) + " ")]) : _vm._e()])];
      }
    }])
  })], 1), _vm.task.category === 'task' && _vm.task.dates.endDate !== null ? _c('span', {
    staticStyle: {
      "width": "16px",
      "height": "16px",
      "cursor": "pointer"
    }
  }, [_c('img', {
    staticClass: "icon-img",
    staticStyle: {
      "background-color": "#e6e8ec",
      "border-radius": "4px",
      "margin-right": "4px"
    },
    attrs: {
      "src": require(`@/assets/images/close.svg`),
      "alt": ""
    },
    on: {
      "click": function ($event) {
        _vm.task.dates.endDate = null;
        _vm.task.dates.startDate = null;
        _vm.updateTask();
      }
    }
  })]) : _vm._e()], 1)]), _c('TaskRowWithDropdown', {
    attrs: {
      "items": _vm.projects,
      "display-key": 'name',
      "value-key": 'id',
      "label": 'Project',
      "icon": 'projects.svg',
      "allow-delete": true,
      "selectedItem": _vm.task.linkedProject,
      "fieldRequired": this.task.type === 'open_house',
      "updater": _vm.projectUpdater
    },
    on: {
      "itemSelected": _vm.updateProject
    }
  }), _c('TaskRowWithDropdown', {
    attrs: {
      "items": _vm.contacts,
      "display-key": 'name',
      "value-key": 'id',
      "label": 'Contact',
      "icon": 'clients.svg',
      "allow-delete": true,
      "selectedItem": _vm.task.linkedContact,
      "updater": _vm.contactUpdater
    },
    on: {
      "itemSelected": _vm.contactProject
    }
  }), _c('TaskRowWithDropdown', {
    attrs: {
      "items": _vm.colleagues,
      "display-key": 'name',
      "value-key": 'userId',
      "label": 'Owner',
      "icon": 'users.svg',
      "selectedItem": _vm.task.assignedTo
    },
    on: {
      "itemSelected": function (item) {
        _vm.task.assignedTo = item;
        _vm.updateTask();
      }
    }
  }), _c('v-row', {
    staticStyle: {
      "cursor": "pointer",
      "display": "flex",
      "align-items": "center",
      "padding-left": "2px",
      "margin-top": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('div', {
    staticClass: "field-icon-con",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg")
    }
  })]), _c('v-col', [_c('div', {
    staticStyle: {
      "color": "#ff1e24"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")])])], 1)], 1), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteTaskOrEvent
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }