var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "description-area"
  }, [!_vm.hideLanguageSelection ? _c('div', {
    staticClass: "language-selection"
  }, [_vm.user.access.company._id === 'xmwei_Rt9ryfpkxL4BL8' ? _c('div', {
    class: {
      'active-view': _vm.activeView === 'lv',
      '': _vm.activeView !== 'lv'
    },
    attrs: {
      "id": "lvPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'lv';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(_vm._s(_vm.$t("LatvianLanguage")))])]) : _vm._e(), !_vm.user.isItaly ? _c('div', {
    class: {
      'active-view': _vm.activeView === 'et',
      '': _vm.activeView !== 'et'
    },
    attrs: {
      "id": "etPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'et';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(_vm._s(_vm.$t("EstonianLanguage")))])]) : _vm._e(), _c('div', {
    class: {
      'active-view': _vm.activeView === 'eng',
      '': _vm.activeView !== 'eng'
    },
    attrs: {
      "id": "engPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'eng';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(_vm._s(_vm.$t("EnglishLanguage")))])]), !_vm.user.isItaly ? _c('div', {
    class: {
      'active-view': _vm.activeView === 'ru',
      '': _vm.activeView !== 'ru'
    },
    attrs: {
      "id": "ruPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'ru';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(_vm._s(_vm.$t("RussianLanguage")))])]) : _vm._e(), !_vm.user.isItaly && _vm.user.access.company._id !== 'xmwei_Rt9ryfpkxL4BL8' ? _c('div', {
    class: {
      'active-view': _vm.activeView === 'fi',
      '': _vm.activeView !== 'fi'
    },
    attrs: {
      "id": "fiPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'fi';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(_vm._s(_vm.$t("FinnishLanguage")))])]) : _vm._e(), _vm.user.isItaly ? _c('div', {
    class: {
      'active-view': _vm.activeView === 'it',
      '': _vm.activeView !== 'it'
    },
    attrs: {
      "id": "itPicker"
    },
    on: {
      "click": function ($event) {
        _vm.activeView = 'it';
      }
    }
  }, [_c('p', {
    staticClass: "content-small semi-bold"
  }, [_vm._v(_vm._s(_vm.$t("ItalyLanguage")))])]) : _vm._e()]) : _vm._e(), _vm.activeView === 'et' && _vm.listing.slogan ? _c('div', {
    class: {
      'slogan-row': !_vm.isDevelopment,
      'slogan-row-basic': _vm.isDevelopment
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(_vm._s(_vm.listing.slogan))])]) : _vm._e(), _vm.activeView === 'eng' && _vm.listing.sloganENG ? _c('div', {
    class: {
      'slogan-row': !_vm.isDevelopment,
      'slogan-row-basic': _vm.isDevelopment
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(_vm._s(_vm.listing.sloganENG))])]) : _vm._e(), _vm.activeView === 'ru' && _vm.listing.sloganRU ? _c('div', {
    class: {
      'slogan-row': !_vm.isDevelopment,
      'slogan-row-basic': _vm.isDevelopment
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(_vm._s(_vm.listing.sloganRU))])]) : _vm._e(), _vm.activeView === 'fi' && _vm.listing.sloganFI ? _c('div', {
    class: {
      'slogan-row': !_vm.isDevelopment,
      'slogan-row-basic': _vm.isDevelopment
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(_vm._s(_vm.listing.sloganFI))])]) : _vm._e(), _vm.activeView === 'lv' && _vm.listing.sloganLV ? _c('div', {
    class: {
      'slogan-row': !_vm.isDevelopment,
      'slogan-row-basic': _vm.isDevelopment
    }
  }, [_c('p', {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(_vm._s(_vm.listing.sloganLV))])]) : _vm._e(), _vm.activeView === 'et' && _vm.listing.description ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.listing.description) + " ")]) : _vm._e(), _vm.activeView === 'et' && _vm.listing.servicesInNeighbourhood ? _c('div', [_c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ServicesInNeighbourhood")) + " ")]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.servicesInNeighbourhood) + " ")])]) : _vm._e(), _vm.activeView === 'et' ? _c('div', [_vm.listing.linkTitle ? _c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.linkTitle) + " ")]) : _vm._e(), _vm.listing.additionalInfoLink ? _c('a', {
    staticStyle: {
      "margin-top": "0px",
      "cursor": "pointer"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openVideoLink(_vm.listing.additionalInfoLink);
      }
    }
  }, [_vm._v(_vm._s(_vm.listing.additionalInfoLink))]) : _vm._e(), _vm.activeView === 'et' && _vm.listing.footerEnabled && _vm.listing.footerText ? _c('p', {
    staticClass: "content-normal",
    domProps: {
      "innerHTML": _vm._s(_vm.listing.footerText['et'])
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.footerText["et"]) + " ")]) : _vm._e()]) : _vm._e(), _vm.activeView === 'eng' && _vm.listing.descriptionENG ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.listing.descriptionENG) + " ")]) : _vm._e(), _vm.activeView === 'eng' && _vm.listing.servicesInNeighbourhoodENG ? _c('div', [_c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ServicesInNeighbourhood")) + " ")]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.servicesInNeighbourhoodENG) + " ")])]) : _vm._e(), _vm.activeView === 'eng' ? _c('div', [_vm.listing.linkTitleENG ? _c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.linkTitleENG) + " ")]) : _vm._e(), _vm.listing.additionalInfoLinkENG ? _c('a', {
    staticStyle: {
      "margin-top": "0px",
      "cursor": "pointer"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openVideoLink(_vm.listing.additionalInfoLinkENG);
      }
    }
  }, [_vm._v(_vm._s(_vm.listing.additionalInfoLinkENG))]) : _vm._e(), _vm.activeView === 'eng' && _vm.listing.footerEnabled && _vm.listing.footerText ? _c('p', {
    staticClass: "content-normal",
    domProps: {
      "innerHTML": _vm._s(_vm.listing.footerText['eng'])
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.footerText["eng"]) + " ")]) : _vm._e()]) : _vm._e(), _vm.activeView === 'ru' && _vm.listing.descriptionRU ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.listing.descriptionRU) + " ")]) : _vm._e(), _vm.activeView === 'ru' && _vm.listing.servicesInNeighbourhoodRU ? _c('div', [_c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ServicesInNeighbourhood")) + " ")]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.servicesInNeighbourhoodRU) + " ")])]) : _vm._e(), _vm.activeView === 'ru' ? _c('div', [_vm.listing.linkTitleRU ? _c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.linkTitleRU) + " ")]) : _vm._e(), _vm.listing.additionalInfoLinkRU ? _c('a', {
    staticStyle: {
      "margin-top": "0px",
      "cursor": "pointer"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openVideoLink(_vm.listing.additionalInfoLinkRU);
      }
    }
  }, [_vm._v(_vm._s(_vm.listing.additionalInfoLinkRU))]) : _vm._e(), _vm.activeView === 'ru' && _vm.listing.footerEnabled && _vm.listing.footerText ? _c('p', {
    staticClass: "content-normal",
    domProps: {
      "innerHTML": _vm._s(_vm.listing.footerText['ru'])
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.footerText["ru"]) + " ")]) : _vm._e()]) : _vm._e(), _vm.activeView === 'fi' && _vm.listing.descriptionFI ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.listing.descriptionFI) + " ")]) : _vm._e(), _vm.activeView === 'fi' && _vm.listing.servicesInNeighbourhoodFI ? _c('div', [_c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ServicesInNeighbourhood")) + " ")]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.servicesInNeighbourhoodFI) + " ")])]) : _vm._e(), _vm.activeView === 'fi' ? _c('div', [_vm.listing.linkTitleFI ? _c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.linkTitleFI) + " ")]) : _vm._e(), _vm.listing.additionalInfoLinkFI ? _c('a', {
    staticStyle: {
      "margin-top": "0px",
      "cursor": "pointer"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openVideoLink(_vm.listing.additionalInfoLinkFI);
      }
    }
  }, [_vm._v(_vm._s(_vm.listing.additionalInfoLinkFI))]) : _vm._e(), _vm.activeView === 'fi' && _vm.listing.footerEnabled && _vm.listing.footerText ? _c('p', {
    staticClass: "content-normal",
    domProps: {
      "innerHTML": _vm._s(_vm.listing.footerText['fi'])
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.footerText["fi"]) + " ")]) : _vm._e()]) : _vm._e(), _vm.activeView === 'lv' && _vm.listing.descriptionLV ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.listing.descriptionLV) + " ")]) : _vm._e(), _vm.activeView === 'lv' && _vm.listing.servicesInNeighbourhoodLV ? _c('div', [_c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ServicesInNeighbourhood")) + " ")]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.servicesInNeighbourhoodLV) + " ")])]) : _vm._e(), _vm.activeView === 'lv' ? _c('div', [_vm.listing.linkTitleLV ? _c('h6', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.linkTitleLV) + " ")]) : _vm._e(), _vm.listing.additionalInfoLinkLV ? _c('a', {
    staticStyle: {
      "margin-top": "0px",
      "cursor": "pointer"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openVideoLink(_vm.listing.additionalInfoLinkLV);
      }
    }
  }, [_vm._v(_vm._s(_vm.listing.additionalInfoLinkLV))]) : _vm._e(), _vm.activeView === 'lv' && _vm.listing.footerEnabled && _vm.listing.footerText ? _c('p', {
    staticClass: "content-normal",
    domProps: {
      "innerHTML": _vm._s(_vm.listing.footerText['lv'])
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.footerText["lv"]) + " ")]) : _vm._e()]) : _vm._e(), _vm.activeView === 'it' && _vm.listing.descriptionIT ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.listing.descriptionIT) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }