<template>
  <div class="selection-background">
    <div class="tab-row">
      <div
          @click="changeTab('private')"
          class="tab"
          :class="
            tab === 'private'
              ? 'tab-selected' : null
          "
      >
        {{ $t("PrivatePerson.Label") }}
      </div>
      <div
          @click="changeTab('company')"
          class="tab"
          :class="
            tab === 'company'
              ? 'tab-selected' : null
          "
          style="margin-left: 24px"
      >
        {{ $t('Organization') }}
      </div>
    </div>
    <div v-if="tab === 'private'">
      <div v-if="clientSearchOpen">
        <ClientListSearch
            :placeholder="$t('Client')"
            :icon-left="'clients.svg'"
            @addNew="openContactAdd"
            @itemChosen="selectClient"
            @closed="closeClientListSearch"
        />
      </div>
      <div v-if="!clientSearchOpen" class="options-row">
        <div
            v-for="(option, index) in privateOptions"
            :key="option.name"
        >
          <div
              v-if="option.value !== 'new_contact'"
              :id="'private-' + index"
              :class="
                option.selected ? 'chosen-object' : 'regular-object'
             "
              style="cursor: default"
              @click="option.selected ? null : selectPrivateOption(option)"
          >
            <img
                v-if="option.value === 'new_contact'"
                src="../../assets/images/plusSign.svg"
                alt=""
                style="margin-right: 8px"
            />
            {{option.name}}
            <img
                v-if="option.selected && option.value !== 'same_as_contact'"
                src="../../assets/images/action_close_borderless.svg"
                alt=""
                @click="option.selected ? selectPrivateOption(option) : null"
                class="chosen-object-icon"
            />
          </div>
          <div
              v-if="option.value === 'new_contact' && (!chosenNewPrivateClient || privateOptions.length === 2) && !addContactButtonHidden"
              class="regular-object"
              style="cursor: pointer"
              @click="selectPrivateOption(option)"
          >
            <img
                src="../../assets/images/plusSign.svg"
                alt=""
                style="margin-right: 8px"
            />
            {{option.name}}
          </div>
        </div>
      </div>
    </div>
    <div v-if="tab === 'company'" class="options-row">
      <div v-if="companyOptions.length > 0" style="display: flex; flex-wrap: wrap; gap: 8px;">
        <div
            v-for="(option, index) in companyOptions"
            :key="index"
        >
          <div
              @click="selectCompanyOption(option, index)"
              :id="'company-' + index"
              :class="
                option.selected ? 'chosen-object' : 'regular-object'
             "
              style="cursor: default;"
          >
            {{option.name}}
          </div>
        </div>
      </div>
      <div v-else>
        {{ $t('AddOrganizationToContact') }}
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import ClientListSearch from "@/components/common/ClientListSearch";

export default {
  name: "ClientSelection",
  props: [
    "contactId",
    "projectContact",
    "existingClient",
    "isEdit",
    "newProjectClient",
  ],
  components: {
    ClientListSearch
  },
  data() {
    return {
      contact: null,
      tab: 'private',
      privateOptions: [
        {
          name: this.$t('SameAsContact'),
          value: "same_as_contact",
          selected: true,
        },
        {
          name: this.$t('AddOneContact'),
          value: "new_contact",
          selected: false,
        }
      ],
      privateOptionsCopy: [
        {
          name: this.$t('SameAsContact'),
          value: "same_as_contact",
          selected: true,
        },
        {
          name: this.$t('AddOneContact'),
          value: "new_contact",
          selected: false,
        }
      ],
      companyOptions: [],
      currentSelection: "same_as_contact",
      selectedClient: null,
      clientSearchOpen: false,
      addContactButtonHidden: false,
      chosenNewPrivateClient: null,
    };
  },
  created: async function () {
    await this.getContact();
    this.contact.organizations.forEach((company) => {
      let companyOption = {
        name: company.company_name,
        value: company.company_name,
        selected: false,
        _id: company._id,
        reg_code: company.reg_code,
        company_name: company.company_name,
      }
      this.companyOptions.push(companyOption);
    })
    if (!this.isEdit) {
      this.selectedClient = this.privateOptions[0];
    } else {
      this.selectedClient = this.existingClient;
      this.tab = this.existingClient.company_name ? "company" : "private";
      if (this.tab === "company") {
        this.privateOptions.forEach((option) => {
          option.selected = false;
        })
        const defaultCompanyIndex = this.companyOptions.findIndex(option => option.company_name === this.existingClient.company_name);
        this.companyOptions[defaultCompanyIndex].selected = true;
      } else {
        if(this.existingClient) {
          if(!this.existingClient.contactName && this.projectContact.contactName) {
          this.existingClient.contactName = this.projectContact.contactName;
        } else if(this.existingClient.contactName !== this.projectContact.contactName){
          this.privateOptions[0].selected = false;
          let privateClient = {
            name: this.existingClient.contactName,
            value: this.existingClient.contactId,
            selected: true,
          }
          this.privateOptions.splice(1, 0, privateClient);
        }
        }
      }
    }
  },
  mounted() {},
  computed: {},
  methods: {
    async getContact() {
      const response = await axios.get(
          `/api/contact/${this.contactId}`
      );
      this.contact = response.data;
    },
    changeTab(tabName) {
      this.addContactButtonHidden = false;
      this.tab = tabName;
    },
    selectPrivateOption(option){
  if(!this.isEdit){
    if(this.privateOptions.length > 2){
      this.privateOptions.pop();
      this.privateOptions.forEach((option, index) => {
        option.selected = index === 0;
      })
      this.clientSearchOpen = false;
      this.addContactButtonHidden = false;
      this.selectedClient = this.privateOptions[0];
      this.chosenNewPrivateClient = this.privateOptions[0];
    } else {
      if(option.value === 'new_contact'){
        this.clientSearchOpen = true;
        this.addContactButtonHidden = true;
        this.$emit('openClientSearch');
        this.selectedClient = option;
      } else if (option.value === 'same_as_contact'){
        this.selectedClient = this.privateOptions[0];
        this.privateOptions[0].selected = true;
      }
    }
  } else {
    if(option.value === 'new_contact'){
      this.clientSearchOpen = true;
      this.addContactButtonHidden = true;
      this.$emit('openClientSearch');
      this.selectedClient = option;
    } else if (option.value === 'same_as_contact'){
      if(this.privateOptions.length > 2){
            let clientIndex = this.privateOptions.findIndex(option =>
                option.value !== "same_as_contact" &&
                option.value !== "new_contact"
            );
            this.privateOptions.splice(clientIndex, 1);
      }
      this.privateOptions.forEach((option) => {
        option.selected = false;
      })
      this.chosenNewPrivateClient = null;
      this.privateOptions[0].selected = true;
      this.selectedClient = this.privateOptions[0];
      this.addContactButtonHidden = false;
    } else if (option.value !== 'same_as_contact' && option.value !== 'new_contact') {
      const privateIndex = this.privateOptions.findIndex(privatePerson => privatePerson.name === option.name);
      this.chosenNewPrivateClient = null;
      this.privateOptions[0].selected = true;
      this.selectedClient = this.privateOptions[0];
      this.privateOptions.splice(privateIndex, 1);
      this.addContactButtonHidden = false;
    }
  }
},
    openContactAdd(){
      this.$emit('openAddPanel');
    },
    selectClient(client) {
      if(client){
        this.chosenNewPrivateClient = client;
        this.selectedClient = client;
        this.changePrivateClient();
      }
      this.clientSearchOpen = false;
    },
    closeClientListSearch(){
      this.addContactButtonHidden = false;
      this.clientSearchOpen = false;
    },
    selectCompanyOption(option, index){
      let optionClassList = document.getElementById('company-' + index).classList;
      if (optionClassList.contains('chosen-object')) {
        this.companyOptions[index].selected = false;
        if(this.existingClient && this.existingClient.company_name){
          this.selectedClient = this.existingClient;
          const defaultCompanyIndex = this.companyOptions.findIndex(option => option.company_name === this.existingClient.company_name);
          this.companyOptions[defaultCompanyIndex].selected = true;
        } else {
          this.selectedClient = this.privateOptions[0];
        }
      } else {
        if (this.selectedClient) {
          this.companyOptions.forEach((company) => {
            company.selected = false;
          })
        }
        this.privateOptions[0].selected = false;
        option.selected = true;
        this.selectedClient = option;
      }
    },
    changePrivateClient(){
      if(this.privateOptions.length > 2){
        this.privateOptions.splice(1, 1);
      }
      if(this.chosenNewPrivateClient){
        const option = {
          name: this.chosenNewPrivateClient.customerName,
          value: this.chosenNewPrivateClient.customerId,
          selected: true,
        }
        this.privateOptions.forEach((privateOption) => {
          privateOption.selected = false;
        })
        this.privateOptions.push(option);
        this.selectedClient = option;
      } else {
        this.privateOptions[0].selected = true;
        this.selectedClient = this.privateOptions[0];
      }
    }
  },
  watch: {
    newProjectClient(){
      this.chosenNewPrivateClient = this.newProjectClient;
      const option = {
        name: this.chosenNewPrivateClient.customerName,
        value: this.chosenNewPrivateClient.customerId,
        selected: true,
      }
      this.privateOptions.forEach((privateOption) => {
        privateOption.selected = false;
      })
      this.privateOptions.push(option);
      this.selectedClient = option;
    },
    selectedClient() {
      if(this.selectedClient.name === this.privateOptions[0].name){
        this.companyOptions.forEach((option) => {
          option.selected = false;
        })
      }
      if(this.selectedClient){
        if(this.tab === 'company' && this.selectedClient !== this.privateOptions[0]){
          this.$emit('clientSelected', 'company', this.selectedClient);
        } else if (this.chosenNewPrivateClient){
          this.$emit('clientSelected', 'private', this.chosenNewPrivateClient);
        } else if (!this.isEdit){
          this.$emit('clientSelected', 'private', this.privateOptions[0]);
        } else if (this.selectedClient.name === this.privateOptions[0].name){
          this.$emit('clientSelected', 'private', this.privateOptions[0]);
        }
      } else {
        this.$emit('clientSelected', 'private', this.privateOptions[0]);
      }
    },
    async tab() {
      if(this.tab === 'company'){
        if(!this.existingClient.company_name){
          this.companyOptions.forEach((option) => {
            option.selected = false;
          })
        } else {
          this.privateOptions[0].selected = false;
          const defaultCompanyIndex = this.companyOptions.findIndex(option => option.company_name === this.existingClient.company_name);
          this.companyOptions[defaultCompanyIndex].selected = true;
          this.selectedClient = this.existingClient;
        }
        if (this.selectedClient.company_name) {
          const defaultCompanyIndex = this.companyOptions.findIndex(option => option.company_name === this.selectedClient.company_name);
          this.companyOptions[defaultCompanyIndex].selected = true;
        }
      } else {
        if(!this.isEdit){
          if(this.selectedClient && this.privateOptions.length > 2){
            this.privateOptions.pop();
          } else {
            this.privateOptions[0].selected = true;
            this.selectedClient = this.privateOptions[0];
          }
        } else {
          if(this.selectedClient.company_name) {
            if(this.privateOptions.length > 2){
              const clientIndex = this.privateOptions.findIndex(option => option.value !== "same_as_contact" && option.value !== "new_contact");
              this.privateOptions.splice(clientIndex, 1);
            }
            this.privateOptions.forEach((option) => {
              option.selected = false;
            })
            this.addContactButtonHidden = false;
          }
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.selection-background {
  background-color: #FAFAFA;
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  padding: 16px 12px;
  width: 100%;
  font-size: 12px;
}
.tab-row {
  display: flex;
  flex-direction: row;
}
.tab {
  padding-bottom: 8px;
  cursor: pointer;
  margin-bottom: 24px;
  width: fit-content;
}
.tab-selected {
  padding-bottom: 8px;
  font-weight: 600;
  border-bottom: 2px solid black;
  cursor: pointer;
  margin-bottom: 24px;
  width: fit-content;
}
.options-row {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.chosen-object {
  display: flex;
  align-items: center;
  background-color: #FFF0D4;
  border: 1px solid #FF5C01;
  border-radius: 8px;
  color: #FF5C01;
  font-weight: bold;
  padding: 8px 12px;
  width: fit-content;
}
.chosen-object-icon {
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  filter: invert(51%) sepia(49%) saturate(6661%) hue-rotate(360deg) brightness(103%) contrast(107%);
}
.regular-object {
  display: flex;
  align-items: center;
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  color: black;
  padding: 8px 12px;
  width: fit-content;
  filter: none;
}
</style>
