var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    ref: "brokerInfo",
    style: _vm.styler,
    attrs: {
      "id": "broker-info"
    }
  }, [_c('div', {
    staticClass: "main-wrapper",
    staticStyle: {
      "background-color": "white",
      "padding": "24px",
      "border-radius": "8px"
    }
  }, [_c('div', {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.userInfo.name))])]), _c('div', {
    staticStyle: {
      "border": "1px solid #e6e8ec"
    }
  }), _vm.userInfo.email ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "content",
    staticStyle: {
      "width": "150px",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Email")) + " ")]), _c('p', {
    staticClass: "deal-item-name hover-option",
    attrs: {
      "id": "deal-probability"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.sendEmail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.userInfo.email) + " ")]), _c('CopyButton', {
    attrs: {
      "toast-message": 'EmailCopied',
      "copy-text": _vm.userInfo.email
    }
  })], 1) : _vm._e(), _vm.userInfo.phoneNumber ? _c('v-row', {
    staticClass: "customer-field-row top12"
  }, [_c('p', {
    staticClass: "content",
    staticStyle: {
      "width": "150px",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PhoneNumber")) + " ")]), _c('p', {
    staticClass: "deal-item-name hover-option",
    attrs: {
      "id": "deal-probability"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.callNumber.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.userInfo.phoneNumber) + " ")]), _c('CopyButton', {
    attrs: {
      "toast-message": 'PhoneNumberCopied',
      "copy-text": _vm.userInfo.phoneNumber
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticClass: "customer-field-row top12"
  }, [_c('p', {
    staticClass: "content",
    staticStyle: {
      "width": "150px",
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("UserGroups")) + " ")]), _c('p', {
    staticClass: "deal-item-name",
    attrs: {
      "id": "deal-probability"
    }
  }, [_vm._v(_vm._s(_vm.groups))])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }