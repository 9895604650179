var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay-orgpanel",
    attrs: {
      "id": "overlay-org"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closePanel.apply(null, arguments);
      },
      "click": function ($event) {
        return _vm.closePanel($event);
      }
    }
  }, [_c('div', {
    staticClass: "org-main-wrapper"
  }, [!_vm.loaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _c('div', {
    staticClass: "org-content"
  }, [_c('div', {
    staticClass: "panel-header"
  }, [_vm.organization.company_name ? _c('h4', [_vm._v(_vm._s(_vm.organization.company_name))]) : _vm.organization.organization ? _c('h4', [_vm._v(_vm._s(_vm.organization.organization))]) : _vm._e(), !_vm.organization.reg_code && !_vm.edit ? _c('button', {
    staticClass: "main-small-secondary"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": "edit",
      "width": "16",
      "height": "16"
    }
  }), _c('span', {
    staticClass: "button-small",
    on: {
      "click": function ($event) {
        _vm.edit = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])]) : _vm._e()]), !_vm.edit ? _c('div', {
    staticClass: "org-content-body"
  }, [_c('div', {
    staticClass: "main-info"
  }, [_vm.organization.orgStatus === '4' ? _c('div', {
    staticClass: "organization-deleted content-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("OrganizationIsDeleted")) + " ")]) : _vm._e(), _c('h5', {
    staticStyle: {
      "margin-bottom": "12px"
    }
  }, [_vm._v(_vm._s(_vm.$t("Subblock.GeneralData")))]), _vm._l(_vm.mainInfo, function (infoRow) {
    return _c('div', {
      key: infoRow.value,
      staticClass: "main-info-row"
    }, [_c('p', {
      staticClass: "main-info-row-title"
    }, [_vm._v(_vm._s(infoRow.title))]), infoRow.extra === 'web' ? _c('a', {
      staticClass: "main-info-row-value",
      attrs: {
        "href": infoRow.value
      }
    }, [_vm._v(" " + _vm._s(infoRow.value) + " ")]) : _c('p', {
      staticClass: "main-info-row-value"
    }, [_vm._v(" " + _vm._s(infoRow.value) + " "), infoRow.extra === 'updatedAt' ? _c('span', {
      staticClass: "check-update-button button-small",
      on: {
        "click": _vm.checkUpdate
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("CheckUpdate")) + " ")]) : _vm._e()])]);
  })], 2), _c('v-divider'), _c('div', {
    staticClass: "related-people"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Contacts")))]), _vm._l(_vm.organization.relatedContacts, function (contact) {
    return _c('div', {
      key: contact.customerId
    }, [_c('ContactSimpleInfoCard', {
      attrs: {
        "contact": contact
      },
      on: {
        "nameOnPress": function ($event) {
          return _vm.relatedPersonPressed(contact.customerId);
        }
      }
    })], 1);
  })], 2), _c('v-divider'), _c('div', {
    staticClass: "related-invoices"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("RelatedInvoices")))]), _vm._l(_vm.organization.relatedInvoices, function (invoice) {
    return _c('div', {
      key: invoice._id + '-' + _vm.invoiceUpdater
    }, [_c('InvoiceSimpleInfoCard', {
      attrs: {
        "invoice": invoice
      },
      on: {
        "onPress": function ($event) {
          return _vm.relatedInvoicePressed(invoice._id);
        }
      }
    })], 1);
  })], 2), _c('v-divider'), _c('div', {
    staticClass: "related-projects"
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("RelatedProjects")))]), _c('BaseToggle', {
    staticStyle: {
      "padding": "8px 0",
      "margin-top": "12px"
    },
    attrs: {
      "label": _vm.$t('ShowArchived')
    },
    model: {
      value: _vm.showArchivedProjects,
      callback: function ($$v) {
        _vm.showArchivedProjects = $$v;
      },
      expression: "showArchivedProjects"
    }
  }), _vm._l(_vm.organization.relatedProjects, function (project) {
    return _c('div', {
      key: project._id
    }, [_c('SimpleInfoCard', {
      staticStyle: {
        "margin-top": "16px"
      },
      attrs: {
        "hideArchived": !_vm.showArchivedProjects,
        "project": project
      },
      on: {
        "onPress": function ($event) {
          return _vm.relatedProjectPressed(project._id);
        }
      }
    })], 1);
  })], 2)], 1) : _c('div', {
    staticClass: "org-form-wrapper"
  }, [_c('v-row', [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('OrganizationsName'),
      "required": true,
      "error-message": 'errorrr',
      "error": _vm.missingOrganizationName
    },
    model: {
      value: _vm.organization.organization,
      callback: function ($$v) {
        _vm.$set(_vm.organization, "organization", $$v);
      },
      expression: "organization.organization"
    }
  })], 1), _c('v-row', [_c('GoogleAddressSearch', {
    attrs: {
      "placeholder": _vm.$t('Object') + ' ' + _vm.$t('Address').toLowerCase()
    },
    model: {
      value: _vm.organization.address,
      callback: function ($$v) {
        _vm.$set(_vm.organization, "address", $$v);
      },
      expression: "organization.address"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "12px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('CustomerField.phoneNumber'),
      "icon-left": "phone_20_20.svg"
    },
    model: {
      value: _vm.organization.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.organization, "phoneNumber", $$v);
      },
      expression: "organization.phoneNumber"
    }
  }), _c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('CustomerField.email'),
      "icon-left": "email_20_20.svg"
    },
    model: {
      value: _vm.organization.email,
      callback: function ($$v) {
        _vm.$set(_vm.organization, "email", $$v);
      },
      expression: "organization.email"
    }
  })], 1)], 1), _vm.edit ? _c('div', {
    staticClass: "org-footer"
  }, [_c('div', {
    staticClass: "org-buttons"
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Cancel')
    },
    on: {
      "click": function ($event) {
        _vm.edit = false;
      }
    }
  }), _c('BaseButton', {
    attrs: {
      "icon-left": 'checkmark_white_16_16.svg',
      "size": "large",
      "primary": "true",
      "label": _vm.$t('Save')
    },
    on: {
      "click": _vm.saveButtonClicked
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    attrs: {
      "name": "sidepanels"
    }
  }, [_vm.selectedInvoice ? _c('InvoiceSidepanel', {
    attrs: {
      "invoice": _vm.selectedInvoice
    },
    on: {
      "closePanel": function ($event) {
        _vm.selectedInvoice = null;
      },
      "editInvoice": _vm.updateInvoice,
      "deleteInvoice": _vm.deleteInvoice,
      "updateInvoiceStatus": _vm.updateInvoiceStatus
    }
  }) : _vm._e(), _vm.selectedContact ? _c('ClientSidepanel', {
    attrs: {
      "customerData": _vm.selectedContact
    },
    on: {
      "panelClosed": function ($event) {
        _vm.selectedContact = null;
      }
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }