<template>
  <div class="comment-wrapper">
    <p
      @click="toggleEdit"
      v-if="commentContent && !editComment"
      class="content-normal"
    >
      {{ commentContent }}
    </p>
    <div
      @click="toggleEdit"
      v-if="!editComment && !commentContent"
      class="link-small"
    >
      + {{ $t("addComment") }}
    </div>
    <textarea
      rows="4"
      v-if="editComment"
      ref="commentbox"
      :placeholder="$t('addComment') + '...'"
      @focusout="saveComment"
      v-model="commentContent"
    />
  </div>
</template>

<script>
import contactApi from "@/http/contact";
import projectApi from "@/http/project";

export default {
  name: "InterestComment",
  props: ["interest", "customerId", "projectId", "comment"],
  data() {
    return {
      module: "contacts",
      editComment: false,
      commentContent: "",
    };
  },
  created() {
    if (this.comment) {
      this.commentContent = this.comment;
    }
    if (this.projectId) {
      this.module = "projects";
    }
  },
  methods: {
    async saveComment() {
      try {
        if (this.module === "contacts") {
          const response = await contactApi.changeInterestComment(this.customerId, {
            interest: this.interest,
            comment: this.commentContent,
          });
          if (response && response.status === 200) {
            this.toastSuccess(this.$t("Updated"))
          }
        } else if (this.module === "projects") {
          const response = await projectApi.changeInterestComment(this.projectId, {
            interest: this.interest,
            comment: this.commentContent,
          });
          if (response && response.status === 200) {
            this.toastSuccess(this.$t("Updated"))
          }
        }

        this.$emit("commentUpdated");
        this.editComment = false;
      } catch (e) {
        this.toastError(this.$t("ErrorUpdating"));
      }
    },
    toggleEdit() {
      this.editComment = !this.editComment;
      if (this.comment) {
        this.commentContent = this.comment;
      }
      if (this.editComment) {
        this.$nextTick(() => {
          this.$refs.commentbox.focus();
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.comment-wrapper {
  border-top: 1px solid #e6e8ec;
  white-space: pre-line;
  padding: 24px;
}
p {
  cursor: pointer;
}
textarea {
  width: 100%;
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  padding: 7px 11px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  outline: none;
}
</style>
