var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', _vm._g(_vm._b({
          staticClass: "copy-button",
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.copy.apply(null, arguments);
            }
          }
        }, 'div', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../../assets/images/copy.svg"),
            "alt": ""
          }
        })])];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Copy")))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }