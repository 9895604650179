var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sidepanel-overlay",
    attrs: {
      "id": "offers-sidepanel-overlay"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closePanel.apply(null, arguments);
      },
      "click": function ($event) {
        return _vm.closePanel($event);
      }
    }
  }, [_c('div', {
    staticClass: "sidepanel",
    attrs: {
      "id": "offers-sidepanel"
    }
  }, [_c('div', {
    staticClass: "sidepanel-header"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("SendOffer")))])]), _vm.loaded ? _c('div', {
    key: 'offers-' + _vm.offersUpdater,
    staticClass: "sidepanel-content"
  }, [_vm.items.length < 1 ? _c('div', {
    staticClass: "no-results-wrapper"
  }, [_c('p', {
    staticClass: "no-results-heading"
  }, [_vm._v(_vm._s(_vm.$t("NoOffersToShow")))]), _c('p', {
    staticClass: "no-results-subheading"
  }, [_vm._v(" " + _vm._s(_vm.$t("ToCreateAnOfferMoveAContactToTheOffersColumnUnderAListing")) + " ")])]) : _vm._e(), _vm._l(_vm.items, function (offer, index) {
    return _c('div', {
      key: offer.leadId,
      staticClass: "offer-card",
      class: {
        'offer-card-first': index === 0,
        'offer-card-middle': index > 0 && index < _vm.items.length - 1,
        'offer-card-last': index === _vm.items.length - 1,
        'offer-card-only': _vm.items.length === 1
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "height": "32px",
        "width": "100%",
        "flex-wrap": "nowrap",
        "justify-content": "space-between"
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap",
        "gap": "8px"
      }
    }, [_c('div', {
      staticClass: "content-normal",
      staticStyle: {
        "display": "flex",
        "cursor": "pointer",
        "align-items": "center"
      },
      on: {
        "click": function ($event) {
          return _vm.openClientSidepanel(offer);
        }
      }
    }, [_vm._v(" " + _vm._s(offer.name) + " ")])]), _c('div', {
      staticStyle: {
        "display": "flex",
        "gap": "8px"
      }
    }, [_c('div', {
      staticClass: "dropdown-button",
      class: {
        'dropdown-button-open': offer.dropdownOpen
      },
      style: _vm.listingsButtonWidth,
      attrs: {
        "id": 'dropdown-button-' + index
      },
      on: {
        "click": function ($event) {
          return _vm.buttonClicked(offer);
        }
      }
    }, [!offer.dropdownOpen ? _c('img', {
      staticStyle: {
        "height": "16px",
        "width": "16px"
      },
      attrs: {
        "src": require("../../assets/images/chevron_square_16_16.svg"),
        "alt": ""
      }
    }) : _vm._e(), offer.dropdownOpen ? _c('img', {
      staticStyle: {
        "height": "16px",
        "width": "16px"
      },
      attrs: {
        "src": require("../../assets/images/chevron_square_light_16_16.svg"),
        "alt": ""
      }
    }) : _vm._e(), offer.listings.length === 1 ? _c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(offer.listings.length + " " + _vm.$t("Listing").toLowerCase()) + " ")]) : _vm._e(), offer.listings.length > 1 ? _c('p', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(offer.listings.length + " " + _vm.$t("Listingst").toLowerCase()) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "actions-label content-small"
    }, [_vm._v(" " + _vm._s(_vm.$t("ShareOffer")) + " ")]), _c('div', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c('v-list-item', _vm._g(_vm._b({
            staticClass: "tooltip-item"
          }, 'v-list-item', attrs, false), on), [_c('div', {
            staticClass: "icon-wrap mail-icon-wrap",
            on: {
              "click": function ($event) {
                return _vm.sendSingleOffer(offer);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": require("../../assets/images/email.svg"),
              "alt": ""
            }
          })])])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("Send") + " " + _vm.$t("e-mail").toLowerCase()) + " ")])])], 1), _c('div', [_c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('v-list-item', _vm._g(_vm._b({
            staticClass: "tooltip-item"
          }, 'v-list-item', attrs, false), on), [_c('div', {
            staticClass: "icon-wrap link-icon-wrap",
            on: {
              "click": function ($event) {
                return _vm.copyLink(offer.leadId);
              }
            }
          }, [_c('img', {
            staticClass: "img20",
            attrs: {
              "src": require("../../assets/images/add-link.svg"),
              "alt": ""
            }
          })])])];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("Copy") + " " + _vm.$t("Link").toLowerCase()) + " ")])])], 1)])]), offer.dropdownOpen ? _c('div', {
      staticStyle: {
        "margin-top": "24px"
      }
    }, _vm._l(offer.listings, function (item, index) {
      return _c('div', {
        key: item.id + index,
        class: {
          'offer-card-first': index === 0,
          'offer-card-middle': index > 0 && index < offer.listings.length - 1,
          'offer-card-last': index === offer.listings.length - 1,
          'offer-card-only': offer.listings.length === 1
        },
        staticStyle: {
          "display": "flex",
          "justify-content": "space-between"
        }
      }, [_c('div', {
        staticStyle: {
          "cursor": "pointer"
        },
        on: {
          "click": function ($event) {
            return _vm.openListingsSidepanel(item);
          }
        }
      }, [_c('p', {
        staticClass: "content-small",
        class: {
          'selected-listing': _vm.listingId === item.id
        },
        staticStyle: {
          "padding": "13px 11px"
        }
      }, [_vm._v(" " + _vm._s(item.name) + " ")])]), _c('div', {
        staticStyle: {
          "display": "flex",
          "flex-wrap": "nowrap",
          "gap": "4px",
          "align-items": "center",
          "cursor": "pointer"
        },
        on: {
          "click": function ($event) {
            return _vm.removeUserFromOffer(offer, item);
          }
        }
      }, [_c('img', {
        attrs: {
          "src": require("../../assets/images/close_20_20.svg")
        }
      }), _c('p', {
        staticClass: "content-small",
        staticStyle: {
          "padding": "11px 12px 11px 0",
          "color": "#ff1e24",
          "font-weight": "bold"
        }
      }, [_vm._v(" " + _vm._s(_vm.$t("Remove").toLowerCase()) + " ")])])]);
    }), 0) : _vm._e()]);
  })], 2) : _vm._e()]), _vm.listingId ? _c('ListingSidepanel', {
    attrs: {
      "listing-preview-id": _vm.listingId,
      "second-sidepanel": true
    },
    on: {
      "overlayClicked": _vm.closeListingSidepanel
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }