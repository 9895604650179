export default [
    {
        _id: "4",
        name: "allValues",
        filterTitle: "AllTransactions",
        isDefault: true,
        isSelected: true,
        conditions: [
            {
                name: "search",
                title: "Search",
                value: "",
                valueTitle: "",
            },
            {
                name: "dealTypes",
                title: "DealTypes",
                value: [],
                valueTitle: "All",
            },
            {
                name: "brokerId",
                title: "Broker",
                value: "",
                valueTitle: "",
            },
          /*   {
                name: "tag",
                title: "Tag",
                value: "this_month",
                valueTitle: "this_month"
            }, */
            {
                name: "status",
                title: "Status",
                value: "",
                valueTitle: "All",
            },
         /*    {
                name: "dateOfTransactionFromDate",
                title: "DateOfTransactionFromDate",
                value: "",
                valueTitle: "All",
            },
            {
                name: "dateOfTransactionToDate",
                title: "DateOfTransactionToDate",
                value: "",
                valueTitle: "All",
            }, */
            {
                name: "transactionDate",
                title: "TransactionDate",
                value: false,
                valueTitle: "",
            },
            {
                name: "transactionDateFrom",
                title: "TransactionDateFrom",
                value: "",
                valueTitle: "",
            },
            {
                name: "transactionDateTo",
                title: "TransactionDateTo",
                value: "",
                valueTitle: "",
            }
        ],
    },
]

