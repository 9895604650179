import Vue from "vue";
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import ToastComponent from "../components/common/Toast";
import "vue-toastification/dist/index.css";

Vue.use(Toast);
Vue.mixin({
  methods: {
    toastSuccess(text) {
      const content = {
        component: ToastComponent,
        props: {
          type: "success",
          text: text,
        },
      };
      const options = {
        position: POSITION.TOP_CENTER,
        timeout: 4000,
        toastClassName: "nice-toast",
        hideProgressBar: true,
        icon: false,
      };
      this.$toast(content, options);
    },
    toastError(text) {
      const content = {
        component: ToastComponent,
        props: {
          type: "error",
          text: text,
        },
      };
      const options = {
        position: POSITION.TOP_CENTER,
        timeout: 4000,
        toastClassName: "nice-toast",
        hideProgressBar: true,
        icon: false,
      };
      this.$toast(content, options);
    },
  },
});
