<template>
  <div
    @click="$emit('clicked')"
    id="edit-button"
    class="edit-button"
    v-bind:class="{
      large: large,
    }"
  >
    <img src="../../assets/images/edit.svg" alt="" />
    <p class="edit-button-text">{{ $t("Edit") }}</p>
  </div>
</template>

<script>
export default {
  name: "EditButton",
  props: ["large"],
};
</script>

<style lang="scss" scoped>
.edit-button {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 4px 6px 4px 6px;

  height: 24px;
  /* grey/grey500 */
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    background-color: #e7e7e7;
    cursor: pointer;
  }
}

.edit-button-text {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0 !important;
}
.large {
  padding: 8px 12px !important;
  height: 32px !important;
}
</style>
