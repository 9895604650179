var render = function render(){
  var _vm$user$access,
    _vm$user$access$featu,
    _vm$user$access$featu2,
    _vm$user$access$featu3,
    _this = this,
    _vm$user$access2,
    _vm$user$access2$feat,
    _vm$user$access2$feat2,
    _vm$user$access2$feat3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    key: _vm.sidepanelUpdater,
    staticClass: "overlay-detailpanel",
    attrs: {
      "id": "overlay-customer"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closePanel.apply(null, arguments);
      },
      "click": function ($event) {
        return _vm.closePanel($event);
      }
    }
  }, [_c('div', {
    staticClass: "panel-wrapper",
    attrs: {
      "id": "panel-wrapper-customer"
    }
  }, [!_vm.dataLoaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), _vm.dataLoaded && !_vm.editModalOpen && !_vm.newCustomerAdd ? _c('div', {
    attrs: {
      "id": "wrapped-header-customer"
    },
    on: {
      "click": function ($event) {
        return _vm.closeAccordion();
      }
    }
  }, [_vm.customer.firstName ? _c('h4', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.customer.firstName) + " " + _vm._s(_vm.customer.lastName) + " ")]) : _vm._e()]) : _vm._e(), _vm.dataLoaded ? _c('div', {
    staticStyle: {
      "overflow-y": "scroll",
      "height": "100%"
    },
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('v-col', {
    staticClass: "customer-row"
  }, [_c('v-row', {
    staticStyle: {
      "justify-content": "space-between"
    }
  }, [_vm.customer.firstName ? _c('h3', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.customer.firstName) + " " + _vm._s(_vm.customer.lastName) + " ")]) : _vm._e(), _vm.customer.statusCode !== 0 && _vm.hasAccess(_vm.customer) ? _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "justify-content": "flex-end"
    }
  }, [_c('EditButton', {
    attrs: {
      "name": "contact-edit-button"
    },
    on: {
      "clicked": function ($event) {
        return _vm.openAddModal(_vm.customer);
      }
    }
  })], 1) : _vm._e(), _vm.customer.statusCode !== 0 && _vm.customer.isProspect ? _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "justify-content": "flex-end"
    }
  }, [_c('div', {
    staticClass: "promote-button",
    on: {
      "click": function ($event) {
        return _vm.promoteToContact(_vm.customer);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/add_contact_black.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "promote-button-text"
  }, [_vm._v(_vm._s(_vm.$t("PromoteToContact")))])])]) : _vm._e()]), !_vm.customer.isProspect ? _c('div', {
    staticClass: "broker-info"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/users.svg"),
      "alt": ""
    }
  }), _vm._l(_vm.customer.access.users, function (creator, userKey) {
    return _c('span', {
      key: userKey,
      staticClass: "button-small hoverable-info",
      staticStyle: {
        "margin-left": "-2px"
      },
      on: {
        "click": function (event) {
          return _vm.openBrokerDetailPanel(event, userKey);
        }
      }
    }, [_vm._v(" " + _vm._s(creator.name) + " " + _vm._s(creator.ownershipType === "owner" ? "(" + _vm.$t("Owner").toLowerCase() + ")" : "") + " ")]);
  }), _vm.hasAccess(_vm.customer) ? _c('button', {
    staticClass: "animated-button",
    on: {
      "click": function ($event) {
        return _vm.openAddUsers();
      }
    }
  }, [_c('p', {
    staticStyle: {
      "font-size": "16px",
      "margin-top": "-1px"
    }
  }, [_vm._v("+")]), _c('span', [_vm._v(_vm._s(_vm.$t("ManageUsers")))])]) : _vm._e()], 2) : _vm._e()], 1), _c('div', {
    staticClass: "main-wrapper",
    staticStyle: {
      "background-color": "white"
    }
  }, [_vm.customer.statusCode === 0 ? _c('v-row', {
    staticStyle: {
      "margin": "32px 32px 0",
      "background": "#fff0f0",
      "border-radius": "4px",
      "display": "flex",
      "justify-content": "center"
    }
  }, [_c('p', {
    staticClass: "content-bold",
    staticStyle: {
      "color": "#ff1e24"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ContactIsDeleted")) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "sub-block"
  }, [_c('v-col', {
    staticClass: "sub-heading"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.$t("Subblock.GeneralData")) + " ")])]), _c('div', {
    staticClass: "main-info"
  }, [_c('v-row', {
    staticClass: "customer-field-row top24",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ContactsName")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.customer.firstName) + " " + _vm._s(_vm.customer.lastName) + " ")])]), _vm.customer.customerLang ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.ContactLanguage")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatCustomerLanguage()) + " ")])]) : _vm._e(), _vm.customer.access && _vm.customer.access.visibility ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("ContactVisibility")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatClientOwnership()) + " ")])]) : _vm._e(), _vm.customer.phoneNumbers.length && _vm.customer.phoneNumbers[0].phoneNumber !== null ? _c('v-row', {
    staticClass: "customer-field-row"
  }, [_c('div', [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PhoneNumber")))])]), _c('div', _vm._l(_vm.customer.phoneNumbers, function (phoneNumber) {
    return _c('v-row', {
      key: phoneNumber._id,
      staticClass: "customer-field-row top12",
      staticStyle: {
        "margin-top": "0"
      }
    }, [phoneNumber.phoneNumber ? _c('p', {
      staticClass: "field-value underlined",
      on: {
        "click": function ($event) {
          return _vm.selectPhone(phoneNumber.phoneNumber);
        }
      }
    }, [_vm._v(" " + _vm._s(phoneNumber.phoneNumber) + " ")]) : _vm._e(), _c('CopyButton', {
      attrs: {
        "toast-message": 'PhoneNumberCopied',
        "copy-text": phoneNumber.phoneNumber
      }
    })], 1);
  }), 1)]) : _vm._e(), _vm.customer.emails.length && _vm.customer.emails[0].email !== null ? _c('v-row', {
    staticClass: "customer-field-row"
  }, [_c('div', [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Email")))])]), _c('div', _vm._l(_vm.customer.emails, function (email) {
    return _c('v-row', {
      key: email._id,
      staticClass: "customer-field-row top12",
      staticStyle: {
        "margin-top": "0"
      }
    }, [email.email ? _c('p', {
      staticClass: "field-value underlined",
      on: {
        "click": function ($event) {
          return _vm.selectEmail(email.email);
        }
      }
    }, [_vm._v(" " + _vm._s(email.email) + " ")]) : _vm._e(), _c('CopyButton', {
      attrs: {
        "toast-message": 'EmailCopied',
        "copy-text": email.email
      }
    })], 1);
  }), 1)]) : _vm._e(), _vm.customer.organizations.length && _vm.customer.organizations[0].organization !== null ? _c('v-row', {
    staticClass: "customer-field-row"
  }, [_c('div', [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("OrganizationsName")))])]), _c('div', _vm._l(_vm.customer.organizations, function (organization) {
    return _c('v-row', {
      key: organization._id,
      staticClass: "customer-field-row top12",
      staticStyle: {
        "margin-top": "0"
      }
    }, [organization.organization ? _c('p', {
      staticClass: "field-value pointer underlined",
      on: {
        "click": function ($event) {
          return _vm.openOrgPanel(organization);
        }
      }
    }, [_vm._v(" " + _vm._s(organization.organization) + " ")]) : _vm._e(), _c('CopyButton', {
      attrs: {
        "toast-message": 'OrganizationDataCopied',
        "copy-text": _vm.selectOrganization(organization)
      }
    })], 1);
  }), 1)]) : _vm._e(), _vm.customer.createdAt ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("Created")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.customer.createdAt)) + " ")])]) : _vm._e(), _vm.customer.address ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("Address")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.customer.address) + " ")])]) : _vm._e(), _vm.customer.countryCode ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("Country")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.customerCountry) + " ")])]) : _vm._e(), _vm.customer.customerType ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerType")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.customerType) + " ")])]) : _vm._e(), _vm.customer.sex ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("Gender")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatCustomerGender()) + " ")])]) : _vm._e(), _vm.customer.birthday ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("BirthdayDate")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.customer.birthday)) + " ")])]) : _vm._e(), _vm.customer.postalCode ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("PostalCode")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.customer.postalCode) + " ")])]) : _vm._e(), _vm.customer.personalCode ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.personalCode")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.customer.personalCode) + " ")])]) : _vm._e(), _vm.customer.bankAccountNumber ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.BankAccountNumber")) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.customer.bankAccountNumber) + " ")])]) : _vm._e(), _vm.customer.tags.buyer || _vm.customer.tags.landlord || _vm.customer.tags.other || _vm.customer.tags.propertyOwner || _vm.customer.tags.tenant || _vm.customer.tags.photographer || _vm.customer.tags.valuator || _vm.customer.tags.bank_representative || _vm.customer.tags.notary || _vm.customer.tags.otherInPartner ? _c('v-row', {
    staticClass: "customer-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Tag")))]), _c('div', [_vm.customer.tags.propertyOwner ? _c('v-row', {
    staticStyle: {
      "gap": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "'background-color'": "#b5e7cf"
    }
  }, [_c('p', {
    staticClass: "tag-name"
  }, [_vm._v(_vm._s(_vm.$t("PO")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("PropertyOwner")) + " ")])]) : _vm._e(), _vm.customer.tags.landlord ? _c('v-row', {
    staticStyle: {
      "gap": "0",
      "margin-top": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#b8d8ff"
    }
  }, [_c('p', {
    staticClass: "tag-name"
  }, [_vm._v(_vm._s(_vm.$t("L")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.LANDLORD")) + " ")])]) : _vm._e(), _vm.customer.tags.buyer ? _c('v-row', {
    staticStyle: {
      "gap": "0",
      "margin-top": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#fff2b3"
    }
  }, [_c('p', {
    staticClass: "tag-name"
  }, [_vm._v(_vm._s(_vm.$t("B")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.BUYER")) + " ")])]) : _vm._e(), _vm.customer.tags.tenant ? _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "gap": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#fcbae2"
    }
  }, [_c('p', {
    staticClass: "tag-name"
  }, [_vm._v(_vm._s(_vm.$t("T").toUpperCase()))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.TENANT")) + " ")])]) : _vm._e(), _vm.customer.tags.other ? _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "gap": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#c3c4c6"
    }
  }, [_c('p', {
    staticClass: "tag-name"
  }, [_vm._v(_vm._s(_vm.$t("O")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CustomerField.CustomerType.Options.OTHER")) + " ")])]) : _vm._e(), _vm.customer.tags.photographer ? _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "gap": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#16afb9"
    }
  }, [_c('p', {
    staticClass: "tag-name white-tag-text"
  }, [_vm._v(_vm._s(_vm.$t("PH")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Photographer")) + " ")])]) : _vm._e(), _vm.customer.tags.valuator ? _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "gap": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#aa086a"
    }
  }, [_c('p', {
    staticClass: "tag-name white-tag-text"
  }, [_vm._v(_vm._s(_vm.$t("VA")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Valuator")) + " ")])]) : _vm._e(), _vm.customer.tags.notary ? _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "gap": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#4c8dd9"
    }
  }, [_c('p', {
    staticClass: "tag-name white-tag-text"
  }, [_vm._v(_vm._s(_vm.$t("NO")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Notary")) + " ")])]) : _vm._e(), _vm.customer.tags.bank_representative ? _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "gap": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#b54616"
    }
  }, [_c('p', {
    staticClass: "tag-name white-tag-text"
  }, [_vm._v(_vm._s(_vm.$t("BA")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("BankRepresentative")) + " ")])]) : _vm._e(), _vm.customer.tags.otherInPartner ? _c('v-row', {
    staticStyle: {
      "margin-top": "0",
      "gap": "0"
    }
  }, [_c('div', {
    staticClass: "tag-container",
    staticStyle: {
      "background-color": "#75787a"
    }
  }, [_c('p', {
    staticClass: "tag-name white-tag-text"
  }, [_vm._v(_vm._s(_vm.$t("O")))])]), _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "padding-bottom": "6px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Other")) + " ")])]) : _vm._e()], 1)]) : _vm._e()], 1), _c('v-col', {
    staticClass: "content-block"
  })], 1), _vm.user.companyId !== '_dw_1O8jj0QTYIRahjbF' ? _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm._m(0), _c('div', [_c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding-left": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Interests")) + " "), _vm.customer.statusCode !== 0 && !_vm.customer.isProspect ? _c('button', {
    staticClass: "add-persons",
    staticStyle: {
      "margin-left": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.openInterests();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-person-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddInterest")))])]) : _vm._e()])]), _c('InterestsArea', {
    key: _vm.interestsUpdater,
    attrs: {
      "customer-data": _vm.customer,
      "interests-data": _vm.customer.interests,
      "listing-key": _vm.listingKey
    },
    on: {
      "editInterest": _vm.openInterests,
      "openListingPanel": _vm.openListingPanel,
      "closeListingPanel": _vm.closeListingPanel,
      "funnelRemoved": function ($event) {
        return _vm.$emit('funnelRemoved');
      },
      "refreshLeads": function ($event) {
        return _vm.$emit('refreshLeads');
      }
    }
  }), _vm.customer.interests.length === 0 ? _c('div', {
    staticStyle: {
      "padding-bottom": "8px"
    }
  }) : _c('div', {
    staticStyle: {
      "padding-bottom": "32px"
    }
  })], 1) : _vm._e(), !_vm.customer.isProspect ? _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px",
      "padding-bottom": "32px"
    }
  }, [_vm._m(1), _c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding-left": "0",
      "padding-bottom": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Subblock.RelatedPersons.COMP")) + " "), _vm.customer.statusCode !== 0 ? _c('button', {
    staticClass: "add-persons-button",
    staticStyle: {
      "margin-left": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.addNewRelatedPerson = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-person-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddCustomer")))])]) : _vm._e()]), _vm.addNewRelatedPerson ? _c('v-row', {
    staticStyle: {
      "padding": "24px 24px 16px 0"
    }
  }, [_c('ClientListSearch', {
    attrs: {
      "id": "contact-related-person-search",
      "placeholder": _vm.$t('Contact'),
      "icon-left": 'clients.svg'
    },
    on: {
      "itemChosen": _vm.addContactToRelatedPeople,
      "addNew": _vm.addNewContact
    }
  })], 1) : _vm._e(), _vm.customer.relations && _vm.customer.relations.length > 0 ? _c('div', {
    staticStyle: {
      "padding-bottom": "24px"
    }
  }) : _vm._e(), _c('span', {
    key: _vm.relatedPeopleUpdater
  }, _vm._l(_vm.customer.relations, function (relatedCustomer, index) {
    return _c('div', {
      key: relatedCustomer.customerId,
      staticStyle: {
        "padding-bottom": "12px"
      }
    }, [index !== 0 ? _c('div', {
      staticClass: "related-person-row"
    }) : _vm._e(), _c('v-row', [_c('div', {
      staticClass: "relationIcon",
      on: {
        "click": function ($event) {
          return _vm.openCustomerDetailpanel(relatedCustomer);
        }
      }
    }, [_c('span', {
      staticClass: "font-20-medium"
    }, [_vm._v(" " + _vm._s(relatedCustomer.customerName[0].toUpperCase()) + " ")])]), _c('v-row', {
      staticStyle: {
        "justify-content": "space-between"
      }
    }, [_c('div', {
      staticClass: "related-person-name"
    }, [_c('span', {
      staticClass: "content-medium related-person-customer-name",
      on: {
        "click": function ($event) {
          return _vm.openCustomerDetailpanel(relatedCustomer);
        }
      }
    }, [_vm._v(" " + _vm._s(relatedCustomer.customerName) + " ")]), relatedCustomer.organization ? _c('span', [_vm._v(" – ")]) : _vm._e(), relatedCustomer.organization ? _c('span', [_vm._v(" " + _vm._s(relatedCustomer.organization) + " ")]) : _vm._e()]), _c('div', {
      staticStyle: {
        "display": "flex"
      }
    }, [_c('p', {
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.openDeleteModal(relatedCustomer);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require("@/assets/images/close_grey.svg"),
        "alt": ""
      }
    })])])])], 1), index === _vm.customer.relations.length - 1 ? _c('div', {
      staticStyle: {
        "margin-bottom": "-12px"
      }
    }) : _vm._e()], 1);
  }), 0)], 1) : _vm._e(), _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm._m(2), !_vm.notesLoaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), _vm.customer.statusCode !== 0 ? _c('div', {
    staticClass: "notes",
    staticStyle: {
      "padding-left": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "16px",
      "padding-bottom": "24px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Notes")))]), _c('div', {
    staticClass: "note-header-content",
    on: {
      "click": function ($event) {
        _vm.addNoteCounter++;
      }
    }
  }, [_c('img', {
    attrs: {
      "height": "16",
      "width": "16",
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("AddNote")))])])]), _c('BaseNotes', {
    attrs: {
      "add-new-note-counter": _vm.addNoteCounter,
      "dropdown-style": {
        right: '0px'
      },
      "show-no-notes-message": false,
      "view": {
        name: 'contact',
        itemId: this.customerPreviewId ? this.customerPreviewId : _vm.routeParams,
        itemName: _vm.customer.customerName
      }
    },
    on: {
      "notesCounter": _vm.setNotesCount
    }
  }), _c('div', {
    key: _vm.notesCountUpdater,
    style: [_vm.notesCount === 0 ? {
      'padding-bottom': '8px'
    } : {
      'padding-bottom': '32px'
    }],
    attrs: {
      "name": "empty-notes-padding"
    }
  })], 1) : _vm._e(), _vm.customer.statusCode === 0 ? _c('div', {
    staticClass: "notes",
    staticStyle: {
      "padding-left": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "16px",
      "padding-bottom": "24px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("Notes")))]), _c('div', {
    staticClass: "note-header-content",
    on: {
      "click": function ($event) {
        _vm.addNoteCounter++;
      }
    }
  }, [_c('img', {
    attrs: {
      "height": "16",
      "width": "16",
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.$t("AddNote")))])])]), _c('BaseNotes', {
    attrs: {
      "add-new-note-counter": _vm.addNoteCounter,
      "dropdown-style": {
        right: '0px'
      },
      "view": {
        name: 'contact',
        itemId: this.customerPreviewId ? this.customerPreviewId : _vm.routeParams,
        itemName: _vm.customer.customerName
      }
    }
  }), _c('div', {
    key: _vm.notesCountUpdater,
    style: [_vm.notesCount === 0 ? {
      'padding-bottom': '8px'
    } : {
      'padding-bottom': '32px'
    }],
    attrs: {
      "name": "empty-notes-padding"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm._m(3), _c('div', {
    staticClass: "activities"
  }, [_c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding": "0 0 0 0"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("TasksAndEvents")) + " "), _vm.customer.statusCode !== 0 ? _c('button', {
    staticClass: "add-tasks",
    staticStyle: {
      "margin-left": "16px"
    },
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.openNewTask
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-task-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddTask.Title")))])]) : _vm._e()])]), _c('div', {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_c('BaseTasksAndEvents', {
    attrs: {
      "show-no-tasks-message": false,
      "view": {
        name: 'contact',
        itemId: _vm.customerPreviewId ? _vm.customerPreviewId : _vm.routeParams
      },
      "open-new-task-add-widget": _vm.openTaskAddWidgetCounter,
      "labels-and-order": {
        overDueTasks: {
          label: false,
          order: 2
        },
        noDueDateTasks: {
          label: false,
          order: 1
        },
        todayTasks: {
          label: false,
          order: 3
        },
        upcomingTasks: {
          label: false,
          order: 4
        }
      },
      "show-toggle-button": true
    },
    on: {
      "tasks": function () {
        return _vm.$emit('update');
      }
    }
  })], 1), _vm.taskEditOpen ? _c('EditPanel', {
    attrs: {
      "clicked-task": _vm.taskInEdit
    },
    on: {
      "deleteTask": _vm.deleteTask,
      "updateKey": _vm.getTasks,
      "closePanel": function ($event) {
        _vm.taskEditOpen = false;
      }
    }
  }) : _vm._e(), _vm.allTasksCounter === 0 ? _c('v-row', {
    staticStyle: {
      "padding-bottom": "8px"
    }
  }) : _vm._e()], 1), !_vm.customer.isProspect && (!_vm.user.config || _vm.user.config && (_vm$user$access = _vm.user.access) !== null && _vm$user$access !== void 0 && (_vm$user$access$featu = _vm$user$access.features) !== null && _vm$user$access$featu !== void 0 && (_vm$user$access$featu2 = _vm$user$access$featu.modules) !== null && _vm$user$access$featu2 !== void 0 && (_vm$user$access$featu3 = _vm$user$access$featu2.projects) !== null && _vm$user$access$featu3 !== void 0 && _vm$user$access$featu3.enabled) && _vm.user.companyId !== '_dw_1O8jj0QTYIRahjbF' ? _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm._m(4), _c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding-left": "0",
      "padding-bottom": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("RelatedProjects")) + " "), _vm.customer.statusCode !== 0 ? _c('button', {
    staticClass: "add-persons deal-button",
    staticStyle: {
      "margin-left": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.projectSearchOpen = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-person-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddProject")))])]) : _vm._e()]), _c('div', {
    staticStyle: {
      "padding-bottom": "24px"
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ShowArchived').toLowerCase()
    },
    model: {
      value: _vm.requestUndoneProjects,
      callback: function ($$v) {
        _vm.requestUndoneProjects = $$v;
      },
      expression: "requestUndoneProjects"
    }
  })], 1), _vm.projectSearchOpen ? _c('v-row', {
    staticStyle: {
      "padding": "0 24px 16px 0"
    }
  }, [_c('BaseProjectSearch', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _this.projectSearchOpen = false;
      },
      expression: "() => (this.projectSearchOpen = false)"
    }],
    attrs: {
      "icon-left": 'search.svg',
      "show-completed-projects": _vm.requestUndoneProjects,
      "show-dropdown-arrow": true,
      "placeholder": _vm.$t('Project'),
      "dropdown-offset-y": 52
    },
    on: {
      "addNew": function () {
        _this.projectSearchOpen = false;
        _vm.openDealAdd();
      },
      "change": function (item) {
        _vm.addRelatedProject(item);
        _this.projectSearchOpen = false;
      }
    }
  })], 1) : _vm._e(), !_vm.projectsLoaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), _vm.projectsLoaded && _vm.relatedDeals ? _c('div', {
    key: _vm.updateRelatedProjects,
    staticStyle: {
      "padding-bottom": "8px"
    }
  }, [_vm.requestUndoneProjects ? _vm._l(_vm.relatedDeals, function (el) {
    return _c('div', {
      key: el._id,
      staticClass: "related-deals",
      on: {
        "click": function ($event) {
          return _vm.goToDealDetail(el._id);
        }
      }
    }, [_c('div', {
      staticClass: "related-project-container",
      class: {
        'green-border': (el.pipelineStep === 'archived' || el.pipelineStep === 'Done') && el.endState && el.endState[el.endState.length - 1].projectEndReason === 'Won',
        'red-border': (el.pipelineStep === 'archived' || el.pipelineStep === 'Done') && el.endState && (el.endState[el.endState.length - 1].projectEndReason === 'Lost' || el.endState[el.endState.length - 1].projectEndReason === 'Cancelled'),
        'regular-border': el.pipelineStep !== 'archived' && el.pipelineStep !== 'Done' || el.endState && el.endState[el.endState.length - 1].projectEndReason !== 'Lost' && el.endState[el.endState.length - 1].projectEndReason !== 'Won' && el.endState[el.endState.length - 1].projectEndReason !== 'Cancelled'
      }
    }, [_c('v-row', {
      staticClass: "project-type",
      staticStyle: {
        "gap": "8px"
      }
    }, [_c('DealTypeContainer', {
      attrs: {
        "small-font": false,
        "deal-type": el.dealType
      }
    }), _c('div', {
      staticClass: "related-project-info"
    }, [_vm._v(" " + _vm._s(_vm.getNumberOfPipelineStage(el.pipelineStep) + " " + _vm.formatPipelineStep(el.pipelineStep) + _vm.getProjectDoneDate(el)) + " ")])], 1), _c('v-row', {
      staticClass: "related-project-name"
    }, [_vm._v(" " + _vm._s(el.name) + " ")])], 1)]);
  }) : _vm._l(_vm.relatedDeals.filter(function (deal) {
    return deal.pipelineStep !== 'archived';
  }), function (el) {
    return _c('div', {
      key: el._id,
      staticClass: "related-deals",
      on: {
        "click": function ($event) {
          return _vm.goToDealDetail(el._id);
        }
      }
    }, [_c('div', {
      staticClass: "related-project-container",
      class: {
        'green-border': (el.pipelineStep === 'archived' || el.pipelineStep === 'Done') && el.endState && el.endState[el.endState.length - 1].projectEndReason === 'Won',
        'red-border': (el.pipelineStep === 'archived' || el.pipelineStep === 'Done') && el.endState && (el.endState[el.endState.length - 1].projectEndReason === 'Lost' || el.endState[el.endState.length - 1].projectEndReason === 'Cancelled'),
        'regular-border': el.pipelineStep !== 'archived' && el.pipelineStep !== 'Done' || el.endState && el.endState[el.endState.length - 1].projectEndReason !== 'Lost' && el.endState[el.endState.length - 1].projectEndReason !== 'Won' && el.endState[el.endState.length - 1].projectEndReason !== 'Cancelled'
      }
    }, [_c('v-row', {
      staticClass: "project-type",
      staticStyle: {
        "gap": "8px"
      }
    }, [_c('DealTypeContainer', {
      attrs: {
        "small-font": false,
        "deal-type": el.dealType
      }
    }), _c('div', {
      staticClass: "related-project-info"
    }, [_vm._v(" " + _vm._s(_vm.getNumberOfPipelineStage(el.pipelineStep) + " " + _vm.formatPipelineStep(el.pipelineStep) + _vm.getProjectDoneDate(el)) + " ")])], 1), _c('v-row', {
      staticClass: "related-project-name"
    }, [_vm._v(" " + _vm._s(el.name) + " ")])], 1)]);
  })], 2) : _vm._e()], 1) : _vm._e(), !_vm.customer.isProspect && (!_vm.user.config || _vm.user.config && (_vm$user$access2 = _vm.user.access) !== null && _vm$user$access2 !== void 0 && (_vm$user$access2$feat = _vm$user$access2.features) !== null && _vm$user$access2$feat !== void 0 && (_vm$user$access2$feat2 = _vm$user$access2$feat.modules) !== null && _vm$user$access2$feat2 !== void 0 && (_vm$user$access2$feat3 = _vm$user$access2$feat2.projects) !== null && _vm$user$access2$feat3 !== void 0 && _vm$user$access2$feat3.enabled) && _vm.user.companyId !== '_dw_1O8jj0QTYIRahjbF' ? _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm._m(5), _c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding-left": "0",
      "padding-bottom": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("RelatedDevProjects")) + " "), _vm.customer.statusCode !== 0 ? _c('button', {
    staticClass: "add-persons deal-button",
    staticStyle: {
      "margin-left": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.developmentSearchOpen = true;
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-person-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddDevelopment")))])]) : _vm._e()]), _c('div', {
    staticStyle: {
      "padding-bottom": "24px"
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ShowArchived').toLowerCase()
    },
    model: {
      value: _vm.requestUndoneDevProjects,
      callback: function ($$v) {
        _vm.requestUndoneDevProjects = $$v;
      },
      expression: "requestUndoneDevProjects"
    }
  })], 1), _vm.developmentSearchOpen ? _c('v-row', {
    staticStyle: {
      "padding": "0 24px 16px 0"
    }
  }, [_c('ItemSearch', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: function () {
        return _this.developmentSearchOpen = false;
      },
      expression: "() => (this.developmentSearchOpen = false)"
    }],
    attrs: {
      "module": 'development',
      "items": _vm.foundItems,
      "placeholder": _vm.$t('Search'),
      "name-key": 'projectName'
    },
    on: {
      "itemChosen": _vm.addRelatedDevelopmentProject,
      "focus-event": _vm.onFocus,
      "load-more": _vm.loadMoreItems,
      "search": _vm.performSearch
    }
  })], 1) : _vm._e(), !_vm.developmentProjectsLoaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), _vm.developmentProjectsLoaded && _vm.relatedDevProjects ? _c('div', {
    key: _vm.updateDevelopmentProjectsList,
    staticStyle: {
      "padding-bottom": "8px"
    }
  }, [_vm.requestUndoneDevProjects ? _vm._l(_vm.relatedDevProjects, function (el) {
    return _c('div', {
      key: el._id,
      staticClass: "related-deals",
      on: {
        "click": function ($event) {
          return _vm.goToDevelopmentDetail(el._id);
        }
      }
    }, [_c('div', {
      staticClass: "related-project-container regular-border"
    }, [_c('v-row', {
      staticClass: "project-type",
      staticStyle: {
        "gap": "8px"
      }
    }, [_c('DealTypeContainer', {
      attrs: {
        "small-font": false,
        "deal-type": el.assetType
      }
    }), el.status === 'archived' ? _c('div', {
      staticClass: "related-project-info"
    }, [_vm._v(" " + _vm._s(_vm.formatPipelineStep(el.status)) + " ")]) : _vm._e()], 1), _c('v-row', {
      staticClass: "related-project-name"
    }, [_vm._v(" " + _vm._s(el.projectName) + " ")])], 1)]);
  }) : _vm._l(_vm.relatedDevProjects.filter(function (deal) {
    return deal.status !== 'archived';
  }), function (el) {
    return _c('div', {
      key: el._id,
      staticClass: "related-deals",
      on: {
        "click": function ($event) {
          return _vm.goToDevelopmentDetail(el._id);
        }
      }
    }, [_c('div', {
      staticClass: "related-project-container regular-border"
    }, [_c('v-row', {
      staticClass: "project-type",
      staticStyle: {
        "gap": "8px"
      }
    }, [_c('DealTypeContainer', {
      attrs: {
        "small-font": false,
        "deal-type": el.assetType
      }
    })], 1), _c('v-row', {
      staticClass: "related-project-name"
    }, [_vm._v(" " + _vm._s(el.projectName) + " ")])], 1)]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.customer.statusCode !== 0 && !_vm.customer.isProspect ? _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm._m(6), _c('div', {
    staticStyle: {
      "padding-top": "16px",
      "padding-bottom": "40px"
    }
  }, [_c('FileUpload', {
    attrs: {
      "files": _vm.customer.files,
      "clientSidepanelOpen": _vm.clientSidepanelOpen,
      "targetId": _vm.customer.customerId,
      "target-object": 'contact',
      "id": 'customer-upload',
      "buttonCornerLocation": false,
      "file-counter": _vm.customer.files.length,
      "aml-panel": 0
    },
    on: {
      "success": function ($event) {
        return _vm.getCustomer(_vm.customer.customerId);
      },
      "update:files": function ($event) {
        return _vm.$set(_vm.customer, "files", $event);
      }
    }
  })], 1)]) : _vm._e(), _vm.customer.statusCode === 0 ? _c('div', {
    staticClass: "sub-block",
    staticStyle: {
      "padding-left": "32px",
      "padding-right": "32px"
    }
  }, [_vm._m(7), _c('div', {
    staticStyle: {
      "padding-top": "16px",
      "padding-bottom": "40px"
    }
  }, [_c('FileUpload', {
    attrs: {
      "files": _vm.customer.files,
      "clientSidepanelOpen": _vm.clientSidepanelOpen,
      "targetId": _vm.customer.customerId,
      "target-object": 'contact',
      "hide-button": true,
      "id": 'customer-upload',
      "buttonCornerLocation": false,
      "file-counter": _vm.customer.files.length,
      "aml-panel": 0
    },
    on: {
      "success": function ($event) {
        return _vm.getCustomer(_vm.customer.customerId);
      },
      "update:files": function ($event) {
        return _vm.$set(_vm.customer, "files", $event);
      }
    }
  })], 1)]) : _vm._e()], 1)], 1) : _vm._e()]), _vm.dealAddOpen ? _c('SidepanelAdd', {
    attrs: {
      "contactPerson": _vm.customer,
      "fromAnotherPanel": true
    },
    on: {
      "closed": function ($event) {
        return _vm.closeDealAdd();
      },
      "saveResponse": _vm.closeDealAdd
    }
  }) : _vm._e(), _vm.orgDetailOpen ? _c('OrganizationSidepanel', {
    attrs: {
      "selected-organization": _vm.clickedOrg
    },
    on: {
      "closed": function ($event) {
        return _vm.closeDealAdd();
      },
      "panelClosed": function ($event) {
        return _vm.closePanel();
      }
    }
  }) : _vm._e(), _vm.interestsOpen ? _c('InterestPanel', {
    attrs: {
      "customer-id": _vm.customer.customerId,
      "existingInterest": _vm.interestToEdit,
      "customer-data": _vm.customer
    },
    on: {
      "closed": function ($event) {
        return _vm.closeInterests();
      }
    }
  }) : _vm._e(), _vm.listingPanelOpen ? _c('ListingSidepanel', {
    attrs: {
      "listing-preview-id": _vm.chosenListingPreviewId,
      "contact-view": true
    },
    on: {
      "overlayClicked": _vm.closeListingPanel
    }
  }) : _vm._e(), _vm.editModalOpen || _vm.newCustomerAdd ? _c('AddModal', {
    staticStyle: {
      "z-index": "120"
    },
    attrs: {
      "customerData": _vm.newCustomerAdd ? null : _vm.customer,
      "isEdit": _vm.editModalOpen,
      "not-contact-view-route": true
    },
    on: {
      "customerUpdated": _vm.customerUpdated,
      "newAddedContact": _vm.addContactToRelatedPeople,
      "closed": _vm.closeAddModal,
      "deleted": _vm.customerDeleted
    }
  }) : _vm._e(), _vm.isAddBrokerOpen ? _c('AddBrokerModal', {
    key: _vm.updater,
    attrs: {
      "item": _vm.customer,
      "customer-modal": true
    },
    on: {
      "update:item": function ($event) {
        _vm.customer = $event;
      },
      "close-modal": function ($event) {
        _vm.isAddBrokerOpen = false;
      },
      "updated": function ($event) {
        return _vm.setupContact();
      },
      "transferCustomerOwnership": _vm.handleTransferCustomer
    }
  }) : _vm._e(), _vm.isDeleteModal ? _c('DeleteModal', {
    staticStyle: {
      "z-index": "121"
    },
    attrs: {
      "removing": true
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModal = false;
      },
      "approved": function ($event) {
        return _vm.deleteRelatedCustomer();
      }
    }
  }) : _vm._e(), _vm.brokerDetailOpened ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.clickedBroker,
      "position": _vm.brokerPanelPosition
    },
    on: {
      "closed": function () {
        return _vm.brokerDetailOpened = false;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "separator-row-main"
  }, [_c('td', {
    staticClass: "separator-row"
  })]);

}]

export { render, staticRenderFns }