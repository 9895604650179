var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleOutsideClick,
      expression: "handleOutsideClick"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 10,
      "resizable": false,
      "drag-handle": ".drag-area"
    },
    on: {
      "dragstop": _vm.onDragStop
    }
  }, [_c('div', {
    staticClass: "link-listing-widget"
  }, [_c('div', {
    staticClass: "widget-header drag-area"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "close-button"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/close_grey.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.closePanel
    }
  })])]), _c('div', {
    staticStyle: {
      "color": "black",
      "padding": "12px 24px 0 24px"
    }
  }, [_c('h5', [_vm._v(_vm._s(_vm.$t("ChangeLinkedProject")))])]), _c('div', {
    staticStyle: {
      "padding": "0 24px",
      "height": "60px"
    }
  }, [_c('v-radio-group', {
    staticStyle: {
      "padding-top": "12px"
    },
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.module,
      callback: function ($$v) {
        _vm.module = $$v;
      },
      expression: "module"
    }
  }, [_c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "color": "#FF5C01",
      "value": "project",
      "label": _vm.$t('Project')
    }
  }), _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "color": "#FF5C01",
      "value": "development",
      "label": _vm.$t('Development')
    }
  })], 1)], 1), _c('div', {
    staticClass: "dropdown-row"
  }, [_vm.module == 'project' ? _c('ItemSearch', {
    attrs: {
      "items": _vm.foundItems,
      "placeholder": _vm.$t('Search'),
      "name-key": 'name'
    },
    on: {
      "itemChosen": _vm.newLink,
      "focus-event": _vm.onFocus,
      "load-more": _vm.loadMoreItems,
      "search": _vm.performSearch
    }
  }) : _vm._e(), _vm.module == 'development' ? _c('ItemSearch', {
    attrs: {
      "items": _vm.foundItems,
      "placeholder": _vm.$t('Search'),
      "name-key": 'projectName'
    },
    on: {
      "itemChosen": _vm.setListingsLink,
      "focus-event": _vm.onFocus,
      "load-more": _vm.loadMoreItems,
      "search": _vm.performSearch
    }
  }) : _vm._e()], 1), _vm.module == 'development' && _vm.buildingItems.length > 0 ? _c('div', {
    staticClass: "dropdown-row"
  }, [_c('BaseDropdown', {
    attrs: {
      "items": _vm.buildingItems,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": false,
      "placeholder": _vm.$t('SelectBuilding'),
      "type": "dropdown",
      "required": "true"
    },
    model: {
      value: _vm.selectedBuilding,
      callback: function ($$v) {
        _vm.selectedBuilding = $$v;
      },
      expression: "selectedBuilding"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "modal-content"
  }, [_c('p', {
    staticClass: "content-title"
  }, [_vm._v(_vm._s(_vm.$t("LinkedProject")))]), _c('div', {
    staticStyle: {
      "font-weight": "500",
      "font-size": "14px",
      "padding-top": "8px",
      "height": "28px",
      "display": "flex",
      "gap": "12px"
    }
  }, [_vm.linkedEntity && _vm.linkedEntity.pipelineStep ? _c('img', {
    attrs: {
      "src": require("../../assets/images/projects.svg"),
      "alt": ""
    }
  }) : _vm._e(), _vm.linkedEntity && _vm.linkedEntity.address && _vm.linkedEntity.address.units ? _c('img', {
    attrs: {
      "src": require("../../assets/images/PropertyDeveloper.svg"),
      "alt": ""
    }
  }) : _vm._e(), _c('p', [_vm._v(" " + _vm._s(_vm.linkedEntity.name ? _vm.linkedEntity.name : _vm.linkedEntity.projectName ? _vm.linkedEntity.projectName : "") + " ")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }