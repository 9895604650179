<template>
  <div
    class="dropdown"
    :style="{ width: dropdownWidth }"
    :tabindex="!isInput ? 0 : null"
    @keydown="handleKeyDown"
    :class="{ 'dropdown-disabled': disabled || shouldDisable }"
    @focus="handleFocus"
    @blur="handleBlur"
  >
    <div
      ref="container"
      class="field-style"
      :key="dropdownId"
      :name="'dropdown-' + dropdownId"
      @mousedown="toggleDropdown"
      @click="focusInput"
      :style="{ height: height, fontSize: fontSize, cursor: cursorStyle }"
      :class="{
        'required-mark': required,
        'dropdown-error': error
      }"
    >
      <label
        v-if="fieldType"
        ref="label"
        class="content label-on-input"
        style="color: #75787a;"
        :class="{ 'focused-class': inputFocus, 'focused-text': dropdownOpen }"
      >{{ fieldType }}</label>
      <div class="input-wrapper" :style="{ transform: translateY }">
        <div class="placeholder-wrapper">
          <div class="input-placeholder" :style="{ color: fontColor, height: height, opacity: placeholderOpacity }">
            <span class="truncate-text">{{ getPlaceholderText() }}</span>
          </div>
           <input
            ref="input"
            class="input-field content input-blur"
            v-model="searchInput"
            v-if="isInput"
            :readonly="!isInputFocused"
            @focus="handleInputFocus()"
            @click="isInputFocused = true"
            @blur="handleInputBlur()"
            :style="{ cursor: cursorStyle, fontSize: fontSize, transform: fieldType ? 'translateY(14px)' : 'translateY(5px)'  }"
            :placeholder="showPlaceholder ? $t('Search') : '' "
            :disabled="isInputDisabled || shouldDisable"
          />
        </div>
      </div>
      <img
        :src="require(`@/assets/images/dropdown_20_20.svg`)"
        alt=""
        :class="{
        'arrow-disabled': disabled || shouldDisable,
        }"
      />
    </div>
    <div class="dropdown-wrapper" :style="{ fontSize: fontSize }">
      <div v-if="dropdownOpen" class="dropdown-items" ref="dropdownContainer">
        <div class="items-wrapper"
          v-for="(entry, index) in flattenedDropdownItems"
          :key="index"
          @mousedown.prevent.stop="!entry.heading && canSelectOrDeselect(entry) && entry.type === 'item' ? toggleItemSelection(entry) : null"
          @mouseover="resetFocusedIndex"
          :ref="`item${index}`"
          :class="{
            'selected': !entry.heading && entry.type === 'item' && isSelected(entry),
              'subtitle': entry.heading
          }"
          :style="{ fontSize: fontSize }"
        >
        <div v-if="entry.type === 'item' && !entry.heading" class="dropdown-item" style="width: 100%;" :class="{'focused-item': !entry.heading && index === currentFocusedIndex}"
        >
            <div class="toggle-wrapper">
                <img class="toggle-img" :src="getToggleImage(entry)" alt="" />
            </div>
            <div class="translated-label-item" v-if="translate && _typeOf(entry) === 'object'">
                {{ $t(entry[displayKey]) }}
            </div>
            <div class="label-item" v-else-if="_typeOf(entry) === 'object'">
                {{ entry[displayKey] }}
            </div>
            <div class="translated-label-item" v-else-if="translate && _typeOf(entry) !== 'object'" >
                {{ $t(entry) }}
            </div>
            <div class="label-item" v-else>
                {{ entry }}
            </div>
        </div>
        <div v-else class="item-sub-title">
            <div class="category-title">{{ $t(entry.name) }}</div>
        </div>
       </div>
      </div>
    </div>
    <div v-if="error && this.required" class="content error-message">
      {{ fieldErrorMessage ? fieldErrorMessage : $t("FormErrors.FieldRequired") }}
    </div>
  </div>
</template>

<script>
import nanoid from "nanoid";
import toggleActive from '@/assets/images/toggle-active.svg'
import toggleInactive from '@/assets/images/toggle-inactive.svg'

export default {
  name: "DropdownMulti",
  props: {
    items: {
      type: Array,
      required: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    width: {
      type: String,
      default: 'auto'
    },
    fontSize: {
      type: String,
      default: '14px'
    },
    required: {
      type: Boolean,
      default: false
    },
    fieldType: String,
    valueKey: {
      type: String,
      required: false,
    },
    displayKey: {
      type: String,
      required: false,
    },
    placeholderText: String,
    selectedItems: {
      type: Array,
      default: () => [],
    },
    enableSearch: {
      type: Boolean,
      default: false
    },
    searchThreshold: {
      type: Number,
      default: 5
    },
    error: null,
    defaultValue: String,
    translate: Boolean,
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    accessLevel: {
      type: String,
      default: undefined
    },
    accessItems: {
    type: Object,
    default: () => ({})
  },
  },
  data() {
    return {
      dropdownId: null,
      filteredItems: [],
      dropdownOpen: false,
      searchInput: '',
      fieldErrorMessage: '',
      toggleActive,
      toggleInactive,
      isInputFocused: false,
      currentFocusedIndex: -1,
      searchRegex: null,
      isInputDisabled: false,
      isSelectingItem: false,
      dropdownWidth: 'auto',
      disabledChange: false,
    };
  },
  mounted() {
    this.dropdownId = nanoid(10);
    if(this.selectedItems) {
      this.filteredItems = this.selectedItems;
    }
    window.addEventListener('click', this.windowClickListener);
  },
  updated() {
    this.checkAndFocusInput();
  },
  watch: {
    disabled(newVal, oldVal) {
      if (newVal === true && oldVal === false) {
        this.disabledChange = true;
      } else {
        this.disabledChange = false;
      }
    },
    selectedItems(newVal) {
      this.filteredItems = newVal;
    },
    value(newVal) {
      this.filteredItems = newVal;
    },
    filteredItems(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('input', newVal);
      }
      this.checkValidity();
    },
    searchInput(newVal) {
      this.searchRegex = new RegExp(newVal.toLowerCase(), 'i');
    },
    dropdownOpen(newValue) {
      this.$nextTick(() => {
        if (newValue && !this.fieldType) {
          const dropdownWidth = this.$refs.container.offsetWidth;
          const dropdownItemsWidth = this.$refs.dropdownContainer.offsetWidth;

          let maxWidth = Math.min(Math.max(dropdownWidth, dropdownItemsWidth), 200);

          this.$refs.container.style.width = `${maxWidth}px`;
          this.$refs.dropdownContainer.style.width = `${maxWidth}px`;

          this.$refs.dropdownContainer.style.wordWrap = 'break-word';
          this.$refs.dropdownContainer.style.overflowWrap = 'break-word';
          this.$refs.dropdownContainer.style.whiteSpace = 'normal';
        } else {
          this.$refs.container.style.width = '100%';
          this.$refs.dropdownContainer.style.width = '100%';
        }
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener('click', this.windowClickListener);
  },
  computed: {
    filteredDropdownItems() {
      if (!this.enableSearch || this.searchInput.length === 0 || !this.searchRegex) {
        return this.items;
      }
      if (Array.isArray(this.items)) {
        return this.items.filter(item => {
          let displayValue = this.displayKey ? this.getValueByPath(item, this.displayKey) : item;
          displayValue = displayValue.toLowerCase();
          return this.searchRegex.test(displayValue);
        });
      }
      else if (typeof this.items === 'object') {
        let result = {};
        Object.keys(this.items).forEach(category => {
          let filteredCategoryItems = this.items[category].filter(item => {
            let displayValue = this.displayKey ? this.getValueByPath(item, this.displayKey) : item;
            displayValue = displayValue.toLowerCase();
            return this.searchRegex.test(displayValue);
          });

          if (filteredCategoryItems.length > 0) {
            result[category] = filteredCategoryItems;
          }
        });
        return result;
      }
      else {
        console.warn("The 'items' prop is neither an array nor an object.");
        return [];
      }
    },
    placeholderOpacity() {
      return this.isInputFocused ? 0 : 1;
    },
    isReadonly() {
      return !this.enableSearch || this.items.length <= this.searchThreshold || this.disabled;
    },
    cursorStyle() {
      if (this.disabled) {
        return 'default';
      } else {
        return 'pointer';
      }
    },
    inputFocus() {
      return (!this.isReadonly && this.isInputFocused) || this.filteredItems.length > 0 || (this.isInputFocused && this.fieldType);
    },
    fieldError() {
      return this.required && this.filteredItems.length === 0;
    },
    focusedItem() {
      return this.$refs[`item${this.currentFocusedIndex}`];
    },
    translateY() {
      return this.fieldType ? 'translateY(6px)' : '';
    },
    fontColor() {
      return this.filteredItems.length > 0 ? '' : '#75787a';
    },
    showPlaceholder() {
      return this.isInputFocused && this.searchInput === '';
    },
    isInput() {
      return this.enableSearch && !this.disabled;
    },
    shouldDisable() {
        return this.disabledChange || this.items.length === 0;
    },
    flattenedDropdownItems() {
      let result = [];

      const itemsToFlatten = this.filteredDropdownItems;
      if (Array.isArray(itemsToFlatten)) {
        itemsToFlatten.forEach(item => {
          result.push({
            type: 'item',
            ...item
          });
        });
      }
      else if (typeof itemsToFlatten === 'object') {
        Object.keys(itemsToFlatten).forEach(category => {
          result.push({
            type: 'category',
            label: category
          });

          itemsToFlatten[category].forEach(item => {
            result.push({
              type: 'item',
              ...item
            });
          });
        });
      }

      return result;
    }
  },
  methods: {
    _typeOf(entry) {
      return typeof entry
    },
    getToggleImage(entry) {
      return this.isSelected(entry) ? this.toggleActive : this.toggleInactive;
    },
    focusInput() {
      if (this.enableSearch && !this.disabled) {
        this.isInputFocused = true;
      }
      this.$refs.input.focus();
    },
    canSelectOrDeselect(entry) {
      if (!this.accessLevel) {
        return true
      } else {
          if(this.accessLevel === 'company_admin') {
          return true;
        }
        const matchedAccessItem = this.accessItems[entry.value._id];
        if (!matchedAccessItem || (matchedAccessItem && !matchedAccessItem.isAdmin)) {
          return false;
        }
        return true;
      }
    },
    checkAndFocusInput() {
      if (this.dropdownOpen && this.selectedItems.length === 0) {
        this.$refs.input.focus();
      }
    },
    handleInputFocus() {
      this.dropdownOpen = true;
      if (!this.isReadonly) {
          this.isInputFocused = true;
      }
      this.$nextTick(() => {
          this.$refs.dropdownContainer.focus();
      });
      this.isInputDisabled = false;
    },
    handleBlur() {
      if (!this.isSelectingItem) {
        this.dropdownOpen = false;
      }
    },
    handleFocus() {
      this.dropdownOpen = true;
      if (!this.isReadonly && !this.isSelectingItem) {
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
    handleInputBlur() {
      this.isInputFocused = false;
      if (!this.dropdownOpen) {
        this.dropdownOpen = false;
      }
    },
    toggleDropdown() {
      if (!this.disabled && this.isReadonly) {
        this.dropdownOpen = !this.dropdownOpen;
      } else if (!this.disabled && !this.isReadonly) {
        this.isInputDisabled = false;
      }
    },
    resetFocusedIndex() {
      this.currentFocusedIndex = -1;
    },
    checkValidity() {
      if (this.required && this.filteredItems.length < 1) {
        this.$emit('update:validity', false);
      } else {
        this.$emit('update:validity', true);
      }
    },
    getPlaceholderText() {
      const count = this.filteredItems.length;
      if (this.disabledChange || this.items.length === 0) {
        return this.defaultValue
      } else {
        if (count === 0) {
          return this.defaultValue !== '' && !this.isInputFocused ? this.defaultValue : this.placeholderText;
        }
        if (count === 1) {
          const item = this.items.find(item =>
            (this.valueKey && typeof item === 'object'
              ? this.getValueByPath(item, this.valueKey)
              : item) ===
            (this.valueKey && typeof this.filteredItems[0] === 'object'
              ? this.getValueByPath(this.filteredItems[0], this.valueKey)
              : this.filteredItems[0]));
          if (item) {
            let result;
            if (typeof item === 'object' && this.displayKey) {
              result = this.getValueByPath(item, this.displayKey);
            } else {
              result = item;
            }
            return this.translate ? this.$t(result) : result;
          } else {
            return '';
          }
        }
        return `${count} ${this.$t('Selected')}`;
      }
    },
    isSelected(item) {
      const itemId = this.valueKey ? this.getValueByPath(item, this.valueKey) : item;
      return this.filteredItems.some(selectedItem =>
        (this.valueKey && typeof selectedItem === 'object'
          ? this.getValueByPath(selectedItem, this.valueKey)
          : selectedItem) === itemId);
    },
    toggleItemSelection(item) {
      this.isSelectingItem = true;
      const itemId = this.valueKey ? this.getValueByPath(item, this.valueKey) : item;
      const isSelected = this.filteredItems.some(selectedItem =>
        (this.valueKey && typeof selectedItem === 'object'
          ? this.getValueByPath(selectedItem, this.valueKey)
          : selectedItem) === itemId);
      if (isSelected) {
        this.filteredItems = this.filteredItems.filter(selectedItem =>
          (this.valueKey && typeof selectedItem === 'object'
            ? this.getValueByPath(selectedItem, this.valueKey)
            : selectedItem) !== itemId);
      } else {
        this.filteredItems = [...this.filteredItems, item];
      }
      if (this.isInputFocused) {
        this.$refs.input.focus();
      }
    },
    getValueByPath(obj, path) {
      if (!obj || typeof obj !== 'object') {
        console.log('not an object')
        return null;
      }
      const keys = path.split('.');
      let current = obj;
      for (const key of keys) {
        if (!current || typeof current !== 'object') {
          return;
        }
        current = current[key];
      }
      return current;
    },
    handleKeyDown(e) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (this.dropdownOpen) {
            this.currentFocusedIndex++;
            while (this.currentFocusedIndex < this.flattenedDropdownItems.length && this.flattenedDropdownItems[this.currentFocusedIndex].type === 'category') {
              this.currentFocusedIndex++;
            }
            if (this.currentFocusedIndex >= this.flattenedDropdownItems.length) {
              this.currentFocusedIndex = this.flattenedDropdownItems.length - 1;
            }
          }
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (this.dropdownOpen) {
            this.currentFocusedIndex--;
            while (this.currentFocusedIndex >= 0 && this.flattenedDropdownItems[this.currentFocusedIndex].type === 'category') {
              this.currentFocusedIndex--;
            }
            if (this.currentFocusedIndex < 0) {
              this.currentFocusedIndex = 0;
            }
          }
          break;
        case 'Enter':
          if (this.dropdownOpen && this.currentFocusedIndex >= 0 && this.flattenedDropdownItems[this.currentFocusedIndex].type !== 'category') {
            this.toggleItemSelection(this.flattenedDropdownItems[this.currentFocusedIndex]);
          }
          break;
        case 'Tab':
          this.dropdownOpen = false;
          break;
      }
    },
    windowClickListener(e) {
      if (!this.$el.contains(e.target)) {
        this.dropdownOpen = false;
        this.currentFocusedIndex = -1;
        this.searchInput = '';
        this.$refs.input.blur();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  font-weight: bold;
  padding: 12px;
}

.truncate-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  flex: 1;
}

.input-blur {
  opacity: 0
}

.dropdown-item {
  display: flex;
  align-items: center;
  height: 48px;
}

.input-blur:focus {
  opacity: 1
}

.placeholder-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.label-on-input {
  position: absolute;
  pointer-events: none;
  transition: transform 0.3s ease, font-size 0.3s ease;
}

.input-wrapper {
  width: 100%;
  position: relative;
  flex-direction: column;
  min-width: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-field {
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
}

.input-placeholder {
  display: flex;
  align-items: center;
  z-index: 1;
  pointer-events: none;
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
}

.focused-class {
  transform: translateY(-12px);
  font-size: 11px !important;
  transition: transform 0.3s ease, font-size 0.3s ease;
}

.focused-text {
  color: #FF5C01 !important;
}

.field-style {
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 7px 11px;
  gap: 12px;
  background: white;
  cursor: pointer;
  min-width: 140px;
}

.form-field-large label {
  color: #75787a;
}

.dropbtn {
  background-color: white;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  padding: 8px 12px 8px 12px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  color: #000000;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: default;
  img {
    margin-left: 8px;
  }
}

.dropdown-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.dropdown-items {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0px 1px 10px 1px rgba(0, 0, 0, 0.08), 0px 3px 10px -2px rgba(0, 0, 0, 0.03);
  z-index: 110;
  border-radius: 8px;
  position: absolute;
  max-height: 288px;
  width: max-content;
  min-width: 140px;
  white-space: nowrap;
  margin: 8px 0 0 0;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  background-color: white;
}

.items-wrapper {
  display: flex;
  height: 48px;
  align-items: center;
  background-color: transparent;
  outline: none;
}

.dropdown-item:hover {
background-color: #f4f5f7;
}

.disable-hover {
  background-color: initial !important;
}

.search-wrapper {
  display: flex;
  padding: 12px;
  border-bottom: 1px solid #ff5c01;
}

.dropbtn-hover {
  &:hover,
  &:focus {
    cursor: pointer;

    background-color: #f4f5f7;
  }
}

.dropbtn-disabled {
  color: #f0f0f0 !important;
}

.dropdown {
  position: relative;
  white-space: nowrap;
}

.dropdown:focus {
  outline: none;
}

.dropdown-error {
  border: 2px solid rgb(255, 30, 36);
}
.sub-label {
  font-size: 12px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold !important;
  line-height: 16px;
}
.dropdown-item-wrapper {
  cursor: pointer;
  padding: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  &:hover {
    background-color: #f4f5f7;
  }
}
.dropdown-item-sub-label {
  padding: 4px 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}


.not-selected,
.selected {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 16px;
}

.selected {
  font-weight: 600;
}

.toggle-wrapper {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
}

.dropdown-placeholder:empty:before {
  content: attr(data-placeholder);
  color: gray;
}

.default-item {
  color: #75787a;
}

.arrow-disabled {
  filter: invert(99%) sepia(1%) saturate(51%) hue-rotate(140deg)
    brightness(115%) contrast(88%);
}

.dropdown-disabled {
  opacity: 0.5;
  cursor: default !important;
}

.error-message {
  color: #ff1e24 !important;
  margin: 12px 12px 0 0;
}


.required-mark label::after {
  content: "*";
  color: #ff1e24;
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.focused-item {
  background: #f4f5f7;
}
</style>

