var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-development"
    }
  }, [_c('div', {
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-development"
    }
  }, [_c('div', {
    staticStyle: {
      "padding-left": "24px"
    },
    attrs: {
      "id": "wrapped-header-development"
    },
    on: {
      "click": _vm.closeContactAdd
    }
  }, [_c('h4', {
    staticStyle: {
      "align-self": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.isEdit ? _vm.$t("ChangeDevelopment") : _vm.$t("AddNewDevelopment")) + " ")])]), _vm.loaded ? _c('div', {
    staticClass: "content-overlay",
    staticStyle: {
      "height": "100%",
      "display": "flex",
      "flex-direction": "column"
    },
    attrs: {
      "id": "content-wrapper"
    }
  }, [_c('div', {
    staticClass: "sticky-header",
    staticStyle: {
      "z-index": "126"
    }
  }, [_c('v-row', {
    staticClass: "top-row",
    staticStyle: {
      "height": "24px",
      "padding-left": "32px"
    }
  }, [!_vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("AddNewDevelopment") + ' ' + _vm.$t("Project").toLowerCase()) + " ")]) : _vm._e(), _vm.isEdit ? _c('h4', {
    staticStyle: {
      "height": "24px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ChangeDevelopment")) + " ")]) : _vm._e()])], 1), _c('div', {
    staticStyle: {
      "padding": "24px 32px 128px 32px",
      "height": "100%",
      "overflow-y": "scroll"
    }
  }, [_c('v-row', {
    staticStyle: {
      "padding-bottom": "12px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("GeneralInformation")))])]), _c('v-row', {
    staticStyle: {
      "gap": "8px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "required": true,
      "id": 'property-developer-name-field',
      "placeholder": _vm.$t('ProjectName')
    },
    model: {
      value: _vm.form.projectName,
      callback: function ($$v) {
        _vm.$set(_vm.form, "projectName", $$v);
      },
      expression: "form.projectName"
    }
  }), _c('v-row', [_vm.user.access.company._id !== 'xmwei_Rt9ryfpkxL4BL8' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "margin-top": "16px",
      "margin-bottom": "16px",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(_vm._s(_vm.$t("AddressType")))]), _c('div', {
    staticClass: "radio-group-label",
    staticStyle: {
      "margin-top": "16px"
    }
  }, [_c('v-radio-group', {
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.form.addressType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "addressType", $$v);
      },
      expression: "form.addressType"
    }
  }, [!_vm.user.isItaly ? _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "id": "address-type-est",
      "color": "#FF5C01",
      "value": "estonia",
      "label": _vm.$t('Estonian')
    },
    on: {
      "click": _vm.switchAddressType
    }
  }) : _vm._e(), _c('v-radio', {
    staticClass: "radio-button",
    attrs: {
      "id": "address-type-generic",
      "color": "#FF5C01",
      "value": "generic",
      "label": _vm.$i18n.locale === 'et' ? _vm.$t('ForeignCountry') : _vm.$t('Generic')
    },
    on: {
      "click": _vm.switchAddressType
    }
  })], 1), _vm.form.addressType === 'generic' && _vm.$i18n.locale === 'et' ? _c('div', {
    staticClass: "disclaimer-row"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/infoMark20.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-small"
  }, [_vm._v(" Välismaa aadressiga tehtud kuulutust ei saa eksportida kinnisvara portaali ")])]) : _vm._e()], 1)]) : _vm._e(), _c('div', {
    staticStyle: {
      "z-index": "125",
      "width": "100%"
    }
  }, [_vm.form.addressType === 'estonia' ? _c('AddressFinder', {
    ref: "addressFinder",
    attrs: {
      "object": _vm.form.address ? _vm.form.address : '',
      "placeholder": _vm.$t('Address'),
      "show-error": _vm.showErrors && !_vm.isFieldValid('object'),
      "error-message": _vm.errors && _vm.errors.object ? _vm.errors.object : ''
    },
    on: {
      "setObject": _vm.setAddressObject,
      "removeObject": _vm.removeAddressObject,
      "loaded": _vm.loadedAF
    }
  }) : _vm._e(), _vm.listingAddressToggle ? _c('div', {
    staticClass: "transfer-listings-addresses"
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeListingsAddress'),
      "type": "number"
    },
    model: {
      value: _vm.changeListingAddress,
      callback: function ($$v) {
        _vm.changeListingAddress = $$v;
      },
      expression: "changeListingAddress"
    }
  })], 1) : _vm._e()], 1), _vm.form.addressType === 'generic' ? _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('GoogleAddressSearch', {
    attrs: {
      "editMode": true,
      "id": 'project-generic-address-search',
      "placeholder": _vm.$t('Address'),
      "required": "true",
      "return-object": true,
      "error": _vm.showErrors && !_vm.isFieldValid('object'),
      "error-message": _vm.errors && _vm.errors.object ? _vm.errors.object : ''
    },
    on: {
      "change": _vm.setAddressObject
    },
    model: {
      value: _vm.googleFormObjects,
      callback: function ($$v) {
        _vm.googleFormObjects = $$v;
      },
      expression: "googleFormObjects"
    }
  })], 1) : _vm._e()])], 1), _c('v-row', {
    staticStyle: {
      "gap": "8px",
      "margin-top": "8px"
    }
  }, [_c('BaseDropdown', {
    attrs: {
      "required": true,
      "id": 'property-developer-currency-field',
      "items": _vm.assetTypes,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": true,
      "placeholder": _vm.$t('DealType1'),
      "type": 'dropdown'
    },
    model: {
      value: _vm.form.assetType,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assetType", $$v);
      },
      expression: "form.assetType"
    }
  }), _c('BaseDropdown', {
    attrs: {
      "required": true,
      "id": 'property-developer-currency-field',
      "items": _vm.assetClasses,
      "show-dropdown-arrow": true,
      "dropdown-allow-null": true,
      "placeholder": _vm.$t('EstateType'),
      "type": 'dropdown'
    },
    model: {
      value: _vm.form.assetClass,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assetClass", $$v);
      },
      expression: "form.assetClass"
    }
  })], 1), _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Contact")))]), _c('ClientListSearch', {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      "id": "project-contact-search",
      "placeholder": _vm.$t('Contact'),
      "icon-left": 'clients.svg',
      "required": true,
      "update-component": _vm.updateSelectedContact,
      "error": _vm.showErrors && !_vm.isFieldValid('contactPerson'),
      "error-message": _vm.errors ? _vm.$t('FormErrors.' + _vm.errors.contactPerson) : ''
    },
    on: {
      "addNew": _vm.openContactAdd,
      "itemChosen": _vm.contactPersonSelected
    },
    model: {
      value: _vm.form.contactPerson,
      callback: function ($$v) {
        _vm.$set(_vm.form, "contactPerson", $$v);
      },
      expression: "form.contactPerson"
    }
  })], 1), _vm.form.contactPerson ? _c('v-row', {
    staticStyle: {
      "padding-bottom": "12px"
    }
  }, [_c('div', {
    staticStyle: {
      "padding-bottom": "12px",
      "padding-top": "12px",
      "font-weight": "600"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Client")) + " ")]), _c('ClientSelection', {
    attrs: {
      "isEdit": _vm.isEdit,
      "existingClient": _vm.initialClient,
      "projectContact": _vm.form.contactPerson,
      "contactId": _vm.form.contactPerson.contactId,
      "newProjectClient": _vm.newProjectClient
    },
    on: {
      "openAddPanel": _vm.addNewPrivateContact,
      "clientSelected": _vm.setProjectClient
    }
  })], 1) : _vm._e(), _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("Image")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "24px",
      "gap": "12px"
    }
  }, [!_vm.isEdit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs": [],
      "upload-msg": _vm.$t('UploadImage')
    },
    on: {
      "changed": function ($event) {
        return _vm.putImages($event);
      }
    }
  }) : _vm._e(), _vm.isEdit ? _c('UploadImages', {
    staticClass: "image-uploader",
    attrs: {
      "Imgs2": _vm.form.images,
      "upload-msg": _vm.$t('UploadImage')
    },
    on: {
      "changed": function ($event) {
        return _vm.updateImages($event);
      }
    }
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    staticClass: "buttons-row",
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.isEdit && _vm.hasAccess(_vm.form, 'developments', 'delete') ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.isDeleteModalOpen = true;
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.changeSidepanelStateDevelopments(0);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "cancel",
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]) : _vm._e(), _vm.isEdit ? _c('button', {
    staticClass: "save-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.updateDevelopment();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e(), !_vm.isEdit ? _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.saveDevelopment();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e()])])]) : _vm._e()]), _vm.clientAddOpen ? _c('AddModal', {
    staticStyle: {
      "z-index": "120"
    },
    attrs: {
      "accordion": true,
      "not-contact-view-route": true
    },
    on: {
      "newAdded": _vm.chooseNewClient,
      "closed": _vm.closeContactAdd
    }
  }) : _vm._e(), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    attrs: {
      "removing": false
    },
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteDevelopment
    }
  }) : _vm._e(), _vm.openAddressConfirmation && _vm.isEdit ? _c('ConfirmationModal', {
    on: {
      "onCancel": _vm.handleListingAddressCancel,
      "onConfirm": function ($event) {
        return _vm.handleListingAddress();
      }
    }
  }, [_c('div', {
    staticClass: "confirmation-wrapper"
  }, [_c('div', {
    staticClass: "confirmation-header"
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("ListingsAddresses")) + " ")])]), _c('div', {
    staticClass: "new-shared-broker"
  }, [_vm._v(" " + _vm._s(_vm.newAddress.address) + " ")]), _c('div', [_c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeListingsAddress'),
      "type": "number"
    },
    model: {
      value: _vm.changeListingAddress,
      callback: function ($$v) {
        _vm.changeListingAddress = $$v;
      },
      expression: "changeListingAddress"
    }
  })], 1)])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }