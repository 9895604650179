var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "invoice-simple-info-card"
  }, [_c('div', {
    staticClass: "invoice-first-row",
    on: {
      "click": function ($event) {
        return _vm.$emit('onPress');
      }
    }
  }, [_c('div', {
    staticClass: "invoice-main-info"
  }, [_c('div', [_vm._v(" " + _vm._s(_vm.invoiceDate) + " ")]), _c('div', {
    staticClass: "content-semibold"
  }, [_vm._v(" " + _vm._s(_vm.invoiceSumTotal) + " ")])]), _c('InvoiceStatusContainer', {
    attrs: {
      "status": _vm.invoice.status
    }
  })], 1), _c('UserNameContainer', {
    attrs: {
      "item": _vm.invoice.creator
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }