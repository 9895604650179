var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-field-container",
    attrs: {
      "id": _vm.id
    }
  }, [_c('div', {
    ref: "container",
    staticClass: "form-field-large",
    style: _vm.fieldStyle,
    attrs: {
      "id": "form-field"
    },
    on: {
      "click": _vm.focusTextarea
    }
  }, [_c('div', {
    class: {
      required: _vm.fieldRequired
    },
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "flex-direction": "column"
    }
  }, [_vm.fieldPlaceholder ? _c('label', {
    ref: "label",
    staticClass: "content",
    style: _vm.labelStyle
  }, [_vm._v(_vm._s(_vm.fieldPlaceholder))]) : _vm._e(), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "textarea",
    staticClass: "content",
    staticStyle: {
      "resize": "none",
      "overflow": "hidden",
      "outline": "none",
      "padding-top": "2px",
      "height": "0px"
    },
    attrs: {
      "id": "content",
      "disabled": _vm.disabled
    },
    domProps: {
      "value": _vm.fieldValue
    },
    on: {
      "focus": _vm.openTextarea,
      "focusout": _vm.unfocusTextarea,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.fieldValue = $event.target.value;
      }
    }
  })])]), _vm.fieldError ? _c('div', {
    staticClass: "content error-message",
    staticStyle: {
      "margin": "12px 12px 0 12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldErrorMessage ? _vm.fieldErrorMessage : _vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }