var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-interests"
    }
  }, [_c('v-card', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "sticky-header"
  }, [_c('div', {
    staticClass: "modal-header"
  }, [!_vm.existingInterest ? _c('h4', {
    staticClass: "modal-title"
  }, [_vm._v(" " + _vm._s(this.$t("AddInterest")) + " ")]) : _c('h4', {
    staticClass: "modal-title"
  }, [_vm._v(_vm._s(this.$t("EditInterest")))])])]), _c('v-divider', {
    staticStyle: {
      "top": "96px",
      "z-index": "111",
      "position": "fixed",
      "width": "560px"
    }
  }), _vm.loaded ? _c('div', {
    staticClass: "content-wrapper"
  }, [!_vm.interest.development ? _c('div', {
    staticClass: "interest-section"
  }, [_c('div', {
    staticClass: "interest-section-header"
  }, [_c('h5', [_vm._v(_vm._s(this.$t("Subblock.GeneralData")))]), _c('div', {
    staticClass: "clear-button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.clearForm('main');
      }
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(this.$t("Clear")) + " ")])])]), _c('div', {
    staticClass: "interest-section-form"
  }, [_c('v-row', [_c('BaseDropdown', {
    key: _vm.interest.transactionType,
    attrs: {
      "id": 'interest-transaction-types',
      "placeholder": this.$t('TransactionType'),
      "dropdown-allow-null": true,
      "items": _vm.correctTransactionTypes,
      "disabled": _vm.interest.transactionType && _vm.module === 'projects',
      "required": true,
      "type": "dropdown",
      "error": _vm.showErrors && !_vm.interest.transactionType
    },
    model: {
      value: _vm.interest.transactionType,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "transactionType", $$v);
      },
      expression: "interest.transactionType"
    }
  })], 1), _c('v-row', [_c('BaseDropdown', {
    key: _vm.interest.estateType,
    attrs: {
      "id": 'interest-estate-types',
      "placeholder": this.$t('EstateType'),
      "dropdown-allow-null": false,
      "items": _vm.estateTypes,
      "required": true,
      "type": "dropdown",
      "error": _vm.showErrors && !_vm.interest.estateType
    },
    on: {
      "change": function ($event) {
        return _vm.resetExtras($event);
      }
    },
    model: {
      value: _vm.interest.estateType,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "estateType", $$v);
      },
      expression: "interest.estateType"
    }
  })], 1), _vm.correctExtraTypes && _vm.correctExtraTypes.length ? _c('v-row', [_c('DropdownMulti', {
    key: 'extras' + _vm.multiUpdater,
    attrs: {
      "custom-style-button": 'width: 100% !important; height: 52px;',
      "custom-style": 'width: 100% !important; height: 52px;',
      "items": _vm.correctExtraTypes,
      "display-key": 'name',
      "value-key": 'name',
      "width": '100%',
      "regular-size": true,
      "default-item": _vm.extraTypes.placeholder,
      "selected": _vm.interest.extraTypes,
      "pre-selected-items": _vm.interest.extraTypes
    },
    on: {
      "selectedItems": function ($event) {
        _vm.interest.extraTypes = Array.prototype.slice.call(arguments);
      }
    }
  })], 1) : _vm._e()], 1)]) : _vm._e(), !_vm.interest.development ? _c('div', {
    staticClass: "interest-section white-background"
  }, [_c('div', {
    staticClass: "interest-section-header"
  }, [_c('h5', [_vm._v(_vm._s(this.$t("Area")))]), _c('div', {
    staticClass: "clear-button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.clearForm('address');
      }
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(this.$t("Clear")) + " ")])])]), _c('div', {
    key: _vm.addressUpdater,
    staticClass: "interest-section-form"
  }, [_vm.counties ? _c('BaseDropdown', {
    attrs: {
      "id": 'interest-counties',
      "placeholder": this.$t('County'),
      "items": _vm.counties,
      "return-object": true,
      "type": "dropdown"
    },
    on: {
      "change": _vm.setCounty
    },
    model: {
      value: _vm.interest.address.county,
      callback: function ($$v) {
        _vm.$set(_vm.interest.address, "county", $$v);
      },
      expression: "interest.address.county"
    }
  }) : _vm._e(), _c('v-row', {
    staticStyle: {
      "display": "flex",
      "margin-top": "12px",
      "gap": "12px",
      "flex-wrap": "nowrap"
    }
  }, [_c('DropdownMulti', {
    attrs: {
      "items": _vm.cities(_vm.interest),
      "selected-items": _vm.interest.address.cities,
      "width": '221px',
      "height": '52px',
      "display-key": 'name',
      "value-key": 'value',
      "default-value": _vm.$t('City')
    },
    on: {
      "input": _vm.updateSelectedCities
    }
  }), _c('DropdownMulti', {
    attrs: {
      "items": _vm.cityParts(_vm.interest),
      "selected-items": _vm.interest.address.cityParts,
      "width": '221px',
      "height": '52px',
      "display-key": 'name',
      "value-key": 'value',
      "default-value": _vm.$t('CityPart')
    },
    on: {
      "input": _vm.updateSelectedCityParts
    }
  })], 1)], 1)]) : _vm._e(), _c('div', {
    staticClass: "interest-section"
  }, [_c('div', {
    staticClass: "interest-section-header"
  }, [_c('h5', [_vm._v(_vm._s(this.$t("GeneralSpecifications")))]), _c('div', {
    staticClass: "clear-button",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.clearForm('general');
      }
    }
  }, [_c('p', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(this.$t("Clear")) + " ")])])]), _c('div', {
    staticClass: "interest-section-form"
  }, [_c('v-row', {
    staticClass: "price-row"
  }, [_c('BaseInput', {
    attrs: {
      "id": "min-price",
      "type": "number",
      "format": 'price',
      "placeholder": this.$t('MinPrice')
    },
    model: {
      value: _vm.interest.minPrice,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "minPrice", $$v);
      },
      expression: "interest.minPrice"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": "max-price",
      "type": "number",
      "format": 'price',
      "placeholder": this.$t('MaxPrice')
    },
    model: {
      value: _vm.interest.maxPrice,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "maxPrice", $$v);
      },
      expression: "interest.maxPrice"
    }
  })], 1), _c('v-row', {
    staticClass: "price-row"
  }, [_c('BaseInput', {
    attrs: {
      "id": "min-price-m2",
      "type": "number",
      "placeholder": this.$t('MinPrice'),
      "icon-right": "euro_m2.svg"
    },
    model: {
      value: _vm.interest.minPricePerSquareMeter,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "minPricePerSquareMeter", $$v);
      },
      expression: "interest.minPricePerSquareMeter"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": "max-price-m2",
      "type": "number",
      "placeholder": this.$t('MaxPrice'),
      "icon-right": "euro_m2.svg"
    },
    model: {
      value: _vm.interest.maxPricePerSquareMeter,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "maxPricePerSquareMeter", $$v);
      },
      expression: "interest.maxPricePerSquareMeter"
    }
  })], 1), _c('v-row', {
    staticClass: "price-row"
  }, [_c('BaseInput', {
    attrs: {
      "id": "min-general-surfaceArea",
      "type": "number",
      "placeholder": 'Min ' + this.$t('generalSurfaceArea'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.interest.minGeneralSurfaceArea,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "minGeneralSurfaceArea", $$v);
      },
      expression: "interest.minGeneralSurfaceArea"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": "max-general-surfaceArea",
      "type": "number",
      "placeholder": 'Max ' + this.$t('generalSurfaceArea'),
      "icon-right": "SquareMeter.svg"
    },
    model: {
      value: _vm.interest.maxGeneralSurfaceArea,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "maxGeneralSurfaceArea", $$v);
      },
      expression: "interest.maxGeneralSurfaceArea"
    }
  })], 1), _c('v-row', {
    staticClass: "price-row"
  }, [_c('BaseInput', {
    attrs: {
      "id": "min-general-surfaceArea",
      "type": "number",
      "placeholder": 'Min ' + this.$t('roomCount')
    },
    model: {
      value: _vm.interest.minNumberOfRooms,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "minNumberOfRooms", $$v);
      },
      expression: "interest.minNumberOfRooms"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": "max-number-of-rooms",
      "type": "number",
      "placeholder": 'Max ' + this.$t('roomCount')
    },
    model: {
      value: _vm.interest.maxNumberOfRooms,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "maxNumberOfRooms", $$v);
      },
      expression: "interest.maxNumberOfRooms"
    }
  })], 1), _c('v-row', {
    staticClass: "price-row"
  }, [_c('BaseInput', {
    attrs: {
      "id": "min-floor",
      "type": "number",
      "placeholder": this.$t('FloorFrom')
    },
    model: {
      value: _vm.interest.minFloor,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "minFloor", $$v);
      },
      expression: "interest.minFloor"
    }
  }), _c('BaseInput', {
    attrs: {
      "id": "max-floor",
      "type": "number",
      "placeholder": this.$t('FloorTo')
    },
    model: {
      value: _vm.interest.maxFloor,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "maxFloor", $$v);
      },
      expression: "interest.maxFloor"
    }
  })], 1)], 1), _c('div', {
    staticClass: "extras-section"
  }, [_c('p', {
    staticClass: "content-small-bold"
  }, [_vm._v(_vm._s(this.$t("Extras")))]), _c('div', {
    staticClass: "extras-row"
  }, [_c('BaseToggle', {
    attrs: {
      "label": this.$t('Balcony')
    },
    model: {
      value: _vm.interest.extras.balcony,
      callback: function ($$v) {
        _vm.$set(_vm.interest.extras, "balcony", $$v);
      },
      expression: "interest.extras.balcony"
    }
  }), _c('BaseToggle', {
    attrs: {
      "label": this.$t('Parking')
    },
    model: {
      value: _vm.interest.extras.parking,
      callback: function ($$v) {
        _vm.$set(_vm.interest.extras, "parking", $$v);
      },
      expression: "interest.extras.parking"
    }
  }), _c('BaseToggle', {
    attrs: {
      "label": this.$t('RoomsSeparate')
    },
    model: {
      value: _vm.interest.extras.roomsSeparate,
      callback: function ($$v) {
        _vm.$set(_vm.interest.extras, "roomsSeparate", $$v);
      },
      expression: "interest.extras.roomsSeparate"
    }
  }), _c('BaseToggle', {
    attrs: {
      "label": this.$t('Storeroom')
    },
    model: {
      value: _vm.interest.extras.storeRoom,
      callback: function ($$v) {
        _vm.$set(_vm.interest.extras, "storeRoom", $$v);
      },
      expression: "interest.extras.storeRoom"
    }
  }), _c('BaseToggle', {
    attrs: {
      "label": this.$t('Sauna')
    },
    model: {
      value: _vm.interest.extras.sauna,
      callback: function ($$v) {
        _vm.$set(_vm.interest.extras, "sauna", $$v);
      },
      expression: "interest.extras.sauna"
    }
  })], 1)])]), _c('div', {
    staticClass: "interest-section white-background"
  }, [_c('div', {
    staticClass: "interest-section-header"
  }, [_c('h5', [_vm._v(_vm._s(this.$t("Comment")))])]), _c('div', {
    staticClass: "interest-section-form"
  }, [_c('BaseTextarea', {
    attrs: {
      "id": "interest-note"
    },
    model: {
      value: _vm.interest.comment,
      callback: function ($$v) {
        _vm.$set(_vm.interest, "comment", $$v);
      },
      expression: "interest.comment"
    }
  })], 1)])]) : _vm._e(), _c('div', {
    staticClass: "buttons-container"
  }, [_c('v-divider', {
    staticStyle: {
      "z-index": "111",
      "position": "absolute",
      "width": "560px"
    }
  }), _c('v-row', {
    staticStyle: {
      "justify-content": "space-between",
      "padding-right": "50px",
      "padding-top": "24px",
      "padding-left": "24px"
    }
  }, [_c('div', [_c('button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.existingInterest && _vm.projectId || _vm.existingInterest && !_vm.customerData.isProspect,
      expression: "\n              (existingInterest && projectId) ||\n              (existingInterest && !customerData.isProspect)\n            "
    }],
    staticClass: "delete-button",
    staticStyle: {
      "align-self": "flex-start"
    },
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.deleteInterest();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/TrashBin.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(this.$t("Delete")) + " ")])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "cancel-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v(" " + _vm._s(this.$t("Cancel")) + " ")]), _c('button', {
    staticClass: "save-button",
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.submitInterest();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "padding-right": "8px"
    },
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(this.$t("Submit")) + " ")])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }