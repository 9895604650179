var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeWidget,
      expression: "closeWidget"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 99,
      "resizable": false,
      "drag-handle": ".drag-area",
      "h": "auto"
    }
  }, [_c('div', {
    staticClass: "drag-area invoice-payment-widget-header"
  }, [_c('img', {
    attrs: {
      "width": "104",
      "height": "11",
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "invoice-payment-widget-content"
  }, [_c('div', {
    staticClass: "font-18-bold",
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('AddPaymentToInvoice')) + " " + _vm._s(_vm.invoice.number) + " ")]), _c('DatePick', {
    attrs: {
      "nextMonthCaption": _vm.nextMonthCaption,
      "weekdays": _vm.weekdays,
      "prevMonthCaption": _vm.prevMonthCaption,
      "months": _vm.months
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var toggle = _ref.toggle;
        return [_c('div', {
          staticStyle: {
            "display": "flex",
            "gap": "12px",
            "cursor": "pointer"
          },
          on: {
            "click": toggle
          }
        }, [_c('img', {
          attrs: {
            "width": "20",
            "height": "20",
            "src": require('@/assets/images/calendar_20_20.svg'),
            "alt": ""
          }
        }), _c('div', {
          staticClass: "required",
          staticStyle: {
            "display": "flex",
            "flex-direction": "column"
          }
        }, [_c('p', {
          staticClass: "label12",
          staticStyle: {
            "color": "#75787a"
          }
        }, [_vm._v(_vm._s(_vm.$t('DateOfPayment')))]), _c('div', {
          staticClass: "content-normal"
        }, [_vm._v(_vm._s(_vm.formatDate(_vm.date)))])])])];
      }
    }]),
    model: {
      value: _vm.date,
      callback: function ($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "16px"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('SumBeforeVAT'),
      "required": true,
      "type": "number",
      "error": _vm.showErrorAmount,
      "error-message": _vm.showErrorAmount,
      "icon-right": 'euroSign.svg'
    },
    model: {
      value: _vm.amount,
      callback: function ($$v) {
        _vm.amount = $$v;
      },
      expression: "amount"
    }
  }), _c('div', {
    staticStyle: {
      "width": "100%",
      "display": "flex",
      "justify-content": "flex-end"
    }
  }, [_c('div', {
    staticClass: "content-semibold",
    staticStyle: {
      "width": "fit-content",
      "padding": "2px 4px",
      "background-color": "#FFF0D4",
      "border-radius": "4px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('TotalSumWithVAT')) + ": " + _vm._s(_vm.$nMapper.price(_vm.amountWithVAT, "€")) + " ")])])], 1)], 1), _c('div', {
    staticClass: "invoice-payment-widget-footer"
  }, [_c('BaseButton', {
    attrs: {
      "size": "large",
      "label": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.closeWidget
    }
  }), _c('BaseButton', {
    attrs: {
      "size": "large",
      "primary": "true",
      "label": _vm.$t('Save')
    },
    on: {
      "click": _vm.updateInvoice
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }