import Vue from "vue";
import {jsPDF} from "jspdf";
import moment from "moment";

class PdfGenerator {

    lineHeightBig = 12;
    lineHeightSmall = 6;
    fontFamily = "Helvetica";
    normal = "normal";
    bold = "bold";
    defaultFontSize = 12;
    marginLeft = 20;
    marginTop = 20;
    
    y = this.marginTop;
    pdfDoc = null;

    constructor(document, lan) {
        this.document = document;
        this.lan = lan;
    }

    generatePdf() {
        this.pdfDoc = new jsPDF();

        this.pdfDoc.setFont(this.fontFamily, this.bold);
        this.pdfDoc.setFontSize(16);
        console.log("test1")
        this.pdfDoc.text(this.document.title[this.lan], this.marginLeft, this._getYCord());
        console.log("test2")

        this.y += this.lineHeightSmall;

        this.pdfDoc.setFont(this.fontFamily, this.normal);
        this.pdfDoc.setFontSize(this.defaultFontSize);
        this.pdfDoc.text(moment().format("DD.MM.YYYY"), this.marginLeft, this._getYCord());
        this.y += this.lineHeightBig;

        for (let sectionIndex = 0; sectionIndex < this.document.sections.length; sectionIndex++) {
            this._drawSection(this.document.sections[sectionIndex])
        }

        this.pdfDoc.save(this.document.title[this.lan]);
    }

    _drawSection(section) {
        if (section.name === "object") {
            this._drawObjectSection(section);
        } else if (section.name === "estateInfo") {
            this._drawEstateInfoSection(section);
        } else if (section.objects.length > 1) {
            this._drawMultiObejctSection(section);
        } else if (section.objects.length === 1) {
            this._drawMultiObejctSection(section);
        } else {
            return;
        }
        this.y += this.lineHeightBig;
    }

    _drawMultiObejctSection(section) {
        let objectRows = [];

        for (let objectIndex = 0; objectIndex < section.objects.length; objectIndex++) {
            const objectItems = this._getObjectFields(section.objects[objectIndex]);
            if (objectItems.length > 0) {
                const objectRow = objectItems.join(", ");
                objectRows.push(objectRow);
            }
        }

        if (objectRows.length > 0) {
            this._drawTitle(section.title[this.lan], objectRows.length);

            objectRows.forEach(objectRow => {
                this._drawContentText(objectRow);
            })
        }
    }

    _drawTitle(title, objectCounter) {
        this.pdfDoc.setFont(this.fontFamily, this.bold);

        this.pdfDoc.text(title, this.marginLeft, this._getYCord(objectCounter));
        this.y += this.lineHeightSmall;

        this.pdfDoc.setFont(this.fontFamily, this.normal);
    }

    _drawContentText(text) {
        this.pdfDoc.setFont(this.fontFamily, this.normal);

        this.pdfDoc.text(text, this.marginLeft, this._getYCord());
        this.y += this.lineHeightSmall;

        // this.pdfDoc.setFont(this.fontFamily, this.normal);
    }

    _drawObjectSection(section) {
        let firstFieldsValue = true;
        section.objects[0].fields.forEach(field => {
            if (!field.value) return true;
            if (firstFieldsValue) {
                this._drawTitle(section.title[this.lan]);
                firstFieldsValue = false;
            }

            if (field.reference && field.reference.fieldName === "listingName") {
                this._drawContentText(field.value);
            } else {
                let text = `${field.title[this.lan]}: ${field.value}`
                this._drawContentText(text);
            }
            // this.y += this.lineHeightSmall;
        })
    }

    _drawEstateInfoSection(section) {
        section.objects[0].fields.forEach(field => {
            if (!field.value) return true;
            if (section.objects[0].title) {
                this._drawTitle(section.objects[0].title[this.lan]);
            } else if (field.title) {
                this._drawTitle(field.title[this.lan]);
            }

            this._drawContentText(field.value);
            this.y += this.lineHeightSmall;
        })
        this.y -= this.lineHeightSmall;
    }

    _getObjectFields(object) {
        let objectItems = [];

        for (let fieldIndex = 0; fieldIndex < object.fields.length; fieldIndex++) {
            const field = object.fields[fieldIndex];
            if (field.value) {
                objectItems.push(field.value);
            }
        }
        return objectItems;
    }

    _getYCord(objectLength) {
        let tempY = this.y;
        if (objectLength) {
            tempY += this.lineHeightSmall * objectLength;
        }
        if (tempY >= 277) {
            this.pdfDoc.addPage();
            this.y = this.marginTop;
        }
        return this.y;
    }

}

const plugin = {
    install () {
        Vue.PdfGenerator = PdfGenerator
        Vue.prototype.$PdfGenerator = PdfGenerator
    }
}

Vue.use(plugin)