<template>
  <div class="group-or-user-select-wrapper" v-if="dropdownHasAccessibleData">
    <div class="group-or-user-select-input-wrapper"
         :class="[`group-or-user-select-input-wrapper-${size}`, `group-or-user-select-input-wrapper-${color}`]"
         @click="inputWrapperClicked" @mousedown.prevent>
      <div class="group-or-user-select-selection-container">
        <img
            :class="[`group-or-user-select-search-icon-open-${color}`, {[`group-or-user-select-search-icon-open-${size}`]: dropdownOpen, 'group-or-user-select-search-icon-closed': !dropdownOpen}]"
            :src="require('@/assets/images/search.svg')" alt=""/>
        <div style="display: flex; flex-direction: column">
          <p class="group-or-user-select-label" :class="{'group-or-user-select-label-active': dropdownOpen}"
             v-if="size === 'large'">{{ $t(labelTranslation) }}</p>
          <p :class="[`group-or-user-select-selected-value-${color}`, {'button-small': size === 'small', 'content group-or-user-select-selected-value': size === 'large'}]"
             :style="selectedLabelStyle"
             v-if="!dropdownOpen">{{ showSelectedValue }}</p>
          <input v-model="searchInput"
                 :class="[`group-or-user-select-input-${size}`, `group-or-user-select-input-${color}`,{'button-small': size === 'small', 'content': size === 'large'}, {'group-or-user-select-input-dropdown-closed': !dropdownOpen}]"
                 :ref="id + inputElementRefName"
                 :placeholder="$tc('SearchForUser')" @focus="openFirstLevelDropdown"
                 @blur="closeDropdown" @keydown="handleKeyDown"/>
        </div>
      </div>
      <img
          :class="[`group-or-user-select-chevron-${color}`, `group-or-user-select-chevron-${size}`, {'group-or-user-select-chevron-icon-open': dropdownOpen}]"
          :src="require('@/assets/images/chevron-down.svg')" alt=""/>
    </div>
    <div :class="`group-or-user-select-dropdown-${size}`" class="group-or-user-select-level-1-dropdown"
         v-if="dropdownOpen" @mousedown.prevent>
      <div @click="selectItem(null)" v-if="!searchInput && allowEmpty" @mouseenter="closeSecondLevelDropdown"
           class="group-or-user-select-dropdown-item group-or-user-select-dropdown-item-level-1"
           :class="[`group-or-user-select-dropdown-item-${size}`, {'group-or-user-select-dropdown-item-active': keyboardNavigation && keyboardNavigation.selectedIndex === -1 && keyboardNavigation.level === 1}]">
        <img v-if="!value" :class="`group-or-user-select-img-placeholder-${size}`"
             :src="require('@/assets/images/checkmark.svg')" alt=""/>
        <div v-else :class="`group-or-user-select-img-placeholder-${size}`"></div>
        <p :class="{'content-small': size === 'small', 'content': size === 'large'}">{{
            $t(emptyValueLabelTranslation)
          }}</p>
      </div>
      <div v-for="[index, [id, item]] of Array.from(firstLevelDropdownData.entries()).entries()"
           @mouseenter="(event) => openSecondLevelDropdown(event, id, index)"
           @click="selectItem(id, item)" :key="'first-level-' + id"
           :ref="'dropdownLevel1Item' + id"
           class="group-or-user-select-dropdown-item group-or-user-select-dropdown-item-level-1"
           :class="[`group-or-user-select-dropdown-item-${size}`, {'group-or-user-select-dropdown-item-active': keyboardNavigation && keyboardNavigation.selectedIndex === index && keyboardNavigation.level === 1}]"
      >
        <img v-if="getSelectedItemIcon(id, item)" :class="`group-or-user-select-img-placeholder-${size}`"
             :src="require(`@/assets/images/${getSelectedItemIcon(id, firstLevelDropdownData.get(id))}.svg`)"
             alt=""/>
        <div v-else :class="`group-or-user-select-img-placeholder-${size}`"></div>
        <p :class="{'content-small': size === 'small', 'content': size === 'large'}" style="width: 100%">
          {{
            item[secondLevelDataKey] ? item[firstLevelValueLabelKey] + ` (${filter2ndLevelItems.parameter !== 'search' ? Object.keys(filterSecondLevelData(item[secondLevelDataKey])).length : Object.keys(item[secondLevelDataKey]).length})` : item[secondLevelValueLabelKey]
          }}</p>
        <img v-if="item[secondLevelDataKey]" height="20" width="20"
             :class="{'group-or-user-select-dropdown-icon-rotated': secondLevelDropdownOfFirstLevelId === id}"
             class="group-or-user-select-dropdown-icon" :src="require('@/assets/images/chevron-right.svg')" alt=""/>
      </div>
    </div>
    <div v-if="secondLevelDropdownData" class="group-or-user-select-level-2-dropdown"
         :style="{top: `${secondDropdownPosition.y}px`, left: `${secondDropdownPosition.x}px`}" @mousedown.prevent>
      <div v-for="(item, id, index) in secondLevelDropdownData" @click="selectItem(id, item)"
           :key="'second-level-' + id"
           :ref="'dropdownLevel2Item' + id"
           :style="{width: `${dropdownItemWidth}px`}"
           class="group-or-user-select-dropdown-item group-or-user-select-dropdown-item-level-2"
           :class="[`group-or-user-select-dropdown-item-${size}`, {'group-or-user-select-dropdown-item-active': keyboardNavigation && keyboardNavigation.selectedIndex === index && keyboardNavigation.level === 2}]"
      >
        <img v-if="getSelectedItemIcon(id, item)" :class="`group-or-user-select-img-placeholder-${size}`"
             :src="require(`@/assets/images/${getSelectedItemIcon(id, item)}.svg`)"
             alt=""/>
        <div v-else :class="`group-or-user-select-img-placeholder-${size}`"></div>
        <p :class="{'content-small': size === 'small', 'content': size === 'large'}">{{
            item[secondLevelValueLabelKey]
          }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "NestedObjectSelect",
  props: {
    id: {
      type: String,
      default: ""
    },
    inputElementRefName: {
      type: String,
      default: 'inputElement'
    },
    module: {
      type: String,
    },
    secondLevelDataKey: {
      type: String,
      default: "users"
    },
    emptyValueLabelTranslation: {
      type: String,
      default: "AllBrokers"
    },
    firstLevelValueType: {
      type: String,
      default: "group"
    },
    firstLevelValueLabelKey: {
      type: String,
      default: 'name'
    },
    secondLevelValueType: {
      type: String,
      default: "user"
    },
    secondLevelValueLabelKey: {
      type: String,
      default: 'name'
    },
    value: {
      type: Object
    },
    allowOnlyFirstLevelData: {
      type: Boolean,
      default: false
    },
    labelTranslation: {
      type: String,
      default: null
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'invert'].includes(value);
      }
    },
    size: {
      type: String,
      default: "small",
      validator: function (value) {
        return ['small', 'large'].includes(value);
      }
    },
    filter2ndLevelItems: {
      type: Object,
      default: () => {
        return {
          parameter: "dropdown", // dropdown - doesn't show in 2nd level, search - doesn't show in search, all - doesn't show in neither
          field: "userStatus",
          value: "Active"
        }
      }
    }
  },
  model: {
    prop: "value",
    event: "change"
  },
  data() {
    return {
      dropdownOpen: false,
      searchInput: "",
      secondLevelDropdownOfFirstLevelId: "",
      secondLevelDropdownData: null,
      secondDropdownPosition: {},
      selectedLabelStyle: {},
      searchData: {},
      dropdownItemWidth: 200,
      keyboardNavigation: null,
    }
  },
  computed: {
    ...mapGetters(["user", "users", "groups"]),
    dropdownHasAccessibleData() {
      let object = this.groupConfigurationSetup();
      return object.accessibleGroupKeys.length > 0;
    },
    firstLevelDropdownData() {
      let {accessibleGroups, accessibleGroupKeys} = this.groupConfigurationSetup()
      if (this.searchInput || this.allowOnlyFirstLevelData) {
        return this.setupSearchData(this.searchInput, accessibleGroups)
      }
      if (accessibleGroupKeys.length === 1) {
        if (this.filter2ndLevelItems.parameter === 'search') {
          return new Map(Object.entries(accessibleGroups[accessibleGroupKeys[0]][this.secondLevelDataKey]));
        } else {
          return new Map(Object.entries(this.filterSecondLevelData(accessibleGroups[accessibleGroupKeys[0]][this.secondLevelDataKey])));
        }

      }

      // Sort the accessibleGroups before return.
      return new Map(Object.entries(accessibleGroups).sort(([, a], [, b]) => {
        const nameA = a[this.firstLevelValueLabelKey]?.toLowerCase();
        const nameB = b[this.firstLevelValueLabelKey]?.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      }));
    },
    showSelectedValue() {
      const valueColorOptions = {
        'false': {
          'default': {
            'small': "#75787a",
            'large': "black"
          },
          'invert': {
            'small': "#fff",
          }
        },
        'true': {
          'default': {
            'small': "black",
            'large': "black"
          },
          'invert': {
            'small': "#fff",
          }
        }
      }
      const color = valueColorOptions[this.value ? 'true' : 'false'][this.color][this.size]
      this.setLabelStyle({color: color})
      if (!this.value) {
        return this.$t(this.emptyValueLabelTranslation);
      }
      let displayText = ""

      switch (this.value.type) {
        case this.firstLevelValueType:
          displayText = this.firstLevelDropdownData.get(this.value.value)[this.firstLevelValueLabelKey]
          break;
        case this.secondLevelValueType:
          // TODO state users as object instead of an array: less client side calculations.
          if (this.users) {
            displayText = this.users.find((user) => user._id === this.value.value).name
          }
          break;
      }
      return displayText
    },
  },
  methods: {
    filterSecondLevelData(secondLevelData) {
      //TODO save the required field "status" to user in group instead.
      const returnData = {}
      for (const user of this.users) {
        if (secondLevelData[user._id] && user[this.filter2ndLevelItems.field] === this.filter2ndLevelItems.value) {
          returnData[user._id] = secondLevelData[user._id];
        }
      }
      return returnData;
    },
    setupSearchData(search, firstLevelData) {
      this.secondLevelDropdownData = null;
      this.secondLevelDropdownOfFirstLevelId = "";
      this.keyboardNavigation = null;
      const result = {}
      for (const firstLevelKey in firstLevelData) {
        const secondLevelData = this.filter2ndLevelItems.parameter !== 'dropdown' ? this.filterSecondLevelData(firstLevelData[firstLevelKey][this.secondLevelDataKey]) : firstLevelData[firstLevelKey][this.secondLevelDataKey];
        if (secondLevelData) {
          for (const secondLevelKey in secondLevelData) {
            let comparedObject = secondLevelData[secondLevelKey]
            if (comparedObject[this.secondLevelValueLabelKey].toLowerCase().includes(search.toLowerCase())) {
              result[secondLevelKey] = comparedObject
            }
          }
        }
      }

      const sortedResult = Object.entries(result).sort(([, a], [, b]) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      return new Map(sortedResult);
    },
    getSelectedItemIcon(id, item) {
      if (!this.value) return "";

      if (id === this.value.value) {
        return "checkmark";
      }
      if (item[this.secondLevelDataKey]) {
        if (item[this.secondLevelDataKey][this.value.value]) {
          return "checkmark_stripe"
        }
      }
      return ''
    },
    setLabelStyle(style) {
      this.selectedLabelStyle = style;
    },
    selectItem(id, item) {
      let emitItem = {value: id};
      if (item) {
        if (item[this.secondLevelDataKey]) {
          emitItem.type = this.firstLevelValueType
        } else {
          emitItem.type = this.secondLevelValueType
        }
      } else {
        emitItem = null;
      }
      this.$nextTick(() => {
        this.$refs[this.id + this.inputElementRefName].blur()
        this.$emit("change", emitItem);
      })
    },
    inputWrapperClicked() {
      if (this.dropdownOpen) return;
      this.$nextTick(() => {
        this.$refs[this.id + this.inputElementRefName].focus()
      })
    },
    openSecondLevelDropdown(event, id, index) {
      if (this.firstLevelDropdownData.get(id)[this.secondLevelDataKey]) {
        let numberOfItemHovered = index + 1;
        const level2Data = this.filter2ndLevelItems.parameter !== 'search' ? this.filterSecondLevelData(this.firstLevelDropdownData.get(id)[this.secondLevelDataKey]) : this.firstLevelDropdownData.get(id)[this.secondLevelDataKey];
        const sortedResult = Object.entries(level2Data).sort(([, a], [, b]) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        this.secondLevelDropdownData = Object.fromEntries(sortedResult)
        this.secondLevelDropdownOfFirstLevelId = id;
        let itemsInSecondLevel = Object.keys(this.secondLevelDropdownData).length;

        let element;
        if (event.currentTarget) {
          element = event.currentTarget;
        } else {
          element = event
        }
        const parentElement = element.parentNode;
        const hoverItemRect = element.getBoundingClientRect();
        this.dropdownItemWidth = hoverItemRect.width


        const itemHeight = this.size === 'large' ? 44 : 40;
        const extraSizeMargin = this.size === 'large' ? 16 : 0;
        const nullSelectionHeight = this.allowEmpty ? itemHeight + extraSizeMargin : extraSizeMargin;

        const position = {
          x: this.dropdownItemWidth + (this.size === 'large' ? 12 : 0),
          y: numberOfItemHovered * itemHeight - parentElement.scrollTop + nullSelectionHeight
        }

        // Checking if second level dropdown appears out of screen.
        const hoverItemElementBottom = hoverItemRect.top + hoverItemRect.height;
        const hoverItemPixelDistanceFromScreenBottom = hoverItemElementBottom - window.innerHeight;

        let secondLevelDropdownHeight = itemsInSecondLevel * itemHeight;
        if (secondLevelDropdownHeight > 344) {
          secondLevelDropdownHeight = 344;
        }
        const secondLevelDropdownPixelDistanceFromScreenBottom = hoverItemPixelDistanceFromScreenBottom + secondLevelDropdownHeight - itemHeight;

        if (secondLevelDropdownPixelDistanceFromScreenBottom > 0) {
          position.y = position.y - secondLevelDropdownPixelDistanceFromScreenBottom
        }

        this.secondDropdownPosition = position
      }
    },
    openFirstLevelDropdown() {
      this.dropdownOpen = true;
    },
    closeDropdown() {
      this.dropdownOpen = false;
      this.searchInput = "";
      this.secondLevelDropdownData = null;
      this.secondLevelDropdownOfFirstLevelId = ""
      this.keyboardNavigation = null;
    },
    groupConfigurationSetup() {
      const modulePolicies = this.user.companyPolicies.filters[this.module]
      const accessibleGroups = {}
      if (this.user.access.accessLevel === 'company_admin' || !this.module || (modulePolicies && modulePolicies.showAll)) {
        for (const groupId in this.groups) {
          if (Object.keys(this.groups[groupId].users).length > 0) {
            accessibleGroups[groupId] = this.groups[groupId];
          }
        }
      } else if (this.user.access.accessLevel === 'group_admin') {
        for (const groupId in this.user.access.groups) {
          if (this.user.access.groups[groupId].isAdmin) {
            accessibleGroups[groupId] = this.groups[groupId]
          }
        }
      }
      const accessibleGroupKeys = Object.keys(accessibleGroups);
      return {accessibleGroupKeys, accessibleGroups};
    },
    closeSecondLevelDropdown() {
      this.secondLevelDropdownData = null
      this.secondLevelDropdownOfFirstLevelId = ""
    },
    handleKeyDown(event) {
      const pressedKey = event.key;
      switch (pressedKey) {
        case 'Enter':
          this.handleEnterKey();
          break;
        case 'ArrowDown':
          this.moveSelection(1);
          break;
        case 'ArrowUp':
          this.moveSelection(-1);
          break;
        case 'ArrowRight':
          this.handleArrowRightKey();
          break;
        case 'ArrowLeft':
          this.handleArrowLeftKey();
          break;
        default:
          break;
      }
    },
    handleEnterKey() {
      if (this.keyboardNavigation) {
        let {id, value} = this.keyboardNavigation;
        if (!value) value = this.firstLevelDropdownData.get(id)
        this.selectItem(id, value);
      } else {
        this.selectItem();
      }
    },
    handleArrowRightKey() {
      if (this.keyboardNavigation?.level === 1 && this.secondLevelDropdownData) {
        const secondLevelDataKeys = Object.keys(this.secondLevelDropdownData);
        this.updateNavigation({
          id: secondLevelDataKeys[0],
          value: this.secondLevelDropdownData[secondLevelDataKeys[0]],
          level1Index: this.keyboardNavigation.selectedIndex,
          selectedIndex: 0,
          level: 2,
        });
        this.adjustScrollPosition(0, secondLevelDataKeys, 2)
      }
    },
    handleArrowLeftKey() {
      if (this.keyboardNavigation?.level === 2) {
        const firstLevelDataKeys = Object.keys(this.firstLevelDropdownData);
        this.updateNavigation({
          id: firstLevelDataKeys[this.keyboardNavigation.level1Index],
          value: this.firstLevelDropdownData[firstLevelDataKeys[this.keyboardNavigation.level1Index]],
          selectedIndex: this.keyboardNavigation.level1Index,
          level: 1,
        });
      }
    },
    moveSelection(step) {
      const levelData = this.getLevelData();
      const {
        selectedIndex,
        level
      } = this.keyboardNavigation ?? {
        selectedIndex: step === 1 && !this.searchInput && this.allowEmpty ? -2 : -1,
        level: 1
      };
      const newIndex = Math.min(Math.max(selectedIndex + step, level === 1 && !this.searchInput && this.allowEmpty ? -1 : 0), levelData.keys.length - 1);
      if (newIndex === selectedIndex && this.keyboardNavigation) return;

      this.updateNavigation({
        id: levelData.keys[newIndex],
        value: levelData.data[levelData.keys[newIndex]],
        selectedIndex: newIndex,
        level,
      });

      this.$nextTick(() => {
        this.adjustScrollPosition(newIndex, levelData.keys, level);
        if (level === 1) {
          if (newIndex >= 0) {
            this.openSecondLevelDropdown(this.$refs[`dropdownLevel1Item${levelData.keys[newIndex]}`][0], levelData.keys[newIndex], newIndex);
          } else {
            this.secondLevelDropdownData = null;
            this.secondLevelDropdownOfFirstLevelId = ""
          }
        }
      });
    },
    getLevelData() {
      const level = this.keyboardNavigation?.level ?? 1;
      const data = level === 1 ? this.firstLevelDropdownData : this.secondLevelDropdownData;
      return {
        keys: level === 1 ? Array.from(this.firstLevelDropdownData.keys()) : Object.keys(data),
        data,
      };
    },
    updateNavigation(newValues) {
      this.keyboardNavigation = {...this.keyboardNavigation, ...newValues};
    },
    roundUpToNearest(number, roundTo) {
      return Math.ceil(number / roundTo) * roundTo;
    },
    adjustScrollPosition(newIndex, keys, level) {
      const refKey = `dropdownLevel${level}Item${keys[newIndex === -1 ? 0 : newIndex]}`;
      const elementRef = this.$refs[refKey];
      if (!elementRef) return;
      const itemHeight = this.size === 'large' ? 44 : 40;
      const currentElement = elementRef[0];
      const elementCount = level === 1 ? this.searchInput || !this.allowEmpty ? 1 : 2 : 1
      const height = (newIndex + elementCount) * itemHeight;
      const parentScrollTop = currentElement.parentNode.scrollTop;
      const parentScrollTopRoundedUp = this.roundUpToNearest(parentScrollTop, itemHeight)

      if (height > 344 + parentScrollTop || height <= parentScrollTopRoundedUp) {
        currentElement.parentNode.scrollTop = height - (height > parentScrollTopRoundedUp ? 344 : itemHeight);
      }
    },

  }
}
</script>

<style lang="scss" scoped>
@import "@/global_variables.scss";

.group-or-user-select-wrapper {
  position: relative;
}

.group-or-user-select-input-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  border: 1px solid $grey500;
  cursor: pointer;
  border-radius: 8px;
  align-items: center;
}

.group-or-user-select-input-wrapper-default {
  background-color: white;
  border: 1px solid $grey500;
}

.group-or-user-select-input-wrapper-invert {
  background-color: black;
  border: 1px solid #fff;
}

.group-or-user-select-input-wrapper-small {
  max-width: 200px;
  padding: 8px 12px;
  gap: 8px;
  height: 34px;
}

.group-or-user-select-input-wrapper-large {
  padding: 7px 11px;
  gap: 12px;
  height: 52px;
}

.group-or-user-select-input-wrapper input {
  width: 100%;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
}

.group-or-user-select-input-small {
  height: 16px;
}

.group-or-user-select-input-large {
  height: 24px;
}

.group-or-user-select-input-default {
  color: black;
}

.group-or-user-select-input-invert {
  color: white;

  &::placeholder {
    color: white
  }
}

.group-or-user-select-selection-container {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 100%;
  width: 100%;
  align-items: center;
}

.group-or-user-select-input-wrapper img {
  transition: width 0.2s ease, transform 0.2s ease;
}

.group-or-user-select-search-icon-open-default {

}

.group-or-user-select-search-icon-open-invert {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);
}

.group-or-user-select-search-icon-open-small {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.group-or-user-select-search-icon-open-large {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.group-or-user-select-search-icon-closed {
  width: 0;
}

.group-or-user-select-chevron-icon-open {
  transform: rotate(180deg);
}

.group-or-user-select-chevron-default {
  color: black;
}

.group-or-user-select-chevron-invert {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(317deg) brightness(103%) contrast(102%);
}

.group-or-user-select-chevron-small {
  height: 16px;
  width: 16px;
}

.group-or-user-select-chevron-large {
  height: 20px;
  width: 20px;
}

.group-or-user-select-input-dropdown-closed {
  width: 0 !important;
  height: 0 !important;
}

.group-or-user-select-level-1-dropdown {
  position: absolute;
  z-index: 100;
  margin-top: 8px;
  border-radius: 8px;
  max-height: 344px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.group-or-user-select-dropdown-item {
  cursor: pointer;
  position: relative;
  display: flex;
  gap: 12px;
  background-color: #fff;

  &:first-of-type {
    border-radius: 8px 8px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }

  &:hover {
    background-color: $grey;
  }
}

.group-or-user-select-dropdown-item-active {
  background-color: $grey;
}

.group-or-user-select-dropdown-item-level-1 {
  padding: 12px;
}

.group-or-user-select-level-2-dropdown {
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 344px;
  border-radius: 8px;
  z-index: 101;
  left: 200px;
  border: 1px solid $grey500;
  box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.group-or-user-select-dropdown-item-level-2 {
  padding: 12px 11px;

  &:first-of-type {
    padding-top: 11px !important;
  }

  &:last-of-type {
    padding-bottom: 11px !important;
  }
}

.group-or-user-select-dropdown-icon {
  transition: transform 0.2s ease;
}

.group-or-user-select-dropdown-icon-rotated {
  transform: rotate(90deg);
}

.group-or-user-select-label {
  height: 12px;
  line-height: 12px;
  font-size: 11px;
  color: $grey950;
}

.group-or-user-select-label-active {
  color: $orange;
}

.group-or-user-select-selected-value-default {
  color: black;
}

.group-or-user-select-selected-value-invert {
  color: white;
}

.group-or-user-select-selected-value {
  height: 24px;
  display: flex;
  align-items: center;
}

.group-or-user-select-dropdown-small {

}

.group-or-user-select-dropdown-large {
  left: 12px;
  right: 12px;
}

.group-or-user-select-dropdown-item-small {
  width: 200px;
  height: 40px;
}

.group-or-user-select-dropdown-item-large {
  width: 100%;
  height: 44px;
}

.group-or-user-select-img-placeholder-small {
  min-height: 16px;
  min-width: 16px;
}

.group-or-user-select-img-placeholder-large {
  min-height: 20px;
  min-width: 20px;
}

</style>