var render = function render(){
  var _vm$user$access, _vm$user$access$featu, _vm$user$access$featu2, _vm$user$access$featu3, _vm$user$access2, _vm$user$access2$feat, _vm$user$access2$feat2, _vm$user$access2$feat3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.user ? _c('v-navigation-drawer', {
    staticClass: "navbar",
    attrs: {
      "permanent": ""
    }
  }, [_c('div', {
    staticClass: "ok-wrap"
  }, [_c('img', {
    staticStyle: {
      "border-radius": "30px",
      "cursor": "pointer"
    },
    attrs: {
      "src": require("../assets/images/logo.svg")
    },
    on: {
      "click": function ($event) {
        return _vm.projectPage();
      }
    }
  })]), _c('v-list', {
    staticClass: "links-wrap",
    attrs: {
      "nav": "",
      "dense": ""
    }
  }, [_vm.showDashboard ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "link": "",
            "to": {
              name: 'DashBoard'
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/dashboard.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 2635776608)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("DashBoard")))])]) : _vm._e(), _vm.showDashboard ? _c('v-row', {
    staticClass: "separator",
    staticStyle: {
      "margin-top": "4px",
      "margin-bottom": "8px"
    }
  }) : _vm._e(), _vm.showProjects ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "link": "",
            "to": {
              name: 'Deals'
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/ProjectIcon.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 3410132449)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Projects")))])]) : _vm._e(), _vm.showListings ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "link": "",
            "to": {
              name: 'Listings'
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/menu/building.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 1601664155)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Listings")))])]) : _vm._e(), _vm.showPropertyDeveloper ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "name": "navbar-property-developer-button",
            "link": "",
            "to": {
              name: 'PropertyDevelopment'
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/PropertyDeveloper.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 2518387542)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("DevelopmentProjects")))])]) : _vm._e(), _vm.showPropertyOwner ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "name": "navbar-propertyowner-button",
            "link": "",
            "to": {
              name: 'PropertyOwner'
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/PropertyOwner.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 772069449)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Properties")))])]) : _vm._e(), !_vm.user.config || _vm.user.config && (_vm$user$access = _vm.user.access) !== null && _vm$user$access !== void 0 && (_vm$user$access$featu = _vm$user$access.features) !== null && _vm$user$access$featu !== void 0 && (_vm$user$access$featu2 = _vm$user$access$featu.modules) !== null && _vm$user$access$featu2 !== void 0 && (_vm$user$access$featu3 = _vm$user$access$featu2.calendar) !== null && _vm$user$access$featu3 !== void 0 && _vm$user$access$featu3.enabled ? _c('v-row', {
    staticClass: "separator",
    staticStyle: {
      "margin-bottom": "8px",
      "margin-top": "4px"
    }
  }) : _vm._e(), _vm.showCalendar ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on,
          attrs = _ref6.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "link": "",
            "to": {
              name: 'Calendar'
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/menu/calendar.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 4092329436)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Calendar")))])]) : _vm._e(), _vm.showContacts ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var on = _ref7.on,
          attrs = _ref7.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "name": "navbar-contacts-button",
            "link": "",
            "to": "/contacts?page=1"
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/menu/client.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 3551232894)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Contacts")))])]) : _vm._e(), _vm.showTransactions ? _c('v-row', {
    staticClass: "separator",
    staticStyle: {
      "margin-bottom": "8px",
      "margin-top": "4px"
    }
  }) : _vm._e(), _vm.showTransactions ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref8) {
        var on = _ref8.on,
          attrs = _ref8.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "link": "",
            "to": "/transactions?page=1"
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/transactions.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 1759132884)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Transactions")))])]) : _vm._e(), _vm.showInvoices ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref9) {
        var on = _ref9.on,
          attrs = _ref9.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "link": "",
            "to": "/invoices/?page=1"
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/invoice.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 3178843976)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Invoices")))])]) : _vm._e(), _vm.showScorecard ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref10) {
        var on = _ref10.on,
          attrs = _ref10.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "name": "navbar-scorecard-button",
            "link": "",
            "to": {
              name: 'Scorecard'
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/scorecard.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 2877325459)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Scorecard")))])]) : _vm._e(), _vm.showSettings && (!_vm.user.config || _vm.user.config && (_vm$user$access2 = _vm.user.access) !== null && _vm$user$access2 !== void 0 && (_vm$user$access2$feat = _vm$user$access2.features) !== null && _vm$user$access2$feat !== void 0 && (_vm$user$access2$feat2 = _vm$user$access2$feat.modules) !== null && _vm$user$access2$feat2 !== void 0 && (_vm$user$access2$feat3 = _vm$user$access2$feat2.invoices) !== null && _vm$user$access2$feat3 !== void 0 && _vm$user$access2$feat3.enabled) ? _c('v-row', {
    staticClass: "separator",
    staticStyle: {
      "margin-top": "4px",
      "margin-bottom": "8px"
    }
  }) : _vm._e(), _vm.showSettings ? _c('v-tooltip', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref11) {
        var on = _ref11.on,
          attrs = _ref11.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({
          attrs: {
            "name": "navbar-admins-button",
            "link": "",
            "to": {
              name: 'Admin'
            }
          }
        }, 'v-list-item', attrs, false), on), [_c('img', {
          attrs: {
            "src": require("../assets/images/menu/admin.svg"),
            "alt": ""
          }
        })])];
      }
    }], null, false, 1981443343)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Admin")))])]) : _vm._e()], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }