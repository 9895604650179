<template>
  <div :id="id" class="input-field-container">
    <div
        ref="container"
        @click="focusInputElement"
        class="form-field-large"
        :style="fieldStyle"
    >
      <div
          :class="{ required: fieldRequired }"
          style="display: flex; width: 100%; flex-direction: column"
      >
        <label
            v-if="fieldPlaceholder"
            ref="label"
            :style="labelStyle"
            class="content"
        >{{ fieldPlaceholder }}</label
        >
        <GmapAutocomplete
            placeholder=""
            @focusin="focusInput"
            @focusout="unfocusInput"
            @input="mapInput($event)"
            @place_changed="setPlace"
            ref="addressSearch"
            class="content"
            style="outline: none;"
            v-model="fieldValue"
        ></GmapAutocomplete>
      </div>
    </div>
    <div
        v-if="fieldError"
        class="content error-message"
        style="margin: 12px 12px 0 12px"
    >
      {{
        fieldErrorMessage ? $t("FormErrors." + errorMessage) : $t("FormErrors.FieldRequired")
      }}
    </div>
  </div>
</template>

<script>
import nanoid from "nanoid";

export default {
  name: "GoogleAddressSearch",
  props: [
    "id",
    "value",
    "placeholder",
    "errorMessage",
    "error",
    "required",
    "returnObject",
    "editMode"
  ],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      fieldValue: "",
      fieldPlaceholder: "",
      fieldError: false,
      fieldErrorMessage: "",
      fieldRequired: false,
      fieldStyle: {
        border: "1px solid #E6E8EC",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "7px 11px",
        gap: "12px",
        background: "white",
        cursor: "text",
      },
      labelStyle: {
        color: "#75787A",
        transition: "",
      },
    };
  },
  mounted() {
    this.initializeGoogleAddressSearch();
  },
  watch: {
    error() {
      this.fieldError = this.error;
      if (this.fieldError) {
        this.fieldStyle.border = "2px solid #FF1E24";
        this.fieldStyle.padding = "6px 10px";
        if (this.fieldFocus) {
          this.$refs.label.style.color = "#FF1E24";
        }
      } else {
        this.fieldStyle.border = "1px solid #E6E8EC";
        this.fieldStyle.padding = "7px 11px";
        this.$refs.label.style.color = "";
        if (this.validateInput(this.fieldValue)) {
          this.$refs.label.style.color = "#FF5C01";
        }
      }
    },
    errorMessage() {
      this.fieldErrorMessage = this.errorMessage;
    },
    fieldValue(value) {
      console.log('Google address fieldvalue watch', value)

      if (!this.returnObject) {
        this.$emit("change", value);
      }
    },
  },
  methods: {
    initializeGoogleAddressSearch() {
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      if (this.required) {
        this.fieldRequired = true;
      }
      if (this.value) {
        console.log('init this value', this.value)
        if (this.editMode) {
          // this.fieldValue = this.value[0].address
          this.fieldValue = this.value.address
        } else {
          this.fieldValue = this.value;
        }

        // this.fieldValue = this.value;

        this.$nextTick(
            () => (this.$refs.addressSearch.$refs.input.style.height = "24px")
        );
        this.activatePlaceholder(false);
        return;
      }
      this.$nextTick(
          () => {
            this.$refs.addressSearch.$refs.input.style.height = "0px";
            this.$refs.addressSearch.$refs.input.style.transition = "0.2s";
          }
      );
    },
    validateInput(value) {
      return !(value === "" || /^\s*$/.test(value));
    },
    activatePlaceholder(fieldActive = true) {
      this.$nextTick(() =>
          this.$refs.label.classList.add("form-field-large-active-placeholder")
      );
      if (this.fieldError) {
        this.labelStyle.color = "#FF1E24";
        return;
      }
      if (fieldActive) {
        this.labelStyle.color = "#FF5C01";
        return;
      }
      if (!fieldActive) {
        this.labelStyle.color = "#75787A";
      }
    },
    deactivatePlaceholder() {
      this.$refs.label.classList.remove("form-field-large-active-placeholder");
      this.labelStyle.color = "#75787A";
    },
    focusInputElement() {
      this.$nextTick(() => this.$refs.addressSearch.$refs.input.focus());
    },
    focusInput() {
      this.labelStyle.transition = "0.2s";
      this.activatePlaceholder();
      this.$nextTick(
          () => (this.$refs.addressSearch.$refs.input.style.height = "24px")
      );
    },
    unfocusInput() {
      if (!this.validateInput(this.fieldValue)) {
        console.log("here")
        this.$nextTick(
            () => (this.$refs.addressSearch.$refs.input.style.height = "0px")
        );
        this.deactivatePlaceholder();
      } else {
        console.log("HERE INSTEAD")
        this.$nextTick(
            () => {
              console.log(this.$refs)
              this.$refs.addressSearch.$refs.input.style.height = "24px"
            }
        );
      }
      this.labelStyle.color = "#75787A";
    },
    mapInput(e) {
      this.fieldValue = e.target.value;
    },
    setPlace(place) {
      console.log("place:", place)
      console.log("PLACE END")
      const formatedPlaceObject = this.formatPlace(place);
      this.selectedFieldDropdownItemValue = formatedPlaceObject;
      this.mapPlaceSelectedLength = place.formatted_address.length;
      this.fieldValue = place.formatted_address;
      if (this.returnObject) {
        this.$emit("change", formatedPlaceObject);
      }
    },
    formatPlace(place) {
      const newPlace = {_id: nanoid(15)};
      place.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          newPlace["city"] = component.long_name;
        }
        if (component.types.includes("route")) {
          newPlace["street"] = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          newPlace["postalCode"] = component.long_name;
        }
        if (component.types.includes("street_number")) {
          newPlace["houseNumber"] = component.long_name;
        }
        if (component.types.includes("country")) {
          newPlace["country"] = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          newPlace["county"] = component.long_name
        }
        if (component.types.includes("administrative_area_level_2")) {
          newPlace["district"] = component.long_name
        }
        if (component.types.includes("sublocality_level_1")) {
          newPlace["district"] = component.long_name
        }
      });
      if (newPlace["city"] && !newPlace["county"]) {
        newPlace["county"] = newPlace["city"];
      }
      newPlace["coordinates"] = {
        lng: place.geometry.location.lng(),
        lat: place.geometry.location.lat(),
      };
      newPlace["address"] = place.formatted_address;
      return newPlace;
    },
  },
};
</script>

<style scoped>
.input-field-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.form-field-large {
  height: 52px;
  width: 100%;
}

.form-field-large label {
  color: #75787a;
  cursor: text;
}

.form-field-large-active-placeholder {
  line-height: 12px !important;
  font-size: 11px !important;
}
</style>