import Vue from "vue";
import moment from "moment";

const transactionFilter = {
    getResult(allTransactions, conditions) {
        let filteredTransactions = [...allTransactions];
        conditions.forEach((condition) => {
            switch (condition.name) {
                case "search":
                    filteredTransactions = this._searchTransactions(filteredTransactions, condition.value);
                    break;
                case "brokerId":
                    filteredTransactions = this._searchByBroker(filteredTransactions, condition.value);
                    break
                case "dealTypes":
                    filteredTransactions = this._searchByDealType(filteredTransactions, condition.value);
                    break;
                case "tag":
                    filteredTransactions = this._searchByTag(filteredTransactions, condition.value);
                    break;
                case "status":
                    filteredTransactions = this._searchByStatus(filteredTransactions, condition.value);
                    break;
                case "dateOfTransactionFromDate":
                    filteredTransactions = this._searchByDateOfTransactionFromDate(filteredTransactions, condition.value);
                    break;
                case "dateOfTransactionToDate":
                    filteredTransactions = this._searchByDateOfTransactionToDate(filteredTransactions, condition.value);
                    break;
            }
        });
        return filteredTransactions;
    },
    _searchTransactions(transactions, value) {
        if (!transactions || !value) return transactions;
        return transactions.filter(
            (transaction) => transaction.transactionName.toLowerCase().indexOf(value.toLowerCase()) > -1
        );
    },
    _searchByBroker(transactions, value) {
        if (!transactions || !value) return transactions;
        return transactions.filter((transaction) => value.includes(transaction.creator.userId));
    },
    _searchByDealType(transactions, value) {
        if (!transactions || !value || value.length === 0) return transactions;
        return transactions.filter((transaction) => value.includes(transaction.transactionType));
    },
    _searchByTag(transactions, value) {
        if (!transactions || !value) return transactions;
        let currentDate = moment().format("MM.YYYY");
        let lastMonthDate = moment().subtract(1, "month").format("MM.YYYY");
        if (value === "this_month") {
            return transactions.filter((transaction) =>
                moment(transaction.transactionDate).format("MM.YYYY").includes(currentDate));
        } else if (value === "last_month") {
            return transactions.filter((transaction) =>
                moment(transaction.transactionDate).format("MM.YYYY").includes(lastMonthDate));
        }
        return transactions;
    },
    _searchByStatus(transactions, value) {
        if (!transactions || !value) return transactions;
        return transactions.filter((transaction) => transaction.status === value);
    },
    _searchByDateOfTransactionToDate(transactions, value) {
        if (!transactions || !value) return transactions;
        return transactions.filter((transaction) => moment(transaction.transactionDate).isBefore(value));
    },
    _searchByDateOfTransactionFromDate(transactions, value) {
        if (!transactions || !value) return transactions;
        return transactions.filter((transaction) => moment(transaction.transactionDate).isAfter(value));
    },
};

const plugin = {
    install() {
        Vue.transactionsFilter = transactionFilter;
        Vue.prototype.$transactionsFilter = transactionFilter;
    },
};

Vue.use(plugin);