var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "save-button-wrapper",
    attrs: {
      "id": _vm.id
    }
  }, [!_vm.loading && !_vm.submitPlaceholder ? _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('clicked');
      }
    }
  }, [_vm.icon ? _c('img', {
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]) : _vm._e(), !_vm.loading && _vm.submitPlaceholder ? _c('button', {
    staticClass: "save-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('clicked');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/Submit.svg"),
      "alt": ""
    }
  }), _vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]) : _vm._e(), _vm.loading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px",
      "margin": "12px 16px"
    }
  }) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }