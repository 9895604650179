import Vue from "vue";
import i18n from "../../i18n/index";
import moment from "moment";

const lfMapper = {
  floors(floor, floors) {
    if (floor && floors) {
      return floor + "/" + floors;
    } else if (!floor && floors) {
      return "-/" + floors;
    } else if (floor && !floors) {
      return floor + "/-";
    }
    return "-";
  },
  showNumber(status, number) {
    if (!status) {
      return number + " (" + i18n.t("Hidden") + ")";
    } else {
      return number;
    }
  },
  showEstateNumber(status, number) {
    if (!number) {
      return i18n.t("OpenELandRegister")
    } else if (!status && number) {
      return number + " (" + i18n.t("Hidden") + ")";
    } else if (number) {
      return number;
    }
    
  },
  dealType(dealType) {
    switch (dealType) {
      case "sale":
        return i18n.t("Sale");
      case "rent":
        return i18n.t("Rent");
      case "short_term_rent":
        return i18n.t("ShortTermRent");
      default:
        return "-";
    }
  },
  condition(condition) {
    switch (condition) {
      case "ALACRITY_NEW_BUILDING":
        return i18n.t("NewBuilding");
      case "ALACRITY_TYPE_UUSVIIMISTLUS":
        return i18n.t("NewlyDecorated");
      case "ALACRITY_RENEWED":
        return i18n.t("Renovated");
      case "ALACRITY_SAN":
        return i18n.t("EssentialRepairDone");
      case "ALACRITY_KESKMINE":
        return i18n.t("Satisfactory");
      case "ALACRITY_TYPE_KAP":
        return i18n.t("NeedsCompleteRenewal");
      case "ALACRITY_TYPE_REM":
        return i18n.t("NeedsDecoration");
      case "ALACRITY_READY":
        return i18n.t("Ready");
      default:
        return "-";
    }
  },
  ownershipForm(ownershipForm) {
    switch (ownershipForm) {
      case "PROPERTY_KORTERIOMAND":
        return i18n.t("ApartmentOwnership");
      case "PROPERTY_TYPES":
        return i18n.t("CommonOwnership");
      case "PROPERTY_ELAMUYHISTU":
        return i18n.t("CooperativeHousing");
      case "PROPERTY_KINNISTU":
        return i18n.t("ImmovableProperty");
      case "PROPERTY_VALLASASI":
        return i18n.t("MovableProperty");
      case "PROPERTY_BUILDING":
        return i18n.t("RightOfSuperficies");
      case "PROPERTY_MUNICIPAL":
        return i18n.t("Municipal");
      default:
        return "-";
    }
  },
  status(status) {
    switch (status) {
      case "draft":
        return i18n.t("Draft");
      case "active":
        return i18n.t("Active");
      case "free":
        return i18n.t("Free");
      case "archived":
        return i18n.t("Archived");
      case "sold":
        return i18n.t("Sold");
      case "rented":
        return i18n.t("Rented");
      case "booked":
        return i18n.t("Booked");
      case "expired":
        return i18n.t("Expired");
    }
  },
  energyLabel(energyLabel) {
    if (energyLabel === "no_certificate") {
      return i18n.t("NoCertificate");
    } else if (energyLabel) {
      return energyLabel.charAt(energyLabel.length - 1);
    } else {
      return "-";
    }
  },
  buildingMaterial(material) {
    switch (material) {
      case "BUILDING_MATERIAL_TYPE_KIVI":
        return i18n.t("StoneHouse");
      case "BUILDING_MATERIAL_TYPE_PALK":
        return i18n.t("LogHouse");
      case "BUILDING_MATERIAL_TYPE_PANEEL":
        return i18n.t("PanelHouse");
      case "BUILDING_MATERIAL_TYPE_PUU":
        return i18n.t("WoodenHouse");
      default:
        return "-";
    }
  },
  readiness(readiness) {
    switch (readiness) {
      case "READINESS_READY":
        return i18n.t("Ready");
      case "READINESS_BOX_WITH_DOORS":
        return i18n.t("BoxWithDoorsAndWindows");
      case "READINESS_ROOFED_BOX":
        return i18n.t("RoofedBox");
      case "READINESS_ROOFLESS_BOX":
        return i18n.t("RooflessBox");
      case "READINESS_FOUNDATION":
        return i18n.t("Foundation");
      default:
        return "-";
    }
  },
  limitations(limitations) {
    switch (limitations) {
      case "CONSTRAINTS_MORTGAGE":
        return i18n.t("InMortage");
      case "CONSTRAINTS_OTHER":
        return i18n.t("OtherLimitations");
      case "CONSTRAINTS_PANT":
        return i18n.t("Pledge");
      case "CONSTRAINTS_PROTECTED":
        return i18n.t("PreservedTerritory");
      case "CONSTRAINTS_RENT":
        return i18n.t("RentAgreement");
      case "CONSTRAINTS_DISTRESS":
        return i18n.t("UnderSeizure");
      default:
        return "-";
    }
  },
  estateType(estateType) {
    switch (estateType) {
      case "commercial":
        return i18n.t("Commercial");
      case "apartment":
        return i18n.t("Apartment");
      case "house":
        return i18n.t("House");
      case "house_part":
        return i18n.t("HousePart");
      case "land":
        return i18n.t("LandPlot");
      case "cottage":
        return i18n.t("SummerHouse");
      case "garage":
        return i18n.t("Garage");
      case "office":
        return i18n.t("Office");
      case "building":
        return i18n.t("Building");
      case "room":
        return i18n.t("Room");
      case "storage":
        return i18n.t("Storage");
      default:
        return "-";
    }
  },
  windowView(windowView) {
    switch (windowView) {
      case "street_view":
        return i18n.t("StreetView");
      case "courtyard_view":
        return i18n.t("CourtyardView");
      case "no_window":
        return i18n.t("NoWindow");
      default:
        return "-";
    }
  },
  dateAvailable(dateAvailable) {
    if (dateAvailable) {
      return moment(dateAvailable, "YYYY-MM-DD").format("DD.MM.YYYY");
    } else {
      return "-";
    }
  },
  expiryDate(date) {
    if (date) {
      return moment(date).format("DD.MM.YYYY");
    } else {
      return "";
    }
  },
};

const plugin = {
  install() {
    Vue.lfMapper = lfMapper;
    Vue.prototype.$lfMapper = lfMapper;
  },
};

Vue.use(plugin);
