import axios from 'axios';
import router from '@/router';

const initialState = {
    invoice: null,
    userInvoices: null,
    invoiceUser: null,
    addInvoiceProject: null,
    invoiceLoading: false,
    invoiceSidepanelOpen: false,
    invoiceUserSidepanelOpen: false,
};

const invoices = {
    state: {...initialState, invoicesUpdater: 0},
    mutations: {
        SET_INVOICE(state, invoice) {
            state.invoice = invoice;
        },
        SET_USER_INVOICES(state, {invoices, user}) {
            state.invoiceUser = user;
            state.userInvoices = invoices;
        },
        TOGGLE_INVOICE_SIDEPANEL(state, open = true) {
            state.invoiceSidepanelOpen = open;
        },
        TOGGLE_INVOICE_USER_SIDEPANEL(state, open = true) {
            state.invoiceUserSidepanelOpen = open;
        },
        SET_LOADING(state, status) {
            state.invoiceLoading = status;
        },
        SET_ADD_INVOICE_PROJECT(state, project) {
            state.addInvoiceProject = {
                _id: project._id,
                name: project.name,
                dealType: project.dealType,
                pipelineStep: project.pipelineStep,
                doneDate: project.doneDate,
                client: project.client,
                access: project.access,
                apartmentNumber: project.apartmentNumber,
            }
        },
        RESET_STATE(state, fieldsToDelete = []) {
            console.log("Resetting invoices state...")
            // Removing invoice id from query parameters.
            const currentQuery = {...router.currentRoute.query};
            for (const field of fieldsToDelete) {
                delete currentQuery[field]
            }
            router.push({
                name: router.currentRoute.name,
                query: currentQuery
            });
            Object.assign(state, {...initialState, invoicesUpdater: state.invoicesUpdater});
        },
        INVOICE_UPDATED(state) {
            state.invoicesUpdater++;
        }
    },
    actions: {
        async fetchInvoice({commit}, invoiceId) {
            commit('SET_LOADING', true);
            commit('TOGGLE_INVOICE_SIDEPANEL')
            try {
                const response = await axios.get(`/api/invoice/${invoiceId}`);
                const fetchedInvoice = response.data;
                commit('SET_INVOICE', fetchedInvoice);
            } catch (error) {
                console.error("Error fetching invoice:", error);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        async fetchUserInvoices({commit, rootState}, userId) {
            commit('SET_LOADING', true);
            commit('TOGGLE_INVOICE_USER_SIDEPANEL')
            try {
                const response = await axios.post(`/api/invoices/brokers/${userId}`, {filters: rootState.d.provisionalFilters["invoices"]});
                const fetchedInvoices = response.data;
                const user = rootState.a.users.find(user => user._id === userId);
                commit('SET_USER_INVOICES', {invoices: fetchedInvoices, user: user});
            } catch (error) {
                console.error("Error fetching invoice:", error);
            } finally {
                commit('SET_LOADING', false);
            }
        },
        openInvoiceAddPanel({commit}, project) {
            commit('RESET_STATE');
            commit('TOGGLE_INVOICE_SIDEPANEL');
            commit('SET_ADD_INVOICE_PROJECT', project);
        },
        closeInvoiceSidepanel({commit}, queryFieldsToDelete = ['invoices', 'invoicesUser']) {
            commit('RESET_STATE', queryFieldsToDelete);
        },
        invoiceUpdated({commit}) {
            commit('INVOICE_UPDATED')
        }
    },
    getters: {
        invoiceSidepanelOpen(state) {
            return state.invoiceSidepanelOpen
        },
        invoiceUserSidepanelOpen(state) {
            return state.invoiceUserSidepanelOpen
        },
        invoice(state) {
            return state.invoice
        },
        userInvoices(state) {
            return state.userInvoices
        },
        invoiceUser(state) {
            return state.invoiceUser
        },
        invoiceLoading(state) {
            return state.invoiceLoading
        },
        addInvoiceProject(state) {
            return state.addInvoiceProject
        },
        invoiceUpdater(state) {
            return state.invoicesUpdater
        }
    }
}

export default invoices;