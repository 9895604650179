import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

Vue.mixin({
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["setNylasAccount"]),
    getNylasAccessToken() {
      try {
        return this.user.nylas?.grantId
      } catch (error) {
        this.setMicrosoftAccount(null);
      }
    },
  },
});
