var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePopOver,
      expression: "closePopOver"
    }],
    staticClass: "overlay",
    attrs: {
      "id": "statuses-dropdown-overlay"
    },
    on: {
      "click": function ($event) {
        return _vm.closePopover($event);
      }
    }
  }, [_c('div', {
    attrs: {
      "id": "statuses-dropdown"
    }
  }, _vm._l(_vm.statuses, function (status, index) {
    return _c('div', {
      key: index,
      staticClass: "status-label-wrapper"
    }, [_c('div', {
      staticClass: "status-label",
      style: 'background:' + status.color,
      on: {
        "click": function ($event) {
          return _vm.openModal(status.value, null);
        }
      }
    }, [_c('p', {
      staticClass: "content-bold"
    }, [_vm._v(_vm._s(status.name))])]), _vm.statusModalOpen ? _c('StatusConfirmationModal', {
      attrs: {
        "count": _vm.selectedListingsList
      },
      on: {
        "canceled": function ($event) {
          return _vm.closeStatusPanel();
        },
        "approved": function ($event) {
          return _vm.updateListingStatus(_vm.chosenStatus, _vm.chosenUntil);
        }
      }
    }) : _vm._e()], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }