<template>
  <div class="description-area">
    <div class="language-selection" v-if="!hideLanguageSelection">
      <div
        v-if="user.access.company._id === 'xmwei_Rt9ryfpkxL4BL8'"
        v-bind:class="{
          'active-view': activeView === 'lv',
          '': activeView !== 'lv',
        }"
        @click="activeView = 'lv'"
        id="lvPicker"
      >
        <p class="content-small semi-bold">{{ $t("LatvianLanguage") }}</p>
      </div>
      <div
        v-if="!user.isItaly"
        v-bind:class="{
          'active-view': activeView === 'et',
          '': activeView !== 'et',
        }"
        @click="activeView = 'et'"
        id="etPicker"
      >
        <p class="content-small semi-bold">{{ $t("EstonianLanguage") }}</p>
      </div>
      <div
        v-bind:class="{
          'active-view': activeView === 'eng',
          '': activeView !== 'eng',
        }"
        @click="activeView = 'eng'"
        id="engPicker"
      >
        <p class="content-small semi-bold">{{ $t("EnglishLanguage") }}</p>
      </div>
      <div
        v-if="!user.isItaly"
        v-bind:class="{
          'active-view': activeView === 'ru',
          '': activeView !== 'ru',
        }"
        @click="activeView = 'ru'"
        id="ruPicker"
      >
        <p class="content-small semi-bold">{{ $t("RussianLanguage") }}</p>
      </div>
      <div
        v-if="!user.isItaly && user.access.company._id !== 'xmwei_Rt9ryfpkxL4BL8'"
        v-bind:class="{
          'active-view': activeView === 'fi',
          '': activeView !== 'fi',
        }"
        @click="activeView = 'fi'"
        id="fiPicker"
      >
        <p class="content-small semi-bold">{{ $t("FinnishLanguage") }}</p>
      </div>
      <div
        v-if="user.isItaly"
        v-bind:class="{
          'active-view': activeView === 'it',
          '': activeView !== 'it',
        }"
        @click="activeView = 'it'"
        id="itPicker"
      >
        <p class="content-small semi-bold">{{ $t("ItalyLanguage") }}</p>
      </div>
    </div>
    <div
      v-if="activeView === 'et' && listing.slogan"
      v-bind:class="{
        'slogan-row': !isDevelopment,
        'slogan-row-basic': isDevelopment,
      }"
    >
      <p style="margin-top: 0px">{{ listing.slogan }}</p>
    </div>
    <div
      v-if="activeView === 'eng' && listing.sloganENG"
      v-bind:class="{
        'slogan-row': !isDevelopment,
        'slogan-row-basic': isDevelopment,
      }"
    >
      <p style="margin-top: 0px">{{ listing.sloganENG }}</p>
    </div>
    <div
      v-if="activeView === 'ru' && listing.sloganRU"
      v-bind:class="{
        'slogan-row': !isDevelopment,
        'slogan-row-basic': isDevelopment,
      }"
    >
      <p style="margin-top: 0px">{{ listing.sloganRU }}</p>
    </div>

    <div
      v-if="activeView === 'fi' && listing.sloganFI"
      v-bind:class="{
        'slogan-row': !isDevelopment,
        'slogan-row-basic': isDevelopment,
      }"
    >
      <p style="margin-top: 0px">{{ listing.sloganFI }}</p>
    </div>

     <div
      v-if="activeView === 'lv' && listing.sloganLV"
      v-bind:class="{
        'slogan-row': !isDevelopment,
        'slogan-row-basic': isDevelopment,
      }"
    >
      <p style="margin-top: 0px">{{ listing.sloganLV }}</p>
    </div>

    <p v-if="activeView === 'et' && listing.description" class="content-normal">
      {{ listing.description }}
    </p>

    <div v-if="activeView === 'et' && listing.servicesInNeighbourhood">
      <h6 style="margin-top: 24px">
        {{ $t("ServicesInNeighbourhood") }}
      </h6>
      <p class="field-value" style="margin-top: 0px">
        {{ listing.servicesInNeighbourhood }}
      </p>
    </div>
    <div v-if="activeView === 'et'">
      <h6 style="margin-top: 24px" v-if="listing.linkTitle">
        {{ listing.linkTitle }}
      </h6>
      <a
        @click="openVideoLink(listing.additionalInfoLink)"
        href="#"
        style="margin-top: 0px; cursor: pointer"
        v-if="listing.additionalInfoLink"
        >{{ listing.additionalInfoLink }}</a
      >
      <p
        class="content-normal"
        v-if="activeView === 'et' && listing.footerEnabled && listing.footerText"
        v-html="listing.footerText['et']"
      >
        {{ listing.footerText["et"] }}
      </p>
    </div>
    <p
      v-if="activeView === 'eng' && listing.descriptionENG"
      class="content-normal"
    >
      {{ listing.descriptionENG }}
    </p>

    <div v-if="activeView === 'eng' && listing.servicesInNeighbourhoodENG">
      <h6 style="margin-top: 24px">
        {{ $t("ServicesInNeighbourhood") }}
      </h6>
      <p class="field-value" style="margin-top: 0px">
        {{ listing.servicesInNeighbourhoodENG }}
      </p>
    </div>
    <div v-if="activeView === 'eng'">
      <h6 style="margin-top: 24px" v-if="listing.linkTitleENG">
        {{ listing.linkTitleENG }}
      </h6>
      <a
        @click="openVideoLink(listing.additionalInfoLinkENG)"
        href="#"
        style="margin-top: 0px; cursor: pointer"
        v-if="listing.additionalInfoLinkENG"
        >{{ listing.additionalInfoLinkENG }}</a
      >
      <p
        class="content-normal"
        v-if="activeView === 'eng' && listing.footerEnabled && listing.footerText"
        v-html="listing.footerText['eng']"
      >
        {{ listing.footerText["eng"] }}
      </p>
    </div>

    <p
      v-if="activeView === 'ru' && listing.descriptionRU"
      class="content-normal"
    >
      {{ listing.descriptionRU }}
    </p>
    <div v-if="activeView === 'ru' && listing.servicesInNeighbourhoodRU">
      <h6 style="margin-top: 24px">
        {{ $t("ServicesInNeighbourhood") }}
      </h6>
      <p class="field-value" style="margin-top: 0px">
        {{ listing.servicesInNeighbourhoodRU }}
      </p>
    </div>
    <div v-if="activeView === 'ru'">
      <h6 style="margin-top: 24px" v-if="listing.linkTitleRU">
        {{ listing.linkTitleRU }}
      </h6>
      <a
        @click="openVideoLink(listing.additionalInfoLinkRU)"
        href="#"
        style="margin-top: 0px; cursor: pointer"
        v-if="listing.additionalInfoLinkRU"
        >{{ listing.additionalInfoLinkRU }}</a
      >
      <p
        class="content-normal"
        v-if="activeView === 'ru' && listing.footerEnabled && listing.footerText"
        v-html="listing.footerText['ru']"
      >
        {{ listing.footerText["ru"] }}
      </p>
    </div>

    <p
      v-if="activeView === 'fi' && listing.descriptionFI"
      class="content-normal"
    >
      {{ listing.descriptionFI }}
    </p>
    <div v-if="activeView === 'fi' && listing.servicesInNeighbourhoodFI">
      <h6 style="margin-top: 24px">
        {{ $t("ServicesInNeighbourhood") }}
      </h6>
      <p class="field-value" style="margin-top: 0px">
        {{ listing.servicesInNeighbourhoodFI }}
      </p>
    </div>
    <div v-if="activeView === 'fi'">
      <h6 style="margin-top: 24px" v-if="listing.linkTitleFI">
        {{ listing.linkTitleFI }}
      </h6>
      <a
        @click="openVideoLink(listing.additionalInfoLinkFI)"
        href="#"
        style="margin-top: 0px; cursor: pointer"
        v-if="listing.additionalInfoLinkFI"
        >{{ listing.additionalInfoLinkFI }}</a
      >
      <p
        class="content-normal"
        v-if="activeView === 'fi' && listing.footerEnabled && listing.footerText"
        v-html="listing.footerText['fi']"
      >
        {{ listing.footerText["fi"] }}
      </p>
    </div>

    <p
      v-if="activeView === 'lv' && listing.descriptionLV"
      class="content-normal"
    >
      {{ listing.descriptionLV }}
    </p>
     <div v-if="activeView === 'lv' && listing.servicesInNeighbourhoodLV">
      <h6 style="margin-top: 24px">
        {{ $t("ServicesInNeighbourhood") }}
      </h6>
      <p class="field-value" style="margin-top: 0px">
        {{ listing.servicesInNeighbourhoodLV }}
      </p>
    </div>
    <div v-if="activeView === 'lv'">
      <h6 style="margin-top: 24px" v-if="listing.linkTitleLV">
        {{ listing.linkTitleLV }}
      </h6>
      <a
        @click="openVideoLink(listing.additionalInfoLinkLV)"
        href="#"
        style="margin-top: 0px; cursor: pointer"
        v-if="listing.additionalInfoLinkLV"
        >{{ listing.additionalInfoLinkLV }}</a
      >
      <p
        class="content-normal"
        v-if="activeView === 'lv' && listing.footerEnabled && listing.footerText"
        v-html="listing.footerText['lv']"
      >
        {{ listing.footerText["lv"] }}
      </p>
    </div>

    <p
      v-if="activeView === 'it' && listing.descriptionIT"
      class="content-normal"
    >
      {{ listing.descriptionIT }}
    </p>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DescriptionArea",
  props: ["listing", "hideLanguageSelection", "isDevelopment"],
  data() {
    return {
      activeView: "et",
    };
  },
  created() {
    if (this.user.isItaly) this.activeView = "eng"
    if (this.user.access.company._id === 'xmwei_Rt9ryfpkxL4BL8') this.activeView = "lv"
  },
  methods: {
    openVideoLink(e) {
      window.open(e, "_blank");
    },
  },
  computed: {
    ...mapGetters(["user"])
  }
};
</script>

<style lang="scss" scoped>
.description-area {
  padding-top: 8px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}
.language-selection {
  display: flex;
  flex-direction: row;
  gap: 24px;
  div {
    cursor: pointer;
  }
}
.active-view {
  padding-bottom: 18px;
  border-bottom: 1px solid black;
}
.slogan-row {
  margin-top: 24px;
  background: #fff0f0;
  border-radius: 8px;
  padding: 12px;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* red */

    color: #ff1e24;
  }
}
.slogan-row-basic {
  margin-top: 24px;
  border-radius: 8px;
  p {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* red */

    color: black;
  }
}
p {
  max-width: 100%;
  white-space: pre-line;
  margin-top: 24px;
  word-break: break-word;
}
.field-name,
.field-value {
  width: 208px;
  font-size: 14px;
  line-height: 20px;
}
</style>
