var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contact-tag-container label10",
    class: 'tag-' + _vm.tagName
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.abbreviation)) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }