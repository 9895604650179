var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeModal,
      expression: "closeModal"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 999,
      "resizable": false,
      "drag-handle": ".drag-area"
    },
    on: {
      "dragstop": _vm.onDragStop
    }
  }, [_c('div', {
    staticClass: "booking-details-widget"
  }, [_c('div', {
    staticClass: "widget-header drag-area"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "modal-content"
  }, [!_vm.bulkBooking ? _c('v-row', {
    staticClass: "modal-row",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets/images/objects.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "listing-title"
  }, [_vm._v(" " + _vm._s(_vm.listing.listingName) + " ")])]) : _vm._e(), _vm.bulkBooking && _vm.listings && _vm.listings.length === 1 ? _c('v-row', {
    staticClass: "modal-row",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets/images/objects.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "listing-title"
  }, [_vm._v(" " + _vm._s(_vm.listings[0].listingName) + " ")])]) : _vm._e(), _c('v-row', {
    staticClass: "modal-row",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets/images/info.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "4px"
    }
  }, [_c('div', {
    staticClass: "booking-info-header"
  }, [_c('v-row', [_c('p', [_vm._v(_vm._s(_vm.$t("BookingType")))]), _c('p', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])])], 1), _c('div', {
    staticClass: "booking-type-label"
  }, _vm._l(_vm.bookingTypes, function (type, index) {
    return _c('p', {
      key: index,
      class: {
        'selected-type': type.value === _vm.newBookingType
      },
      attrs: {
        "id": 'type-' + type.value
      },
      on: {
        "click": function ($event) {
          return _vm.selectBookingType(type.value);
        }
      }
    }, [_vm._v(" " + _vm._s(type.name) + " ")]);
  }), 0)])]), _c('v-row', {
    staticClass: "modal-row",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets/images/calendar.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "4px"
    }
  }, [_c('div', {
    staticClass: "booking-info-header"
  }, [_c('v-row', [_c('p', [_vm._v(_vm._s(_vm.$t("BookedUntil")))]), _c('p', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])])], 1), _c('date-pick', {
    key: _vm.updateCalendarChoice,
    staticStyle: {
      "display": "flex"
    },
    attrs: {
      "isDateDisabled": _vm.isFutureDate
    },
    on: {
      "input": function ($event) {
        return _vm.addBookingDate($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var toggle = _ref.toggle;
        return [_c('p', {
          staticClass: "content",
          staticStyle: {
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.formatBookedUntilDate(_vm.listing.bookedUntil)) + " ")])];
      }
    }]),
    model: {
      value: _vm.listing.bookedUntil,
      callback: function ($$v) {
        _vm.$set(_vm.listing, "bookedUntil", $$v);
      },
      expression: "listing.bookedUntil"
    }
  })], 1)]), !_vm.newBookerContact ? _c('v-row', {
    staticClass: "modal-row booker-search",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_vm.editBooker ? _c('TaskLinkedToSearch', {
    ref: "search",
    attrs: {
      "items": _vm.contacts,
      "icon-name": "clients.svg",
      "booking": "true",
      "modal-type": "client",
      "label": _vm.$t('Booker').toLowerCase()
    },
    on: {
      "change": _vm.selectBooker
    },
    model: {
      value: _vm.newBookerContact,
      callback: function ($$v) {
        _vm.newBookerContact = $$v;
      },
      expression: "newBookerContact"
    }
  }) : _vm._e()], 1) : _vm.newBookerContact && !_vm.editBooker ? _c('v-row', {
    staticClass: "modal-row",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets/images/clients.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "booker-selection",
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "4px"
    },
    on: {
      "click": _vm.toggleBookerEdit
    }
  }, [_c('div', {
    staticClass: "booking-info-header"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Booker")))])]), _c('p', [_vm._v(" " + _vm._s(_vm.newBookerContact.name) + " ")])])]) : _vm._e(), _c('v-row', {
    staticClass: "modal-row comment",
    staticStyle: {
      "margin-top": "0"
    }
  }, [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets/images/chat-no-bubble.svg"),
      "alt": ""
    }
  }), !_vm.newBookingComment && !_vm.editComment ? _c('div', {
    on: {
      "click": _vm.openCommentEdit
    }
  }, [_c('p', {
    staticClass: "link-small"
  }, [_vm._v(" + " + _vm._s(_vm.$t("addComment").charAt(0).toUpperCase() + _vm.$t("addComment").slice(1)) + " ")])]) : _vm._e(), _vm.editComment ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBookingComment,
      expression: "newBookingComment"
    }],
    ref: "commentbox",
    staticClass: "textarea-booking",
    attrs: {
      "id": "textarea",
      "rows": "2"
    },
    domProps: {
      "value": _vm.newBookingComment
    },
    on: {
      "focusout": _vm.saveComment,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.newBookingComment = $event.target.value;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.newBookingComment && !_vm.editComment ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "4px"
    },
    on: {
      "click": _vm.openCommentEdit
    }
  }, [_c('div', {
    staticClass: "booking-info-header"
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Comment")))])]), _c('p', [_vm._v(" " + _vm._s(_vm.newBookingComment) + " ")])]) : _vm._e()])], 1), _c('v-divider', {
    staticClass: "booking-modal-divider"
  }), _c('div', {
    staticClass: "modal-footer"
  }, [!_vm.bulkBooking ? _c('div', {
    staticClass: "remove-button",
    on: {
      "click": _vm.removeBooking
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("RemoveBooking")))])]) : _vm._e(), _c('div', {
    staticClass: "other-buttons"
  }, [_c('BaseButton', {
    staticClass: "button-cancel",
    attrs: {
      "size": "large",
      "label": _vm.$t('Cancel')
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal('cancel');
      }
    }
  }), _c('BaseButton', {
    attrs: {
      "size": "large",
      "primary": "true",
      "label": _vm.$t('Save')
    },
    on: {
      "click": _vm.saveBooking
    }
  })], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }