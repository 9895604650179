import axios from "axios";

export default {
  async getFooterText() {
    return (await axios.get(`/api/company/listings/footer`)).data;
  },
  async saveFooterText(footer) {
    return (await axios.post(`/api/company/listings/footer`, { footer })).data;
  },

  async toggleFooterStatus(status) {
    return (await axios.post(`/api/company/listings/footer/status`, { status }))
      .data;
  },
  async getActiveDays() {
    return (await axios.get(`/api/company/listings/active-days`)).data;
  },
  async saveActiveDays(activeDays) {
    return (
      await axios.post(`/api/company/listings/active-days`, { activeDays })
    ).data;
  },
  async toggleActiveDaysStatus(status) {
    return (
      await axios.post(`/api/company/listings/active-days/status`, { status })
    ).data;
  },
  async saveVisibility(visibility) {
    return (await axios.post(`/api/company/policy/visibility`, { visibility }))
      .data;
  },
  async getPolicies() {
    return (await axios.get(`/api/company/policies`)).data;
  },
  async addSharedResource(newSharedResource) {
    return (
      await axios.post(`/api/admin/add-shared-resource`, { newSharedResource })
    ).data;
  },
  async getSharedResources() {
    return (await axios.get(`/api/admin/get-shared-resources`)).data;
  },
  async editSharedResource(editedSharedResource) {
    return (
      await axios.post(`/api/admin/edit-shared-resource`, {
        editedSharedResource,
      })
    ).data;
  },
  async deleteSharedResource(resourceToDelete) {
    return (
      await axios.post(`/api/admin/delete-shared-resource`, {
        resourceToDelete,
      })
    ).data;
  },
  async createSelfUser(user) {
    return (await axios.post(`/api/self-register/create-user`, { user })).data;
  },
  async uploadCorporateImage(image) {
    return (await axios.post(`/api/company/upload-corporate-image`, { image }))
      .data;
  },
  async toggleCorporateImageStatus(status) {
    return (
      await axios.post(`/api/company/listings/corporate-image/status`, {
        status,
      })
    ).data;
  },
  async changePassword(password) {
      return (await axios.post(`/api/admin/change-password`, {password})).data
  },
  async saveBrokerLstImage(image, userId) {
      return (await axios.post(`/api/admin/upload-broker-lst-image`, { image, userId })).data
  },
  async savePolicyFilter(filters) {
    return (await axios.post(`/api/company/policy/filters`, {filters})).data
  }
};
