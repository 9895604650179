var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "cursor": "pointer",
      "display": "flex",
      "align-items": "center",
      "gap": "12px"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.changeValue.apply(null, arguments);
      }
    }
  }, [!_vm.toggleValue && _vm.green ? _c('img', {
    style: _vm.disabled ? 'opacity: 0.5' : '',
    attrs: {
      "src": require(`@/assets/images/toggle-red.svg`)
    }
  }) : _vm._e(), !_vm.toggleValue && !_vm.green ? _c('img', {
    style: _vm.disabled ? 'opacity: 0.5' : '',
    attrs: {
      "src": require(`@/assets/images/toggle-inactive.svg`)
    }
  }) : _vm._e(), _vm.toggleValue && !_vm.green && !_vm.toggleColour ? _c('img', {
    style: _vm.disabled ? 'opacity: 0.5' : '',
    attrs: {
      "src": require(`@/assets/images/toggle-active.svg`)
    }
  }) : _vm._e(), _vm.toggleValue && _vm.green ? _c('img', {
    style: _vm.disabled ? 'opacity: 0.5' : '',
    attrs: {
      "src": require(`@/assets/images/toggle-green.svg`)
    }
  }) : _vm._e(), _vm.toggleValue && _vm.toggleColour === 'turquoise' ? _c('img', {
    style: _vm.disabled ? 'opacity: 0.5' : '',
    attrs: {
      "src": require(`@/assets/images/toggle-turquoise.svg`)
    }
  }) : _vm._e(), _vm.toggleValue && _vm.toggleColour === 'black' ? _c('img', {
    style: _vm.disabled ? 'opacity: 0.5' : '',
    attrs: {
      "src": require(`@/assets/images/toggle-black.svg`)
    }
  }) : _vm._e(), _vm.toggleLabel ? _c('div', {
    staticClass: "content",
    class: {
      'white-text': _vm.labelColour && _vm.labelColour === 'white'
    }
  }, [_vm._v(" " + _vm._s(_vm.toggleLabel) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }