var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.show ? _c('div', {
    staticClass: "project-simple-info-card",
    class: _vm.cardBorderColor,
    on: {
      "click": function ($event) {
        return _vm.$emit('onPress');
      }
    }
  }, [_c('div', {
    staticClass: "project-first-row"
  }, [_c('DealTypeContainer', {
    attrs: {
      "small-font": false,
      "deal-type": _vm.project.dealType
    }
  }), _c('PipelineInfoContainer', {
    attrs: {
      "projectPipelineStepTimeLog": _vm.project.pipelineStepTimeLog
    }
  })], 1), _c('div', {
    staticClass: "link"
  }, [_vm._v(" " + _vm._s(_vm.project.name) + " ")])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }