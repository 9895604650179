import Vue from "vue";
import axios from "axios";

Vue.mixin({
  methods: {
    async logError(logMessage, err) {
      const message = {
        level: "error",
        message: logMessage,
        meta: {
          error: JSON.stringify(err),
        },
      };
      await axios.post("/api/log", message);
    },
  },
});
