var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contact-tags"
  }, _vm._l(_vm.tags, function (tagName) {
    return _c('ContactTagContainer', {
      key: tagName,
      attrs: {
        "tag-name": tagName
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }