<template>
  <div>
    <div>
       <v-select
        v-model="selectedLanguage"
        :items="languages"
        outlined
        item-text="name"
        item-value="value"
        :label="$t('DisplayLanguage')"
        v-click-outside="closePanel"
        ></v-select>
       <!--   <select @change="changeSelect2" v-model="selectedLanguage" name="select1">
      <option value="fruits">fruits</option>
      <option value="vegetables">vegetables</option>
      <option value="flowers">flowers</option>
  </select> -->
    </div>
  </div>
  
</template>
<script>

import {mapActions} from "vuex";

export default {
  name: "LanguageSelection",
  props: ["closedPanel"],
  components: {
  },
  data() {
    return {
      languages: [
        {name: 'Dansk', value: 'dk', tag: 'Danish'},
        {name: 'Nederlands', value: 'nl', tag: 'Dutch'},
        {name: 'English', value: 'en', tag: 'English'},
        {name: 'Eesti', value: 'et', tag: 'Estonian'},
        {name: 'Français', value: 'fr', tag: 'French'},
        {name: 'Deutsch', value: 'de', tag: 'German'},
        {name: 'Italiano', value: 'it', tag: 'Italian'},
        {name: 'Latviešu', value: 'lv', tag: 'Latvian'},
        {name: 'Lietuvių', value: 'lt', tag: 'Lithuanian'},
        {name: 'Português', value: 'pt', tag: 'Portuguese'},
        {name: 'Polski', value: 'pl', tag: 'Polish'},
        {name: 'Русский', value: 'ru', tag: 'Russian'},
        {name: 'Română', value: 'ro', tag: 'Romanian'},
        {name: 'Español', value: 'es', tag: 'Spanish'},
        {name: 'Svenska', value: 'se', tag: 'wedish'},

        {name: 'Български', value: 'bg', tag: 'Bulgarian'},
        {name: 'Català', value: 'ct', tag: 'Catalan'},
        {name: 'Čeština', value: 'cz', tag: 'Czech'},
        {name: 'Suomi', value: 'fi', tag: 'Finnish'},
        {name: 'Ελληνικά', value: 'gr', tag: 'Greek'},
        {name: 'Magyar', value: 'hu', tag: 'Hungarian'},
        {name: 'Norsk', value: 'no', tag: 'Norwegian'},
        {name: 'Slovenčina', value: 'sk', tag: 'Slovak'},
        {name: 'Slovenščina', value: 'si', tag: 'Slovenian'},
        {name: 'Türkçe', value: 'tr', tag: 'Turkish'},
      ],
      selectedLanguage: "",
      previousSelected: null,
      openConfirmationPanel: false
    };
  },
  created() {
    for (let _language of this.languages) {
        if (this.$i18n.locale === _language.value) {
            this.selectedLanguage = _language.value 
            this.previousSelected = this.selectedLanguage      
        }
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    ...mapActions(["setLanguage", "setLanguageSet"]),
    closePanel(){
        if (this.selectedLanguage !== this.previousSelected && !this.closedPanel) {
            this.$emit("confirmationOpen", true, this.selectedLanguage)
            this.selectedLanguage = null
            this.previousSelected = null
        }
    },
    changeLocale(loc) {
      this.setLanguage(loc);
      this.setLanguageSet(true);
      this.$i18n.locale = loc;
      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";


::v-deep .v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__prepend-outer {
    margin-top: 10px !important
}
::v-deep  .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 44px !important;
    max-height: 44px !important;
}
</style>
