import Vue from "vue";
import moment from "moment";

const projectsFilter = {
    getResult(allProjects, conditions) {
        let filteredDeals = [...allProjects];
        conditions.forEach((condition) => {
            switch (condition.name) {
                case "search":
                    filteredDeals = this._searchProjects(filteredDeals, condition.value);
                    break;
                case "brokerId":
                    filteredDeals = this._searchByBroker(filteredDeals, condition.value);
                    break;
                case "dealTypes":
                    filteredDeals = this._searchByDealType(filteredDeals, condition.value);
                    break;
                case "createdToDate":
                    filteredDeals = this._searchByCreatedToDate(filteredDeals, condition.value);
                    break;
                case "createdFromDate":
                    filteredDeals = this._searchByCreatedFromDate(filteredDeals, condition.value);
            }
        });
        return filteredDeals;
    },
    _searchProjects(projects, value) {
        if (!projects || !value) return projects;
        return projects.filter(
            (project) => project.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        );
    },
    _searchByBroker(projects, value) {
        if (!projects || !value) return projects;
        let resultArray = []
        projects.forEach((project)=> {
            if (project.creator.userId.includes(value)) {
                resultArray.push(project)
            }
            else if (project.sharedCreators) {
                project.sharedCreators.forEach((sharedCreator)=> {
                    if (sharedCreator.userId.includes(value)) {
                        resultArray.push(project)
                    }
                })
            }
        })
        return resultArray
        //return projects.filter(project => value.includes(project.creator.userId));
    },
    _searchByDealType(projects, value) {
        if (!projects || !value || value.length === 0) return projects;
        return projects.filter((project) => value.includes(project.dealType));
    },
    _searchByCreatedToDate(projects, value) {
        if (!projects || !value) return projects;
        return projects.filter((project) => moment(project.createdAt).isBefore(value));
    },
    _searchByCreatedFromDate(projects, value) {
        if (!projects || !value) return projects;
        return projects.filter((project) => moment(project.createdAt).isAfter(value));
    },
};

const plugin = {
    install() {
        Vue.projectsFilter = projectsFilter;
        Vue.prototype.$projectsFilter = projectsFilter;
    },
};

Vue.use(plugin);