<template>
  <div @keydown="searchItem" :id="id" class="input-field-container">
    <div
    v-if="fieldType !== 'date'"
      ref="container"
      :key="fieldUpdater"
      :name="'dropdown-' + id"
      @click="focusInput"
      class="form-field-large"
      :class="{ disabled: fieldDisabled }"
      :style="fieldStyle"
    >
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        style="display: block"
        v-if="iconLeft"
        :src="require(`@/assets/images/` + iconLeft)"
        alt=""
      />
      <div
        :class="{ required: fieldRequired }"
        style="display: flex; width: 100%; flex-direction: column"
      >
        <label
          v-if="fieldPlaceholder"
          ref="label"
          :style="labelStyle"
          class="content"
          >{{ fieldPlaceholder }}</label
        >
        <input
          @focus="focusInputElementPlaceholder"
          @keyup.enter="focusInputElement"
          :type="fieldType"
          :disabled="fieldDisabled"
          @focusout="unfocusInput"
          ref="input"
          id="input-element"
          class="input-field content"
          v-model="fieldValue"
        />
      </div>
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        v-if="fieldDropdownArrow"
        :src="require(`@/assets/images/dropdown_20_20.svg`)"
        alt=""
      />
      <img
        :class="{ 'icon-disabled': fieldDisabled }"
        v-if="iconRight"
        :src="require(`@/assets/images/` + iconRight)"
        alt=""
      />
    </div>
    <div
      style="border-radius: 8px;"
      @click="toggle"
      :class="{ disabled: fieldDisabled }">
      <DatePick
        v-if="fieldType === 'date'"
        :disabled="fieldDisabled"
        :format="'YYYY-MM-DD'"
        :selectableYearRange="selectableYearRange"
        @input="updateDate"
        class="date-field"
        v-model="fieldValue"
        tabindex="0"
        ref="datePick" 
        v-click-outside="handleOutsideClick" 
        @mousedown.native="handleClick($event)" 
        @blur.native="handleBlur"
      >
        <template v-slot:default="{ toggle }">
          <div class="date-picker-content form-field-large" :style="fieldStyle" @click="toggle">
            <img
              style="cursor: pointer"
              height="20"
              width="20"
              :src="require('@/assets/images/calendar_20_20.svg')"
              alt=""
            />
              <div style="width: 100%; cursor: pointer; display: flex;" :style="{ flexDirection: fieldValue ? 'column' : 'row' }" :class="{ required: fieldRequired }">
                <label
                  v-if="fieldPlaceholder"
                  ref="dateLabel"
                  :style="dateLabelStyles"
                  :class="{ 'required-mark': fieldValue }"
                >{{ fieldPlaceholder }}</label>
                <div class="date-picker-value">
                  <div class="content-normal" style="cursor: pointer;" v-if="fieldValue">{{ formatDate(fieldValue) }}</div>
                </div>
              </div>
              <img
                :class="{ 'icon-disabled': fieldDisabled }"
                v-if="fieldValue"
                :src="require(`@/assets/images/cross.svg`)"
                alt=""
                style="z-index: 999;"
                @click.prevent.stop="clearFieldValue"
              />
              <img
                :class="{ 'icon-disabled': fieldDisabled }"
                v-if="fieldDropdownArrow"
                :src="require(`@/assets/images/dropdown_20_20.svg`)"
                alt=""
              />
            </div>
        </template>
      </DatePick>
    </div>
    <div
      ref="dropdown"
      v-click-outside="closeDropdown"
      v-if="fieldDropdownOpen || (fieldDropdownOpen && fieldDropdownAddNew)"
      class="dropdown-container"
      id="container-dropdown"
      :style="fieldDropdownStyle"
      style="left: 12px; top: 4px; right: 12px"
    >
      <div
        tabindex="0"
        @keyup.enter="selectDropdownitem(null)"
        v-if="fieldValue.length > 3 && fieldDropdownAllowNull && !required"
        @click="selectDropdownitem(null)"
        class="content dropdown-item"
      >
        {{ $t("none") }}
      </div>
      <div
        style="position: relative; overflow-y: auto; max-height: 200px"
        :id="id + 'dropdown-parent-container'"
        :class="{
          'dropdown-medium': fieldDropdownAllowNull,
          'dropdown-small': fieldDropdownAllowNull && fieldDropdownAddNew,
        }"
      >
        <div
          tabindex="0"
          @keyup.enter="selectDropdownitem(item)"
          @click="selectDropdownitem(item)"
          :id="id + '-dropdown-item-' + index"
          :name="id + '-dropdown-item'"
          v-for="(item, index) in usableItems"
          :key="item.value ? item.value : index"
          class="content dropdown-item"
          v-bind:class="{
            'content dropdown-item': !item.hidden,
            hidden: item.hidden,
          }"
          style="display: flex; flex-wrap: nowrap"
        >
          {{ item.name ? item.name : item.text }}
        </div>
      </div>
      <div
        v-if="fieldDropdownAddNew"
        @click="dropdownAddNewButton"
        style="display: flex; gap: 8px; justify-content: center"
        class="dropdown-item"
        :class="{ bordert: fieldDropdownItems.length > 0 }"
      >
        <img :src="require(`@/assets/images/action-add_16_16.svg`)" />
        <p class="content">{{ $t("AddNew") }}</p>
      </div>
    </div>
    <div
      v-if="fieldError"
      class="content error-message"
      style="margin: 12px 12px 0px 0px"
    >
      {{
        fieldErrorMessage ? fieldErrorMessage : $t("FormErrors.FieldRequired")
      }}
    </div>
  </div>
</template>

<script>
import nanoid from "nanoid";
import axios from "axios";
import DatePick from 'vue-date-pick';
import moment from "moment";

export default {
  name: "BaseDropdown",
  props: [
    "type",
    "value",
    "id",
    "placeholder",
    "required",
    "size",
    "iconLeft",
    "iconRight",
    "errorMessage",
    "error",
    "disabled",
    "showDropdownArrow",
    "items",
    "returnObject",
    "dropdownOffsetY",
    "dropdownAddNew",
    "dropdownAllowNull",
    "dropdownZIndex",
  ],
  model: {
    prop: "value",
    event: "change",
  },
  components: { DatePick },
  data() {
    return {
      fieldType: "dropdown",
      fieldValue: "",
      fieldPlaceholder: "",
      fieldRequired: false,
      fieldSize: "large",
      fieldClass: "form-field-large",
      fieldError: false,
      fieldErrorMessage: false,
      fieldDisabled: false,
      originalArr: null,
      usableItems: [],
      updateDropdownItems: 0,
      fieldStyle: {
        border: "1px solid #E6E8EC",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        padding: "7px 11px",
        gap: "12px",
        background: "white",
        cursor: "text",
      },
      labelStyle: {
        color: "#75787A",
      },
      selectedItemValue: "",
      fieldUpdater: 0,
      selectedFieldDropdownItemValue: null,
      fieldDropdownArrow: false,
      fieldDropdownItems: null,
      fieldDropdownOpen: false,
      fieldDropdownStyle: {
        left: "12px",
        top: "4px",
        right: "12px",
      },
      fieldDropdownAddNew: false,
      fieldDropdownAllowNull: true,
      fieldOrganizationsFound: [],
      fieldOrganizationsFoundClone: [],
      search: "",
      isFocused: false,
      preventBlur: false,
      selectableYearRange: {
        from: moment().subtract(110, 'years').year(),
        to: moment().add(10, 'years').year()
      }
    };
  },
  watch: {
    fieldValue(value) {
      this.searchValue(value);

      if (
        this.fieldType === "dropdown" &&
        this.selectedFieldDropdownItemValue !== null
      ) {
        this.$emit("change", this.selectedFieldDropdownItemValue);
        return;
      }
      if (
        this.fieldType === "dropdown" &&
        !this.selectedFieldDropdownItemValue
      ) {
        this.$emit("change", this.fieldValue);
        return;
      }
      if (this.fieldType === "date") {
        this.$emit("change", this.fieldValue);
        return;
      }
      if (this.fieldType === "organizationsearch") {
        if (this.selectedFieldDropdownItemValue) {
          let organization = {
            _id: nanoid(16),
            company_name: this.selectedFieldDropdownItemValue.company_name,
            reg_code: this.selectedFieldDropdownItemValue.reg_code,
            organization: this.selectedFieldDropdownItemValue.company_name,
            organizationSearchingMethod: "Estonian",
          };
          this.$emit("change", organization);
          this.selectedFieldDropdownItemValue = null;
          return;
        }
        if (
          this.selectedItemValue &&
          this.selectedItemValue.length > value.length
        ) {
          this.$emit("change", null);
        }
        if (!this.selectedFieldDropdownItemValue && this.fieldValue === "") {
          this.$emit("change", null);
        }
        if (this.value.company_name === value) {
          return;
        }
        if (this.fieldValue !== "" && this.fieldType === "organizationsearch") {
          this.organizationSearch(value);
        }
      }
    },
    errorMessage() {
      this.fieldError = true;
      this.fieldErrorMessage = this.errorMessage;
    },
    error() {
      this.checkError();
    },
    disabled() {
      this.fieldDisabled = this.disabled;
    },
  },
  mounted() {
    this.initialize();
    this.fieldDisabled = this.disabled;
    this.searchValue(this.fieldValue);
    this.checkError();
  },
  computed: {
    dateLabelStyles() {
      return {
        color: this.isFocused ? '#FF5C01' : '#75787A',
        fontSize: this.fieldValue ? '11px' : '14px',
        lineHeight: this.fieldValue ? '12px !important' : '20px',
        transition: '0.2s',
        cursor: 'pointer'
      };
    }
  },
  methods: {
    clearFieldValue() {
    this.fieldValue = '';
    this.isFocused = false;
    this.handleOutsideClick();
  },
    handleClick(event) {
    let targetElement = event.target;
    do {
      if (targetElement.tagName === 'TD') {
        this.isFocused = false;
        return;
      }
      if (targetElement.classList && targetElement.classList.contains('vdpOuterWrap')) {
        console.log('Inside .vdpOuterWrap');
        return;
      }
      if (targetElement.tagName === 'SELECT' || targetElement.tagName === 'BUTTON') {
        console.log('Clicked on SELECT or BUTTON');
        this.preventBlur = true;
        return;
      }
      targetElement = targetElement.parentNode;
      } while (targetElement);
      this.isFocused = !this.isFocused;
    },
    handleOutsideClick() {
      this.preventBlur = false;
      this.isFocused = false;
    },
    handleDocumentClick(event) {
      const datePickElement = this.$refs.datePick.$el;
      let targetElement = event.target;
      do {
        if (targetElement === datePickElement || (targetElement.classList && targetElement.classList.contains('vdpOuterWrap'))) {
          return;
        }
        targetElement = targetElement.parentNode;
      } while (targetElement);
      this.preventBlur = false;
    },
    handleBlur() {
      this.$nextTick(() => {
        if (this.preventBlur) {
          this.preventBlur = false;
          return;
        }
        this.isFocused = false;
      });
    },
    formatDate(date) {
      const parsedDate = moment(date, 'YYYY-MM-DD');
      if (!parsedDate.isValid()) {
        return '';
      }
      return parsedDate.format("DD.MM.YYYY");
    },
    checkError() {
      this.fieldError = this.error;
      if (this.fieldError) {
        this.fieldStyle.border = "2px solid #FF1E24";
        this.fieldStyle.padding = "6px 10px";
        if (this.fieldFocus) {
          this.$refs.label.style.color = "#FF1E24";
        }
      } else {
        this.fieldStyle.border = "1px solid #E6E8EC";
        this.fieldStyle.padding = "7px 11px";
        this.$refs.label.style.color = "";
        if (this.validateInput(this.fieldValue)) {
          this.$refs.label.style.color = "#FF5C01";
        }
      }
    },
    async initialize() {
      await this.mapProps();
    },
    searchItem(event) {
      if (!this.fieldDropdownOpen) return;

      if (event.key === "Backspace") {
        this.search = this.search.slice(0, -1);
      }

      let tempSearch = this.search + event.key;
      let itemIndex = null;
      let searchWord = tempSearch.toLowerCase();
      let searchKey = "name";
      if (this.usableItems && this.usableItems.length) {
        this.usableItems.forEach((item, index) => {
          if (item[searchKey].toLowerCase().startsWith(searchWord)) {
            itemIndex = index;
          }
        });
      }

      if (itemIndex == null) return;

      this.search = this.search + event.key;

      let parentElement = document.getElementById(
        this.id + "dropdown-parent-container"
      );
      let childElement = document.getElementById(
        this.id + "-dropdown-item-" + itemIndex
      );
      parentElement.scrollTop =
        childElement.offsetTop - parentElement.offsetTop;
      childElement.focus();
    },
    searchValue() {
      if (this.type !== "date" && this.fieldType !== "organizationsearch") {
        this.originalArr = [...this.fieldDropdownItems];
        // if (this.originalArr.length >= 10) {
        //   if (val != "" && val != null) {
        //     let searchValue = val.charAt(0).toUpperCase() + val.slice(1);
        //     let tempItemsTitle = this.originalArr.filter(
        //       (item) => item.name.indexOf(searchValue) > -1
        //     );
        //     this.usableItems = [...tempItemsTitle];
        //   } else {
        //     this.usableItems = this.originalArr;
        //   }
        // } else if (this.originalArr.length < 10) {
        document.getElementById("input-element").blur();
        this.usableItems = this.originalArr;
        // }
      }
    },
    mapProps() {
      if (this.dropdownOffsetY) {
        this.fieldDropdownStyle.top = `${this.dropdownOffsetY}px`;
        // this.fieldDropdownStyle.top = "unset";
        //this.fieldDropdownStyle.height = "300px";
        // this.fieldDropdownStyle.bottom = "0px";
      }
      if (this.dropdownZIndex) {
        this.fieldDropdownStyle.zIndex = this.dropdownZIndex;
      }
      if (this.type) {
        const propType = this.type.toLowerCase();
        if (this.required) {
          this.fieldRequired = true;
        }
        if (propType === "date") {
          this.fieldType = propType;
          this.initializeDateSearch();
          return;
        }
        if (propType === "organizationsearch") {
          this.selectedItemValue = this.value.organization;
          this.fieldType = propType;
          this.initializeOrganizationSearch();
          return;
        }
      }
      this.initializeDropdown();
    },
    validateInput(value) {
      return !(value === "" || /^\s*$/.test(value));
    },
    openDropdown(focus = true) {
      if (this.usableItems && this.usableItems.length >= 10) {
        this.$refs.input.focus();
      }
      if (
        !this.validateInput(this.fieldValue) &&
        this.fieldType === "organizationsearch"
      ) {
        this.$nextTick(() => (this.$refs.input.style.height = "24px"));
        this.activatePlaceholder();
        this.$nextTick(() => this.$refs.input.focus());
        this.fieldOrganizationsFound = [];
        this.fieldDropdownItems = [];
      }
      this.fieldDropdownOpen = true;
      if (focus) {
        this.$nextTick(() => this.$refs.dropdown.focus());
      }
    },
    closeDropdown() {
      this.fieldDropdownOpen = false;
    },
    focusInputElementPlaceholder() {
      this.activatePlaceholder();
      this.$refs.input.style.transition = "0.2s";
      this.$refs.input.style.height = "24px";
      if (this.fieldType === "date") {
        this.$nextTick(() => this.$refs.input.showPicker());
      }
    },
    focusInput() {
      this.$refs.label.style.transition = "0.2s";
      if (this.fieldDisabled) {
        return;
      }
      switch (this.fieldType) {
        case "dropdown":
          this.openDropdown();
          break;
        case "organizationsearch":
          this.openDropdown(false);
          this.activatePlaceholder();
          break;
        case "date":
          this.activatePlaceholder();
          this.$refs.input.style.height = "24px";
          this.$refs.input.focus();
          break;
      }
    },
    focusInputElement() {
      if (this.fieldType === "organizationsearch") {
        this.focusInput();
        return;
      }
      this.fieldDropdownOpen = true;
    },
    async unfocusInput() {
      if (!this.validateInput(this.fieldValue)) {
        this.$refs.input.style.height = "0px";
        this.deactivatePlaceholder();
        if (this.fieldType === "organizationsearch") {
          this.closeDropdown();
        }
      }
      this.fieldFocus = false;
      this.labelStyle.color = "#75787A";
    },
    activatePlaceholder(fieldActive = true) {
      this.$nextTick(() =>
        this.$refs.label.classList.add("form-field-large-active-placeholder")
      );
      if (this.fieldError) {
        this.labelStyle.color = "#FF1E24";
        return;
      }
      if (fieldActive) {
        this.labelStyle.color = "#FF5C01";
        return;
      }
      if (!fieldActive) {
        this.labelStyle.color = "#75787A";
      }
      //added
      this.$nextTick(() => this.$refs.label.classList.add("required-mark"));
    },
    deactivatePlaceholder() {
      this.$refs.label.classList.remove("form-field-large-active-placeholder");
      this.labelStyle.color = "#75787A";
      //added
      this.$refs.label.classList.remove("required-mark");
    },
    initializeOrganizationSearch() {
      if (this.dropdownAddNew) {
        this.fieldDropdownAddNew = true;
      }
      if (this.dropdownAllowNull === false) {
        this.fieldDropdownAllowNull = false;
      }
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      if (this.value) {
        if (this.value.organization) {
          this.activatePlaceholder(false);
          this.fieldValue = this.value.organization;
        } else {
          this.$nextTick(() => (this.$refs.input.style.height = "0px"));
        }
      }
    },
    async organizationSearch(value) {
      if (
        value.length === 3 ||
        ((this.fieldOrganizationsFound.length === 0 ||
          this.fieldOrganizationsFound.length === 1) &&
          value.length > 2)
      ) {
        const response = await axios.get(`/api/fetch-organizations/${value}`);
        const foundItemsApi = response.data;
        if (foundItemsApi.length > 0) {
          this.fieldOrganizationsFound = [];
          foundItemsApi.forEach((el) => {
            if (/^\d+$/.test(el.reg_code)) {
              this.fieldOrganizationsFound.push(el);
            }
          });
        }
        this.fieldOrganizationsFoundClone = this.fieldOrganizationsFound;
      }
      if (value.length > 3) {
        this.fieldOrganizationsFound = [];
        this.fieldOrganizationsFoundClone.forEach((organization) => {
          if (
            organization.company_name
              .toLowerCase()
              .includes(value.toLowerCase())
          ) {
            this.fieldOrganizationsFound.push(organization);
          }
        });
      }
      if (this.fieldType === "organizationsearch") {
        this.usableItems = [];
        this.fieldOrganizationsFound.forEach((org) => {
          this.usableItems.push({
            name: org.company_name,
            value: org.reg_code,
          });
        });
        return;
      }

      this.fieldDropdownItems = [];
      this.fieldOrganizationsFound.forEach((org) => {
        this.fieldDropdownItems.push({
          name: org.company_name,
          value: org.reg_code,
        });
      });
      this.openDropdown(false);
    },
    selectDropdownitem(item) {
      if (!item && this.fieldType === "organizationsearch") {
        this.fieldValue = "";
        this.selectedFieldDropdownItemValue = null;
        return;
      }
      if (item && this.fieldType === "organizationsearch") {
        this.selectedItemValue = item.name;
        this.fieldValue = item.name;
        this.selectedFieldDropdownItemValue = {
          company_name: item.name,
          reg_code: item.value,
        };
        this.closeDropdown();
        return;
      }
      this.$emit("itemSelected")
      if (item === null || item.value === null) {
        this.fieldValue = "";
        this.selectedFieldDropdownItemValue = null;
        this.$refs.input.style.height = "0px";
        this.deactivatePlaceholder();
        this.closeDropdown();
        this.$emit("change", null);
        return;
      }
      this.fieldValue = item.name ? item.name : item.text;
      if (this.returnObject) {
        this.selectedFieldDropdownItemValue = {
          value: item.value,
          name: item.name ? item.name : item.text,
        };
      } else {
        this.selectedFieldDropdownItemValue = item.value;
      }
      this.$refs.input.style.height = "24px";
      this.activatePlaceholder(false);
      this.closeDropdown();
    },
    initializeDropdown() {
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      if (this.items) {
        this.fieldDropdownItems = this.items;
      }
      if (this.dropdownAllowNull === false) {
        this.fieldDropdownAllowNull = false;
      }
      if (this.dropdownAddNew) {
        this.fieldDropdownAddNew = true;
      }
      if (this.value) {
        if (this.value.value) {
          this.fieldValue = this.fieldDropdownItems.find(
            (item) => item.value === this.value.value
          );
        } else {
          this.fieldValue = this.fieldDropdownItems.find(
            (item) => item.value === this.value
          );
        }
        if (this.fieldValue) {
          this.activatePlaceholder(false);
          this.selectedFieldDropdownItemValue = this.fieldValue.value;

          if (this.returnObject) {
            this.selectedFieldDropdownItemValue = {
              value: this.fieldValue.value,
              name: this.fieldValue.name,
            };
          }
          this.fieldValue = this.fieldValue.name
            ? this.fieldValue.name
            : this.fieldValue.text;
        } else {
          this.$refs.input.style.height = "0px";
        }
      } else {
        this.$refs.input.style.height = "0px";
      }
      if (this.showDropdownArrow) {
        this.fieldDropdownArrow = true;
      }
      this.$nextTick(() => (this.$refs.label.style.cursor = "pointer"));
      this.$nextTick(() => (this.$refs.input.style.cursor = "pointer"));
      this.fieldStyle.cursor = "pointer";
    },
    initializeDateSearch() {
      if (this.placeholder) {
        this.fieldPlaceholder = this.placeholder;
      }
      if (this.value) {
        this.fieldValue = this.value;
        this.activatePlaceholder(false);
      }
      if (this.showDropdownArrow) {
        this.fieldDropdownArrow = true;
      }
      if (this.fieldValue) {
        this.$nextTick(() => (this.$refs.input.style.height = "24px"));
      } else {
        this.$nextTick(() => (this.$refs.input.style.height = "0px"));
      }
      this.$nextTick(() => (this.$refs.input.style.marginLeft = "-1px"));
      this.$nextTick(() => (this.$refs.input.max = "9999-12-31"));
      this.$nextTick(() => (this.$refs.label.style.cursor = "pointer"));
      this.$nextTick(() => (this.$refs.input.style.cursor = "pointer"));
      this.fieldStyle.cursor = "pointer";
    },
    toggle() {
      if (this.fieldDisabled) return;
    },
  },
};
</script>

<style lang="scss">
@import "src/global_variables";

.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.date-picker-value {
  margin: 2px 0 0 0;
}

.disabled {
  background: $grey !important;
}

.vdpHeadCellContent {
  font-size: 1.2em !important;
}

.date-picker-content {
  display: flex;
  align-items: center;
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  padding: 7px 11px;
  background: white;
  cursor: pointer;
  width: 100%; 
  height: 52px; 
  display: flex; 
  gap: 12px;
}

.date-field {
  display: flex;
  align-items: flex-end;
}

.input-field-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.form-field-large {
  height: 52px;
  width: 100%;
}

.form-field-large label {
  color: #75787a;
  cursor: text;
}

.input-field {
  width: 100%;
  height: 24px;
  outline: none;
  cursor: text;
}

.form-field-large-active-placeholder {
  height: 12px !important;
  line-height: 12px !important;
  font-size: 11px !important;
}

.dropdown-container {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 1px 10px 1px rgba(0, 0, 0, 0.08), 0px 3px 10px -2px rgba(0, 0, 0, 0.03);
  z-index: 110;
  border-radius: 8px;
  position: absolute;
  max-height: 300px;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.hidden {
  display: none !important;
}
.dropdown-medium {
  max-height: 256px !important;
}

.dropdown-small {
  max-height: 212px !important;
}

.dropdown-item {
  padding: 12px;
  background: white;
  cursor: pointer;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04); */
}

.dropdown-item:focus {
  outline: 0;
  background-color: #f4f5f7;
}

.dropdown-item:hover {
  background: #f4f5f7;
}

.form-field-large input[type="date"]:after {
  content: "";
}

.form-field-large input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
}

.form-field-large input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 48px;
  color: transparent;
  background: transparent;
  cursor: pointer;
}
.error-message {
  color: #ff1e24 !important;
}
.required label::after {
  position: absolute !important;
  line-height: unset !important;
}

.required-mark::after {
  font-size: 12px !important;
  line-height: unset !important;
  position: absolute !important;
}
.required label::after,
.required h6::after {
  content: "*";
  color: #ff1e24;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
</style>
