var render = function render(){
  var _vm$listing$marketpla;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.listing && (_vm.listing.status === 'active' || _vm.listing.status === 'booked') ? _c('div', {
    staticClass: "listing-outputs-wrapper"
  }, [_c('div', {
    staticClass: "listing-outputs"
  }, _vm._l((_vm$listing$marketpla = _vm.listing.marketplaces) === null || _vm$listing$marketpla === void 0 ? void 0 : _vm$listing$marketpla.filter(function (mp) {
    return !mp.hidden;
  }), function (marketplace) {
    return _c('div', {
      key: marketplace.name,
      staticClass: "listing-output",
      class: {
        'listing-output-active': marketplace.isActive && !marketplace.pendingUntil,
        'listing-output-inactive': !marketplace.isActive,
        'listing-output-pending': marketplace.isActive && marketplace.pendingUntil
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.openMarketplaceURL(marketplace);
        }
      }
    }, [marketplace.url ? _c('img', {
      attrs: {
        "width": "12",
        "height": "12",
        "src": require('@/assets/images/objects.svg'),
        "alt": "link"
      }
    }) : _vm._e(), _c('p', {
      staticClass: "content-small"
    }, [_vm._v(_vm._s(marketplace.name))])]);
  }), 0), _c('img', {
    ref: "outputSettings",
    staticClass: "settings-wheel",
    attrs: {
      "src": require('@/assets/images/export-settings.svg'),
      "alt": "settings"
    },
    on: {
      "click": _vm.openOutputSettings
    }
  })]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }