var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-info-container"
  }, [_c('div', {
    staticClass: "user-info",
    on: {
      "click": _vm.toggleMenu
    }
  }, [_c('div', {
    staticClass: "user-icon"
  }, [_c('span', {
    staticClass: "font-20-medium"
  }, [_vm._v(" " + _vm._s(_vm.user.name.charAt(0).toUpperCase()) + " ")])]), _c('span', {
    staticClass: "user-name"
  }, [_vm._v(" " + _vm._s(_vm.user.name) + " ")]), _c('img', {
    attrs: {
      "src": require("../../assets/images/chevron_down_20_20.svg"),
      "alt": ""
    }
  })]), _vm.dropdownOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeMenu,
      expression: "closeMenu"
    }],
    staticClass: "info-menu"
  }, [_c('h5', [_vm._v(_vm._s(_vm.user.name))]), _c('div', {
    staticClass: "font-16-regular"
  }, [_vm._v(_vm._s(_vm.user.email))]), _c('v-row', {
    staticClass: "content-medium",
    staticStyle: {
      "margin-top": "16px",
      "align-items": "center"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/palm34.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticStyle: {
      "margin-left": "4px"
    }
  }, [_vm._v(" " + _vm._s(this.$t("SetVacationPeriod") + ' (Coming soon)'))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "16px",
      "margin-bottom": "16px",
      "height": "44px"
    }
  }, [_c('LanguageSelection', {
    attrs: {
      "closed-panel": _vm.openConfirmationPanel
    },
    on: {
      "confirmationOpen": _vm.openConfirmPanel
    }
  })], 1), _c('div', {
    staticClass: "menu-item",
    staticStyle: {
      "padding": "0px"
    },
    on: {
      "click": function () {
        return _vm.selectItem('settings');
      }
    }
  }, [_c('img', {
    staticClass: "img24",
    attrs: {
      "src": require(`../../assets/images/admin.svg`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(this.$t('AccountSettings')) + " ")])]), _c('div', {
    staticStyle: {
      "padding": "0px",
      "margin-top": "16px"
    }
  }, [_c('v-row', [_c('p', {
    staticClass: "content-small-gray"
  }, [_vm._v(_vm._s(this.$t("AnyQuestions")))])]), _c('v-row', {
    staticStyle: {
      "margin-top": "4px",
      "margin-bottom": "16px"
    }
  }, [_c('p', {
    staticClass: "content-medium"
  }, [_vm._v("support@runproperty.com")])])], 1), _c('div', {
    staticClass: "separator",
    staticStyle: {
      "margin-bottom": "24px"
    }
  }), _c('div', {
    staticClass: "user-info"
  }, [_c('div', {
    staticClass: "user-icon"
  }, [_c('span', {
    staticClass: "font-20-medium"
  }, [_vm._v(" " + _vm._s(_vm.user.access.company.name.charAt(0).toUpperCase()) + " ")])]), _c('div', {
    staticClass: "sub-class",
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap",
      "flex-direction": "column"
    }
  }, [_c('span', {
    staticClass: "user-name content-medium"
  }, [_vm._v(" " + _vm._s(_vm.user.access.company.name) + " ")]), _c('span', {
    staticClass: "user-name content-small-gray"
  }, [_vm._v(" " + _vm._s(_vm.getUserHomeGroup()) + " ")]), _c('span', {
    staticClass: "user-name content-small-orange"
  }, [_vm._v(" " + _vm._s(_vm.formatUserAccess()) + " ")])])]), _c('div', {
    staticClass: "separator",
    staticStyle: {
      "margin-top": "24px",
      "margin-bottom": "16px"
    }
  }), _c('div', {
    staticClass: "menu-item",
    staticStyle: {
      "padding": "0px"
    },
    on: {
      "click": function () {
        return _vm.selectItem('logout');
      }
    }
  }, [_c('img', {
    staticClass: "img24",
    attrs: {
      "src": require(`../../assets/images/log-out.svg`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content"
  }, [_vm._v(" " + _vm._s(this.$t('Logout')) + " ")])])], 1) : _vm._e(), _vm.openConfirmationPanel ? _c('ConfirmationModal', {
    attrs: {
      "text-start-icon": 'info.svg'
    },
    on: {
      "onConfirm": function ($event) {
        return _vm.emitConfirmationModalConfirm();
      },
      "onCancel": function ($event) {
        return _vm.closeConfirmationPanel();
      }
    }
  }, [_c('p', {
    staticClass: "content-normal",
    staticStyle: {
      "padding": "2px 0"
    }
  }, [_vm._v(" " + _vm._s("Are you sure you want to change language?") + " ")])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }