<template>
  <div class="add-broker-modal-wrapper">
    <vue-draggable-resizable
      v-if="!isDeleteModalOpen"
      :z="121"
      :resizable="false"
      drag-handle=".drag-area"
      class="widget-container"
      @dragstop="onDragStop"
      v-click-outside="closePanel"
    >
      <div class="add-broker-widget">
        <div class="widget-header drag-area">
          <div>
            <img src="@/assets/images/menu.svg" alt="" />
          </div>
          <div class="close-button">
            <img
              src="@/assets/images/close_grey.svg"
              alt=""
              @click="closePanel"
            />
          </div>
        </div>
        <div class="dropdown-row" v-if="filteredBrokers.length > 0">
          <ItemSearch
            :items="filteredBrokers"
            :placeholder="$t('UserSearch')"
            :name-key="'name'"
            @itemChosen="brokerSelected"
          />
        </div>
        <div class="modal-content">
          <p class="content-title">{{ $t("SharedWith") }}</p>
          <div
            v-for="(creator, index) in sharedCreators"
            :key="creator._id"
            class="person-row"
          >
            <div class="broker-icon" @click="removeFromModule(creator)">
              <span id="text" class="font-20-medium">
                {{ creator.name.charAt(0).toUpperCase() }}
              </span>
            </div>
            <span class="person-name">
              {{ creator.name }}
            </span>
            <span
              @click="openChangeOwnershipDropdown(creator, index)"
              :key="showDropdownUpdater"
              :style="{
                cursor: creator.ownershipType === 'owner' ? 'text' : 'pointer',
              }"
              class="ownership-dropdown"
            >
              {{
                creator.ownershipType === "owner"
                  ? $t(`${"Owner"}`).toLowerCase()
                  : $t(`access.level.${creator.accessLevel}`)
              }}
              <img
                v-if="creator.dropdownOpen && creator.ownershipType !== 'owner'"
                src="@/assets/images/chevron-up.svg"
                alt=""
              />
              <img
                v-if="
                  !creator.dropdownOpen && creator.ownershipType !== 'owner'
                "
                src="@/assets/images/chevron-down.svg"
                alt=""
              />
            </span>
            <div
              v-if="creator.dropdownOpen"
              :key="showOwnerShipDropdownUpdater"
              v-click-outside="() => closeDropdown(creator)"
              class="transfer-dropdown"
            >
              <div
                v-for="(option, index) in dropdownOptions"
                :key="index"
                @click="assignOwnershipType(creator, option)"
                class="dropdown-option"
                :class="
                  index === 0
                    ? 'first-option'
                    : index === dropdownOptions.length - 1
                    ? 'last-option'
                    : ''
                "
              >
                <p
                  :class="option.value === 'remove_from' ? 'remove-broker' : ''"
                >
                  {{ option.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import ItemSearch from "@/components/common/ItemSearch";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddBrokerModal",
  props: [
    "item",
    "customerModal",
    "projectModal",
    "listingModal",
    "developmentModal",
  ],
  components: {
    ItemSearch,
    VueDraggableResizable,
  },
  data() {
    return {
      posX: 0,
      posY: 0,
      brokers: [],
      showDropdownUpdater: 0,
      showOwnerShipDropdownUpdater: 0,
      selectedBroker: null,
      sharedCreators: [],
      newSharedCreators: [],
      dropdownOptions: [],
      selectedBrokerUpdater: 0,
      isDeleteModalOpen: false,
      selectedCreatorForRemoval: null,
    };
  },
  created() {
    this.getBrokers();
    this.setup();
    this.removeOverlappingOverlay();
  },
  computed: {
    filteredBrokers: function () {
      return this.brokers.filter((broker) => {
        return !this.item.access.users[broker.value];
      });
    },
    ...mapGetters(["user", "users"]),
  },
  beforeDestroy() {
    const updatedItem = {
      ...this.item,
      sharedCreators: this.sharedCreators,
    };
    this.$emit("update:listing", updatedItem);
  },
  methods: {
    ...mapActions(["getUsers"]),
    closeDropdown(creator) {
      creator.dropdownOpen = false;
      this.creator = creator;
      this.showOwnerShipDropdownUpdater++;
    },
    assignOwnershipType(creator, option) {
      if (option.value === "transfer_ownership") {
        this.transferOwnership(creator);
      } else if (option.value === "remove_from") {
        this.removeFromModule(creator);
      }
    },
    async transferOwnership(creator) {
      if (this.hasAccess(this.item)) {
        let brokerId = creator._id;
        if (this.listingModal) {
          let body = { newOwnerId: brokerId, name: creator.name, listingId: this.item._id };
          this.$emit("transferListingOwnership", body);
        } else if (this.customerModal) {
          let body = { newOwnerId: brokerId, customerId: this.item.customerId };
          this.$emit("transferCustomerOwnership", body);
        } else if (this.projectModal) {
          let body = { newOwnerId: brokerId, name: creator.name, projectId: this.item._id };
          this.$emit("transferProjectOwnership", body);
        } else if (this.developmentModal) {
          let body = { newOwnerId: brokerId, name: creator.name, id: this.item._id };
          this.$emit("transferDevelopmentProjectOwnership", body);
        }
      } else {
        this.toastError(this.$t("noOwnershipChangeAccess"));
      }
    },
    async removeFromModule(creator) {
      let response = null;
      if (this.listingModal) {
        let body = {
          listingId: this.item._id,
          userId: creator._id,
          name: creator.name
        };
        this.$emit('removeSharedOwner', body)
      } else if (this.customerModal) {
        let body = {
          customerId: this.item.customerId,
          userId: creator._id,
        };
        response = await axios.post("/api/contact/remove-shared-creator", body);
      } else if (this.developmentModal) {
        let body = {
          id: this.item._id,
          userId: creator._id,
          name: creator.name,
        };
        this.$emit('removeSharedOwner', body)
      } else if (this.projectModal) {
        let body = {
          projectId: this.item._id,
          userId: creator._id,
          name: creator.name,
          projectObjects: this.item.objects,
        };
        this.$emit('removeSharedOwner', body)
        this.sharedCreators.forEach((broker, index) => {
          if (broker.userId === creator._id) {
            this.sharedCreators.splice(index, 1);
          }
        });
      }
      if (response.status === 400) {
        this.toastError(this.$t("CannotRemoveOwner"));
      } else {
        this.sharedCreators = this.sharedCreators.filter(
          (sharedCreator) => sharedCreator._id !== creator._id
        );
        const updatedItem = {
          ...this.item,
          sharedCreators: this.sharedCreators,
        };
        this.$emit("update:item", updatedItem);
        this.$emit("updated");
      }
    },
    async saveAdditionalBrokers(newSharedBroker) {
      if (this.listingModal) {
        let body = {
          sharedCreators: newSharedBroker,
          listingId: this.item._id,
        };
        this.$emit("addSharedOwner", body);
      } else if (this.customerModal) {
        let body = {
          sharedCreators: newSharedBroker,
          customerId: this.item.customerId,
        };
        await axios.post("/api/contact/add-shared-creator", body);
      } else if (this.developmentModal) {
        let body = {
          sharedCreators: newSharedBroker,
          id: this.item._id,
        };
        this.$emit("addSharedOwner", body);
      } else if (this.projectModal) {
        let body = {
          sharedCreators: newSharedBroker,
          projectId: this.item._id,
          projectObjects: this.item.objects,
        };
        this.$emit("addSharedOwner", body);
      }
      this.$emit("updated");
    },
    setup() {
      const access = this.item.access;
      const itemSharedCreators = [];
      for (let key in access.users) {
        access.users[key].dropdownOpen = false;
        access.users[key]._id = key;
        itemSharedCreators.push(access.users[key]);
      }
      this.sharedCreators = itemSharedCreators;
      if (this.hasAccess(this.item)) {
        let removeFromString;
        if (this.listingModal) {
          removeFromString = this.$t("RemoveFromListing");
        } else if (this.customerModal) {
          removeFromString = this.$t("RemoveFromCustomer");
        } else if (this.projectModal) {
          removeFromString = this.$t("RemoveFromProject");
        } else if (this.developmentModal) {
          removeFromString = this.$t("RemoveFromDevelopment");
        }
        this.dropdownOptions = [
          { name: this.$t("SetAsOwner"), value: "transfer_ownership" },
          { name: removeFromString, value: "remove_from" },
        ];
      }
    },
    removeOverlappingOverlay() {
      if (document.getElementById("offers-sidepanel-overlay")) {
        document.getElementById("listing-overlay").style.position = "initial";
      }
    },
    openChangeOwnershipDropdown(creator, creatorIndex) {
      if (!this.hasAccess(this.item)) return;
      if (creator.ownershipType === "owner") return;
      this.sharedCreators.forEach((creator, index) => {
        if (creatorIndex !== index) {
          creator.dropdownOpen = false;
        } else {
          creator.dropdownOpen = !creator.dropdownOpen;
          this.showDropdownUpdater++;
        }
      });
    },
    brokerSelected(person) {
      this.filteredBrokers.forEach((broker) => {
        if (broker.value === person.value) {
          this.sharedCreators.push({
            name: person.name,
            ownershipType: "CanEdit",
            accessLevel: "full",
            _id: person.value,
            userId: person.value,
          });
          this.saveAdditionalBrokers({
            name: person.name,
            ownershipType: "CanEdit",
            accessLevel: "full",
            userId: person.value,
          });
          this.selectedBrokerUpdater++;
        }
      });
    },
    onDragStop(left, top) {
      this.posX = left;
      this.posY = top;
    },
    closePanel() {
      this.$emit("close-modal");
    },
    async getBrokers() {
      await this.getUsers();
      const users = this.users;
      users.forEach((user) => {
      if (user.userStatus === "Active") {
          this.brokers.push({
            name: user.name,
            value: user._id,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.widget-container {
  position: fixed;
  left: 34%;
  top: 26% !important;
  padding-bottom: 20px;
  padding-right: 40px;
}

.add-broker-widget {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11),
    0px 0px 0px rgba(0, 0, 0, 0.1);
  width: 570px;
  height: fit-content;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  position: fixed;
  background: white;
  color: white !important;
  border-radius: 8px;
}

.drag-area {
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
}

.close-button {
  cursor: pointer;
  padding: 2px 16px 0 0;
  margin: auto 0;
}

.widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  width: 570px;
  background-color: #f4f5f7;
  border-radius: 8px 8px 0 0;
}

.dropdown-row {
  display: flex;
  width: 570px;
  padding: 24px;
}

.modal-content {
  height: fit-content;
  width: 570px;
  padding: 0 24px 18px 24px;
}

.content-title {
  font-size: 12px;
  color: #75787a;
}

.person-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
  align-items: center;
}

.person-name {
  color: black;
  font-size: 14px;
  margin-left: 12px;
  overflow-wrap: break-word;
  margin-right: auto;
}

.ownership-dropdown {
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.transfer-dropdown {
  width: 144px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border: 1px solid #e6e8ec;
  height: fit-content;
  background-color: white;
  position: absolute;
  margin-left: 460px;
  margin-top: 110px;
  border-radius: 8px;
}

.dropdown-option {
  cursor: pointer;
  padding: 12px;
  color: black;

  &:hover {
    background-color: #f4f5f7;
  }
}

.first-option {
  border-radius: 8px 8px 0 0;
}

.last-option {
  border-radius: 0 0 8px 8px;
}

.remove-broker {
  font-weight: 600;
  color: #ff1e24;
}

.owner-icon {
  border-radius: 50%;
  padding: 0 4px;
  background: #ff5c01;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.broker-icon {
  border-radius: 50%;
  padding: 0 4px;
  background: #ff5c01;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    #text {
      display: none;
    }

    cursor: pointer;
    background-image: url("../../assets/images/red_trash_20_20.svg");
    background-repeat: no-repeat;
    background-color: #fef1f0;
    background-attachment: fixed;
    background-position: center center;
    background-size: 20px;
  }
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 570px;
  height: 88px;
  padding: 24px;
  border-top: 1px solid #e6e8ec;
}
</style>
