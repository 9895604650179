var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticClass: "user-name-container content-small",
    on: {
      "click": function (event) {
        return _vm.userNameOnPress(event);
      }
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.item.name) + " ")]), _vm.isOwner ? _c('span', [_vm._v(" (" + _vm._s(_vm.$t("Owner").toLowerCase()) + ") ")]) : _vm._e()]), _vm.brokerInfoPanelOpen ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.item,
      "position": _vm.brokerInfoPanelPosition
    },
    on: {
      "closed": function ($event) {
        _vm.brokerInfoPanelOpen = false;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }