<template>
  <div
    style="cursor: pointer; display: flex; align-items: center; gap: 12px"
    @click.stop="changeValue"
  >
    <img
      v-if="!toggleValue && green"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-red.svg`)"
    />
    <img
      v-if="!toggleValue && !green"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-inactive.svg`)"
    />
    <img
      v-if="toggleValue && !green && !toggleColour"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-active.svg`)"
    />
    <img
      v-if="toggleValue && green"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-green.svg`)"
    />
    <img
      v-if="toggleValue && toggleColour === 'turquoise'"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-turquoise.svg`)"
    />
    <img
      v-if="toggleValue && toggleColour === 'black'"
      :style="disabled ? 'opacity: 0.5' : ''"
      :src="require(`@/assets/images/toggle-black.svg`)"
    />
    <div
      v-if="toggleLabel"
      class="content"
      v-bind:class="{ 'white-text': labelColour && labelColour === 'white' }"
    >
      {{ toggleLabel }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseToggle",
  props: ["value", "label", "labelColour", "disabled", "green", "toggleColour"],
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      toggleValue: "",
      toggleLabel: "",
    };
  },
  created() {
    this.toggleValue = this.value;
    this.toggleLabel = this.label;
  },
  methods: {
    changeValue() {
      if (this.disabled) return;
      this.toggleValue = !this.toggleValue;
      this.$emit("change", this.toggleValue);
      this.$emit("click", this.toggleValue);
    },
  },
};
</script>

<style scoped>
.white-text {
  color: white !important;
}
</style>
