var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-field-container",
    attrs: {
      "id": _vm.id
    }
  }, [_c('div', {
    ref: "container",
    staticClass: "form-field-large",
    style: _vm.fieldStyle,
    on: {
      "click": _vm.focusInputElement
    }
  }, [_c('div', {
    class: {
      required: _vm.fieldRequired
    },
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "flex-direction": "column"
    }
  }, [_vm.fieldPlaceholder ? _c('label', {
    ref: "label",
    staticClass: "content",
    style: _vm.labelStyle
  }, [_vm._v(_vm._s(_vm.fieldPlaceholder))]) : _vm._e(), _c('GmapAutocomplete', {
    ref: "addressSearch",
    staticClass: "content",
    staticStyle: {
      "outline": "none"
    },
    attrs: {
      "placeholder": ""
    },
    on: {
      "focusin": _vm.focusInput,
      "focusout": _vm.unfocusInput,
      "input": function ($event) {
        return _vm.mapInput($event);
      },
      "place_changed": _vm.setPlace
    },
    model: {
      value: _vm.fieldValue,
      callback: function ($$v) {
        _vm.fieldValue = $$v;
      },
      expression: "fieldValue"
    }
  })], 1)]), _vm.fieldError ? _c('div', {
    staticClass: "content error-message",
    staticStyle: {
      "margin": "12px 12px 0 12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldErrorMessage ? _vm.$t("FormErrors." + _vm.errorMessage) : _vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }