import contactsFilter from "./contactsFilter";
import listingsFilter from "./listingsFilter";
import projectsFilter from "./projectsFilter";
import transactionsFilter from "./transactionsFilter";
import brokerTransactionsFilter from "./brokerTransactionsFilter";
import invoiceFilters from "./invoiceFilters";
import dashboardFilter from "./dashboardFilter";

export default {
    "contacts": contactsFilter,
    "listings": listingsFilter,
    "projects": projectsFilter,
    "transactions": transactionsFilter,
    "brokerTransactions": brokerTransactionsFilter,
    "invoices": invoiceFilters,
    "dashboards": dashboardFilter
}
