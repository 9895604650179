var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('img', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "src": require("../../assets/images/question_mark.svg"),
      "alt": ""
    },
    on: {
      "click": _vm.toggleMenu
    }
  }), _vm.dropdownOpen ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeMenu,
      expression: "closeMenu"
    }],
    staticClass: "info-menu"
  }, _vm._l(_vm.menuItems, function (item, index) {
    return _c('div', {
      key: 'menu-item-' + index,
      staticClass: "menu-item",
      on: {
        "click": function () {
          return _vm.selectItem(item.name);
        }
      }
    }, [_c('img', {
      staticClass: "img16",
      attrs: {
        "src": require(`../../assets/images/${item.iconUrl}`),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "content-small"
    }, [_vm._v(" " + _vm._s(item.field) + " ")])]);
  }), 0) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }