var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-field-container",
    attrs: {
      "id": _vm.id
    },
    on: {
      "click": _vm.emitElementClicked
    }
  }, [_c('div', {
    key: _vm.fieldUpdater,
    ref: "container",
    class: _vm.fieldClass,
    style: _vm.fieldStyle,
    attrs: {
      "id": _vm.id
    },
    on: {
      "click": _vm.focusInput,
      "dragover": _vm.handleDrag
    }
  }, [_vm.iconLeft ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": require(`@/assets/images/` + _vm.iconLeft),
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    class: {
      required: _vm.fieldRequired
    },
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "flex-direction": "column"
    }
  }, [_vm.fieldPlaceholder ? _c('label', {
    ref: "label",
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.fieldPlaceholder))]) : _vm._e(), _vm.fieldType === 'checkbox' && _vm.inputFieldTypes.includes(_vm.fieldType) ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "input",
    staticClass: "input-field content",
    attrs: {
      "id": 'input-' + _vm.id,
      "disabled": _vm.fieldDisabled,
      "hidden": _vm.hidden,
      "autocomplete": _vm.autoComplete,
      "maxlength": _vm.maxCharsLength,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.fieldValue) ? _vm._i(_vm.fieldValue, null) > -1 : _vm.fieldValue
    },
    on: {
      "focus": _vm.focusInputElement,
      "focusout": _vm.unfocusInput,
      "change": function ($event) {
        var $$a = _vm.fieldValue,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.fieldValue = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.fieldValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.fieldValue = $$c;
        }
      }
    }
  }) : _vm.fieldType === 'radio' && _vm.inputFieldTypes.includes(_vm.fieldType) ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "input",
    staticClass: "input-field content",
    attrs: {
      "id": 'input-' + _vm.id,
      "disabled": _vm.fieldDisabled,
      "hidden": _vm.hidden,
      "autocomplete": _vm.autoComplete,
      "maxlength": _vm.maxCharsLength,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.fieldValue, null)
    },
    on: {
      "focus": _vm.focusInputElement,
      "focusout": _vm.unfocusInput,
      "change": function ($event) {
        _vm.fieldValue = null;
      }
    }
  }) : _vm.inputFieldTypes.includes(_vm.fieldType) ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "input",
    staticClass: "input-field content",
    attrs: {
      "id": 'input-' + _vm.id,
      "disabled": _vm.fieldDisabled,
      "hidden": _vm.hidden,
      "autocomplete": _vm.autoComplete,
      "maxlength": _vm.maxCharsLength,
      "type": _vm.fieldType
    },
    domProps: {
      "value": _vm.fieldValue
    },
    on: {
      "focus": _vm.focusInputElement,
      "focusout": _vm.unfocusInput,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.fieldValue = $event.target.value;
      }
    }
  }) : _vm._e()]), _vm.iconRight ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    attrs: {
      "src": require(`@/assets/images/` + _vm.iconRight),
      "alt": ""
    }
  }) : _vm._e()]), _vm.fieldError ? _c('div', {
    staticClass: "content error-message",
    staticStyle: {
      "margin": "12px 12px 0 0"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldErrorMessage ? _vm.fieldErrorMessage : _vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }