var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "comment-wrapper"
  }, [_c('div', {
    staticClass: "detail-row-first"
  }, [_c('div', {
    staticClass: "transaction-type-wrapper"
  }, [_vm.interest.transactionType === 'sale' ? _c('img', {
    attrs: {
      "src": require("../../assets/images/shopping-cart.svg"),
      "alt": ""
    }
  }) : _c('img', {
    attrs: {
      "src": require("../../assets/images/renting.svg")
    }
  }), _c('p', {
    staticClass: "content-semibold"
  }, [_vm._v(" " + _vm._s(_vm.formatTransactionType(_vm.interest.transactionType)) + " ")])]), _c('EditButton', {
    attrs: {
      "name": "interest-edit-button"
    },
    on: {
      "clicked": function ($event) {
        return _vm.editInterest(_vm.interest);
      }
    }
  })], 1), _c('div', {
    staticClass: "detail-row"
  }, [_c('img', {
    attrs: {
      "src": _vm.getEstateImage,
      "alt": ""
    }
  }), _c('div', {
    staticClass: "detail-row-address"
  }, [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.formatEstateType(_vm.interest.estateType)) + " ")]), _vm.extras && _vm.extras.length ? _c('ul', _vm._l(_vm.extras, function (extra) {
    return _c('li', {
      key: extra.value
    }, [_c('p', {
      staticClass: "content-normal"
    }, [_vm._v(" " + _vm._s(_vm.$t(`${extra.name}`)) + " ")])]);
  }), 0) : _vm._e()])]), _vm.interest.address.county ? _c('div', {
    staticClass: "detail-row"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/map_marker.svg'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "detail-row-address"
  }, [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.interest.address.county.name) + " ")]), _vm.interest.address.cities && _vm.interest.address.cities.length ? _c('ul', _vm._l(_vm.interest.address.cities, function (city) {
    return _c('li', {
      key: city.value
    }, [_c('p', {
      staticClass: "content-normal"
    }, [_vm._v(" " + _vm._s(_vm.cityAndCityPartsText(city, _vm.interest.address.cityParts)) + " ")])]);
  }), 0) : _vm._e()])]) : _vm._e(), _vm.interest.minPrice || _vm.interest.maxPrice ? _c('div', {
    staticClass: "detail-row"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/euroSign.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.formatRange(_vm.interestMinPrice, _vm.interestMaxPrice)) + " ")])]) : _vm._e(), _vm.interest.minPricePerSquareMeter || _vm.interest.maxPricePerSquareMeter ? _c('div', {
    staticClass: "detail-row-sqm"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/euro_m2.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.formatRange(_vm.interestMinPricePerSquareMeter, _vm.interestMaxPricePerSquareMeter)) + " ")])]) : _vm._e(), _vm.interest.minGeneralSurfaceArea || _vm.interest.maxGeneralSurfaceArea ? _c('div', {
    staticClass: "detail-row"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/area.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.formatRange(_vm.interest.minGeneralSurfaceArea, _vm.interest.maxGeneralSurfaceArea)) + " m² ")])]) : _vm._e(), _vm.interest.minNumberOfRooms || _vm.interest.maxNumberOfRooms ? _c('div', {
    staticClass: "detail-row"
  }, [_c('img', {
    attrs: {
      "src": require('../../assets/images/door.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.formatRange(_vm.interest.minNumberOfRooms, _vm.interest.maxNumberOfRooms)) + " " + _vm._s(_vm.$t("rooms")) + " ")])]) : _vm._e(), _vm.interestHasExtras ? _c('div', {
    staticClass: "detail-row"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/info.svg'),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "detail-row-address"
  }, [_c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Extras")) + " ")]), _c('ul', _vm._l(_vm.interestExtras, function (extra) {
    return _c('li', {
      key: extra.value
    }, [_c('p', {
      staticClass: "content-normal"
    }, [_vm._v(" " + _vm._s(extra.name) + " ")])]);
  }), 0)])]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }