<template>
  <div
    v-bind:class="{
      'toast-wrapper toast-success': type === 'success',
      'toast-wrapper toast-error': type === 'error',
    }"
  >
    <img
      v-if="type === 'success'"
      src="../../assets/images/checkmark-14.svg"
      alt=""
    />
    <img v-else src="../../assets/images/alert.svg" alt="" />
    <div class="toast-message-wrapper">
      <p
        v-bind:class="{
          'toast-message toast-success-message': type === 'success',
          'toast-message toast-error-message': type === 'error',
        }"
      >
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToastComponent",
  props: ["text", "type"],
};
</script>

<style lang="scss">
.toast-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 64px 24px 24px;
  width: 640px;
  height: 72px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.toast-success {
  border: 2px solid #0aaf60;
}
.toast-error {
  border: 2px solid #ff1e24;
}
.nice-toast {
  padding: 0 !important;
  width: 100%;
  overflow: visible !important;
}
.toast-message-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 512px;
}
.toast-message {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
}
.toast-success-message {
  color: #0aaf60;
}
.toast-error-message {
  color: #ff1e24;
}

@media screen and (max-width: 720px) {
  .toast-message-wrapper {
    width: auto;
  }

  .toast-wrapper {
    width: auto;
  }

  .nice-toast {
    background: transparent !important;
  }
}

</style>
