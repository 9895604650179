<template>
  <div class="invoice-simple-info-card"

  >
    <div class="invoice-first-row"
         @click="$emit('onPress')"
    >

      <div class="invoice-main-info">
        <div>
          {{ invoiceDate }}
        </div>

        <div class="content-semibold">
          {{ invoiceSumTotal }}
        </div>
      </div>

      <InvoiceStatusContainer
          :status="invoice.status"
      />

    </div>

    <UserNameContainer
        :item="invoice.creator"
    />

  </div>
</template>

<script>
import UserNameContainer from "@/components/Admin/UserNameContainer";
import InvoiceStatusContainer from "@/components/Invoices/InvoiceStatusContainer";
import moment from "moment";
export default {
  name: "InvoiceSimpleInfoCard",
  components: {InvoiceStatusContainer, UserNameContainer},
  props: ["invoice"],
  data: () => ({
  }),
  created() {
  },
  mounted() {

  },
  computed: {
    invoiceDate() {
      return moment(this.invoice.invoiceDate).format("DD.MM.YYYY")
    },
    invoiceSumTotal() {
      return this.$nMapper.price(this.invoice.sumVAT, "€");
    }
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.invoice-main-info {
  display: flex;
  gap: 12px;
}

.invoice-first-row {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  cursor: pointer;
}

.invoice-simple-info-card {
  display: grid;
  gap: 12px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $grey500;
}

</style>
