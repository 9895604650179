<template>
  <div v-if="listing && (listing.status === 'active' || listing.status === 'booked')" class="listing-outputs-wrapper">
    <div class="listing-outputs">
      <div @click.stop="openMarketplaceURL(marketplace)" v-for="marketplace in listing.marketplaces?.filter((mp) => !mp.hidden)"
           :key="marketplace.name"
           :class="{
             'listing-output-active': marketplace.isActive && !marketplace.pendingUntil,
              'listing-output-inactive': !marketplace.isActive,
               'listing-output-pending': marketplace.isActive && marketplace.pendingUntil}"
           class="listing-output">
        <img v-if="marketplace.url" width="12" height="12" :src="require('@/assets/images/objects.svg')" alt="link"/>
        <p class="content-small">{{ marketplace.name }}</p>
      </div>
    </div>
    <img @click="openOutputSettings" ref="outputSettings" class="settings-wheel"
         :src="require('@/assets/images/export-settings.svg')" alt="settings"/>
  </div>
</template>

<script>
export default {
  name: "Outputs",
  props: ["listing"],
  data() {
    return {
      settingsOpen: false
    }
  },
  methods: {
    openMarketplaceURL(marketplace) {
      if (marketplace.url || marketplace.portalURL) {
        window.open(marketplace.url || marketplace.portalURL, "_blank").focus();
      }
    },
    openOutputSettings() {
      const rect = this.$refs.outputSettings?.getBoundingClientRect();
      this.$emit('openOutputSettings', {x: rect.left - 300 || 0, y: rect.top + 32 || 0})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/global_variables.scss";

.listing-outputs-wrapper {
  display: flex;
  position: relative;
  gap: 8px;
}

.listing-outputs {
  display: flex;
  gap: 2px;
}

.listing-output {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 4px;

  p {
    font-weight: 600;
  }
}

.listing-output-active {
  border: 2px solid $green;

  p {
    color: $green;
  }

  img {
    filter: invert(57%) sepia(39%) saturate(4910%) hue-rotate(116deg) brightness(90%) contrast(92%);
  }

  &:hover {
    background-color: $green;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }

    p {
      color: white;
    }
  }
}

.listing-output-inactive {
  border: 2px solid $red;

  p {
    color: $red;
  }

  img {
    filter: invert(20%) sepia(67%) saturate(3344%) hue-rotate(345deg) brightness(113%) contrast(110%);
  }

  &:hover {
    background-color: $red;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }

    p {
      color: white;
    }
  }
}

.listing-output-pending {
  border: 2px solid $yellow;

  p {
    color: $yellow;
  }

  img {
    filter: invert(57%) sepia(39%) saturate(4910%) hue-rotate(116deg) brightness(90%) contrast(92%);
  }

  &:hover {
    background-color: $red;

    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
    }

    p {
      color: white;
    }
  }
}

.settings-wheel {
  cursor: pointer;

  filter: invert(82%) sepia(4%) saturate(105%) hue-rotate(182deg) brightness(97%) contrast(89%);

  &:hover {
    filter: unset;
  }
}
</style>