var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.info.length > 0 ? _c('span', {
    staticClass: "project-pipeline-info-container content-small"
  }, [_vm._v(" " + _vm._s(_vm.info.join(" • ")) + " ")]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }