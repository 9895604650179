import axios from "axios";

let controller = new AbortController();
let signal = controller.signal;

export default {
  newAbortController() {
    controller = new AbortController();
    signal = controller.signal;
  },
  getAbortController() {
    return controller;
  },
  async getContactById(id) {
    return (await axios.get(`/api/contact/${id}`, { signal: signal })).data;
  },
  async getContactPublicCustomById(id, fields) {
    return await axios.post(`/api/contact/public/custom/${id}`, fields);
  },
  async getAllContacts() {
    return (await axios.get("/api/contacts", { signal: signal })).data;
  },
  async getContactsByFilters(filters) {
    return (
      await axios.post("/api/contacts/filters", filters, {
      signal: signal 
    })).data;
  },
  async getContactsByPagination(data) {
    let page = data.page ? data.page : 1;
    return (
      await axios.post(`/api/contacts-pagination/${page}`, data, {
        signal: signal,
      })
    ).data;
  },
  async getAllPartners() {
    return (await axios.get("/api/partners", { signal: signal })).data;
  },
  async getAllCustomers() {
    return (await axios.get("/api/customers", { signal: signal })).data;
  },
  async createContact(contact) {
    return await axios.post("/api/contacts", contact);
  },
  async updateContact(contact) {
    return (await axios.patch("/api/contact", contact, {})).data;
  },
  async deleteContact(id) {
    return (await axios.post(`/api/contact/delete/${id}`)).data;
  },
  async saveFunnel(contactId, funnel) {
    return await axios.post(`/api/contact/leads/${contactId}`, funnel);
  },
  async updateFunnel(contactId, funnel) {
    return await axios.patch(`/api/contact/leads/${contactId}`, funnel);
  },
  async deleteFunnel(contactId, funnel) {
    return await axios.post(`/api/contact/leads/delete/${contactId}`, funnel);
  },
  async deleteInterest(contactId, interest) {
    return await axios.post(
      `/api/contact/interest/delete/${contactId}`,
      interest
    );
  },
  async changeInterestStatus(contactId, body) {
    return await axios.post(`/api/contact/interest/status/${contactId}`, body);
  },
  async changeInterestWideSearch(contactId, body) {
    return await axios.post(`/api/contact/interest/widesearch/${contactId}`, body);
  },
  async changeInterestComment(contactId, body) {
    return await axios.post(`/api/contact/interest/comment/${contactId}`, body);
  },
  async duplicateInterest(contactId, body) {
    return await axios.post(
      `/api/contact/interest/duplicate/${contactId}`,
      body
    );
  },
  async getNotes(id) {
    return (await axios.get(`/api/contact/notes/${id}`, { signal: signal }))
      .data;
  },
  async getTasks(id) {
    return (await axios.get(`/api/contact/tasks/${id}`, { signal: signal }))
      .data;
  },
  async getInterests(id) {
    return (
      await axios.get(`/api/contact/interests/${id}`, {
        signal: signal,
      })
    ).data;
  },
  async updateCustomerPublicLead(id, body) {
    return axios.patch(`/api/contact/public/leads/${id}`, body);
  },
};
