<template>
  <div class="extras" id="extra-section">
    <v-row id="extra-info-wrapper" class="extra-info-row">
      <v-col class="icon-col"
        ><img src="../../assets/images/info.svg" alt=""
      /></v-col>
      <v-col class="info-col"
        ><h6>{{ $t("Extras") }}</h6>
        <v-row id="info-text-row">
          <p
            v-if="
              listing.balcony && listing.balconySize && listing.balconySize != 0
            "
            class="content-normal grey-950"
          >
            {{ $t("Balcony") + " " }}{{ listing.balconySize }} m²,&nbsp;
          </p>
          <p
            v-if="
              listing.balcony &&
              (!listing.balconySize || listing.balconySize == 0)
            "
            class="content-normal grey-950"
          >
            {{ $t("Balcony") }},&nbsp;
          </p>
          <p v-if="listing.closedBalcony" class="content-normal grey-950">
            {{ $t("ClosedBalcony") }},&nbsp;
          </p>
          <p v-if="listing.fullyGlazedBalcony" class="content-normal grey-950">
            {{ $t("FullyGlazedBalcony") }},&nbsp;
          </p>
          <p v-if="listing.frenchBalcony" class="content-normal grey-950">
            {{ $t("FrenchBalcony") }},&nbsp;
          </p>
          <p v-if="listing.garage" class="content-normal grey-950">
            {{ $t("Garage") }},&nbsp;
          </p>
          <p v-if="listing.undergroundGarage" class="content-normal grey-950">
            {{ $t("UndergroundGarage") }},&nbsp;
          </p>
          <p v-if="listing.lift" class="content-normal grey-950">
            {{ $t("Lift") }},&nbsp;
          </p>
          <p v-if="listing.parquet" class="content-normal grey-950">
            {{ $t("Parquet") }},&nbsp;
          </p>
          <p v-if="listing.packetWindows" class="content-normal grey-950">
            {{ $t("PacketWindows") }},&nbsp;
          </p>
          <p v-if="listing.furniture" class="content-normal grey-950">
            {{ $t("Furniture") }},&nbsp;
          </p>
          <p v-if="listing.furnitureAbility" class="content-normal grey-950">
            {{ $t("FurnitureChangePossibility") }},&nbsp;
          </p>
          <p
            v-if="listing.severalFloorsPlanning"
            class="content-normal grey-950"
          >
            {{ $t("PlanThroughMultipleFloors") }},&nbsp;
          </p>
          <p v-if="listing.highCeilings" class="content-normal grey-950">
            {{ $t("HighCeilings") }},&nbsp;
          </p>
          <p v-if="listing.electricalWiring" class="content-normal grey-950">
            {{ $t("NewWiring") }},&nbsp;
          </p>
          <p v-if="listing.electricity" class="content-normal grey-950">
            {{ $t("Electricity") }},&nbsp;
          </p>
          <p v-if="listing.wardrobe" class="content-normal grey-950">
            {{ $t("Wardrobe") }},&nbsp;
          </p>
          <p v-if="listing.attic" class="content-normal grey-950">
            {{ $t("Attic") }},&nbsp;
          </p>
          <p v-if="listing.storeRoom" class="content-normal grey-950">
            {{ $t("Storeroom") }},&nbsp;
          </p>
          <p v-if="listing.terrace" class="content-normal grey-950">
            {{ $t("Terrace") }},&nbsp;
          </p>
          <p v-if="listing.showCaseWindows" class="content-normal grey-950">
            {{ $t("ShowCaseWindows") }},&nbsp;
          </p>
          <p v-if="listing.streetEntrance" class="content-normal grey-950">
            {{ $t("StreetEntrance") }},&nbsp;
          </p>
          <p v-if="listing.trestle" class="content-normal grey-950">
            {{ $t("Trestle") }},&nbsp;
          </p>
          <p v-if="listing.goodsLift" class="content-normal grey-950">
            {{ $t("GoodsLift") }},&nbsp;
          </p>
          <p
            v-if="listing.roofType"
            class="content-normal grey-950"
          >
            {{ formatRoofType(listing.roofType) }},&nbsp;
          </p>
          <p
            v-if="
              listing.parkingOption && listing.parkingOption != 'NO_PARKING'
            "
            class="content-normal grey-950"
          >
            {{ formatParkingOption(listing.parkingOption) + " "
            }}{{ $t("Parking").toLowerCase() }},&nbsp;
          </p>
          <p
            v-if="listing.numberOfDesks && listing.estateType == 'commercial'"
            class="content-normal grey-950"
          >
            {{ $t("NumberOfDesks") + " ("
            }}{{ listing.numberOfDesks + ")" }},&nbsp;
          </p>
          <p v-if="listing.tv" class="content-normal grey-950">
            {{ $t("TV") }},&nbsp;
          </p>
          <p v-if="listing.basementFloor" class="content-normal grey-950">
            {{ $t("BasementFloor") }},&nbsp;
          </p>
          <p v-if="listing.rooftopApartment" class="content-normal grey-950">
            {{ $t("RoofTopApartment") }},&nbsp;
          </p>
          <p v-if="listing.separateEntrance" class="content-normal grey-950">
            {{ $t("SeparateEntrance") }},&nbsp;
          </p>
          <p v-if="listing.closedYard" class="content-normal grey-950">
            {{ $t("ClosedYard") }},&nbsp;
          </p>
          <p v-if="listing.meetingRoom" class="content-normal grey-950">
            {{ $t("MeetingRoom") }},&nbsp;
          </p>
          <p
            v-if="listing.industrialPowerSupply"
            class="content-normal grey-950"
          >
            {{ $t("IndustrialPowerSupply") }},&nbsp;
          </p>
          <p v-if="listing.climaticDevice" class="content-normal grey-950">
            {{ $t("ClimaticDevice") }},&nbsp;
          </p>
          <p v-if="listing.ventilation" class="content-normal grey-950">
            {{ $t("Ventilation") }},&nbsp;
          </p>
          <p v-if="listing.well" class="content-normal grey-950">
            {{ $t("Well") }},&nbsp;
          </p>
          <p v-if="listing.sewerage" class="content-normal grey-950">
            {{ $t("Sewerage") }},&nbsp;
          </p>
          <p v-if="listing.gasIncluded" class="content-normal grey-950">
            {{ $t("GasIncluded") }},&nbsp;
          </p>
          <p v-if="listing.water" class="content-normal grey-950">
            {{ $t("Water") }},&nbsp;
          </p>
          <p v-if="listing.road" class="content-normal grey-950">
            {{ $t("Road") }},&nbsp;
          </p>
          <p v-if="listing.publicTransport" class="content-normal grey-950">
            {{ $t("PublicTransport") }},&nbsp;
          </p>
          <p v-if="listing.handicappedAdaptedAccess" class="content-normal grey-950">
            {{ $t("HandicappedAdaptedAccess") }},&nbsp;
          </p>
          <p v-if="listing.handicappedAdaptedUse" class="content-normal grey-950">
            {{ $t("HandicappedAdaptedUse") }},&nbsp;
          </p>
          <p v-if="listing.orientationNorth" class="content-normal grey-950">
            {{ $t("OrientationNorth") }},&nbsp;
          </p>
          <p v-if="listing.orientationSouth" class="content-normal grey-950">
            {{ $t("OrientationSouth") }},&nbsp;
          </p>
          <p v-if="listing.orientationWest" class="content-normal grey-950">
            {{ $t("OrientationWest") }},&nbsp;
          </p>
          <p v-if="listing.orientationEast" class="content-normal grey-950">
            {{ $t("OrientationEast") }},&nbsp;
          </p>
          <p v-if="listing.parkingPrice" class="content-normal grey-950">
            {{ $t("ParkingPrice") + " ("
            }}{{ listing.parkingPrice + "€)" }},&nbsp;
          </p>
          <p v-if="listing.tenantNumber" class="content-normal grey-950">
            {{ $t("ParkingPrice") + " ("
            }}{{ listing.tenantNumber + ")" }},&nbsp;
          </p>
          <p v-if="listing.recommendedForChildren" class="content-normal grey-950">
            {{ $t("RecommendedForChildren") }},&nbsp;
          </p>
          <p v-if="listing.studio" class="content-normal grey-950">
            {{ $t("Studio") }},&nbsp;
          </p>
          <p v-if="listing.depositMonths" class="content-normal grey-950">
            {{ $t("DepositMonths") + " ("
            }}{{ listing.depositMonths + ")" }},&nbsp;
          </p>
          <p v-if="listing.isAuction" class="content-normal grey-950">
            {{ $t("IsAuction") }},&nbsp;
          </p>
          <p v-if="listing.minAuctionBidIncrement" class="content-normal grey-950">
            {{ $t("MinAuctionBidIncrement") }},&nbsp;
          </p>
          <p v-if="listing.auctionDeposit" class="content-normal grey-950">
            {{ $t("AuctionDeposit") + " ("
            }}{{ listing.auctionDeposit + "%)" }},&nbsp;
          </p>
          <p v-if="listing.auctionDate" class="content-normal grey-950">
            {{ $t("AuctionDate") + " ("
            }}{{ $lfMapper.expiryDate(listing.auctionDate) + ")" }},&nbsp;
          </p>
          <p v-if="listing.auctionTribunal" class="content-normal grey-950">
            {{ $t("AuctionTribunal") + " ("
            }}{{ listing.auctionTribunal + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.gardenType" class="content-normal grey-950">
            {{ $t("GardenType") + " ("
            }}{{ listing.gardenType + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.parkingSpaceArea" class="content-normal grey-950">
            {{ $t("ParkingSpaceArea") + " ("
            }}{{ listing.parkingSpaceArea + "m²)" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.outdoorParkingSpaceAvailable" class="content-normal grey-950">
            {{ $t("OutdoorParkingSpaceAvailable") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.outdoorParkingSpaceType" class="content-normal grey-950">
            {{ $t("OutdoorParkingSpaceType") + " ("
            }}{{ listing.outdoorParkingSpaceType + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.outdoorParkingSpaceNumber" class="content-normal grey-950">
            {{ $t("OutdoorParkingSpaceNumber") + " ("
            }}{{ listing.outdoorParkingSpaceNumber + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.energyCertificatePerformance" class="content-normal grey-950">
            {{ $t("EnergyCertificatePerformance") + " ("
            }}{{ listing.energyCertificatePerformance + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.energyCertificateLaw" class="content-normal grey-950">
            {{ $t("EnergyCertificateLaw") + " ("
            }}{{ listing.energyCertificateLaw + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.garden" class="content-normal grey-950">
            {{ $t("Garden") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.floorLevel" class="content-normal grey-950">
            {{ $t("FloorLevel") + " ("
            }}{{ listing.floorLevel + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.chimney" class="content-normal grey-950">
            {{ $t("Chimney") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.currentOccupation" class="content-normal grey-950">
            {{ $t("CurrentOccupation") + " ("
            }}{{ listing.currentOccupation + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.garageCapacity" class="content-normal grey-950">
            {{ $t("GarageCapacity") + " ("
            }}{{ listing.garageCapacity + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.parkingAutomaticDoor" class="content-normal grey-950">
            {{ $t("ParkingAutomaticDoor") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.parkingPlaceCovered" class="content-normal grey-950">
            {{ $t("ParkingPlaceCovered") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.buildingAdapted" class="content-normal grey-950">
            {{ $t("BuildingAdapted") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.conditionedAirType" class="content-normal grey-950">
            {{ $t("ConditionedAirType") + " ("
            }}{{ listing.conditionedAirType + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.doorman" class="content-normal grey-950">
            {{ $t("Doorman") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.emergencyExit" class="content-normal grey-950">
            {{ $t("EmergencyExit") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.emergencyLights" class="content-normal grey-950">
            {{ $t("EmergencyLights") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.extinguishers" class="content-normal grey-950">
            {{ $t("Extinguishers") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.fireDetectors" class="content-normal grey-950">
            {{ $t("FireDetectors") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.fireDoors" class="content-normal grey-950">
            {{ $t("FireDoors") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.floorsProperty" class="content-normal grey-950">
            {{ $t("FloorsProperty") + " ("
            }}{{ listing.floorsProperty + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.officeBuilding" class="content-normal grey-950">
            {{ $t("OfficeBuilding") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.roomsSplitted" class="content-normal grey-950">
            {{ $t("RoomsSplitted") + " ("
            }}{{ listing.roomsSplitted + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.sprinklers" class="content-normal grey-950">
            {{ $t("Sprinklers") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.suspendedCeiling" class="content-normal grey-950">
            {{ $t("SuspendedCeiling") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.suspendedFloor" class="content-normal grey-950">
            {{ $t("SuspendedFloor") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.windowsDouble" class="content-normal grey-950">
            {{ $t("SindowsDouble") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.areaTradableMinimum" class="content-normal grey-950">
            {{ $t("AreaTradableMinimum") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.auxiliaryEntrance" class="content-normal grey-950">
            {{ $t("AuxiliaryEntrance") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.bathroomAdapted" class="content-normal grey-950">
            {{ $t("BathroomAdapted") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.bridgeCrane" class="content-normal grey-950">
            {{ $t("BridgeCrane") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.facadeArea" class="content-normal grey-950">
            {{ $t("FacadeArea") + " ("
            }}{{ listing.facadeArea + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.lastActivity" class="content-normal grey-950">
            {{ $t("LastActivity") + " ("
            }}{{ listing.lastActivity + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.loadingDock" class="content-normal grey-950">
            {{ $t("LoadingDock") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.locatedAtCorner" class="content-normal grey-950">
            {{ $t("LocatedAtCorner") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.smokeExtraction" class="content-normal grey-950">
            {{ $t("SmokeExtraction") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.windowsNumber" class="content-normal grey-950">
            {{ $t("WindowsNumber") + " ("
            }}{{ listing.windowsNumber + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.classificationBlocks" class="content-normal grey-950">
            {{ $t("ClassificationBlocks") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.classificationChalet" class="content-normal grey-950">
            {{ $t("ClassificationChalet") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.classificationCommercial" class="content-normal grey-950">
            {{ $t("ClassificationCommercial") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.classificationHotel" class="content-normal grey-950">
            {{ $t("ClassificationHotel") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.classificationIndustrial" class="content-normal grey-950">
            {{ $t("ClassificationIndustrial") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.classificationOffice" class="content-normal grey-950">
            {{ $t("ClassificationOffice") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.classificationOther" class="content-normal grey-950">
            {{ $t("ClassificationOther") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.classificationPublic" class="content-normal grey-950">
            {{ $t("ClassificationPublic") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.naturalGas" class="content-normal grey-950">
            {{ $t("NaturalGas") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.areaHeight" class="content-normal grey-950">
            {{ $t("AreaHeight") + " ("
            }}{{ listing.areaHeight + ")" }},&nbsp;
          </p>
          <p v-if="listing.propertyTenants" class="content-normal grey-950">
            {{ $t("PropertyTenants") + " ("
            }}{{ listing.propertyTenants + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.minTenantAge" class="content-normal grey-950">
            {{ $t("MinTenantAge") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.maxTenantAge" class="content-normal grey-950">
            {{ $t("MaxTenantAge") + " ("
            }}{{ listing.maxTenantAge + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.couplesAllowed" class="content-normal grey-950">
            {{ $t("CouplesAllowed") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.bedType" class="content-normal grey-950">
            {{ $t("BedType") + " ("
            }}{{ listing.bedType + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.minimalStay" class="content-normal grey-950">
            {{ $t("MinimalStay") + " ("
            }}{{ listing.minimalStay + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.windowView" class="content-normal grey-950">
            {{ $t("WindowView") + " ("
            }}{{ $lfMapper.windowView(listing.windowView) + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.ownerLiving" class="content-normal grey-950">
            {{ $t("OwnerLiving") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.tenantGender" class="content-normal grey-950">
            {{ $t("TenantGender") + " ("
            }}{{ listing.tenantGender + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.houseKeeper" class="content-normal grey-950">
            {{ $t("HouseKeeper") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.privateBathroom" class="content-normal grey-950">
            {{ $t("PrivateBathroom") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.genderPreference" class="content-normal grey-950">
            {{ $t("GenderPreference") + " ("
            }}{{ listing.genderPreference + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.occupation" class="content-normal grey-950">
            {{ $t("Occupation") + " ("
            }}{{ listing.occupation + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.lgtbFriendly" class="content-normal grey-950">
            {{ $t("LgtbFriendly") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.occupiedNow" class="content-normal grey-950">
            {{ $t("OccupiedNow") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.tenantWorkers" class="content-normal grey-950">
            {{ $t("TenantWorkers") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.tenantStudents" class="content-normal grey-950">
            {{ $t("TenantStudents") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.minAge" class="content-normal grey-950">
            {{ $t("MinAge") + " ("
            }}{{ listing.minAge + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.maxAge" class="content-normal grey-950">
            {{ $t("MaxAge") + " ("
            }}{{ listing.maxAge + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.exteriorAccessibility" class="content-normal grey-950">
            {{ $t("ExteriorAccessibility") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.interiorAccessibility" class="content-normal grey-950">
            {{ $t("InteriorAccessibility") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.lifeStyle" class="content-normal grey-950">
            {{ $t("LifeStyle") + " ("
            }}{{ listing.lifeStyle + ")" }},&nbsp;&nbsp;
          </p>
        </v-row>
      </v-col>
    </v-row>
    <v-row id="sanitary-info-wrapper" class="extra-info-row">
      <v-col class="icon-col"
        ><img src="../../assets/images/sanitary.svg" alt=""
      /></v-col>
      <v-col class="info-col"
        ><h6>{{ $t("SanitaryArrangements") }}</h6>
        <v-row id="sanitary-text-row">
          <p v-if="listing.bathroomInside" class="content-normal grey-950">
            {{ $t("BathroomInside") }},&nbsp;
          </p>
          <p v-if="listing.bathroomType" class="content-normal grey-950">
            {{ $t("BathroomType") + " ("
            }}{{ listing.bathroomType + ")" }},
          </p>
          <p v-if="listing.washingMachine" class="content-normal grey-950">
            {{ $t("WashingMachine") }},&nbsp;
          </p>
          <p v-if="listing.bath" class="content-normal grey-950">
            {{ $t("Bath") }},&nbsp;
          </p>
          <p v-if="listing.wcBath" class="content-normal grey-950">
            {{ $t("SeparateToiletAndBath") }},&nbsp;
          </p>
          <p v-if="listing.boiler" class="content-normal grey-950">
            {{ $t("Boiler") }},&nbsp;
          </p>
          <p v-if="listing.pool" class="content-normal grey-950">
            {{ $t("Pool") }},&nbsp;
          </p>
          <p v-if="listing.shower" class="content-normal grey-950">
            {{ $t("Shower") }},&nbsp;
          </p>
          <p v-if="listing.localWaterSupply" class="content-normal grey-950">
            {{ $t("LocalWaterSupply") }},&nbsp;
          </p>
          <p v-if="listing.centralWaterSupply" class="content-normal grey-950">
            {{ $t("CentralWaterSupply") }},&nbsp;
          </p>
          <p v-if="listing.localSewerage" class="content-normal grey-950">
            {{ $t("LocalSewerage") }},&nbsp;
          </p>
          <p v-if="listing.centralSewerage" class="content-normal grey-950">
            {{ $t("CentralSewerage") }},&nbsp;
          </p>
          <p v-if="listing.sauna" class="content-normal grey-950">
            {{ $t("Sauna") }}
          </p>
        </v-row>
      </v-col>
    </v-row>
    <v-row id="comm-info-wrapper" class="extra-info-row">
      <v-col class="icon-col"
        ><img src="../../assets/images/com_and_sec.svg" alt=""
      /></v-col>
      <v-col class="info-col"
        ><h6>{{ $t("Communications&Security") }}</h6>
        <v-row id="comm-text-row">
          <p v-if="listing.security24h" class="content-normal grey-950">
            {{ $t("Security24h") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.access24h" class="content-normal grey-950">
            {{ $t("Access24h") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.securityAlarm" class="content-normal grey-950">
            {{ $t("SecurityAlarm") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.securityCamera" class="content-normal grey-950">
            {{ $t("SecurityCamera") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.securitySystem" class="content-normal grey-950">
            {{ $t("SecuritySystem") }},&nbsp;
          </p>
          <p v-if="listing.internet" class="content-normal grey-950">
            {{ $t("Internet") }},&nbsp;
          </p>
          <p
            v-if="listing.securitySystem && listing.estateType != 'land'"
            class="content-normal grey-950"
          >
            {{ $t("SecuritySystem") + " ("
            }}{{ formatSecuritySystem(listing.securitySystem) + ")" }},&nbsp;
          </p>
          <p v-if="listing.cableTv" class="content-normal grey-950">
            {{ $t("CableTv") }},&nbsp;
          </p>
          <p v-if="listing.telephone" class="content-normal grey-950">
            {{ $t("Phone") }},&nbsp;
          </p>
          <p v-if="listing.securityDoor" class="content-normal grey-950">
            {{ $t("SecurityDoor") }},&nbsp;
          </p>
          <p v-if="listing.staircaseLocked" class="content-normal grey-950">
            {{ $t("LockedStairway") }},&nbsp;
          </p>
          <p
            v-if="listing.neighbourhoodSecurity"
            class="content-normal grey-950"
          >
            {{ $t("NeighbourhoodWatch") }},&nbsp;
          </p>
          <p v-if="listing.guard" class="content-normal grey-950">
            {{ $t("Guard") }},&nbsp;
          </p>
          <p
            v-if="
              listing.fence &&
              listing.estateType != 'commercial' &&
              listing.estateType != 'land' &&
              listing.estateType != 'apartment'
            "
            class="content-normal grey-950"
          >
            {{ $t("Fence") }},&nbsp;
          </p>
          <p v-if="listing.videoSecurity" class="content-normal grey-950">
            {{ $t("VideoSurveillance") }},&nbsp;
          </p>
        </v-row>
      </v-col>
    </v-row>
    <v-row id="heating-info-wrapper" class="extra-info-row">
      <v-col class="icon-col"
        ><img src="../../assets/images/hvac.svg" alt=""
      /></v-col>
      <v-col class="info-col"
        ><h6>{{ $t("HeatingandVentilation") }}</h6>
        <v-row id="heating-text-row">
          <p v-if="listing.heating" class="content-normal grey-950">
            {{ $t("Heating") }},&nbsp;
          </p>
          <p v-if="listing.firePlace" class="content-normal grey-950">
            {{ $t("Fireplace") }},&nbsp;
          </p>
          <p v-if="listing.centralHeating" class="content-normal grey-950">
            {{ $t("CentralHeating") }},&nbsp;
          </p>
          <p v-if="listing.gasHeating" class="content-normal grey-950">
            {{ $t("GasHeating") }},&nbsp;
          </p>
          <p v-if="listing.furnaceHeating" class="content-normal grey-950">
            {{ $t("FurnaceHeating") }},&nbsp;
          </p>
          <p v-if="listing.electricalHeating" class="content-normal grey-950">
            {{ $t("ElectricalHeating") }},&nbsp;
          </p>
          <p v-if="listing.solidFuel" class="content-normal grey-950">
            {{ $t("SolidFuel") }},&nbsp;
          </p>
          <p v-if="listing.combinedHeating" class="content-normal grey-950">
            {{ $t("CombinedHeating") }},&nbsp;
          </p>
          <p v-if="listing.floorHeating" class="content-normal grey-950">
            {{ $t("FloorHeating") }},&nbsp;
          </p>
          <p v-if="listing.centralGas" class="content-normal grey-950">
            {{ $t("CentralGas") }},&nbsp;
          </p>
          <p v-if="listing.conditioner" class="content-normal grey-950">
            {{ $t("AirConditioner") }},&nbsp;
          </p>
          <p v-if="listing.geothermalHeating" class="content-normal grey-950">
            {{ $t("GeothermalHeating") }},&nbsp;
          </p>
          <p v-if="listing.airHeatPump" class="content-normal grey-950">
            {{ $t("AirHeatPump") }},&nbsp;
          </p>
          <p
            v-if="listing.airWaterPump && listing.liquidFuel"
            class="content-normal grey-950"
          >
            {{ $t("AirWaterHeatPump") }},&nbsp;
          </p>
          <p
            v-if="listing.airWaterPump && !listing.liquidFuel"
            class="content-normal grey-950"
          >
            {{ $t("AirWaterHeatPump") }}
          </p>
          <p v-if="listing.liquidFuel" class="content-normal grey-950">
            {{ $t("LiquidFuel") }}
          </p>
        </v-row>
      </v-col>
    </v-row>
    <v-row id="kitchen-info-wrapper" class="extra-info-row">
      <v-col class="icon-col"
        ><img src="../../assets/images/Kitchen.svg" alt=""
      /></v-col>
      <v-col class="info-col"
        ><h6>{{ $t("Kitchen") }}</h6>
        <v-row id="kitchen-text-row">
          <p v-if="listing.stove" class="content-normal grey-950">
            {{ formatStove(listing.stove) }},&nbsp;
          </p>
           <p v-if="listing.electricalStove" class="content-normal grey-950">
            {{ $t("ElectricalStove") }},&nbsp;
          </p>
           <p v-if="listing.gasStove" class="content-normal grey-950">
            {{ $t("GasStove") }},&nbsp;
          </p>
           <p v-if="listing.ceramicStove" class="content-normal grey-950">
            {{ $t("CeramicStove") }},&nbsp;
          </p>
           <p v-if="listing.fireWoodStove" class="content-normal grey-950">
            {{ $t("FireWoodStove") }},&nbsp;
          </p>
           <p v-if="listing.inductionStove" class="content-normal grey-950">
            {{ $t("InductionHob") }},&nbsp;
          </p>
          <p v-if="listing.openedKitchen" class="content-normal grey-950">
            {{ $t("OpenKitchen") }},&nbsp;
          </p>
          <p v-if="listing.kitchenFurniture" class="content-normal grey-950">
            {{ $t("KitchenFurniture") }},&nbsp;
          </p>
          <p v-if="listing.fridge" class="content-normal grey-950">
            {{ $t("Fridge") }},&nbsp;
          </p>
          <p v-if="listing.kitchen" class="content-normal grey-950">
            {{ $t("Kitchen") }},&nbsp;
          </p>
          <p v-if="listing.equipment" class="content-normal grey-950">
            {{ listing.equipment }},&nbsp;
          </p>
        </v-row>
      </v-col>
    </v-row>

    <v-row id="surroundings-info-wrapper" class="extra-info-row">
      <v-col class="icon-col"
        ><img
          style="width: 24px; height: 24px"
          src="../../assets/images/Surroundings.svg"
          alt=""
      /></v-col>
      <v-col class="info-col"
        ><h6>{{ $t("Surroundings") }}</h6>
        <v-row id="surroundings-text-row">
          <p v-if="listing.ideaLocation" class="content-normal grey-950">
            {{ $t("IdeaLocation") + " ("
            }}{{ listing.ideaLocation + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.accessType" class="content-normal grey-950">
            {{ $t("AccessType") + " ("
            }}{{ listing.accessType + ")" }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.streetLighting" class="content-normal grey-950">
            {{ $t("StreetLighting") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.sidewalk" class="content-normal grey-950">
            {{ $t("Sidewalk") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.roadAccess" class="content-normal grey-950">
            {{ $t("RoadAccess") }},&nbsp;&nbsp;
          </p>
          <p v-if="listing.nearestLocationKm" class="content-normal grey-950">
            {{ $t("NearestLocationKm") + " ("
            }}{{ listing.nearestLocationKm + ")" }},&nbsp;&nbsp;
          </p>
          <p
            v-if="
              listing.reservoir &&
              listing.reservoir != 'WATERBODY_OTHER' &&
              listing.estateType != 'garage'
            "
            class="content-normal grey-950"
          >
            {{ formatReservoirs(listing.reservoir) }},&nbsp;
          </p>
          <p
            v-if="listing.reservoirName && listing.estateType != 'garage'"
            class="content-normal grey-950"
          >
            {{
              $t("BodyOfWaterName") + " (" + listing.reservoirName + ")"
            }},&nbsp;
          </p>
          <p
            v-if="listing.reservoirDistance && listing.estateType != 'garage'"
            class="content-normal grey-950"
          >
            {{
              $t("BodyOfWaterDistance") +
              " (" +
              listing.reservoirDistance +
              "m)"
            }},&nbsp;
          </p>
          <p
            v-if="
              listing.coastLineLength &&
              listing.estateType != 'garage' &&
              listing.estateType != 'apartment'
            "
            class="content-normal grey-950"
          >
            {{
              $t("CoastLineLength1") + " (" + listing.coastLineLength + "m)"
            }},&nbsp;
          </p>
          <p
            v-if="
              listing.neighbours &&
              listing.estateType != 'garage' &&
              listing.estateType != 'commercial'
            "
            class="content-normal grey-950"
          >
            {{
              $t("Neighbours") +
              " (" +
              formatNeighbours(listing.neighbours) +
              ")"
            }},&nbsp;
          </p>
          <p
            v-if="listing.building && listing.estateType != 'garage'"
            class="content-normal grey-950"
          >
            {{
              $t("Building1") + " (" + formatBuilding(listing.building) + ")"
            }},&nbsp;
          </p>
          <p
            v-if="listing.roads && listing.estateType != 'garage'"
            class="content-normal grey-950"
          >
            {{ $t("Roads") + " (" + formatRoads(listing.roads) + ")" }},&nbsp;
          </p>
          <p
            v-if="listing.location && listing.estateType != 'garage'"
            class="content-normal grey-950"
          >
            {{
              $t("IsLocated") +
              " " +
              formatLocations(listing.location).toLowerCase()
            }},&nbsp;
          </p>
          <p
            v-if="
              listing.forestIsNear &&
              listing.estateType != 'commercial' &&
              listing.estateType != 'garage' &&
              listing.estateType != 'apartment'
            "
            class="content-normal grey-950"
          >
            {{ $t("ForestIsNear") }},&nbsp;
          </p>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ExtraInfo",
  props: ["listing"],
  mounted() {
    this.formatInfoText();
    this.formatSanitaryText();
    this.formatCommText();
    this.formatHeatingText();
    this.formatKitchenText();
    this.formatSurroundingsText();
    this.formatText();
  },
  methods: {
    formatText() {
      let infoTexts1 = document.getElementById("info-text-row").childNodes;
      let lastInfoText1 = null;
      for (let i = 0; i < infoTexts1.length; i++) {
        if (infoTexts1[i].nodeName === "P") {
          lastInfoText1 = infoTexts1[i];
        }
      }

      let infoTexts2 = document.getElementById("sanitary-text-row").childNodes;
      let lastInfoText2 = null;
      for (let i = 0; i < infoTexts2.length; i++) {
        if (infoTexts2[i].nodeName === "P") {
          lastInfoText2 = infoTexts2[i];
        }
      }
      let infoTexts3 = document.getElementById("comm-text-row").childNodes;
      let lastInfoText3 = null;
      for (let i = 0; i < infoTexts3.length; i++) {
        if (infoTexts3[i].nodeName === "P") {
          lastInfoText3 = infoTexts3[i];
        }
      }
      let infoTexts4 = document.getElementById("heating-text-row").childNodes;
      let lastInfoText4 = null;
      for (let i = 0; i < infoTexts4.length; i++) {
        if (infoTexts4[i].nodeName === "P") {
          lastInfoText4 = infoTexts4[i];
        }
      }
      let infoTexts5 = document.getElementById("kitchen-text-row").childNodes;
      let lastInfoText5 = null;
      for (let i = 0; i < infoTexts5.length; i++) {
        if (infoTexts5[i].nodeName === "P") {
          lastInfoText5 = infoTexts5[i];
        }
      }
      let infoTexts6 = document.getElementById(
        "surroundings-text-row"
      ).childNodes;
      let lastInfoText6 = null;
      for (let i = 0; i < infoTexts6.length; i++) {
        if (infoTexts6[i].nodeName === "P") {
          lastInfoText6 = infoTexts6[i];
        }
      }
      if (
        !lastInfoText1 &&
        !lastInfoText2 &&
        !lastInfoText3 &&
        !lastInfoText4 &&
        !lastInfoText5 &&
        !lastInfoText6
      ) {
        document.getElementById("extra-section").style.display = "none";
      }
    },
    formatStove(stove) {
      if (stove == "STOVE_ELECTRIC") {
        return this.$t("ElectricalStove");
      } else if (stove == "STOVE_GAS") {
        return this.$t("GasStove");
      } else if (stove == "STOVE_CERAMIC") {
        return this.$t("CeramicStove");
      } else if (stove == "STOVE_WOOD") {
        return this.$t("FireWoodStove");
      } else if (stove == "STOVE_INDUCTION") {
        return this.$t("InductionHob");
      }
    },
    formatReservoirs(reservoir) {
      if (reservoir == "WATERBODY_SEA") {
        return this.$t("Sea");
      } else if (reservoir == "WATERBODY_LAKE") {
        return this.$t("Lake");
      } else if (reservoir == "WATERBODY_RIVER") {
        return this.$t("River");
      } else if (reservoir == "WATERBODY_OTHER") {
        return this.$t("OtherWaterBody");
      }
    },
    formatNeighbours(neighbour) {
      if (neighbour == "AROUND") {
        return this.$t("Around");
      } else if (neighbour == "NEXT_TO") {
        return this.$t("NextTo");
      } else if (neighbour == "AT_ONE_SIDE") {
        return this.$t("AtOneSide");
      } else if (neighbour == "FARTHER") {
        return this.$t("Farther");
      }
    },
    formatBuilding(building) {
      if (building == "BUILDING_PRIVATE_HOUSE") {
        return this.$t("PrivateHouses");
      } else if (building == "BUILDING_PRIVATE_HOUSEAPART") {
        return this.$t("PrivateHousesOrApartments");
      } else if (building == "BUILDING_APARTMENT") {
        return this.$t("ApartmentBuildings");
      } else if (building == "BUILDING_COMMERCIAL") {
        return this.$t("CommercialBuildings");
      } else if (building == "BUILDING_MANUFACTURE") {
        return this.$t("ManufacturingBuildings");
      } else if (building == "BUILDING_NO") {
        return this.$t("NoBuildings");
      }
    },
    formatRoads(road) {
      if (road == "ROADS_GOOD_CONDITION") {
        return this.$t("InGoodCondition");
      } else if (road == "ROADS_SATISFACTORY_CONDITION") {
        return this.$t("InSatisfactoryCondition");
      } else if (road == "ROADS_BAD_CONDITION") {
        return this.$t("InBadCondition");
      } else if (road == "ROADS_PAVED") {
        return this.$t("Paved");
      } else if (road == "ROADS_GRAVEL") {
        return this.$t("GravelRoad");
      }
    },
    formatLocations(location) {
      if (location == "LOCATION_CENTRE") {
        return this.$t("InCentre");
      } else if (location == "LOCATION_SUBURB") {
        return this.$t("InSuburb");
      } else if (location == "LOCATION_OUTSIDE") {
        return this.$t("OutsideTheSettlement");
      } else if (location == "LOCATION_CITY") {
        return this.$t("InTheCity");
      }
    },
    formatSecuritySystem(security) {
      if (security == "SECURITY_SYSTEM_CABLING") {
        return this.$t("CablingDone");
      } else if (security == "SECURITY_SYSTEM_INSTALLED") {
        return this.$t("Installed");
      }
    },
    formatParkingOption(option) {
      if (option == "PARKING_FEE") {
        return this.$t("Fee");
      } else if (option == "PARKING_FREE") {
        return this.$t("Free1");
      } else if (option == "NO_PARKING") {
        return this.$t("NoParking");
      }
    },
    formatRoofType(roof) {
      if (roof == "ROOF_TYPE_BITUMIN") {
        return this.$t("BitumenRoof");
      } else if (roof == "ROOF_TYPE_ETERNIIT") {
        return this.$t("EternitRoof");
      } else if (roof == "ROOF_TYPE_KIVI") {
        return this.$t("StoneRoof");
      } else if (roof == "ROOF_TYPE_SLATE") {
        return this.$t("SlateRoof");
      } else if (roof == "ROOF_TYPE_TILING") {
        return this.$t("TilingRoof");
      } else if (roof == "ROOF_TYPE_PLEKK") {
        return this.$t("TinSheetRoof");
      } else if (roof == "ROOF_TYPE_PVC") {
        return this.$t("PVCRoof");
      } else if (roof == "ROOF_TYPE_RULL") {
        return this.$t("RolledMaterialRoof");
      } else if (roof == "ROOF_TYPE_TSINKPLEKK") {
        return this.$t("ZincSheetRoof");
      }
    },
    formatInfoText() {
      let infoTexts = document.getElementById("info-text-row").childNodes;
      let lastInfoText = null;
      for (let i = 0; i < infoTexts.length; i++) {
        if (infoTexts[i].nodeName === "P") {
          lastInfoText = infoTexts[i];
        }
      }
      if (!lastInfoText) {
        document.getElementById("extra-info-wrapper").style.display = "none";
        return;
      }
      let infoText = lastInfoText.innerHTML;
      console.log("info tekst: ", infoText);

      let infoTextEnd = infoText.slice(infoText.length - 8);
      if (infoTextEnd === ",&nbsp; ") {
        lastInfoText.innerText = infoText.slice(0, -8);
      }
    },
    formatSanitaryText() {
      let infoTexts = document.getElementById("sanitary-text-row").childNodes;
      let lastInfoText = null;
      for (let i = 0; i < infoTexts.length; i++) {
        if (infoTexts[i].nodeName === "P") {
          lastInfoText = infoTexts[i];
        }
      }
      if (!lastInfoText) {
        document.getElementById("sanitary-info-wrapper").style.display = "none";
        return;
      }
      let infoText = lastInfoText.innerHTML;
      let infoTextEnd = infoText.slice(infoText.length - 8);
      if (infoTextEnd === ",&nbsp; ") {
        lastInfoText.innerText = infoText.slice(0, -8);
      }
    },
    formatCommText() {
      let infoTexts = document.getElementById("comm-text-row").childNodes;
      let lastInfoText = null;
      for (let i = 0; i < infoTexts.length; i++) {
        if (infoTexts[i].nodeName === "P") {
          lastInfoText = infoTexts[i];
        }
      }
      if (!lastInfoText) {
        document.getElementById("comm-info-wrapper").style.display = "none";
        return;
      }
      let infoText = lastInfoText.innerHTML;
      let infoTextEnd = infoText.slice(infoText.length - 8);
      if (infoTextEnd === ",&nbsp; ") {
        lastInfoText.innerText = infoText.slice(0, -8);
      }
    },
    formatHeatingText() {
      let infoTexts = document.getElementById("heating-text-row").childNodes;
      let lastInfoText = null;
      for (let i = 0; i < infoTexts.length; i++) {
        if (infoTexts[i].nodeName === "P") {
          lastInfoText = infoTexts[i];
        }
      }
      if (!lastInfoText) {
        document.getElementById("heating-info-wrapper").style.display = "none";
        return;
      }
      let infoText = lastInfoText.innerHTML;
      let infoTextEnd = infoText.slice(infoText.length - 8);
      if (infoTextEnd === ",&nbsp; ") {
        lastInfoText.innerText = infoText.slice(0, -8);
      }
    },
    formatKitchenText() {
      let infoTexts = document.getElementById("kitchen-text-row").childNodes;
      let lastInfoText = null;
      for (let i = 0; i < infoTexts.length; i++) {
        if (infoTexts[i].nodeName === "P") {
          lastInfoText = infoTexts[i];
        }
      }
      if (!lastInfoText) {
        document.getElementById("kitchen-info-wrapper").style.display = "none";
        return;
      }
      let infoText = lastInfoText.innerHTML;
      let infoTextEnd = infoText.slice(infoText.length - 8);
      if (infoTextEnd === ",&nbsp; ") {
        lastInfoText.innerText = infoText.slice(0, -8);
      }
    },
    formatSurroundingsText() {
      let infoTexts = document.getElementById(
        "surroundings-text-row"
      ).childNodes;
      let lastInfoText = null;
      for (let i = 0; i < infoTexts.length; i++) {
        if (infoTexts[i].nodeName === "P") {
          lastInfoText = infoTexts[i];
        }
      }
      if (!lastInfoText) {
        document.getElementById("surroundings-info-wrapper").style.display =
          "none";
        return;
      }
      let infoText = lastInfoText.innerHTML;
      let infoTextEnd = infoText.slice(infoText.length - 8);
      if (infoTextEnd === ",&nbsp; ") {
        lastInfoText.innerText = infoText.slice(0, -8);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.extras {
  padding-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}
.icon-col {
  padding: 0;
  max-width: 40px;
}
.info-col {
  padding: 0;
  width: 100%;
}
.grey-950 {
  color: #75787a;
}
#info-text-row,
#kitchen-text-row,
#sanitary-text-row,
#heating-text-row,
#comm-text-row,
#surroundings-text-row {
  margin-top: 4px;
}
#sanitary-info-wrapper,
#kitchen-info-wrapper,
#heating-info-wrapper,
#comm-info-wrapper,
#surroundings-info-wrapper {
  margin-top: 24px;
}
</style>
