var render = function render(){
  var _vm$listing, _vm$listing$meta, _vm$listing$meta$imag, _vm$listing$meta$imag2, _vm$listing2, _vm$listing2$meta, _vm$listing2$meta$ima, _vm$listing2$meta$ima2, _vm$listing3, _vm$listing3$meta, _vm$listing3$meta$ima, _vm$listing$images, _vm$listing4, _vm$listing4$meta, _vm$listing4$meta$ima, _vm$listing$images2, _vm$listing5, _vm$listing5$meta, _vm$listing5$meta$ima, _vm$listing$images3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "listingOverlay",
    class: {
      'listing-overlay-full': !_vm.contactView && !_vm.projectView,
      'listing-overlay-small': _vm.contactView,
      'listing-overlay-small-border': _vm.projectView
    },
    style: _vm.style,
    attrs: {
      "id": "listing-overlay"
    },
    on: {
      "click": function ($event) {
        return _vm.closePanel($event);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    staticClass: "listing-panel",
    style: _vm.mobileView ? 'width: 380px' : null
  }, [_vm.listing.linkedDeal && _vm.openHouse ? _c('div', {
    key: _vm.updater,
    staticStyle: {
      "background-color": "#7200e2",
      "width": "100%",
      "height": "32px",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('OpenHouseContainer', {
    attrs: {
      "task": _vm.openHouse,
      "invert": false
    }
  })], 1) : _vm._e(), !_vm.listingLoaded ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "absolute",
      "top": "50%",
      "left": "50%"
    }
  }) : _vm._e(), _vm.listing.status === 'archived' && _vm.relatedProject && (_vm.relatedProject.pipelineStep === 'archived' || _vm.relatedProject.pipelineStep === 'Done') ? _c('div', {
    staticClass: "project-archived-header content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("ToMakeThisListingActiveGoTo")) + " "), _c('a', {
    staticClass: "link-small",
    attrs: {
      "href": _vm.listing.isNewDevelopment ? _vm.path + '/property-development/' + _vm.relatedProject._id : _vm.path + '/projects/' + _vm.listing.linkedDeal + '/general'
    }
  }, [_vm._v(_vm._s(_vm.$t("Project's").toLowerCase()))]), _vm._v(" " + _vm._s(_vm.$t("andPress")) + " "), _c('p', {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("\"" + _vm._s(_vm.$t("DuplicateAsNew")) + "\"")]), _vm._v(" " + _vm._s(_vm.$t("Button").toLowerCase()) + ". ")]) : _vm._e(), _vm.listingLoaded ? _c('div', {
    key: _vm.updater,
    staticClass: "listing-panel-header",
    attrs: {
      "id": "listingpanel-header"
    }
  }, [_vm.listing.isNewDevelopment ? _c('div', [_c('BaseToggle', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.listing.developmentStatus && _vm.listing.developmentStatus !== 'archived',
      expression: "\n            listing.developmentStatus &&\n            listing.developmentStatus !== 'archived'\n          "
    }],
    attrs: {
      "value": _vm.listing.isPublic,
      "label": _vm.$t('Public')
    },
    on: {
      "change": function (val) {
        return _vm.toggleListingPublic(val, _vm.listing._id);
      }
    }
  })], 1) : _vm._e(), !_vm.publicListing && !_vm.companyLanderPage && !_vm.clientReportPage ? _c('v-row', {
    staticClass: "header-first-row",
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "margin": "0"
    }
  }, [_c('v-col', {
    staticStyle: {
      "padding": "0"
    }
  }, [!_vm.listing.isNewDevelopment || _vm.listing.isNewDevelopment && _vm.listing.isPublic ? _c('v-row', [_c('div', {
    staticStyle: {
      "flex": "auto",
      "display": "flex",
      "flex-direction": "row",
      "flex-wrap": "nowrap",
      "margin-right": "4px",
      "margin-top": "1px",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('p', {
    staticClass: "statusLabel content-bold",
    class: {
      'draft-label ': _vm.listing.status === 'draft',
      'active-label ': _vm.listing.status === 'active',
      'booked-label': _vm.listing.status === 'booked',
      'sold-label ': _vm.listing.status === 'sold',
      'archived-label': _vm.listing.status === 'archived',
      'rented-label': _vm.listing.status === 'rented',
      'expired-label': _vm.listing.status === 'expired'
    },
    attrs: {
      "id": 'statusLabel' + _vm.listing._id
    },
    on: {
      "click": _vm.openStatusDropdown
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.status(_vm.listing.status)) + " ")]), _c('v-tooltip', {
    key: _vm.bookingUpdater,
    attrs: {
      "bottom": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_vm.listing.status === 'booked' ? _c('p', _vm._g(_vm._b({
          staticClass: "booked-label booked-info-label-sidepanel"
        }, 'p', attrs, false), on), [_c('img', {
          staticStyle: {
            "filter": "invert(1)"
          },
          attrs: {
            "src": require("../../assets/images/info.svg"),
            "alt": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openBookingDetailsModal(_vm.listing);
            }
          }
        })]) : _vm._e()];
      }
    }], null, false, 86066846)
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("BookingDetails")) + " ")])]), _vm.listing.status === 'expired' && _vm.listing.expiryDate ? _c('p', {
    staticClass: "content-small",
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.expiryDate(_vm.listing.expiryDate)) + " ")]) : _vm._e(), _vm.listing.status === 'active' || _vm.listing.status === 'booked' ? _c('Outputs', {
    attrs: {
      "listing": _vm.listing
    },
    on: {
      "openOutputSettings": function (outputPosition) {
        return _vm.openOutputsModal(outputPosition);
      }
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1), _c('v-col', {
    staticStyle: {
      "max-width": "240px",
      "padding": "0"
    }
  }, [!_vm.companyLanderPage ? _c('v-row', {
    staticStyle: {
      "justify-content": "flex-end"
    }
  }, [_c('div', {
    staticClass: "button",
    staticStyle: {
      "margin-right": "2px"
    },
    on: {
      "click": function ($event) {
        return _vm.goToPrintPreview();
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/print.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Print")) + " ")])]), _vm.$route.params.fromListingsTable && _vm.hasAccess(_vm.listing) && (!_vm.relatedProject || _vm.listing.status !== 'archived' || _vm.listing.status === 'archived' && _vm.relatedProject && _vm.relatedProject.pipelineStep !== 'archived' && _vm.relatedProject.pipelineStep !== 'Done') ? _c('a', {
    staticClass: "button",
    staticStyle: {
      "margin": "0 2px",
      "text-decoration": "unset"
    },
    attrs: {
      "href": _vm.editPath,
      "id": "link-button"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return function (event) {
          return _vm.editListingMode(event);
        }.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])]) : _vm._e(), !_vm.$route.params.fromListingsTable && _vm.hasAccess(_vm.listing) ? _c('a', {
    staticClass: "button",
    staticStyle: {
      "margin": "0 2px",
      "text-decoration": "unset"
    },
    attrs: {
      "id": "link-button",
      "href": _vm.editPath
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return function (event) {
          return _vm.editListingMode(event);
        }.apply(null, arguments);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "button-text"
  }, [_vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "button",
    staticStyle: {
      "margin": "0 2px"
    },
    on: {
      "click": function ($event) {
        return _vm.copyToClipboard();
      }
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require("../../assets/images/add-link.svg"),
      "alt": ""
    }
  })]), !_vm.relatedProject || _vm.listing.status !== 'archived' || _vm.listing.status === 'archived' && _vm.relatedProject && _vm.relatedProject.pipelineStep !== 'archived' && _vm.relatedProject.pipelineStep !== 'Done' ? _c('div', {
    staticClass: "button",
    staticStyle: {
      "margin-left": "2px"
    }
  }, [_c('IconDropdown', {
    staticStyle: {
      "width": "16px",
      "margin-top": "-1px"
    },
    attrs: {
      "custom-style": 'border: 1px solid $grey500; display: flex; align-items: center; justify-content: center; margin: auto; border-radius: 6px; height: 24px; padding: 0 4px; cursor: pointer; width: 20px; &:hover { background-color: #F4F5F7; }',
      "listing-view": true,
      "items": _vm.additional_activities,
      "display-key": 'name',
      "value-key": 'value',
      "icon": 'dot_menu.svg'
    },
    on: {
      "itemSelected": _vm.selectActivity
    }
  })], 1) : _vm._e()]) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.listing.developmentStatus || _vm.listing.developmentStatus && _vm.listing.developmentStatus !== 'archived',
      expression: "\n          !listing.developmentStatus ||\n          (listing.developmentStatus &&\n            listing.developmentStatus !== 'archived')\n        "
    }],
    staticClass: "dates-row"
  }, [_vm.listing.status === 'booked' && _vm.listing.bookedUntil ? _c('p', {
    staticClass: "content-small booking-until-date",
    staticStyle: {
      "padding-top": "2px"
    },
    on: {
      "click": function ($event) {
        return _vm.openBookingDetailsModal(_vm.listing);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.formatDate(_vm.listing.bookedUntil)) + " ")]) : _vm._e(), _vm.listing.status === 'active' && _vm.listing.activeUntil ? _c('date-pick', {
    attrs: {
      "isDateDisabled": _vm.isFutureDate
    },
    on: {
      "input": function ($event) {
        return _vm.changeActiveUntil(_vm.listing, $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var toggle = _ref2.toggle;
        return [_vm.listing.status === 'active' && _vm.listing.activeUntil ? _c('p', {
          staticClass: "content-small",
          staticStyle: {
            "padding-top": "2px",
            "cursor": "pointer"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("until")) + " " + _vm._s(_vm.listing.activeUntil) + " ")]) : _vm._e()];
      }
    }], null, false, 830238120)
  }) : _vm._e()], 1), _c('v-row', {
    staticClass: "header-first-row",
    staticStyle: {
      "margin-top": "8px",
      "padding-bottom": "8px"
    }
  }, [_c('h4', [_vm._v(_vm._s(_vm.listing.listingName))])]), _vm.publicListing && _vm.activeNavItem === 'offers' ? _c('v-row', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('div', {
    staticClass: "content-small",
    staticStyle: {
      "min-width": "100px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("WishToView")) + " ")]), _c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "8px"
    }
  }, [_c('button', {
    staticClass: "transparent-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('reject', _vm.listing._id);
      }
    }
  }, [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("No")) + " ")])]), _c('button', {
    staticClass: "transparent-button yes-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('accept', _vm.listing._id);
      }
    }
  }, [_c('div', {
    staticClass: "content-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("Yes")) + " ")])])])]) : _vm._e(), _vm.publicListing && _vm.activeNavItem === 'lost' ? _c('v-row', {
    staticStyle: {
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('div', {
    staticClass: "content-small",
    staticStyle: {
      "min-width": "100px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ReviewOfferAgain")) + " ")]), _c('div', {
    staticStyle: {
      "width": "32px"
    }
  }, [_c('button', {
    staticClass: "transparent-button yes-button",
    on: {
      "click": function ($event) {
        return _vm.$emit('undoLost', _vm.listing._id);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/undo.svg"),
      "alt": ""
    }
  })])])]) : _vm._e(), !_vm.publicListing && !_vm.companyLanderPage && !_vm.clientReportPage ? _c('div', {
    staticStyle: {
      "width": "inherit"
    }
  }, [_vm.relatedProject && _vm.relatedProject.name ? _c('v-row', {
    staticClass: "project-info"
  }, [!_vm.listing.isNewDevelopment ? _c('img', {
    attrs: {
      "src": require("../../assets/images/projects.svg"),
      "alt": ""
    }
  }) : _vm._e(), _vm.listing.isNewDevelopment ? _c('img', {
    attrs: {
      "src": require("../../assets/images/PropertyDeveloper.svg"),
      "alt": ""
    }
  }) : _vm._e(), _c('a', {
    staticClass: "button-small hoverable-info",
    staticStyle: {
      "text-decoration-line": "none"
    },
    attrs: {
      "href": _vm.listing.isNewDevelopment ? _vm.path + '/property-development/' + _vm.relatedProject._id : _vm.path + '/projects/' + _vm.listing.linkedDeal + '/general'
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.openProject(_vm.listing);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.relatedProject.name) + " ")]), _c('div', {
    staticClass: "open-new-tab-button",
    on: {
      "click": function ($event) {
        return _vm.openProjectNewTab(_vm.listing);
      }
    }
  }, [_c('img', {
    staticStyle: {
      "width": "16px",
      "height": "16px"
    },
    attrs: {
      "src": require("../../assets/images/open_new_tab.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t("OpenInNewTab")))])]), _c('div', {
    staticClass: "open-new-tab-button",
    on: {
      "click": _vm.openLinkListings
    }
  }, [_c('img', {
    staticStyle: {
      "height": "16px",
      "width": "16px"
    },
    attrs: {
      "src": require("../../assets/images/edit.svg"),
      "alt": ""
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t("ChangeProject")))])])]) : _vm._e(), !_vm.relatedProject && !_vm.listing.isNewDevelopment ? _c('div', {
    staticClass: "project-info"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/projects.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "button-small"
  }, [_vm._v("-")])]) : _vm._e()], 1) : _vm._e(), !_vm.companyLanderPage && !_vm.clientReportPage && !_vm.activeNavItem ? _c('div', {
    staticClass: "broker-info"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/users.svg"),
      "alt": ""
    }
  }), _vm._l(_vm.listing.access.users, function (creator, userKey) {
    return _c('span', {
      key: userKey,
      staticClass: "button-small hoverable-info",
      staticStyle: {
        "margin-left": "-2px"
      },
      on: {
        "click": function (event) {
          return _vm.openBrokerDetailPanel(event, userKey);
        }
      }
    }, [_vm._v(" " + _vm._s(creator.name) + " " + _vm._s(creator.ownershipType === "owner" ? "(" + _vm.$t("Owner").toLowerCase() + ")" : "") + " ")]);
  }), _vm.hasAccess(_vm.listing) ? _c('button', {
    staticClass: "animated-button",
    on: {
      "click": function ($event) {
        return _vm.openAddUsers();
      }
    }
  }, [_c('p', {
    staticStyle: {
      "font-size": "16px",
      "margin-top": "-1px"
    }
  }, [_vm._v("+")]), _c('span', [_vm._v(_vm._s(_vm.$t("ManageUsers")))])]) : _vm._e()], 2) : _vm._e()], 1) : _vm._e(), _vm.listingLoaded ? _c('div', {
    key: _vm.updater,
    staticClass: "listing-panel-body",
    attrs: {
      "id": "listing-body"
    }
  }, [((_vm$listing = _vm.listing) === null || _vm$listing === void 0 ? void 0 : (_vm$listing$meta = _vm$listing.meta) === null || _vm$listing$meta === void 0 ? void 0 : (_vm$listing$meta$imag = _vm$listing$meta.images) === null || _vm$listing$meta$imag === void 0 ? void 0 : (_vm$listing$meta$imag2 = _vm$listing$meta$imag.status) === null || _vm$listing$meta$imag2 === void 0 ? void 0 : _vm$listing$meta$imag2.original) === 'uploading' || ((_vm$listing2 = _vm.listing) === null || _vm$listing2 === void 0 ? void 0 : (_vm$listing2$meta = _vm$listing2.meta) === null || _vm$listing2$meta === void 0 ? void 0 : (_vm$listing2$meta$ima = _vm$listing2$meta.images) === null || _vm$listing2$meta$ima === void 0 ? void 0 : (_vm$listing2$meta$ima2 = _vm$listing2$meta$ima.status) === null || _vm$listing2$meta$ima2 === void 0 ? void 0 : _vm$listing2$meta$ima2.original) === 'pending' ? _c('div', {
    staticClass: "listing-images-updating"
  }, [_c('img', {
    attrs: {
      "height": "20",
      "width": "20",
      "src": require('@/assets/images/alert.svg'),
      "alt": "info"
    }
  }), ((_vm$listing3 = _vm.listing) === null || _vm$listing3 === void 0 ? void 0 : (_vm$listing3$meta = _vm$listing3.meta) === null || _vm$listing3$meta === void 0 ? void 0 : (_vm$listing3$meta$ima = _vm$listing3$meta.images) === null || _vm$listing3$meta$ima === void 0 ? void 0 : _vm$listing3$meta$ima.count) - (((_vm$listing$images = _vm.listing.images) === null || _vm$listing$images === void 0 ? void 0 : _vm$listing$images.length) || 0) > 0 ? _c('p', [_vm._v(" " + _vm._s(((_vm$listing4 = _vm.listing) === null || _vm$listing4 === void 0 ? void 0 : (_vm$listing4$meta = _vm$listing4.meta) === null || _vm$listing4$meta === void 0 ? void 0 : (_vm$listing4$meta$ima = _vm$listing4$meta.images) === null || _vm$listing4$meta$ima === void 0 ? void 0 : _vm$listing4$meta$ima.count) - (((_vm$listing$images2 = _vm.listing.images) === null || _vm$listing$images2 === void 0 ? void 0 : _vm$listing$images2.length) || 0)) + " " + _vm._s(_vm.$tc("ImagesAreCurrentlyBeingUploaded", ((_vm$listing5 = _vm.listing) === null || _vm$listing5 === void 0 ? void 0 : (_vm$listing5$meta = _vm$listing5.meta) === null || _vm$listing5$meta === void 0 ? void 0 : (_vm$listing5$meta$ima = _vm$listing5$meta.images) === null || _vm$listing5$meta$ima === void 0 ? void 0 : _vm$listing5$meta$ima.count) - (((_vm$listing$images3 = _vm.listing.images) === null || _vm$listing$images3 === void 0 ? void 0 : _vm$listing$images3.length) || 0))) + " ")]) : _c('p', [_vm._v(_vm._s(_vm.$t("ImagesAreBeingUpdated")))])]) : _vm._e(), _vm.listing.images && _vm.listing.images.length ? _c('div', {
    staticClass: "images-row"
  }, [_vm._l(_vm.renderedThumbnails(), function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "image-preview",
      staticStyle: {
        "display": "flex",
        "gap": "8px"
      },
      attrs: {
        "id": "lightgallery"
      },
      on: {
        "click": function ($event) {
          return _vm.openImageGallery(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": image,
        "alt": "image"
      }
    })]);
  }), _c('div', {
    staticClass: "all-images",
    on: {
      "click": function ($event) {
        return _vm.openImageGallery(null);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/image.svg"),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-small top12"
  }, [_vm._v("All images")]), _c('p', {
    staticClass: "content-small"
  }, [_vm._v("(" + _vm._s(_vm.countVisibleImages) + ")")])])], 2) : _vm._e(), _c('div', {
    class: {
      'main-info': 1,
      'main-info-mobile': _vm.mobileView
    }
  }, [_c('h5', {
    staticStyle: {
      "margin-bottom": "24px"
    }
  }, [_vm._v(_vm._s(_vm.$t("Subblock.GeneralData")))]), _c('v-row', {
    staticClass: "listing-field-row top24"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Created")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatCreatedDate(_vm.listing.createdAt)) + " ")])]), _vm.listing.firstPublishedAt ? _c('v-row', {
    staticClass: "listing-field-row top24"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Published")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatCreatedDate(_vm.listing.firstPublishedAt)) + " ")])]) : _vm._e(), _vm.listing.updatedAt ? _c('v-row', {
    staticClass: "listing-field-row top24"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Modified")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatCreatedDate(_vm.listing.updatedAt)) + " ")])]) : _vm._e(), _vm.listing.identifierUpdatedAt ? _c('v-row', {
    staticClass: "listing-field-row top24"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s("ID" + " " + _vm.$t("Updated").toLowerCase()) + " ")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatCreatedDate(_vm.listing.identifierUpdatedAt)) + " ")])]) : _vm._e(), _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ListingType")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.dealType(_vm.listing.dealType)) + " ")])]), _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("EstateType")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.estateType(_vm.listing.estateType)) + " ")])]), _vm.listing.houseNumber && (!_vm.companyLanderPage && !_vm.publicListing || (_vm.companyLanderPage || _vm.publicListing) && _vm.listing.showHouseNumber) ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("HouseNumber")))]), !_vm.companyLanderPage ? _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.showNumber(_vm.listing.showHouseNumber, _vm.listing.houseNumber)) + " ")]) : _vm.companyLanderPage && _vm.listing.showHouseNumber ? _c('p', [_vm._v(" " + _vm._s(_vm.listing.houseNumber) + " ")]) : _vm._e()]) : _vm._e(), _vm.listing.apartmentNumber && (!_vm.companyLanderPage && !_vm.publicListing || (_vm.companyLanderPage || _vm.publicListing) && _vm.listing.showApartmentNumber) ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ApartmentNumber")))]), !_vm.companyLanderPage ? _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.showNumber(_vm.listing.showApartmentNumber, _vm.listing.apartmentNumber)) + " ")]) : _vm.companyLanderPage && _vm.listing.showApartmentNumber ? _c('p', [_vm._v(" " + _vm._s(_vm.listing.apartmentNumber) + " ")]) : _vm._e()]) : _vm._e(), _vm.listing.price || _vm.listing.price === 0 ? _c('v-row', {
    staticClass: "listing-field-row top16 price-info-dropdown"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("Price")) + " ")]), _c('p', {
    staticClass: "field-value",
    class: {
      'price-history-log': _vm.listing.priceHistoryLog && _vm.listing.priceHistoryLog.length
    },
    staticStyle: {
      "max-width": "max-content"
    },
    on: {
      "click": _vm.openDropdown
    }
  }, [_vm._v(" " + _vm._s(_vm.listingPrice) + " ")]), _vm.listing.priceHistoryLog && _vm.listing.priceHistoryLog.length && _vm.dropdownIsOpen ? _c('div', {
    staticClass: "price-info-container price-info-container-hover"
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeDropdown,
      expression: "closeDropdown"
    }],
    staticClass: "price-history-info-dropdown"
  }, _vm._l(_vm.listing.priceHistoryLog.slice(-20), function (info, index) {
    return _c('div', {
      key: 'price-info-' + index,
      staticClass: "price-info"
    }, [_c('div', {
      staticClass: "content-normal"
    }, [_vm._v(" " + _vm._s(_vm.formatDate(info.date)) + " ")]), _c('div', {
      staticClass: "content-semibold",
      staticStyle: {
        "width": "100%",
        "text-align": "right"
      }
    }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(info.to, "€")) + " ")])]);
  }), 0)]) : _vm._e()]) : _vm._e(), _vm.listing.priceTransfer || _vm.listing.priceTransfer === 0 ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PriceTransfer")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.listing.priceTransfer))])]) : _vm._e(), _vm.listing.pricePerSquareMeter ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Price")) + " /m²")]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.listing.pricePerSquareMeter) + " €/m²")])]) : _vm._e(), _vm.listing.estateType !== 'land' && _vm.listing.generalSurfaceArea ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("GeneralSurfaceArea")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.listing.generalSurfaceArea) + " m²")])]) : _vm._e(), _vm.listing.areaBuildable ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AreaBuildable")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.listing.areaBuildable) + " m²")])]) : _vm._e(), _vm.listing.landArea && _vm.listing.estateType === 'land' && (_vm.listing.landArea.m2 || _vm.listing.landArea.ha) ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("LandArea")))]), _vm.listing.landArea.m2 && _vm.listing.landArea.m2Selected ? _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.landArea.m2) + " m² ")]) : _vm._e(), _vm.listing.landArea.ha && _vm.listing.landArea.haSelected ? _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.landArea.ha) + " ha ")]) : _vm._e()]) : _vm._e(), _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("AvailableFrom")))]), _vm.listing.availableImmediately ? _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$t("Immediately")) + " ")]) : _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.listing.dateAvailable)) + " ")])]), _vm.listing.expensesInSummer ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ExpensesInSummer")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.listing.expensesInSummer) + " €")])]) : _vm._e(), _vm.listing.expensesInWinter ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("ExpensesInWinter")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.listing.expensesInWinter) + " €")])]) : _vm._e(), _vm.listing.floor && !_vm.listing.floors && _vm.listing.estateType !== 'land' && _vm.listing.estateType !== 'house' && _vm.listing.estateType !== 'cottage' ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Floor")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.floor) + " ")])]) : _vm._e(), !_vm.listing.floor && _vm.listing.floors && _vm.listing.estateType !== 'land' || !_vm.listing.floor && _vm.listing.floors && (_vm.listing.estateType === 'house' || _vm.listing.estateType === 'house_part' || _vm.listing.estateType === 'cottage') ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Floors")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.floors) + " ")])]) : _vm._e(), _vm.listing.floor && _vm.listing.floors && _vm.listing.estateType !== 'land' && _vm.listing.estateType !== 'house' && _vm.listing.estateType !== 'cottage' ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Floor")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.floors(_vm.listing.floor, _vm.listing.floors)) + " ")])]) : _vm._e(), _vm.listing.buildYear && _vm.listing.estateType !== 'land' ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BuildYear")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.buildYear) + " ")])]) : _vm._e(), _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("RentToOwn")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.rentToOwn ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Tradable")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.additionalInfo.tradable ? _vm.$t("Yes") : _vm.$t("No")) + " ")])]), _vm.listing.rooms ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("RoomCount")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.listing.rooms))])]) : _vm._e(), _vm.listing.bedrooms ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BedroomCount")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(_vm._s(_vm.listing.bedrooms))])]) : _vm._e(), _vm.listing.bathroomCount ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BathroomCount")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.bathroomCount) + " ")])]) : _vm._e(), _vm.listing.roomsSeparate ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("RoomsSeparate")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$t("Yes")) + " ")])]) : _vm._e(), _vm.listing.condition ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Condition")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.condition(_vm.listing.condition)) + " ")])]) : _vm._e(), _vm.listing.ownershipForm ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("OwnershipType")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.ownershipForm(_vm.listing.ownershipForm)) + " ")])]) : _vm._e(), _vm.listing.priceCommunity ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("PriceCommunity")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.priceCommunity + " €") + " ")])]) : _vm._e(), _vm.listing.cadastralNumber && (!_vm.companyLanderPage && !_vm.publicListing || (_vm.companyLanderPage || _vm.publicListing) && _vm.listing.showCadastralNumber) ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("CadastralNumber")) + " ")]), !_vm.companyLanderPage && !_vm.publicListing ? _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "cursor": "pointer",
      "text-decoration-line": "underline"
    },
    on: {
      "click": function ($event) {
        return _vm.openCadastralLink(_vm.listing.cadastralNumber);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.showNumber(_vm.listing.showCadastralNumber, _vm.listing.cadastralNumber)) + " ")]) : _vm._e(), (_vm.companyLanderPage || _vm.publicListing) && _vm.listing.showCadastralNumber ? _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "cursor": "pointer",
      "text-decoration-line": "underline"
    },
    on: {
      "click": function ($event) {
        return _vm.openCadastralLink(_vm.listing.cadastralNumber);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.cadastralNumber) + " ")]) : _vm._e()]) : _vm._e(), (!_vm.companyLanderPage && !_vm.publicListing || (_vm.companyLanderPage || _vm.publicListing) && _vm.listing.showEstateNumber) && !_vm.user.isItaly ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("EstateNumber")) + " ")]), !_vm.companyLanderPage && !_vm.publicListing ? _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "cursor": "pointer",
      "text-decoration": "underline"
    },
    on: {
      "click": function ($event) {
        return _vm.openELandRegistryLink(_vm.listing.cadastralNumber, _vm.listing.estateNumber);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.showEstateNumber(_vm.listing.showEstateNumber, _vm.listing.estateNumber)) + " ")]) : _vm._e(), (_vm.companyLanderPage || _vm.publicListing) && _vm.listing.showEstateNumber ? _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.listing.estateNumber) + " ")]) : _vm._e()]) : _vm._e(), _vm.listing.ehrNumber && (!_vm.companyLanderPage && !_vm.publicListing || _vm.companyLanderPage || _vm.publicListing) && !_vm.user.isItaly ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("EhrNumber")) + " ")]), !_vm.companyLanderPage && !_vm.publicListing ? _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "cursor": "pointer",
      "text-decoration-line": "underline"
    },
    on: {
      "click": function ($event) {
        return _vm.openEhrNumberLink(_vm.listing.ehrNumber);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.ehrNumber) + " ")]) : _vm._e(), _vm.companyLanderPage || _vm.publicListing ? _c('p', {
    staticClass: "field-value",
    staticStyle: {
      "cursor": "pointer",
      "text-decoration-line": "underline"
    },
    on: {
      "click": function ($event) {
        return _vm.openEhrNumberLink(_vm.listing.ehrNumber);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.ehrNumber) + " ")]) : _vm._e()]) : _vm._e(), _vm.listing.buildingMaterial ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("BuildingMaterial")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.buildingMaterial(_vm.listing.buildingMaterial)) + " ")])]) : _vm._e(), _vm.listing.energyLabel ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("EnergyLabel")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.energyLabel(_vm.listing.energyLabel)) + " ")])]) : _vm._e(), _vm.listing.readiness ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Readiness")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.readiness(_vm.listing.readiness)) + " ")])]) : _vm._e(), _vm.listing.limitations ? _c('v-row', {
    staticClass: "listing-field-row top16"
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Limitations")))]), _c('p', {
    staticClass: "field-value"
  }, [_vm._v(" " + _vm._s(_vm.$lfMapper.limitations(_vm.listing.limitations)) + " ")])]) : _vm._e(), _vm.listing.estateType == 'commercial' && (_vm.listing.office || _vm.listing.tradeshop || _vm.listing.accomodation || _vm.listing.services || _vm.listing.warehouse || _vm.listing.production || _vm.listing.foodServices || _vm.listing.notDefined || _vm.listing.warehouseProduction || _vm.listing.otherUsePossible) ? _c('v-row', {
    staticClass: "listing-field-row top16",
    staticStyle: {
      "gap": "0px"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Purpose")))]), _c('div', {
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.listing.office ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Office")) + " ")]) : _vm._e(), _vm.listing.tradeshop ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("TradeShop")) + " ")]) : _vm._e(), _vm.listing.accomodation ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Accomodation")) + " ")]) : _vm._e(), _vm.listing.services ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Services")) + " ")]) : _vm._e(), _vm.listing.warehouse ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("WareHouse")) + " ")]) : _vm._e(), _vm.listing.production ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("Production")) + " ")]) : _vm._e(), _vm.listing.foodServices ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("FoodServices")) + " ")]) : _vm._e(), _vm.listing.notDefined ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("NotDefined")) + " ")]) : _vm._e(), _vm.listing.warehouseProduction ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("WareHouseProduction")) + " ")]) : _vm._e(), _vm.listing.otherUsePossible ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("OtherUsePossible")) + " ")]) : _vm._e()])]) : _vm._e(), _vm.listing.estateType == 'land' && (_vm.listing.commercialLand || _vm.listing.industrialLand || _vm.listing.productionLand || _vm.listing.forestLand || _vm.listing.residentialLand || _vm.listing.profityieldingLand || _vm.listing.residentialLandApartment || _vm.listing.otherUse) ? _c('v-row', {
    staticClass: "listing-field-row top16",
    staticStyle: {
      "gap": "0px"
    }
  }, [_c('p', {
    staticClass: "field-name"
  }, [_vm._v(_vm._s(_vm.$t("Purpose")))]), _c('div', {
    staticStyle: {
      "padding-left": "24px"
    }
  }, [_vm.listing.commercialLand ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("CommercialLand")) + " ")]) : _vm._e(), _vm.listing.industrialLand ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("IndustrialLand")) + " ")]) : _vm._e(), _vm.listing.productionLand ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("ProductionLand")) + " ")]) : _vm._e(), _vm.listing.forestLand ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("ForestLand")) + " ")]) : _vm._e(), _vm.listing.residentialLand ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("ResidentialLand")) + " ")]) : _vm._e(), _vm.listing.profityieldingLand ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("ProfitYieldingLand")) + " ")]) : _vm._e(), _vm.listing.residentialLandApartment ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("ResidentialLandApartments")) + " ")]) : _vm._e(), _vm.listing.otherUse ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(" " + _vm._s(_vm.$t("OtherUse")) + " ")]) : _vm._e()])]) : _vm._e()], 1), _c('ExtraInfo', {
    attrs: {
      "listing": _vm.listing
    }
  }), _c('DescriptionArea', {
    attrs: {
      "listing": _vm.listing
    }
  }), _c('div', [_vm.listing.videoLink ? _c('v-row', {
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("VideoLink")))])]) : _vm._e(), _vm.listing.videoLink ? _c('a', {
    staticClass: "field-value",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openVideoLink(_vm.listing.videoLink);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.videoLink) + " ")]) : _vm._e(), _vm.listing.virtualTourLink ? _c('v-row', {
    staticStyle: {
      "margin-top": "24px"
    }
  }, [_c('h6', [_vm._v(_vm._s(_vm.$t("VirtualTourLink")))])]) : _vm._e(), _c('a', {
    staticClass: "field-value",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.openVideoLink(_vm.listing.virtualTourLink);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.listing.virtualTourLink) + " ")])], 1), _c('div', {
    staticStyle: {
      "padding-top": "32px"
    },
    attrs: {
      "id": "map"
    }
  }, [_c('GmapMap', {
    staticStyle: {
      "width": "100%",
      "height": "300px"
    },
    attrs: {
      "center": _vm.mapCoordinates,
      "zoom": 18,
      "map-type-id": "terrain"
    }
  }, [_c('GmapMarker', {
    attrs: {
      "id": "marker",
      "position": _vm.markerCoordinates,
      "clickable": true,
      "draggable": true
    }
  })], 1)], 1), _vm.mobileView ? _c('button', {
    staticClass: "back-button",
    on: {
      "click": _vm.closePanel
    }
  }, [_c('img', {
    staticClass: "img16",
    staticStyle: {
      "filter": "invert(1)"
    },
    attrs: {
      "src": require("../../assets/images/chevron-left.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "content-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")])]) : _vm._e()], 1) : _vm._e()]), _vm.confirmModalOpen ? _c('ConfirmationModal', {
    on: {
      "onConfirm": _vm.createNewId,
      "onCancel": function () {
        return _vm.confirmModalOpen = false;
      }
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t("CreateNewId")) + " ")])]) : _vm._e(), _vm.statusesOpen ? _c('StatusDropdown', {
    attrs: {
      "position": _vm.position,
      "listing": _vm.listing
    },
    on: {
      "closed": function ($event) {
        _vm.statusesOpen = false;
      },
      "statusChanged": _vm.changeListingStatus,
      "open-booking-modal": _vm.openBookingDetailsModal
    }
  }) : _vm._e(), _vm.isAddBrokerOpen ? _c('AddBrokerModal', {
    key: _vm.updater,
    attrs: {
      "item": _vm.listing,
      "listing-modal": true
    },
    on: {
      "update:item": function ($event) {
        _vm.listing = $event;
      },
      "close-modal": function ($event) {
        _vm.isAddBrokerOpen = false;
      },
      "updated": function ($event) {
        return _vm.setupListing();
      },
      "addSharedOwner": _vm.handleAddSharedOwner,
      "transferListingOwnership": _vm.handleTransferListing,
      "removeSharedOwner": _vm.handleSharedBrokerRemoved
    }
  }) : _vm._e(), _vm.transferConfirmationOpen ? _c('ConfirmationModal', {
    on: {
      "onCancel": _vm.handleConfirmationCancel,
      "onConfirm": function ($event) {
        return _vm.transferListingOwnerhsip();
      }
    }
  }, [_c('div', {
    staticClass: "confirmation-wrapper"
  }, [_c('div', {
    staticClass: "confirmation-header"
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', [_vm._v(" " + _vm._s(_vm.$t("TransferingOwner")) + " ")])]), _c('div', {
    staticClass: "new-shared-broker"
  }, [_vm._v(" " + _vm._s(_vm.transferedSharedBroker) + " ")]), _c('div', [_c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeOwnerOfListingsProject'),
      "type": "number"
    },
    model: {
      value: _vm.transferProject,
      callback: function ($$v) {
        _vm.transferProject = $$v;
      },
      expression: "transferProject"
    }
  })], 1)])])]) : _vm._e(), _vm.addSharedOwnerConfirmationOpen ? _c('ConfirmationModal', {
    on: {
      "onCancel": function ($event) {
        _vm.addSharedOwnerConfirmationOpen = false;
      },
      "onConfirm": _vm.confirmAddSharedOwner
    }
  }, [_c('div', {
    staticClass: "confirmation-wrapper"
  }, [_c('div', {
    staticClass: "confirmation-header"
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "add-shared-owner"
  }, [_vm._v(" " + _vm._s(_vm.$t("AddSharedOwner")) + " ")])]), _c('div', {
    staticClass: "new-shared-broker"
  }, [_vm._v(" " + _vm._s(_vm.newSharedBroker.name) + " ")]), _c('div', [_c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeOwnerOfListingsProject'),
      "type": "number"
    },
    model: {
      value: _vm.transferProject,
      callback: function ($$v) {
        _vm.transferProject = $$v;
      },
      expression: "transferProject"
    }
  })], 1)])])]) : _vm._e(), _vm.removeSharedOwnerConfirmationOpen ? _c('ConfirmationModal', {
    on: {
      "onCancel": function ($event) {
        _vm.removeSharedOwnerConfirmationOpen = false;
      },
      "onConfirm": _vm.confirmRemoveSharedOwner
    }
  }, [_c('div', {
    staticClass: "confirmation-wrapper"
  }, [_c('div', {
    staticClass: "confirmation-header"
  }, [_c('img', {
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "src": require(`@/assets/images/info.svg`),
      "alt": ""
    }
  }), _c('div', {
    staticClass: "add-shared-owner"
  }, [_vm._v(" " + _vm._s(_vm.$t("RemoveSharedOwner")) + " ")])]), _c('div', {
    staticClass: "new-shared-broker"
  }, [_vm._v(" " + _vm._s(_vm.removedSharedBroker) + " ")]), _c('div', [_c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ChangeOwnerOfListingsProject'),
      "type": "number"
    },
    model: {
      value: _vm.transferProject,
      callback: function ($$v) {
        _vm.transferProject = $$v;
      },
      expression: "transferProject"
    }
  })], 1)])])]) : _vm._e(), _vm.isLinkListing ? _c('LinkListingModal', {
    key: _vm.updater,
    attrs: {
      "item": _vm.listing,
      "linked-entity": _vm.relatedProject,
      "disableClickOutside": _vm.linkConfirmation
    },
    on: {
      "update:item": function ($event) {
        _vm.listing = $event;
      },
      "close-modal": function ($event) {
        _vm.isLinkListing = false;
      },
      "new-link": _vm.handleNewLink
    }
  }) : _vm._e(), _vm.linkConfirmation ? _c('ConfirmationModal', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      },
      "onConfirm": function ($event) {
        return _vm.handleLinkConfirm(_vm.selectedItem);
      },
      "onCancel": function () {
        return _vm.linkConfirmation = false;
      }
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t("LinkWithProject")))]), _c('h5', {
    staticClass: "title-text",
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "padding-top": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedItem.building ? _vm.selectedItem.building : _vm.selectedItem.name) + " ")])])]) : _vm._e(), _vm.brokerDetailOpened ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.clickedBroker,
      "position": _vm.brokerPanelPosition
    },
    on: {
      "closed": _vm.closeBrokerDetailPanel
    }
  }) : _vm._e(), _vm.bookingDetailsModalOpen ? _c('BookingDetailsModal', {
    attrs: {
      "opened-listing": _vm.bookedListing
    },
    on: {
      "update-listing": _vm.changeListingStatus,
      "close-modal": _vm.closeBookingDetailsModal
    }
  }) : _vm._e(), _c('OutputsModal', {
    attrs: {
      "toggle-modal": _vm.toggleOutputsModal,
      "listing": _vm.listing,
      "position": _vm.modalPosition
    },
    on: {
      "updateStatuses": _vm.updateFromModal
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }