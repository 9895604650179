<template>
  <div class="settings-wrapper">
    <div class="setting-row">
      <div class="setting-header">
        <div class="content-semibold">
          {{ $t("Interest") }} {{ $t("Status").toLocaleLowerCase() }}
        </div>
        <BaseToggle
          v-model="interest.statusCode"
          @click="$emit('changeInterestStatus', interest.statusCode, interest)"
          :label="$t('Active')"
        />
      </div>
      <div class="setting-description content-small">
        {{ $t("InterestStatusDescription") }}
      </div>
    </div>

    <div class="setting-row">
      <div class="setting-header">
        <div class="content-semibold">{{ $t("Wide") }} {{ $t("search") }}</div>
        <BaseToggle
          v-model="interest.wideSearch"
          @click="
            $emit('changeInterestWideSearch', interest.wideSearch, interest)
          "
          :label="$t('Active')"
          :disabled="!interest.statusCode"
        />
      </div>
      <div class="setting-description content-small">
        {{ $t("InterestWideSearchDescription") }}
      </div>
    </div>
    <div class="setting-row" v-if="projectId">
      <div class="setting-header">
        <div class="content-semibold">{{ $t("ExportToWWW") }}</div>
        <BaseToggle
          class="output-toggle"
          :value="
            interest.marketplaces.find((market) => market.name === 'www')
              .isActive
          "
          :label="$t('Active')"
          @change="toggleOutput()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseToggle from "@/components/common/BaseToggle";
export default {
  name: "InterestSettings",
  components: { BaseToggle },
  props: ["interest", "projectId"],
  methods: {
    toggleOutput() {
      const marketplaceIndex = this.interest.marketplaces.findIndex(
        (market) => market.name === "www"
      );
      this.interest.marketplaces[marketplaceIndex].isActive =
        !this.interest.marketplaces[marketplaceIndex].isActive;

      this.$emit("toggled", {
        interestId: this.interest._id,
        marketplaces: this.interest.marketplaces,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/global_variables";

.settings-wrapper {
  border-top: 1px solid #e6e8ec;
  padding: 24px;
  display: grid;
  gap: 24px;
}

.setting-description {
  color: $grey950 !important;
}

.setting-row {
  display: grid;
  gap: 4px;
}

.setting-header {
  display: flex;
  gap: 16px;
}
</style>
