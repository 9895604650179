<template>
  <div style="position: relative; width: 100%">
    <div v-if="defaultSearch">
      <div class="task-linked-to-search-input" :style="[ searchOpen ? '' : { borderRadius: '8px' } ]" @click="openSearch">
        <img :src="require('@/assets/images/search20.svg')" alt="" />
        <input
          :placeholder="placeholder"
          class="content-normal"
          v-model="searchItemText"
          ref="searchInput"
        />
      </div>
    </div>
    <div v-else-if="smallSearch">
      <div class="small-search-input" @click="openSearch">
        <img
          :src="require('@/assets/images/add_16_16.svg')"
        />
        <input
          :placeholder="placeholder"
          v-model="searchItemText"
          ref="searchInput"
        />
      </div>
    </div>
    <div v-else class="task-linked-to-search-content">
      <div class="task-linked-to-search-container" @click="openSearch">
        <img
          v-if="iconName"
          :src="require(`@/assets/images/${iconName}`)"
          alt=""
          :class="{
            'standard-icon': !booking,
            'booking-icon': booking,
          }"
        />
        <div
          :class="{ required: required }"
          class="task-linked-to-search-container-content"
        >
          <p v-if="chosenItemText"
            :class="{
              'colored-label label12': !booking,
              'link-small': booking,
            }"
          >
            {{ $t(label) }}
          </p>
          <p v-else class="link-small" style="font-size: 12px; line-height: 16px;">
            {{ "+ " + $t('Add') + " " + $t(label).toLowerCase()}}
          </p>
          <div class="content-normal">{{ chosenItemText }}</div>
        </div>
      </div>
      <img
        @click="emitClearValue"
        v-if="value && !disableClear"
        style="cursor: pointer"
        width="20"
        height="20"
        :src="require('@/assets/images/close_24_24.svg')"
        alt=""
      />
    </div>
    <div
      ref="linkedToSearchDropdown"
      v-if="searchOpen"
      v-click-outside="closeSearch"
      class="task-linked-to-search-dropdown" 
      :style="{ width: inputWidth }"
    >
      <div class="task-linked-to-search-input">
        <img :src="require('@/assets/images/search20.svg')" alt="" />
        <input
          :placeholder="placeholder"
          class="content-normal"
          v-model="searchItemText"
          ref="searchInput"
        />
        <img
          @click="emitClearValue"
          v-if="(value || searchItemText.length > 0) && !disableClear"
          style="cursor: pointer"
          width="20"
          height="20"
          :src="require('@/assets/images/close_24_24.svg')"
          alt=""
        />
      </div>
      <div class="task-linked-to-search-dropdown-items">
        <div
          @click="selectItem(item)"
          v-for="item of foundItems"
          class="task-linked-to-search-dropdown-item content-normal"
          :key="item._id"
        >
          <p :class="{ 'selected-item-text': item.id === value  }" class="mr-1">
            {{ item.name }}
          </p>
          <span
            style="color: #939597"
            v-if="modalType === 'client' && item.organization"
          >
            {{ " - " }}{{ item.organization }}
          </span>
          <span style="color: #939597" v-else-if="modalType === 'client'">
            {{ " - " }}{{ $t("PrivatePerson.Label").toLowerCase() }}
          </span>
          <span
            style="color: #939597; margin-left: 4px"
            v-if="modalType === 'client' && item.contactIsPartner"
          >
            ( Partner )
          </span>
        </div>
      </div>
      <div
        v-if="modalType === 'client'"
        class="task-linked-to-search-add-contact-button"
        @click="addButtonClicked"
      >
        <img src="../../assets/images/action-add_16_16.svg" alt="add-img" />
        {{ $t("AddContact") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TaskLinkedToSearch",
  props: [
    "value",
    "booking",
    "label",
    "iconName",
    "required",
    "items",
    "disableClear",
    "notFoundItemName",
    "modalType",
    "newContactSelected",
    "contactModalOpen",
    "defaultSearch",
    "customPlaceholder",
    "smallSearch",
    "inputWidth"
  ],
  data() {
    return {
      searchOpen: false,
      searchItemText: "",
      chosenItemText: "",
      foundItems: [],
      keepSearchOpen: false,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    contactModalOpen() {
      this.keepSearchOpen = true;
    },
    sidePanelContactAdd(val) {
      if (val) {
        this.keepSearchOpen = true;
      }
    },
    newAddedContact() {
      this.closeSearch();
    },
    newContactSelected() {
      this.closeSearch();
    },
    value(valueFromOutside) {
      this.handleValueChange(valueFromOutside);
    },
    searchItemText(searchValue) {
     this.foundItems = this.items.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.organization && item.organization.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.phoneNumber && item.phoneNumber.toLowerCase().includes(searchValue.toLowerCase())
      );
      this.$nextTick(() => this.handleDropdownOutOfViewport());
    },
  },
  created() {
    if (this.items) {
      this.foundItems = this.items;
    }
    this.handleValueChange(this.value);
    console.log('customPaceholder: ', this.customPlaceholder)
  },
  computed: {
    ...mapGetters(["sidePanelContactAdd", "newAddedContact"]),
    placeholder() {
      if (!this.customPlaceholder) {
        return this.$t(this.label + "'s") + " " + this.$t("search");
      } else {
        return this.customPlaceholder
      }

    },
  },
  methods: {
    ...mapActions(["toggleContactAddPanel"]),
    addButtonClicked() {
      this.$emit("addButtonClicked");
      this.toggleContactAddPanel();
    },
    handleDropdownOutOfViewport() {
      if (!this.$refs || !this.$refs.linkedToSearchDropdown) {
        return;
      }
      this.$refs.linkedToSearchDropdown.style.top = "0px";
      const dropdownContainer = this.$refs.linkedToSearchDropdown;
      const rect = dropdownContainer.getBoundingClientRect();
      if (
        rect.bottom >
        (window.innerHeight || document.documentElement.clientHeight)
      ) {
        this.$refs.linkedToSearchDropdown.style.top = `-${
          Math.floor(rect.bottom) - Math.floor(window.innerHeight)
        }px`;
      } else {
        this.$refs.linkedToSearchDropdown.style.top = `0px`;
      }
    },
    handleValueChange(valueFromOutside) {
      if (valueFromOutside) {
        const canFindItem = this.foundItems.find(
          (item) => item.id === valueFromOutside
        );
        let correctText = "";
        if (canFindItem) {
          correctText = this.foundItems.find(
            (item) => item.id === valueFromOutside
          ).name;
        } else {
          if (!this.items.find((item) => item.id === valueFromOutside)) {
            this.items.push({
              id: valueFromOutside,
              name: this.notFoundItemName,
            });
            correctText = this.notFoundItemName;
          }
          correctText = this.notFoundItemName;
          this.closeSearch();
        }
        this.chosenItemText = correctText;
        //this.searchItemText = correctText;
      } else {
        this.chosenItemText = "";
        this.searchItemText = "";
      }
    },
    selectItem(item) {
      this.closeSearch();
      this.$emit("change", item.id || item._id);
    },
    focusSearchInput() {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    emitClearValue() {
      if (this.value) {
        this.$emit("change", null);
      } else {
        this.searchItemText = "";
      }
    },
    openSearch() {
      this.searchOpen = true;
      this.focusSearchInput();
      this.$nextTick(() => {
        this.handleDropdownOutOfViewport();
      });
    },
    closeSearch() {
      if (!this.keepSearchOpen) {
        this.searchOpen = false;
      }
      this.keepSearchOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.task-linked-to-search-content {
  display: flex;
  align-items: center;
}

.task-linked-to-search-container {
  width: 100%;
  height: 100%;
  min-height: 20px;
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  cursor: pointer;
}

.task-linked-to-search-container-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.colored-label {
  color: $grey950 !important;
}

.standard-icon {
  width: 20px;
  height: 20px;
}

.booking-icon {
  width: 16px;
  height: 16px;
}

.task-linked-to-search-dropdown {
  position: absolute;
  background: white;
  top: 0;
  z-index: 1;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.03),
    0 0 1px rgba(0, 0, 0, 0.04);
}

.task-linked-to-search-input {
  height: 36px;
  border-radius: 8px 8px 0 0;
  padding: 6px 8px;
  border: 1px solid #e6e8ec;
  display: flex;
  gap: 8px;
  align-items: center;
}

.task-linked-to-search-input input {
  outline: none;
  border: none;
  height: 20px;
  width: 100%;
}

.small-search-input {
  height: 24px;
  border-radius: 8px 0 0 8px;
  padding: 6px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.small-search-input:hover{
  background-color:#f4f5f7;
}

.small-search-input input {
  outline: none;
  border: none;
  height: 24px;
  width: 100%;
  cursor: pointer;
}

.small-search-input input::placeholder {
  color: black;
}

.task-linked-to-search-dropdown-items {
  z-index: 2;
  max-height: 288px;
  overflow-y: scroll;
}

.task-linked-to-search-add-contact-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e6e8ec;
  border-radius: 0 0 5px 5px;
  padding: 8px 12px;
  cursor: pointer;
  color: black;
}

.task-linked-to-search-add-contact-button > img[alt="add-img"] {
  padding-right: 8px;
  height: 28px;
  width: 28px;
}

.task-linked-to-search-dropdown-item {
  display: flex;
  min-height: 36px;
  padding: 8px 12px;
  border-left: 1px solid #e6e8ec;
  border-right: 1px solid #e6e8ec;
  cursor: pointer;
}

.task-linked-to-search-dropdown-item:last-child {
  border-radius: 0 0 8px 8px;
}

.task-linked-to-search-dropdown-item:hover {
  background: #f4f5f7;
}

.required p::after {
  display: inline-block !important;
  text-decoration: unset;
}

.selected-item-text {
  font-weight: bold;
}
</style>
