import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import i18n from "./i18n/index";
import "@/mixins/toast";
import {createToastInterface, POSITION} from "vue-toastification";
import ToastComponent from "./components/common/Toast";
import "vue-toastification/dist/index.css";
import "@/mixins/microsoft";
import "@/mixins/nylas";
import "@/mixins/logger";
import "@/mixins/access";
import "@/mixins/sidepanels";
import "@/mixins/user";
import "@/helpers/index";
import VueHtmlToPaper from "vue-html-to-paper";
import * as VueGoogleMaps from "vue2-google-maps";
import browserDetect from "vue-browser-detect-plugin";
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);
Vue.use(browserDetect);
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyDcKJ-XoY_Jdp5mPgRkcyn0E8jW83LFBCU",
        libraries: "places",
    },
});

const toastContent = {
    component: ToastComponent,
    props: {
        type: "error",
        text: "Too many requests",
    },
};
const toastOptions = {
    position: POSITION.TOP_CENTER,
    timeout: 4000,
    toastClassName: "nice-toast",
    hideProgressBar: true,
    icon: false,
};
export const toast = createToastInterface(toastOptions, Vue);

Vue.config.productionTip = false;
Vue.config.devtools = false;

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        if (error.response.status === 401 || error.response.status === 502) {
            await store.dispatch("logout");
            await router.push("/login");
        }
        if (error.response.status === 429) {
            toast(toastContent, toastOptions);
        }
        return error.response;
    }
);
Vue.prototype.$http = axios;


/*
Vue.config.errorHandler = async (err, vm, info) => {
    console.error(err);
    const deviceInfo = window.navigator.userAgent;
    await axios.post('/api/log-error', {
        error: err.message,
        stack: err.stack,
        info: info,
        component: vm.$options.name || vm.$options._componentTag,
        deviceInfo: deviceInfo,
    }).then(() => {
        console.log('Server successfully informed of error.')
    }).catch((error) => {
        console.error('Failed to inform server of error:', error);
    });
}

 */

// VueHtmlToPaper
const options = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=no", "scrollbars=no"],
    styles: [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
        "https://unpkg.com/kidlat-css/css/kidlat.css",
        "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
        "/assets/App.scss",
        "/assets/ListingsFullDetail.scss",
        "/assets/GmapMap.scss",
        // "/assets/DocumentPreview.scss",
        // '/../src/assets/styles/ListingsFullDetail.scss'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: "testing",
    // windowTitle: window.document.title, // override the window title
};
Vue.use(VueHtmlToPaper, options);

let socketUrl = window.location.hostname;

if (socketUrl === "localhost") socketUrl = "http://localhost:4000";
Vue.use(
    new VueSocketIO({
        debug: false,
        connection: SocketIO(socketUrl, {
            path: "/socket",
            transports: ["websocket"],
        }), //options object is Optional
    })
);

new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
