var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-field-container",
    attrs: {
      "id": _vm.id
    }
  }, [_c('div', {
    key: _vm.id + '-' + _vm.fieldUpdater,
    ref: "container",
    staticClass: "form-field-large",
    style: _vm.fieldStyle,
    on: {
      "click": _vm.focusInputElement
    }
  }, [_vm.iconLeft ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    staticStyle: {
      "display": "block"
    },
    attrs: {
      "src": require(`@/assets/images/` + _vm.iconLeft),
      "alt": ""
    }
  }) : _vm._e(), _c('div', {
    class: {
      required: _vm.fieldRequired
    },
    staticStyle: {
      "display": "flex",
      "width": "100%",
      "flex-direction": "column"
    }
  }, [_vm.fieldPlaceholder ? _c('label', {
    ref: "label",
    staticClass: "content",
    style: _vm.labelStyle
  }, [_vm._v(_vm._s(_vm.fieldPlaceholder))]) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.fieldValue,
      expression: "fieldValue"
    }],
    ref: "input",
    staticClass: "input-field content",
    attrs: {
      "disabled": _vm.fieldDisabled
    },
    domProps: {
      "value": _vm.fieldValue
    },
    on: {
      "focusin": _vm.focusInputElementPlaceholder,
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDropdown.apply(null, arguments);
      },
      "focusout": _vm.unfocusInput,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.fieldValue = $event.target.value;
      }
    }
  })]), this.fieldValue ? _c('div', {
    staticClass: "remove-button",
    on: {
      "click": function ($event) {
        return _vm.selectDropdownitem(null);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/close.svg`),
      "width": "24",
      "height": "24"
    }
  })]) : _vm._e(), _vm.fieldDropdownArrow ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    attrs: {
      "src": require(`@/assets/images/dropdown_20_20.svg`),
      "alt": ""
    }
  }) : _vm._e(), _vm.iconRight ? _c('img', {
    class: {
      'icon-disabled': _vm.fieldDisabled
    },
    attrs: {
      "src": require(`@/assets/images/` + _vm.iconRight),
      "alt": ""
    }
  }) : _vm._e()]), _vm.fieldDropdownOpen || _vm.fieldDropdownOpen && _vm.fieldDropdownAddNew ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickoutside,
      expression: "clickoutside"
    }],
    ref: "dropdown",
    staticClass: "dropdown-container",
    style: _vm.fieldDropdownStyle
  }, [_vm.fieldDropdownItems.length > 0 ? _c('div', {
    class: {
      'dropdown-medium': _vm.fieldDropdownAllowNull,
      'dropdown-small': _vm.fieldDropdownAllowNull && _vm.fieldDropdownAddNew
    },
    staticStyle: {
      "position": "relative",
      "overflow-y": "auto",
      "max-height": "200px !important"
    }
  }, _vm._l(_vm.fieldDropdownItems, function (item, index) {
    return _c('div', {
      key: item.value ? item.value : index,
      staticClass: "content dropdown-item",
      staticStyle: {
        "display": "flex",
        "flex-wrap": "nowrap"
      },
      attrs: {
        "tabindex": "0",
        "id": _vm.id + '-dropdown-item-' + index
      },
      on: {
        "keyup": [function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
          return _vm.closeDropdown.apply(null, arguments);
        }, function ($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
          return _vm.selectDropdownitem(item);
        }],
        "click": function ($event) {
          return _vm.selectDropdownitem(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0) : _vm.fieldValue && _vm.fieldDropdownItems.length === 0 ? _c('div', {
    staticStyle: {
      "background-color": "white"
    }
  }, [_c('p', {
    staticClass: "content no-results"
  }, [_vm._v(_vm._s(_vm.$t("NoResultsFound")))])]) : _vm._e(), _vm.fieldDropdownAddNew ? _c('div', {
    staticClass: "dropdown-item add-new",
    staticStyle: {
      "display": "flex",
      "gap": "8px",
      "justify-content": "center"
    },
    attrs: {
      "tabindex": "0"
    },
    on: {
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        return _vm.closeDropdown.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.dropdownAddNewButton.apply(null, arguments);
      }],
      "click": _vm.dropdownAddNewButton
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/action-add_16_16.svg`),
      "width": "22",
      "height": "22"
    }
  }), _c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$t("AddNew")))])]) : _vm._e()]) : _vm._e(), _vm.fieldError ? _c('div', {
    staticClass: "content error-message",
    staticStyle: {
      "margin": "12px 12px 0 0"
    }
  }, [_vm._v(" " + _vm._s(_vm.fieldErrorMessage ? _vm.fieldErrorMessage : _vm.$t("FormErrors.FieldRequired")) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }