var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delete-overlay"
  }, [_c('div', {
    staticClass: "main-wrapper"
  }, [!_vm.removing && !_vm.customMessage ? _c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$t("ConfirmDelete")))]) : _vm._e(), _vm.removing && !_vm.customMessage ? _c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.$t("ConfirmRemove")))]) : _vm._e(), _vm.customMessage ? _c('p', {
    staticClass: "content"
  }, [_vm._v(_vm._s(_vm.customMessage))]) : _vm._e(), _c('div', {
    staticClass: "buttons-row"
  }, [_c('button', {
    staticClass: "cancel",
    attrs: {
      "id": "cancel-button"
    },
    on: {
      "click": _vm.canceled
    }
  }, [_vm._v(_vm._s(_vm.$t("Cancel")))]), !_vm.removing ? _c('button', {
    staticClass: "delete-button",
    attrs: {
      "id": "delete-button"
    },
    on: {
      "click": _vm.approved
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "30px",
      "height": "30px"
    }
  }) : _vm._e(), !_vm.isLoading ? _c('p', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(_vm._s(_vm.$t("Delete")))]) : _vm._e()]) : _vm._e(), _vm.removing ? _c('button', {
    staticClass: "delete-button",
    staticStyle: {
      "color": "white"
    },
    on: {
      "click": _vm.approved
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Remove")) + " ")]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }