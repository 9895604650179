import Vue from "vue";
import i18n from "../../i18n/index";

const pfMapper = {
  pipelineStep(pipelineStep) {
    switch (pipelineStep) {
      case "qualified":
        return i18n.t("NewLeads");
      case "contactMade":
        return i18n.t("NegotiatingRepresentation");
      case "preparation":
        return i18n.t("Preparation");
      case "review":
        return i18n.t("ShowingAndAdvertising");
      case "Finished":
        return i18n.t("FinalizingDeal");
      case "Done":
        return i18n.t("DoneDone");
      case "archived":
        return i18n.t("Archived");
      default:
        return "-";
    }
  },
};

const plugin = {
  install() {
    Vue.lfMapper = pfMapper;
    Vue.prototype.$pfMapper = pfMapper;
  },
};

Vue.use(plugin);
