var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sub-block"
  }, [!_vm.showButtons ? _c('h5', {
    staticClass: "sub-heading",
    staticStyle: {
      "padding-left": "0px",
      "padding-bottom": "32px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Files")) + " "), !_vm.clientSidepanelOpen ? _c('v-row', {
    staticStyle: {
      "padding-left": "8px",
      "max-width": "32px",
      "max-height": "16px"
    }
  }, [_c('div', {
    staticClass: "counter-icon"
  }, [_c('p', {
    staticClass: "content-small",
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(_vm._s(_vm.fileCounter))])])]) : _vm._e(), !_vm.hideButton ? _c('label', {
    staticClass: "add-notes",
    class: {
      'right-corner-location': _vm.buttonCornerLocation,
      'near-subheading-location': !_vm.buttonCornerLocation
    },
    attrs: {
      "for": _vm.id,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.uploadFiles($event);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/plus.svg"),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "add-note-inside"
  }, [_vm._v(_vm._s(_vm.$t("AddFiles")))])]) : _vm._e()], 1) : _vm._e(), _vm.isUploading ? _c('div', {
    staticClass: "overlay"
  }, [_vm._m(0)]) : _vm._e(), _vm._l(_vm.refactoredFiles, function (el, index) {
    return _c('v-row', {
      key: index,
      staticClass: "file-row"
    }, [_c('span', {
      staticStyle: {
        "display": "flex",
        "gap": "8px"
      }
    }, [!el.amlId ? _c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "src": require("../../assets/images/action-delete.svg"),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          _vm.isDeleteModalOpen = true;
          _vm.fileWaitingDeletion = el;
        }
      }
    }) : _vm._e(), _c('span', {
      staticClass: "file-name",
      on: {
        "click": function ($event) {
          return _vm.previewFile(el.fileName, el.amlId);
        }
      }
    }, [_vm._v(" " + _vm._s(el.fileName) + " ")])]), _c('span', {
      staticClass: "content-small",
      staticStyle: {
        "font-weight": "400",
        "color": "#75787a",
        "gap": "8px"
      }
    }, [_c('span', [_vm._v(" " + _vm._s(el.uploaderName) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.moment(el.createdAt)) + " ")])])]);
  }), _c('input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "id": _vm.id,
      "multiple": ""
    },
    on: {
      "change": _vm.uploadFiles
    }
  }), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": function ($event) {
        return _vm.deleteFile(_vm.fileWaitingDeletion);
      }
    }
  }) : _vm._e(), _c('a', {
    attrs: {
      "href": "",
      "id": "download-link",
      "download": ""
    }
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay__inner"
  }, [_c('div', {
    staticClass: "overlay__content"
  }, [_c('span', {
    staticClass: "spinner"
  })])]);

}]

export { render, staticRenderFns }