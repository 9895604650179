var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "12px"
    }
  }, [_c('div', {
    staticClass: "invoice-sharing-row",
    staticStyle: {
      "align-items": "start"
    }
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('SumBeforeVAT'),
      "error": _vm.error.showError,
      "error-message": _vm.error.errorMessage,
      "type": "number",
      "required": true,
      "icon-right": 'euroSign.svg'
    },
    model: {
      value: _vm.invoiceSum,
      callback: function ($$v) {
        _vm.invoiceSum = $$v;
      },
      expression: "invoiceSum"
    }
  }), _c('div', {
    staticStyle: {
      "padding": "12px"
    }
  }, [_c('div', {
    staticClass: "invoice-sharing-row-sum content-semibold"
  }, [_vm._v(" " + _vm._s(`${_vm.$t("TotalSumWithVAT")}: ${_vm.$nMapper.price(_vm.invoiceSumVat, "€")}`) + " ")])])], 1), _vm.invoiceSharedUsers.length > 0 ? _c('div', {
    staticClass: "invoice-sharing-toggle"
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('SplitUsingAmounts')
    },
    on: {
      "change": function (value) {
        return _vm.updateInvoicePreferences(value);
      }
    },
    model: {
      value: _vm.useAmountsInsteadOfPercentage,
      callback: function ($$v) {
        _vm.useAmountsInsteadOfPercentage = $$v;
      },
      expression: "useAmountsInsteadOfPercentage"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "invoice-sharing-row"
  }, [_c('GroupOrUserSelect', {
    attrs: {
      "value": {
        type: 'user',
        value: _vm.invoiceOwner._id
      },
      "size": "large",
      "labelTranslation": "InvoiceOwner",
      "allow-empty": false
    },
    on: {
      "change": function (value) {
        return _vm.userSelected(value);
      }
    }
  }), _vm.invoiceSharedUsers.length > 0 && _vm.useAmountsInsteadOfPercentage ? _c('div', {
    staticClass: "invoice-sharing-row-data"
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Share'),
      "type": "number",
      "icon-right": 'euroSign.svg'
    },
    on: {
      "click": function ($event) {
        return _vm.editUserValues(_vm.invoiceOwner);
      },
      "change": function ($event) {
        return _vm.checkTypeChange(_vm.invoiceOwner);
      },
      "blur": function ($event) {
        return _vm.setBackToEqual(_vm.invoiceOwner);
      }
    },
    model: {
      value: _vm.invoiceOwner.share.sum,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceOwner.share, "sum", $$v);
      },
      expression: "invoiceOwner.share.sum"
    }
  })], 1) : _vm._e(), _vm.invoiceSharedUsers.length > 0 && !_vm.useAmountsInsteadOfPercentage ? _c('div', {
    staticClass: "invoice-sharing-row-shared"
  }, [_c('BaseInput', {
    attrs: {
      "placeholder": _vm.$t('Share'),
      "type": "number",
      "icon-right": 'percentage.svg'
    },
    on: {
      "click": function ($event) {
        return _vm.editUserValues(_vm.invoiceOwner);
      },
      "change": function ($event) {
        return _vm.checkTypeChange(_vm.invoiceOwner);
      },
      "blur": function ($event) {
        return _vm.setBackToEqual(_vm.invoiceOwner);
      }
    },
    model: {
      value: _vm.invoiceOwner.share.percentage,
      callback: function ($$v) {
        _vm.$set(_vm.invoiceOwner.share, "percentage", $$v);
      },
      expression: "invoiceOwner.share.percentage"
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "padding": "12px"
    }
  }, [_vm.invoiceOwner.share.sum ? _c('div', {
    staticClass: "content-semibold",
    staticStyle: {
      "width": "fit-content",
      "height": "24px",
      "padding": "2px 4px",
      "background-color": "#fff0d4",
      "border-radius": "4px",
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.formatFixed(_vm.invoiceOwner.share.sum, 2), "€")) + " ")]) : _vm._e()])], 1) : _vm._e()], 1), _vm._l(_vm.invoiceSharedUsers, function (user, index) {
    return _c('div', {
      key: 'invoice-additional-user-' + index,
      staticClass: "invoice-sharing-row"
    }, [_c('GroupOrUserSelect', {
      attrs: {
        "value": user._id ? {
          type: 'user',
          value: user._id
        } : null,
        "emptyValueLabelTranslation": "ThirdParty",
        "size": "large",
        "labelTranslation": "BrokerName"
      },
      on: {
        "change": function (value) {
          return _vm.userSelected(value, index);
        }
      }
    }), _vm.useAmountsInsteadOfPercentage ? _c('div', [_c('BaseInput', {
      attrs: {
        "placeholder": _vm.$t('Share'),
        "type": "number",
        "icon-right": 'euroSign.svg'
      },
      on: {
        "click": function ($event) {
          return _vm.editUserValues(user, index);
        },
        "change": function ($event) {
          return _vm.checkTypeChange(user, index);
        },
        "blur": function ($event) {
          return _vm.setBackToEqual(user);
        }
      },
      model: {
        value: user.share.sum,
        callback: function ($$v) {
          _vm.$set(user.share, "sum", $$v);
        },
        expression: "user.share.sum"
      }
    })], 1) : _c('div', {
      staticClass: "invoice-sharing-row-shared"
    }, [_c('BaseInput', {
      attrs: {
        "placeholder": _vm.$t('Share'),
        "type": "number",
        "icon-right": 'percentage.svg'
      },
      on: {
        "click": function ($event) {
          return _vm.editUserValues(user, index);
        },
        "change": function ($event) {
          return _vm.checkTypeChange(user, index);
        },
        "blur": function ($event) {
          return _vm.setBackToEqual(user);
        }
      },
      model: {
        value: user.share.percentage,
        callback: function ($$v) {
          _vm.$set(user.share, "percentage", $$v);
        },
        expression: "user.share.percentage"
      }
    }), _c('div', {
      staticStyle: {
        "display": "flex",
        "align-items": "center",
        "padding": "12px"
      }
    }, [user.share.sumVAT ? _c('div', {
      staticClass: "content-semibold",
      staticStyle: {
        "width": "fit-content",
        "height": "24px",
        "padding": "2px 4px",
        "background-color": "#fff0d4",
        "border-radius": "4px",
        "white-space": "nowrap"
      }
    }, [_vm._v(" " + _vm._s(_vm.$nMapper.price(_vm.formatFixed(user.share.sum, 2), "€")) + " ")]) : _vm._e()])], 1), _c('div', {
      staticStyle: {
        "width": "24px",
        "position": "absolute",
        "right": "-40px",
        "height": "24px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.removeUserFromInvoice(index);
        }
      }
    }, [_c('img', {
      attrs: {
        "height": "24",
        "width": "24",
        "src": require('@/assets/images/close_red_24_24.svg'),
        "alt": ""
      }
    })])], 1);
  }), _c('button', {
    staticClass: "invoice-sharing-add-user",
    on: {
      "click": _vm.addUserToInvoice
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/users.svg'),
      "alt": ""
    }
  }), _c('span', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("+AddAnotherBroker")))])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }