var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tasks-and-events-container"
  }, [_vm.showToggleButton ? _c('div', {
    class: {
      'tasks-header': _vm.tasks.length > 0 || _vm.showNoTasksMessage
    }
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('ShowCompleted')
    },
    model: {
      value: _vm.showCompletedTasks,
      callback: function ($$v) {
        _vm.showCompletedTasks = $$v;
      },
      expression: "showCompletedTasks"
    }
  })], 1) : _vm._e(), _vm.tasksLoaded && _vm.tasks.length > 0 ? _c('div', {
    key: 'tasks-' + _vm.tasksKeyUpdater,
    staticClass: "tasks-content"
  }, [_vm.sortedTasks[_vm.findTaskCollectionByOrder(1)[0]].length > 0 ? _c('div', {
    staticClass: "tasks-content-section",
    class: {
      'section-label-present-bottom': _vm.findTaskCollectionByOrder(1)[1].label && !_vm.findTaskCollectionByOrder(2)[1].label
    }
  }, [_vm.findTaskCollectionByOrder(1)[1].label ? _c('div', {
    staticClass: "label12"
  }, [_vm._v(" " + _vm._s(_vm.$t(`${_vm.returnLabelTranslation(_vm.findTaskCollectionByOrder(1)[0])}`)) + " ")]) : _vm._e(), _vm._l(_vm.sortedTasks[_vm.findTaskCollectionByOrder(1)[0]], function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task,
        "end-date-passed": _vm.findTaskCollectionByOrder(1)[0] === 'overDueTasks'
      },
      on: {
        "taskUpdated": _vm.emitNewTasks,
        "addParticipants": function (value) {
          return _vm.openTaskAddWidget(value, 'participants');
        },
        "openTaskViewWidget": _vm.openTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e(), _vm.sortedTasks[_vm.findTaskCollectionByOrder(2)[0]].length > 0 ? _c('div', {
    staticClass: "tasks-content-section",
    class: {
      'section-label-present': _vm.findTaskCollectionByOrder(2)[1].label,
      'section-label-present-bottom': _vm.findTaskCollectionByOrder(2)[1].label && !_vm.findTaskCollectionByOrder(3)[1].label
    }
  }, [_vm.findTaskCollectionByOrder(2)[1].label ? _c('div', {
    staticClass: "label12"
  }, [_vm._v(" " + _vm._s(_vm.$t(`${_vm.returnLabelTranslation(_vm.findTaskCollectionByOrder(2)[0])}`)) + " ")]) : _vm._e(), _vm._l(_vm.sortedTasks[Object.entries(_vm.labelsAndOrder).find(function (item) {
    return item[1].order === 2;
  })[0]], function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task,
        "end-date-passed": Object.entries(_vm.labelsAndOrder).find(function (item) {
          return item[1].order === 2;
        })[0] === 'overDueTasks'
      },
      on: {
        "taskUpdated": _vm.emitNewTasks,
        "addParticipants": function (value) {
          return _vm.openTaskAddWidget(value, 'participants');
        },
        "openTaskViewWidget": _vm.openTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e(), _vm.sortedTasks[_vm.findTaskCollectionByOrder(3)[0]].length > 0 ? _c('div', {
    staticClass: "tasks-content-section",
    class: {
      'section-label-present': _vm.findTaskCollectionByOrder(3)[1].label,
      'section-label-present-bottom': _vm.findTaskCollectionByOrder(3)[1].label && !_vm.findTaskCollectionByOrder(4)[1].label
    }
  }, [_vm.findTaskCollectionByOrder(3)[1].label ? _c('div', {
    staticClass: "label12"
  }, [_vm._v(" " + _vm._s(_vm.$t(`${_vm.returnLabelTranslation(_vm.findTaskCollectionByOrder(3)[0])}`)) + " ")]) : _vm._e(), _vm._l(_vm.sortedTasks[_vm.findTaskCollectionByOrder(3)[0]], function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task,
        "end-date-passed": _vm.findTaskCollectionByOrder(3)[0] === 'overDueTasks'
      },
      on: {
        "taskUpdated": _vm.emitNewTasks,
        "addParticipants": function (value) {
          return _vm.openTaskAddWidget(value, 'participants');
        },
        "openTaskViewWidget": _vm.openTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e(), _vm.sortedTasks[_vm.findTaskCollectionByOrder(4)[0]].length > 0 ? _c('div', {
    staticClass: "tasks-content-section",
    class: {
      'section-label-present': _vm.findTaskCollectionByOrder(4)[1].label
    }
  }, [_vm.findTaskCollectionByOrder(4)[1].label ? _c('div', {
    staticClass: "label12"
  }, [_vm._v(" " + _vm._s(_vm.$t(`${_vm.returnLabelTranslation(_vm.findTaskCollectionByOrder(4)[0])}`)) + " ")]) : _vm._e(), _vm._l(_vm.sortedTasks[_vm.findTaskCollectionByOrder(4)[0]], function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task,
        "end-date-passed": _vm.findTaskCollectionByOrder(4)[0] === 'overDueTasks'
      },
      on: {
        "taskUpdated": _vm.emitNewTasks,
        "addParticipants": function (value) {
          return _vm.openTaskAddWidget(value, 'participants');
        },
        "openTaskViewWidget": _vm.openTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e()]) : _vm._e(), _vm.tasksLoaded && _vm.tasks.length < 1 && _vm.showNoTasksMessage ? _c('div', {
    staticClass: "tasks-content-empty"
  }, [_c('div', {
    staticClass: "subheading"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoTasksEvents")) + " ")]), _c('div', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("Click")) + " "), _c('span', {
    staticStyle: {
      "font-weight": "bold",
      "padding": "0 4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("ToAddNewTaskOrEvent")) + " ")])]) : _vm._e(), !_vm.tasksLoaded ? _c('div', {
    staticClass: "tasks-content-empty"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e(), _vm.taskAddWidgetOpen ? _c('TaskAddWidget', {
    attrs: {
      "focus-field": _vm.focusOnTaskAddField,
      "new-task-time": _vm.taskAddWidgetTaskTime,
      "linked-object": {
        name: _vm.view.name,
        id: _vm.view.itemId,
        itemName: _vm.view.itemName
      },
      "task-to-edit": _vm.taskToEdit
    },
    on: {
      "closeWidget": _vm.closeTaskAddWidget,
      "getTasks": _vm.getTasks,
      "clearFocusField": function ($event) {
        _vm.focusOnTaskAddField = null;
      }
    }
  }) : _vm._e(), _vm.taskViewWidgetOpen && !_vm.isDeleteModalOpen ? _c('TaskViewWidget', {
    attrs: {
      "task": _vm.taskViewWidgetValue
    },
    on: {
      "taskUpdated": _vm.emitNewTasks,
      "closeWidget": _vm.closeTaskViewWidget,
      "openProjectSidepanel": _vm.openProject,
      "openContactSidepanel": _vm.openContactSidepanel,
      "addParticipants": function (value) {
        return _vm.openTaskAddWidget(value, 'participants');
      },
      "openDeleteModal": _vm.openDeleteModal,
      "openTask": _vm.openParentTask,
      "editTask": _vm.openTaskAddWidget
    }
  }) : _vm._e(), _vm.isDeleteModalOpen ? _c('DeleteModal', {
    on: {
      "canceled": function ($event) {
        _vm.isDeleteModalOpen = false;
      },
      "approved": _vm.deleteTask
    }
  }) : _vm._e(), _vm.selectedContactId ? _c('ClientSidepanel', {
    attrs: {
      "name": _vm.view.name,
      "customer-data": _vm.selectedContactId
    },
    on: {
      "updated": _vm.getTasks,
      "panelClosed": function ($event) {
        _vm.selectedContactId = null;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }