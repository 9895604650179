import axios from "axios";

const propDev = {
  state: {
    development: null,
    prospects: {
      prospects: [],
      refresher: 0,
      editMode: false,
      addRow: false,
      addAnotherProspectSubmit: 0,
      saveProspectSubmit: 0,
      updateProspectsSubmit: 0,
      selected: [],
    },
  },
  mutations: {
    TOGGLE_PROSPECTS_EDIT_MODE(state) {
      state.prospects.editMode = !state.prospects.editMode;
    },
    CANCEL_PROSPECTS_EDIT_MODE(state) {
      state.prospects.editMode = false;
    },
    TOGGLE_PROSPECTS_ADD_ROW(state) {
      state.prospects.addRow = !state.prospects.addRow;
    },
    CANCEL_PROSPECTS_ADD_ROW(state) {
      state.prospects.addRow = false;
    },
    SAVE_AND_ADD_ANOTHER(state) {
      state.prospects.addAnotherProspectSubmit++;
    },
    SAVE_PROSPECT(state) {
      state.prospects.saveProspectSubmit++;
    },
    SET_SELECTED_PROSPECTS(state, prospects) {
      state.prospects.selected = prospects;
      console.log("SET_SELECTED_PROSPECTS", prospects);
    },
    SET_PROSPECTS(state, prospects) {
      state.prospects.prospects = prospects;
      state.prospects.refresher++;
    },
    SET_DEVELOPMENT(state, development) {
      state.development = development;
    },
    UPDATE_PROSPECTS(state) {
      state.prospects.updateProspectsSubmit++;
    },
  },
  actions: {
    async getDevelopment({ commit }, developmentId) {
      const response = await axios.get(`/api/development/${developmentId}`);
      if (response.status === 200) {
        commit("SET_DEVELOPMENT", response.data);
      } else {
        throw new Error('No access')
      }
    },
    async getProspects({ commit }, developmentId) {
      let prospects = await axios.get(
        `/api/development/${developmentId}/prospects`
      );
      console.log("prospektid: ", prospects.data);

      commit("SET_PROSPECTS", prospects.data.prospects);
    },
    toggleProspectsEditMode({ commit }) {
      commit("TOGGLE_PROSPECTS_EDIT_MODE");
    },
    cancelProspectsEditMode({ commit }) {
      commit("CANCEL_PROSPECTS_EDIT_MODE");
    },
    cancelProspectsAddRow({ commit }) {
      commit("CANCEL_PROSPECTS_ADD_ROW");
    },
    toggleProspectsAddRow({ commit }) {
      commit("TOGGLE_PROSPECTS_ADD_ROW");
    },
    setSelectedProspects({ commit }, payload) {
      commit("SET_SELECTED_PROSPECTS", payload);
    },
    saveAndAddAnotherProspect({ commit }) {
      commit("SAVE_AND_ADD_ANOTHER");
    },
    saveProspect({ commit }) {
      commit("SAVE_PROSPECT");
    },
    updateProspects({ commit }) {
      commit("UPDATE_PROSPECTS");
    },
  },
  getters: {
    prospects(state) {
      return state.prospects.prospects;
    },
    editModeProspects(state) {
      return state.prospects.editMode;
    },
    addRowProspects(state) {
      return state.prospects.addRow;
    },
    selectedProspects(state) {
      return state.prospects.selected;
    },
    prospectsRefresh(state) {
      return state.prospects.refresher;
    },
    addAnotherProspectSubmit(state) {
      return state.prospects.addAnotherProspectSubmit;
    },
    saveProspectSubmit(state) {
      return state.prospects.saveProspectSubmit;
    },
    updateProspectsSubmit(state) {
      return state.prospects.updateProspectsSubmit;
    },
  },
};

export default propDev;
